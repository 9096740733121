import React from "react";
import useStyles from "./styles";

interface Props {
	OnDragOver?: () => void;
	OnDrop: (data: string) => void;
	Sentence?: string;
}


const Layout: React.FC<Props> = (Props) => {

	const classes = useStyles();

	const OnDragOverHandle = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		if (Props.OnDragOver) {
			Props.OnDragOver();
		}
	}

	const OnDropHandle = (event: React.DragEvent<HTMLDivElement>) => {
		Props.OnDrop(event.dataTransfer.getData("text"));
	}

	return (
		<div className={classes.root} onDragOver={OnDragOverHandle} onDrop={OnDropHandle}>
			<div className={classes.description}>{Props.Sentence ? Props.Sentence : "Faites glissez vos éléments dans la zone"}</div>
			{Props.children}
		</div>
	);
}

export default Layout;
