import {
  ADD_TO_CART, UPDATE_CART, RESET_CART, UPDATE_COMMANDS, SET_RESTAURANT
} from './actionTypes';

import { ReducerData, Command, Dish } from "../../../types";

export interface CustomerState {
  commands: Command[]
  cart: { Dishes: { dish: Dish, commentaire?: string }[] }
  restaurant: string
}

const initialState: CustomerState = {
  commands: [],
  cart: {
    Dishes: [],
  },
  restaurant: "",
};

const customerReducer = (
  state: CustomerState = initialState,
  action: ReducerData
) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_TO_CART: {
      const dish = payload;
      state.cart.Dishes.push(dish);
      return state;
    }

    case UPDATE_CART: {
      const newState = state;
      newState.cart = payload;
      return newState;
    }

    case RESET_CART: {
      const newState = state;
      newState.cart = { Dishes: [] };
      return newState;
    }

    case SET_RESTAURANT: {
      const newState = state;
      const nom = payload;
      newState.restaurant = nom;
      return newState;
    }

    case UPDATE_COMMANDS: {
      const newState = state;
      newState.commands = payload;
      return newState;
    }

    default:
      return state;
  }
};

export default customerReducer;
