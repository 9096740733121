import React from "react";
import {
    AppBar,
	Button,
	CssBaseline,
    Link,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Toolbar,
	Typography,
} from "@material-ui/core";
import useStyles from "./styles";

import DescriptionForm from './DescriptionForm';
import DetailsForm from './DetailsForm';
import Review from './Review';

interface Props {
    
}

/* const InputDish = {
    name: "",
    description: "",
    price: "",
    category: "",
    picture: "",
    discounts: "",
    ingrédients: []
} */

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="/">
          SCOOKS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

const steps = ['Dish description', 'Dish details', 'Review your dish'];

function getStepContent(step : any) {
  switch (step) {
    case 0:
      return <DescriptionForm />;
    case 1:
      return <DetailsForm />;
    case 2:
      return <Review />;
    default:
      throw new Error('Unknown step');
  }
}

const Layout: React.FC<Props> = () => {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

	return (
        <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            SCOOKS
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Create Dish
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Your dish has been created.
                </Typography>
                <Typography variant="subtitle1">
                    Click on "" to access all the dishes. 
                    You will be redirected to...
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Create dish' : 'Next'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
	);
}

export default Layout;
