import React from "react";


interface Props {
}

const Review: React.FC<Props> = () => {

	return (
        <React.Fragment>
            Review
        </React.Fragment>
	);
}

export default Review;
