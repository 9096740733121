
export class FlagUtil {

    public static has(refValue:number, key: number): boolean {
        return !!(refValue & key);
    }

    public static add(refValue:number, key: number): number{
        return (refValue | key);
    }

    public static delete(refValue:number, key: number): number {
        return (refValue & ~key)
    }

    public toggle(refValue:number, key: number): number {
        return FlagUtil.has(refValue, key) ? FlagUtil.delete(refValue, key) : FlagUtil.add(refValue, key);
    }
};