import React, { Fragment, useCallback, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import { CssBaseline, Button, FormControlLabel, Divider, Grid, FormGroup, TextField, InputAdornment, Accordion, AccordionSummary, AccordionDetails, IconButton, CircularProgress, Paper } from "@material-ui/core";
import { APIManager } from "../../services";
import { BaseItem, CARD_ITEM_TYPE, DISPLAY_SHAPE_ITEM, ICard, ICategoryLink, ICategoryOrganization, IDish, IEditableIngredient, IIngredient, IMenu, TileData } from "../../types";
import { useSnackbar } from "notistack";
import { Formik } from "formik";
import * as Yup from "yup";
import { CardServices, DishServices } from "../../api";
import UploadImgZone from "../UploadImgZone";
import CancelIcon from '@material-ui/icons/Cancel';
import VerticalList from "../VerticalList";
import arrayMove from "array-move";
import { IMG_CONSTANTES } from "../../types/constantes/constantes";
import { v4 as uuidv4 } from 'uuid';
import { useMemo } from "react";
import IngredientMaker from "../IngredientMaker/IngredientMaker";
import { AddIcon } from "@material-ui/data-grid";
import { IOSSwitch } from "../IosSwitch/IosSwitch";
import httpStatus from "http-status";
import { NumberUtils } from "../../utils";
import { YumButton } from "../../Lib/UI/YumButton";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { SortableContainer } from "react-sortable-hoc";
import DishListItem from "./DishListItem";
import SortableItem from "../v2/SortableItem/SortableItem";
import _ from "lodash";
import HandlerDisplayTypeItem from "./HandlerDisplayTypeItem";
import TransfertDish, { ITransfertDishProps } from "./TransfertDish";

interface Props {
	card: ICard;
    category: ICategoryLink,
    cardCategories: ICategoryLink[],
    back: () => any,
}


enum STATE_PAGE_MANAGER {
	PAGE_DISH,
	EDIT_DISH,
	CREATDE_MENU,
	EDIT_MENU,
	CARD_MANAGER,
	HOME,
}

const ManageDigitalCard: React.FC<Props> = ({ card, category, back, cardCategories } : Props) => {
	const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    //let authContext = useContext(store);

    const [pageState, setPageState] = React.useState<STATE_PAGE_MANAGER>(STATE_PAGE_MANAGER.HOME);
    const [
        //dishEdit
        , setDishEdit] = React.useState<IDish | undefined>(undefined);
    const [currentCard, setCurrentCard] = React.useState<ICard | undefined>(undefined);
    const [currentOganization, setCurrentOganization] = React.useState<IDish[] | undefined>(undefined);

    const [items, setItems] = React.useState<{
        identifiant: string,
        removeItem: (index: string) => void,
        index: number,
        item?: IDish | IMenu,
        type: CARD_ITEM_TYPE,
    }[]>([]);
    const [sort, setSort] = React.useState<boolean>(false);
    //const [submitItems, setSubmitItems] = React.useState<boolean>(false);

    const removeComponentFromItemList = (index: string) => {

        let array = items.filter((x) => {
            return x.identifiant !== index;
        });
        setItems([...array]);
    }

    useEffect(() => {
        console.log("Launch get organization", currentOganization);
        if(currentOganization){
            return;
        }
        getCardOrganization(card._id!);
    }, [currentCard]);

    useEffect(() => {
        if(currentCard){
            return;
        }
        actualizeCard(card._id as string);
    }, []);

    const actualizeCard = async (idCard: string) => {

        try {
            let apiCard = APIManager.getCardServices();
            let actualizedCard = await apiCard.GetOneCard(idCard);

            setCurrentCard(actualizedCard);
        } catch (error) {
            enqueueSnackbar("Une erreur est survenue et la carte n'as pas pu être mise a jour, si l'erreur persiste contactez le service client", { 
                variant: "warning",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal:'right',
                }
            });
        } 
     
    }

    const getCardOrganization = async (idCard: string) => {

        try {
            let apiCategoryOrga = APIManager.getCategoryServices();
            let organization = await apiCategoryOrga.GetCategoryOrganization(category._id!, card._id!)

            console.log("Donnée chargé !! ", organization);
            setCurrentOganization(organization.organization as IDish[]);
        } catch (error) {
           console.log("erreor", error);
        } 
     
    }

    const GetOnlyDishFromCardItemFromCategory = useCallback(() => {
        console.log("change of those ", currentCard, currentOganization);
        if (!currentCard || !currentCard.items ) {
            console.log("something is missing bro !");
            return [];
        }
        
        let ItemsInCategory = GetOnlyCardItemInCategory().filter((cardItem) => cardItem.type === CARD_ITEM_TYPE.DISH && cardItem.dishes).map((cardItem) => cardItem.dishes as IDish);

        // Reorganisation des items pour les classer dans l'ordre
        //let concatenedArray = _.uniqBy(_.unionBy([...currentOganization], [...ItemsInCategory], '_id'), '_id');
        console.log("Oranisation supposé ", currentOganization?.map(x => x._id!))
        console.log("Oranisation actuel ", ItemsInCategory.map(x => x._id!))
        let firstSort = SortToOrganize(ItemsInCategory, currentOganization ?? [], (i: IDish) => i._id!);
        let nextSort =  SortToOrganize(currentOganization ?? [], ItemsInCategory, (i: IDish) => i._id!);

        console.log("first sort ", firstSort);
        console.log("next sort ", nextSort);

        if (nextSort.length !==  ItemsInCategory.length)
            ItemsInCategory = firstSort;
        else 
            ItemsInCategory = nextSort
        //console.log("concatenedArray", concatenedArray);

        //console.log("sorted list ", sortMarkets());
        return ItemsInCategory;
    }, [currentCard, currentOganization]);

    const GetOnlyMenuFromCardItemFromCategory = useCallback(() => {
        if (!currentCard || !currentCard.items) {
            return [];
        }
        return GetOnlyCardItemInCategory().filter((cardItem) => cardItem.type === CARD_ITEM_TYPE.MENU && cardItem.menus).map((cardItem) => cardItem.menus as IMenu);
    }, [currentCard?.items]);

    const GetOnlyCardItemInCategory = useCallback(() => {
        if (!currentCard || !currentCard.items) {
            return [];
        }
        return (currentCard.items).filter((cardItem) => {
            let baseItem: BaseItem | undefined = undefined;

            if (cardItem.type === CARD_ITEM_TYPE.DISH) {
                baseItem = cardItem.dishes as BaseItem;
            } else {
                baseItem = cardItem.menus as BaseItem;
            }
            return (baseItem.category as ICategoryLink)._id as string === category._id;
        });
    }, [currentCard]);

    const SortToOrganize = (array: any[], sortArray: any[], fnc: (i: any) => string) => {
        return [...array].sort(
            (a, b) => sortArray.indexOf(fnc(a)) - sortArray.indexOf(fnc(b))
        );
    }

    const GetDishByCategoryLength = useMemo(() => {
        return GetOnlyDishFromCardItemFromCategory().length;
    }, [GetOnlyDishFromCardItemFromCategory]);

    /* const GetMenuInCategoryLength = useMemo(() => {
        return GetOnlyMenuFromCardItemFromCategory().length;
    }, [GetOnlyMenuFromCardItemFromCategory]); */

    const dishToTile = useCallback(() => {
		return GetOnlyDishFromCardItemFromCategory().map((dish: IDish) => {
			return {
				keyTile: dish._id as string,
				picture: dish.picturePrincipal !== IMG_CONSTANTES.DEFAULT_IMG ? dish.picturePrincipal : IMG_CONSTANTES.DEFAULT_IMG,
				title: dish.name,
				price: dish.priceTTC,
			} as TileData;
		});
	}, [GetOnlyDishFromCardItemFromCategory]);

    const menuToTile = useCallback(() => {
		return GetOnlyMenuFromCardItemFromCategory().map((menu: IMenu) => {
			return {
				keyTile: menu._id as string,
				picture: menu.picturePrincipal !== IMG_CONSTANTES.DEFAULT_IMG ? menu.picturePrincipal : IMG_CONSTANTES.DEFAULT_IMG,
				title: menu.name,
				price: menu.priceTTC,
			} as TileData;
		});
	}, [currentCard?.items]);

    function DynamicComponent(props: any) {

		switch(pageState) {
            case STATE_PAGE_MANAGER.HOME:
            default:
                return <Home />;
        }
	}

    function DishItem(props: any) {
        return (<div></div>)
    }

    function Home(props: any) {

        const [isOpenTransfert, setIsOpenTransfert] = React.useState<boolean>(false);
        const [currentDishSelected, setCurrentDishSelected] = React.useState<IDish | undefined>(undefined);

        const handleEditDish = (id: string) => {
            console.log("Edit dish id ", id);
            if (!currentCard) {
                return;
            }
            let dish = GetOnlyDishFromCardItemFromCategory().find(x => x._id === id);
    
            if (dish) {
                //setDishEdit(dish);
                let newarray = [...items];

                newarray.push({ 
                    identifiant: uuidv4(), 
                    removeItem: removeComponentFromItemList,
                    index: items.length,
                    item: dish,
                    type: CARD_ITEM_TYPE.DISH,
                });
                setItems([...newarray]);
            } else {
                enqueueSnackbar("Impossible d'éditer le plat", { 
                    variant: "warning",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }
        }

        const handleDuplicateDish = (id: string) => {
            console.log("DUplicate dish id ", id);
            if (!currentCard) {
                return;
            }
            let dish = GetOnlyDishFromCardItemFromCategory().find(x => x._id === id);
    
            if (dish) {
                let apicard: DishServices = APIManager.getDishServices();

                apicard.Duplicate(id, card._id!).then((d) => {
                    console.log("OKKK");
                    actualizeCard(card._id!);
                }).catch((d) => {
                    console.log("error", d);
                });
                
            } else {
                enqueueSnackbar("Impossible d'éditer le plat", { 
                    variant: "warning",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }
        }

        const handleCloseTransfertDish = (isOpen: boolean) => {
            actualizeCard(currentCard?._id!);
            setIsOpenTransfert(isOpen);
        }

        const handleTransfertDish = (idDish: string) => { 
            if (!currentCard) {
                return;
            }
            let dish = GetOnlyDishFromCardItemFromCategory().find(x => x._id === idDish);
    
            if (dish) {
                setCurrentDishSelected(dish);
                setIsOpenTransfert(true);
                
            } else {
                enqueueSnackbar("Impossible de transférer le plat", { 
                    variant: "warning",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }
        }

        /* const handleEditMenu = (id: string) => {
            if (!currentCard) {
                return;
            }
            let menu = GetOnlyMenuFromCardItemFromCategory().find(x => x._id === id);
    
            if (menu) {
                //setDishEdit(dish);
                let newarray = [...items];

                newarray.push({ 
                    identifiant: uuidv4(), 
                    removeItem: removeComponentFromItemList,
                    index: items.length,
                    item: menu,
                    type: CARD_ITEM_TYPE.MENU,
                });
                setItems([...newarray]);
            } else {
                enqueueSnackbar("Impossible d'éditer le plat", { 
                    variant: "warning",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }
        } */

        function deleteDish(idDish: string) {
            let apicard: CardServices = APIManager.getCardServices();
    
            apicard.deleteDishFromCard(idDish, currentCard?._id as string).then(async (res) => {
                if (!res) {
                    enqueueSnackbar("La suppression du plat n'as été effectué que partiellement.", { 
                        variant: "warning",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                } else {
                    enqueueSnackbar("Le plat selectionné a été supprimé.", { 
                        variant: "success",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                }
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar("Une erreur est survenue contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }).finally(() => {
                actualizeCard(card._id as string);
            });
        }

        const onSortEnd = ({oldIndex, newIndex}: any) => {

            let ItemsInCategory = GetOnlyDishFromCardItemFromCategory();


            if (!ItemsInCategory || ItemsInCategory.length === 0) {
                return;
            }
    
            let newOrder = arrayMove(ItemsInCategory, oldIndex, newIndex);
    
            APIManager.getCategoryServices().setOrganization(category._id!, card._id!, newOrder.map(x => x._id as string))
            .then((result) => {
                if (result) {
                    enqueueSnackbar(`L'ordre de la categorie ${category.name} à été mis à jour.`, { 
                        variant: "success",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                    setCurrentOganization(newOrder);
                } else {
                    enqueueSnackbar(`une erreur inconnue est survenue. Si le problème persiste contactez le service client.`, { 
                        variant: "warning",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                }
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar(`une erreur inconnue est survenue. Si le problème persiste contactez le service client.`, { 
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });			
            })
        }

        function deleteMenu(idMenu: string) {
            let apicard: CardServices = APIManager.getCardServices();
    
            apicard.deleteMenuFromCard(idMenu, currentCard?._id as string).then(async (res) => {
                if (!res) {
                    enqueueSnackbar("La suppression du menu n'as été effectué que partiellement.", { 
                        variant: "warning",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                } else {
                    enqueueSnackbar("Le menu selectionné a été supprimé.", { 
                        variant: "success",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                }
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar("Une erreur est survenue contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }).finally(() => {
                //actualizeCard()
                actualizeCard(card._id as string);
            });
        }
        return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography 
                component="span" 
                variant="body1"
                style={{ width: "100%"}}
                align="center"
                gutterBottom>
                Ajoutez, Modifiez, ou Supprimez des élements de la catégorie 
                "<Typography
                    className={classes.tagline} 
                    component="span" 
                    variant="subtitle1">
                    {category.name}
                </Typography>" de votre carte
            </Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {items.map((component) => {
                    return (<div key={component.identifiant}>
                        {component.type === CARD_ITEM_TYPE.DISH && React.createElement(AddDish, 
                            { 
                                identifiant: component.identifiant, 
                                removeItem: component.removeItem,
                                index: component.index,
                                Dish: component.item,
                                
                            })}
                        {component.type === CARD_ITEM_TYPE.MENU && React.createElement(AddMenu, 
                            { 
                                identifiant: component.identifiant, 
                                removeItem: component.removeItem,
                                index: component.index,
                                Menu: component.item,
                                
                            })}
                        <Divider />
                    </div>);
                })}
            </div>
            <div
                style={{ 
                    display: "flex", 
                    flexDirection: "row", 
                    justifyContent: "space-around",
                    padding: "2vh 0" 
                }}>
                <Button 
                    onClick={() => {
                        //let newarray = [...items];

                        setItems([...items, { 
                            identifiant: uuidv4(), 
                            removeItem: removeComponentFromItemList,
                            index: items.length,
                            type: CARD_ITEM_TYPE.DISH,
                        }]);
                    }}
                    style={{ margin: "0 1vh"}}
                    variant="contained"
                    color="secondary">
                    Ajouter un élements +
                </Button>
                {/*<Button
                    onClick={() => {
                        //let newarray = [...items];

                        setItems([...items, { 
                            identifiant: uuidv4(), 
                            removeItem: removeComponentFromItemList,
                            index: items.length,
                            type: CARD_ITEM_TYPE.MENU,
                        }]);
                    }}
                    style={{ margin: "0 1vh"}}
                    variant="contained" 
                    color="secondary">
                    Ajouter un Menu +
                </Button>
                {<Button
                    onClick={() => {
                        setSubmitItems(true);
                    }}
                    variant="contained" 
                    color="secondary">
                    Enregistrement
                </Button>*/}
            </div>
            <div>
                <Typography component="h5" variant="subtitle1" gutterBottom><span role="img" aria-label="img">🥙</span><span role="img" aria-label="img">🍱</span> Les plats de la catégorie <span role="img" aria-label="img">🌮</span><span role="img" aria-label="img">🍔</span></Typography>
                <div style={{ margin: "2vh 2vw", width: "100%"}}>
                    {/*<SearchBarDish
                        OnCategorySelected={(category) => {}}
                        OnChangeSearch={(searchText) => { lookingForDishes(searchText); }}
                        OnKeyDownEnter={(searchText) => { lookingForDishes(searchText); }}
                        dataSelect={["test"]}
                        label={"Categorie"}
                        displayLogo={false}
                    /> */}
                    <YumButton
                        onClick={() => { setSort(!sort); }}
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", marginBottom:"10px" }}>
                        <Typography
                            component="span" 
                            variant="body1" 
                            style={{ marginRight: "5px" }}>Trier</Typography>
                        {sort && <LockOpenIcon />}
                        {!sort && <LockIcon />}
                    </YumButton>
                </div>
                <div style={{ margin: "2vh 2vw", width: "100%"}}>
                    {/*GetDishByCategoryLength > 0 && !sort && 
                    (<VerticalList data={dishToTile()}
                        onItemClick={(idDish: any) =>  {
                            handleEditDish(idDish);
                            //setPageState(STATE_PAGE_MANAGER.PAGE_DISH);
                        }}
                        OnCreateClick={() => {
                            setDishEdit(undefined);
                            setPageState(STATE_PAGE_MANAGER.PAGE_DISH);
                        }}
                        OnDeleteClick={(idDish: any) => deleteDish(idDish)}
                        displayAddButton={false}
                    ></VerticalList>)*/}
                    {GetDishByCategoryLength > 0 && !sort &&
                    (dishToTile().map((d, i) => <DishListItem key={`dish-item-${i}`} id={d.keyTile} 
                                                            title={d.title!} price={d.price!} 
                                                            onItemDoubleClick={handleEditDish} 
                                                            onItemDelete={deleteDish}
                                                            onDuplicate={handleDuplicateDish}
                                                            onTransfert={handleTransfertDish}
                                                            disabled={false} />))}
                    {GetDishByCategoryLength > 0 && sort &&
                    (<SortableItem OnSortEnd={onSortEnd}>
                        {dishToTile().map((d, i) => <DishListItem key={`dish-item-${i}`} id={d.keyTile} 
                                                            title={d.title!} price={d.price!} 
                                                            onItemDoubleClick={handleEditDish} 
                                                            onItemDelete={deleteDish}
                                                            disabled={sort} />)}
                    </SortableItem>
                        )}
                   {GetDishByCategoryLength === 0 && 
                        (<Typography
                            component="span" 
                            variant="body1"
                            style={{ width: "100%"}}
                            align="center"
                            gutterBottom>
                            La catégorie "<span className={classes.tagline}>{category.name}</span>" ne possède aucun plat.
                        </Typography>)}
                    {isOpenTransfert && <TransfertDish cardId={currentCard!._id!} currentCategoryId={currentDishSelected!.category as string} 
                    dishId={currentDishSelected!._id!} isOpen={isOpenTransfert} setIsOpen={handleCloseTransfertDish} />}
                </div>
            </div>
            {/*<div>
                <Typography component="h5" variant="h3"><span role="img" aria-label="img">🍣</span><span role="img" aria-label="img">🍖</span> Les menus de la catégorie <span role="img" aria-label="img">🍕</span><span role="img" aria-label="img">🌭</span></Typography>
                <div style={{ margin: "2vh 2vw"}}>
                    <SearchBarDish
                        OnChangeSearch={(category) => {}}
                        OnKeyDownEnter={(category) => {}}
                        label={""}
                        displayLogo={false}
                        placeholder={"Recherche un menu grace à son nom"}
                    />
                </div>
                <div style={{ margin: "2vh 2vw", width: "100%"}}>
                    {GetMenuInCategoryLength > 0 &&  (<VerticalList
                        data={menuToTile()}
                        onItemClick={(idMenu: any) =>  {
                            handleEditMenu(idMenu);
                        }}
                        OnCreateClick={() => {
                            //setDishEdit(undefined);
                            //setPageState(STATE_PAGE_MANAGER.PAGE_DISH);
                        }}
                        OnDeleteClick={(idMenu: any) => deleteMenu(idMenu) }
                        displayAddButton={false}
                    ></VerticalList>)}
                    {GetMenuInCategoryLength === 0 && 
                        (<Typography
                            component="span" 
                            variant="h6"
                            style={{ width: "100%"}}
                            align="center"
                            gutterBottom>
                            La catégorie "<Typography component="span" className={classes.tagline}>{category.name}</Typography>" ne possède aucun menu.
                        </Typography>)}
                </div>
            </div>*/}
        </div>)
    }

	return (
		<Fragment>
			<CssBaseline />
            <DynamicComponent />
		</Fragment>
	);
    

    /**
     * Composant de création et d'ajout rapide d'un plat
     * @param props 
     * @returns 
     */
    function AddDish(props: any) {
        const [isSubmited, setIsSubmited] = React.useState<boolean>(false);

        //const [isAvailable, setAvailable] = React.useState(props.Dish ? props.Dish : true);
        const [imagePrincipale, setImagePrincipal] = React.useState<File | null>(null);
        //const [gallery, setGallery] = React.useState<File[]>([]);
	    //const [category, setCategory] = React.useState<string>(props.Dish ? ((props.Dish.category as ICategoryLink).category as ICategory).name : "");
	    //const [HTprice, setHTprice] = React.useState<number>(props.Dish ? props.Dish.priceHT : 0.00);
        //const [TTCprice, setTTprice] = React.useState<number>(props.Dish ? props.Dish.priceTTC : 0.00);
	    //const [description, setDescription] = React.useState(props.Dish ? props.Dish.description : '');
	    const [ingredients, setIngredient] = React.useState<IEditableIngredient[]>(props.Dish ? props.Dish.ingredients : []);
	    //const [name, setName] = React.useState<string>(props.Dish ? props.Dish.name : '');
        //const [schedules, setSchedules] = React.useState<IDiscountSchedule[]>(props.Dish ? props.Dish.discountSchedule : []);
        const [volumetrie, setVolumetrie] = React.useState<{ identifiant: string, price: number | undefined, declination: string | undefined }[]>(props.Dish && ((props.Dish as IDish).metadata as any)?.drinkMeta?.volumes ? ((props.Dish as IDish).metadata as any)?.drinkMeta?.volumes : []);

        const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
        const [isFinishSubmit, 
            //setIsFinishSubmit
        ] = React.useState<boolean>(false);
        const [expanded, setExpanded] = React.useState<string | undefined>(undefined);
        const formRef = React.useRef<HTMLFormElement>(null);

        const handleChange = (event: React.ChangeEvent<{}>, isExpanded: boolean, accordionName: string) => {
            setExpanded(isExpanded ? accordionName : undefined);
        };

        const HandleChangeOnTTCPrice = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fnc:  (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
            let valueField = parseFloat(event.target.value);
            
            if (isNaN(valueField)) {
                return;
            }
            let valueHT = parseFloat((valueField * (100/(100+20))).toFixed(2));
            fnc('_HTprice', valueHT, true);
        }

        const updateDish = (values: {
            name: string;
            description: string | undefined;
            available: boolean;
            _HTprice: number;
            _TTCprice: number;
            displayShape: DISPLAY_SHAPE_ITEM;
        }, metadata: object) => {

            let dataDish: IDish = {
                name: values.name,
                description: values.description ? values.description : undefined,
                priceHT: NumberUtils.toFixedNumber(values._TTCprice, 2),
                priceTTC: NumberUtils.toFixedNumber(values._TTCprice, 2),
                category: category._id as string,
                ingredients: ingredients.map(x => { return { 
                    ingredient: {
                        name: (x.ingredient as IIngredient).name,
                        category: (x.ingredient as IIngredient).category,
                    },
	                editable: x.editable,
                } as IEditableIngredient}),
                available:  values.available,
                discountSchedule: props.Dish.discountSchedule,
                additionnalData: props.Dish.additionnalData,
                archived: props.Dish.archived,
                picturePrincipal: props.Dish.picturePrincipal,
                trader: props.Dish.trader,
                metadata: metadata,
                displayShape: values.displayShape,
            };

            setIsRequesting(true);
            APIManager.getDishServices().uploadDishSchedule(props.Dish._id, dataDish)
            .then((newDish) => {

                removeComponentFromItemList(props.identifiant);

                if (imagePrincipale) {
                    let form = new FormData();
    
                    form.append("principal", imagePrincipale as File); 
                    APIManager.getDishServices().UploadImg(props.Dish._id, form)
                    .then((result) => {     
                        if (result) {
                            enqueueSnackbar(`L'image du plat "${values.name}" à été mis à jour`, { 
                                variant: "success",
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal:'right',
                                }
                            });
                            return;
                        }
                        enqueueSnackbar(`L'image du plat "${values.name}" n'a pas pu être mis à jour `, { 
                            variant: "warning",
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal:'right',
                            }
                        });
                       
                    })
                    .catch((err) => {
                        enqueueSnackbar(`Une erreur inconnue est survenue. Merci de contacter le service client si l'erreur persiste.`, { 
                            variant: "error",
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal:'right',
                            }
                        });
                    }).finally(() => {
                        console.log("Try to load new data");
                        getCardOrganization(card._id!);
                        actualizeCard(card._id as string);
                        setIsRequesting(false);
                    });
                } else  {
                    setIsRequesting(false);
                }
                enqueueSnackbar(`Le plat "${values.name}" à été mis à jour`, { 
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            })
            .catch((err) => {
                enqueueSnackbar(`Le plat "${values.name}" n'a pas pu être mis à jour`, { 
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
                setIsRequesting(false);
            }).finally(() => {
                getCardOrganization(card._id!);
                actualizeCard(card._id as string);
                setIsRequesting(false);
            });          
        };

        const createDish = (form: FormData) => {

            setIsRequesting(true);
            APIManager.getCardServices().createDishScheduleNew(form, currentCard?._id as string).then((value: Boolean) => {
                if (value) {
                    enqueueSnackbar(`Le plat a été crée`, { 
                        variant: "success",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                    removeComponentFromItemList(props.identifiant);
                    return;
                } else {
                    enqueueSnackbar(`Le plat à été crée mais toutes les images n'ont pas été enregistré`, { 
                        variant: "warning",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                }   
            }).catch((err: Error) => {

                console.log(err);

                enqueueSnackbar(err.message.indexOf(`${httpStatus.PARTIAL_CONTENT}`) > -1 ? "Le plat a été mis a jour sans son image" : "La création du plat a échoué, veuillez ré-essayer ? si le probleme persiste contactez le service client", { 
                    variant: err.message.indexOf(`${httpStatus.PARTIAL_CONTENT}`) > -1 ? "warning" : "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }).finally(() => {
                getCardOrganization(card._id!);
                actualizeCard(card._id as string);
                setIsRequesting(false);
            });

        }

        /* const handleAddSchedule = (item: IDiscountSchedule) => {
            console.log("Add discount :", item);
        } */


        /* const handleReductionClose = (key: number) => {
            let newdiscounts = discount.filter((val: IDiscountSchedule, keyTab: number) => {
                if (key === keyTab) {
                    return false;
                }
                return true;
            });
            setDiscount([...newdiscounts])
        } */

        useEffect(() => {
            if (props.submitAll === true && isFinishSubmit === false) {
                console.log('launch form submit');
                //handleSubmit();
            }
        }, [props.submitAll, isFinishSubmit]);

        return (
        <div style={{ position: "relative" }} >
            <IconButton 
                color="secondary" 
                onClick={(event) => {
                    removeComponentFromItemList(props.identifiant);
                }}
                style={{ 
                    position: "absolute", 
                    right: "5px", 
                    zIndex: 2 
                }} 
                aria-label="settings">
                <CancelIcon />
            </IconButton>
            <Typography style={{ margin: "1vh 0"}} variant="h5" component="h3" align="center" gutterBottom>PLAT {props.index}</Typography>
            <Formik
                initialValues={{ 
                    name: props.Dish ? props.Dish.name : '',
                    description: props.Dish ? props.Dish.description : '',
                    _HTprice: props.Dish ? props.Dish.priceHT : 0.00,
                    _TTCprice: props.Dish ? props.Dish.priceTTC : 0.00,
                    available: props.Dish ? props.Dish.available : true,
                    displayShape: props.Dish ? props.Dish.displayShape : DISPLAY_SHAPE_ITEM.Square,
                }}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(500).required("Un intitulé pour votre plat doit être fournis."),
                    description: Yup.string(),
                    _TTCprice: Yup.number().min(0.1, "Votre prix TTC doit être supérieur a 0.").required("Un prix TTC doit être indiqué."),
                    _HTprice: Yup.number().min(0.1, "Votre prix TTC doit être supérieur a 0.").required("Le prix ht n'est pas fixé"),
                    available: Yup.bool().default(true),
                    displayShape: Yup.mixed<DISPLAY_SHAPE_ITEM>().oneOf(Object.values(DISPLAY_SHAPE_ITEM)).required('Choose a shape for your items'),
                })}
                onSubmit={(values) => {
                    //let userId = authContext.authObj.userProfile != {} ? (authContext.authObj.userProfile as ITrader)._id as string : "";
                    let form = new FormData();
                    let metadata = {
                        drinkMeta: {
                            type: "drink",
                            volumes: volumetrie.filter((x) => x.declination && x.declination !== "" && x.price),
                        }
                    }
                    //setIsFinishSubmit(false);
                    
                    console.log('valuuuue !', values);
                    if (props.Dish) {
                        updateDish(values, metadata);
                    } else {
                        form.append("name", values.name);
                        form.append("description", values.description);
                        form.append("priceHT", values._HTprice.toString());
                        form.append("priceTTC", values._TTCprice.toString());
                        form.append("category", category._id as string);
                        form.append("ingredients", JSON.stringify(ingredients));
                        form.append("available", JSON.stringify(true));
                        form.append("discountSchedule", JSON.stringify([]));
                        form.append("principal", imagePrincipale as File); 
                        form.append("additionnalData", JSON.stringify([]));
                        form.append("metadata", JSON.stringify(metadata));
                        form.append("displayShape", values.displayShape as string);

                        createDish(form);
                    }
                   
                }}>
                {formProps => (
                    <form ref={formRef} className={classes.formElement} onSubmit={(e) => {
                        e.preventDefault();
                        formProps.handleSubmit();
                      }}>
                        <article>
                            <Grid container spacing={4} alignItems="center" justify="center" >
                                <Grid 
                                    item 
                                    component='div'
                                    xs={6} 
                                    sm={6} 
                                    md={6}>
                                    <FormGroup>
                                        <TextField
                                            id="name"
                                            type="text"
                                            label="Intitulé du plat*"
                                            name="name"
                                            placeholder="Exemple: Poke végétarien, Bière pression, ..."
                                            fullWidth
                                            variant="outlined"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.name}
                                            error={isSubmited && formProps.errors.name ? true : false}
                                            helperText={isSubmited && formProps.errors.name}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid 
                                    item 
                                    component='div'
                                    xs={6} 
                                    sm={6} 
                                    md={6}>
                                    <TextField
                                        id="_TTCprice"
                                        name="_TTCprice"
                                        label="Prix TTC"
                                        placeholder="Exemple: 8.67"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        value={formProps.values._TTCprice}
                                        onChange={(e) => {
                                            HandleChangeOnTTCPrice(e, formProps.setFieldValue);
                                            formProps.handleChange(e);
                                        }}
                                        onBlur={formProps.handleBlur}
                                        error={isSubmited && formProps.errors._TTCprice ? true : false}
                                        helperText={isSubmited && formProps.errors._TTCprice}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment style={{}} position="end">
                                                    €
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        />
                                </Grid>
                                <Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <TextField
                                        id="description"
                                        type="text"
                                        label="Décrivez votre plat"
                                        name="description"
                                        fullWidth
                                        variant="outlined"
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values.description}
                                        placeholder="Exemple: Plat hawaien à base de coriandre"
                                        error={isSubmited && formProps.errors.description ? true : false}
                                        helperText={isSubmited && formProps.errors.description}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                    />
                                </Grid>
                                <Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <Button
                                            style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center",marginBottom: "3vh" }}
                                            color="secondary"
                                            >
                                            <Typography
                                                component="span" 
                                                variant="body2"
                                                onClick={() => {
                                                    setVolumetrie([...volumetrie, {
                                                        identifiant: uuidv4(), 
                                                        declination: undefined,
                                                        price: undefined
                                                    }]);
                                                }}
                                                style={{ marginRight: "5px" }}>Ajouter une déclinaison</Typography>
                                            <AddIcon />
                                        </Button>
                                        <Grid 
                                            container
                                            direction="row"
                                            spacing={4}
                                            style={{ width: "100%" }}
                                            alignItems="flex-start">
                                            {volumetrie.map((volume, index) => 
                                                <Grid 
                                                    item
                                                    key={index}
                                                    style={{ display: "flex", justifyContent: "center", alignItems: "center",
                                                        position: "relative",border: "1px solid black", padding: "20px"}}
                                                    component='div'
                                                    xs={12} 
                                                    sm={6} 
                                                    md={3}>
                                                    <IconButton 
                                                        color="secondary" 
                                                        onClick={(event) => {
                                                            let filteredArray = volumetrie.filter((x) => x.identifiant !== volume.identifiant);
                                                            
                                                            setVolumetrie([...filteredArray]);
                                                        }}
                                                        style={{ 
                                                            position: "absolute", 
                                                            right: 0,
                                                            top: 0,
                                                            zIndex: 2 
                                                        }} 
                                                        aria-label="settings">
                                                        <CancelIcon />
                                                    </IconButton>
                                                    <TextField
                                                        id="declination"
                                                        name="declination"
                                                        style={{ paddingRight: "5px"}}
                                                        defaultValue={volume.declination}
                                                        label="Déclinaison"
                                                        placeholder="Exemple: 75cl"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                            volume.declination = event.target.value;
                                                        }}
                                                        onBlur={(event) => {
                                                            console.log()
                                                        }}
                                                        fullWidth
                                                        />
                                                    <TextField 
                                                        id="price"
                                                        name="price"
                                                        defaultValue={volume.price}
                                                        style={{ paddingLeft: "5px"}}
                                                        label="Prix TTC"
                                                        onChange={(event) => {
                                                            try {
                                                                let valueField = parseFloat(event.target.value);
                                                                if (isNaN(valueField)) {
                                                                    return;
                                                                }
                                                                volume.price = NumberUtils.toFixedNumber(valueField, 2);
                                                            } catch (err) {
                                                                console.log("event prix", err);
                                                                return;
                                                            }
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment style={{}} position="end">
                                                                    €
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        error={volume.price !== undefined && isNaN(parseFloat(volume.price.toString())) ? true : false}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        placeholder="Exemple: 8.67"
                                                        fullWidth/>
                                                </Grid>)}
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <Accordion expanded={expanded === 'panel1'} onChange={(ev, isExpanded) => {
                                        handleChange(ev, isExpanded, 'panel1');
                                    }}>
                                        <AccordionSummary
                                            expandIcon={<Typography><span role="img" aria-label="img">📷</span></Typography>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            >
                                            <Typography
                                                variant="subtitle1" 
                                                component="span">
                                                    Image principal
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ display: "flex", flexDirection: "column"}}>
                                            <HandlerDisplayTypeItem 
                                                defaultValue={formProps.values.displayShape} 
                                                onChange={(v) => { formProps.setFieldValue('displayShape', v, true);}}/>
                                            <UploadImgZone 
                                                minHeight={200}
                                                minWidth={350}
                                                shape={formProps.values.displayShape}
                                                fileLoaded={(props.Dish as IDish)?.picturePrincipal}
                                                onFileChange={(fileEv) => setImagePrincipal(fileEv)}
                                                onGalleryChange={(files) => {}}
                                                titleGallery="Images secondaires"
                                                titlePrincipal="Image principale" />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <Accordion expanded={expanded === 'panel2'} onChange={(ev, isExpanded) => {
                                        handleChange(ev, isExpanded, 'panel2');
                                    }}>
                                        <AccordionSummary
                                        expandIcon={<Typography><span role="img" aria-label="img">👁️</span></Typography>}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        >
                                            <Typography 
                                                variant="subtitle1" 
                                                component="span">
                                                Ingrédients
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <IngredientMaker 
                                                ingredients={ingredients}
                                                updateIngredients={(ingredient) => {
                                                    setIngredient(ingredient);
                                                }} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/*<Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <Accordion expanded={expanded === 'panel3'} onChange={(ev, isExpanded) => {
                                        handleChange(ev, isExpanded, 'panel3');
                                    }}>
                                        <AccordionSummary
                                        expandIcon={<Typography>👁️</Typography>}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        >
                                            <Typography 
                                                variant="h4" 
                                                component="span">
                                                Réductions
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <AdvancedSchedule 
                                                data={schedules} 
                                                onItemClick={(key: number) => {
                                                    console.log('La reduction ' + key + ' a été clickez!');
                                                }}
                                                onAdd={handleAddSchedule}
                                                onDelClick={handleReductionClose} />
                                        </AccordionDetails>
                                    </Accordion>
                                            </Grid>*/}
                                {props.Dish && <Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <FormControlLabel
                                            control={<IOSSwitch
                                                id="available"
                                                checked={formProps.values.available}
                                                name="available" />}
                                            label="Disponibilité"
                                            labelPlacement="start"
                                            onChange={formProps.handleChange}/>
                                </Grid>}     
                                <Grid 
									item 
									component='div'
									xs={12} 
									sm={12} 
									md={12}>
									<Button 
										onClick={(event) => {
											setIsSubmited(true);
										}} 
										type="submit" 
										variant="contained" 
										color="secondary"
                                        disabled={isRequesting}
										fullWidth>
										{!isRequesting && props.Dish ? "Editer" : "Créer"} {!isRequesting &&formProps.values.name === "" ? `plat ${props.index}` : formProps.values.name}
                                        {isRequesting ? <CircularProgress /> : ""}
									</Button>
								</Grid>                           
                                                         
                            </Grid>
                        </article>
                    </form>
                )}
            </Formik>
        </div>);
    }

    function AddMenu(props: any) {

        const menuToTile = (dishesArray: IDish[]) => {
            return dishesArray.map((dish) => {
                return {
                    keyTile: dish._id as string,
                    picture: dish.picturePrincipal,
                    title: dish.name,
                    price: dish.priceTTC,
                } as TileData;
            })
        }

        const [isSubmited, setIsSubmited] = React.useState<boolean>(false);

        //const [isAvailable, setAvailable] = React.useState(props.Menu ? props.Menu : true);
        const [imagePrincipale, setImagePrincipal] = React.useState<File | null>(null);
        //const [schedules, setSchedules] = React.useState<IDiscountSchedule[]>(props.Menu ? props.Menu.discountSchedule : []);

        const [
            //isRequesting
            , setIsRequesting] = React.useState<boolean>(false);
        //const [isFinishSubmit, setIsFinishSubmit] = React.useState<boolean>(false);
        const [expanded, setExpanded] = React.useState<string | undefined>(undefined);
        const [dishesInMenu, 
            //setDishesInMenu
        ] = React.useState<TileData[]>(props.Menu ?  menuToTile((props.Menu as IMenu).dishes as IDish[]): []);
        //const [baseDishes, setBaseDishes] = React.useState<TileData[]>(dishToTile());
        //const [searchDishes, setSearchDishes] = React.useState<TileData[]>(baseDishes);
        //const [selectedCategory, setSelectedCategory] = React.useState<ICategory | undefined>(undefined);

        const formRef = React.useRef<HTMLFormElement>(null);

        const handleChange = (event: React.ChangeEvent<{}>, isExpanded: boolean, accordionName: string) => {
            setExpanded(isExpanded ? accordionName : undefined);
        };

        

        const HandleChangeOnTTCPrice = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fnc:  (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
            let valueField = parseFloat(event.target.value);
            
            if (isNaN(valueField)) {
                return;
            }
            let valueHT = parseFloat((valueField * (100/(100+20))).toFixed(2));
            fnc('_HTprice', valueHT, true);
        }

        const updateMenu = (values: {
            name: string;
            description: string | undefined;
            _HTprice: number;
            _TTCprice: number;
            available: boolean;
            displayShape: DISPLAY_SHAPE_ITEM;
        }) => {

            let dataMenu: IMenu = {
                name: values.name,
                description: values.description ? values.description : undefined,
                priceHT: values._HTprice,
                priceTTC: values._TTCprice,
                category: category._id as string,
                dishes: dishesInMenu.length > 0 ? dishesInMenu.map(x => x.keyTile) : [],
                available:  values.available,
                discountSchedule: props.Menu.discountSchedule,
                additionnalData: props.Menu.additionnalData,
                archived: props.Menu.archived,
                picturePrincipal: props.Menu.picturePrincipal,
                trader: props.Menu.trader,
                displayShape: values.displayShape,
            };

            setIsRequesting(true);
            APIManager.getMenuServices().uploadMenuSchedule(props.Menu._id, dataMenu)
            .then(() => {
                enqueueSnackbar(`Le plat "${values.name}" à été mis à jour`, { 
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
                removeComponentFromItemList(props.identifiant);
                if (imagePrincipale) {
                    let form = new FormData();
    
                    form.append("principal", imagePrincipale as File); 
                    APIManager.getMenuServices().UploadImg(props.Menu._id, form)
                    .then((result) => {             
                        if (result) {
                            enqueueSnackbar(`L'image du menu "${values.name}" à été mis à jour`, { 
                                variant: "success",
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal:'right',
                                }
                            });
                            removeComponentFromItemList(props.identifiant);
                            return;
                        }
                        enqueueSnackbar(`L'image du menu "${values.name}" n'a pas pu être mis à jour `, { 
                            variant: "warning",
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal:'right',
                            }
                        });
                    })
                    .catch((err) => {
                        enqueueSnackbar(`Une erreur inconnue est survenue. Merci de contacter le service client si l'erreur persiste.`, { 
                            variant: "error",
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal:'right',
                            }
                        });
                    }).finally(() => {
                        actualizeCard(card._id as string);
                        setIsRequesting(false);
                    });
                } else {
                    actualizeCard(card._id as string);
                    setIsRequesting(false);
                }
            })
            .catch((err) => {
                enqueueSnackbar(`Le plat "${values.name}" n'a pas pu être mis à jour`, { 
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
                setIsRequesting(false);
            });
           
        };

        const createMenu = (form: FormData) => {

            APIManager.getCardServices().createMenuScheduleForm(form, currentCard?._id as string).then((value: Boolean) => {
                enqueueSnackbar(value ? "Le menu a été crée " : "Le menu a été crée mais toutes les images n'ont pas été enregistré", { 
                    variant: value ? "success" : "info",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                    
                });
                if (value) {
                    enqueueSnackbar(`Le menu a été crée `, { 
                        variant: "success",
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal:'right',
                        }
                    });
                    removeComponentFromItemList(props.identifiant);
                    return;
                }
                enqueueSnackbar(`Le menu a été crée mais toutes les images n'ont pas été enregistré`, { 
                    variant: "warning",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }).catch((err) => {
                enqueueSnackbar("La création du menu a échoué voulez vous ré-essayer ? si le probleme persiste contactez le service client", { 
                    variant: "error",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }).finally(() => {
                actualizeCard(card._id as string);
                setIsRequesting(false);
            });

        }

        /* const OnDrop = (data: string) => {
            let droppedDish = dishToTile().find((x) => {
                if (x.keyTile === data) {
                    return true;
                }
                return false;
            });
            console.log('Dish in menu on drop', dishesInMenu)
            if (droppedDish && !dishesInMenu.find(x => x.keyTile === data)) {
                setDishesInMenu([...dishesInMenu, droppedDish]);
            }
        } */

        /* const handleDelete = (event: any, key: string) => {
            let newDishInMenu = dishesInMenu.filter(x => x.keyTile !== key);

            setDishesInMenu([...newDishInMenu]);
            let tileSelected = selectedTileDish.find(x => x.keyTile === key);
    
            if (tileSelected) {
                let updatedSelectedTile = selectedTileDish.filter(x => x.keyTile !== key);
    
                setSelectedTileDish([...updatedSelectedTile]);
                setTileDish([...tileDish, tileSelected]);
            }
        }; */

        /* const OnCategorySelected = (category: any) => {
            if (category?._id === undefined) {
                return;
            }
            setSelectedCategory(category as ICategory);
            let dishesFromCategory = GetOnlyDishFromCardItemFromCategory().filter((dish) => {
                if ((dish.category as ICategoryLink)._id === (category as ICategory)._id) {
                    return true;
                }
                return false;
            }).map(x => ({
				keyTile: x._id as string,
				picture: x.picturePrincipal !== IMG_CONSTANTES.DEFAULT_IMG ? x.picturePrincipal : IMG_CONSTANTES.DEFAULT_IMG,
				title: x.name,
				price: x.priceTTC,
			}));

            console.log("New dish search : ", dishesFromCategory);
            setSearchDishes([...dishesFromCategory]);
        } */
    
        /* const OnKeyDownEnter = (searchText: string) => {
            let searchTiles = tileDish.filter((tile) => {
                if (tile.title && tile.title.indexOf(searchText) !== -1) {
                    return true;
                }
                return false;
            });
            setSearchTile([...searchTiles]);
        } */

        /* const OnChangeSearch = (searchText: string) => {
            console.log("Search :" + searchText);

            if (searchText === '') {
                let dishesFromCategory = GetOnlyDishFromCardItemFromCategory().filter((dish) => {
                    if ((dish.category as ICategoryLink)._id === category._id) {
                        return true;
                    }
                    return false;
                }).map(x => ({
                    keyTile: x._id as string,
                    picture: x.picturePrincipal !== IMG_CONSTANTES.DEFAULT_IMG ? x.picturePrincipal : IMG_CONSTANTES.DEFAULT_IMG,
                    title: x.name,
                    price: x.priceTTC,
                }));
    
                setSearchDishes([...dishesFromCategory]);
                return;
            }
            let searchTiles = searchDishes.filter((tile) => {
                if (tile.title && tile.title.indexOf(searchText) !== -1) {
                    return true;
                }
                return false;
            });
            setSearchDishes([...searchTiles]);
        } */

        return (
        <div style={{ position: "relative" }} >
            <IconButton 
                color="secondary" 
                onClick={(event) => {
                    removeComponentFromItemList(props.identifiant);
                }}
                style={{ 
                    position: "absolute", 
                    right: "5px", 
                    zIndex: 2 
                }} 
                aria-label="settings">
                <CancelIcon />
            </IconButton>
            <Typography style={{ margin: "1vh 0"}} variant="h5" component="h3" align="center" gutterBottom>Menu {props.index}</Typography>
            <Formik
                initialValues={{ 
                    name: props.Menu ? props.Menu.name : '',
                    description: props.Menu ? props.Menu.description : '',
                    _HTprice: props.Menu ? props.Menu.priceHT : 0.00,
                    _TTCprice: props.Menu ? props.Menu.priceTTC : 0.00,
                    available: props.Menu ? props.Menu.available : true,
                    displayShape: props.Dish ? props.Dish.displayShape : DISPLAY_SHAPE_ITEM.Large,
                }}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(500).required("Un intitulé pour votre menu doit être fournis."),
                    description: Yup.string(),
                    _TTCprice: Yup.number().min(0.1, "Votre prix TTC doit être supérieur a 0.").required("Un prix TTC doit être indiqué."),
                    _HTprice: Yup.number().min(0.1, "Votre prix TTC doit être supérieur a 0.").required("Le prix ht n'est pas fixé"),
                    available: Yup.bool().default(true),
                    displayShape: Yup.mixed<DISPLAY_SHAPE_ITEM>().oneOf(Object.values(DISPLAY_SHAPE_ITEM)).required('Choose a shape for your items'),
                })}
                onSubmit={(values) => {
                    //let userId = authContext.authObj.userProfile !== {} ? (authContext.authObj.userProfile as ITrader)._id as string : "";
                  
                    if (props.Menu) {
                        updateMenu(values);
                    } else {
                        let form = new FormData();

                        //setIsFinishSubmit(false);
                        form.append("name", values.name);
                        form.append("description", values.description);
                        form.append("priceHT", values._HTprice.toString());
                        form.append("priceTTC", values._TTCprice.toString());
                        form.append("category", category._id as string);
                        form.append("dishes", JSON.stringify(dishesInMenu.length > 0 ? dishesInMenu.map(x => x.keyTile) : []));
                        form.append("available", JSON.stringify(true));
                        form.append("discountSchedule", JSON.stringify([]));
                        form.append("principal", imagePrincipale as File); 
                        form.append("additionnalData", JSON.stringify([]));  
                        form.append("displayShape", values.displayShape);  
                        createMenu(form);
                    }                   
                }}>
                {formProps => (
                    <form ref={formRef} className={classes.formElement} onSubmit={(e) => {
                        e.preventDefault();
                        formProps.handleSubmit();
                      }}>
                        <article>
                            <Grid container spacing={4} alignItems="center" justify="center" >
                                <Grid 
                                    item 
                                    component='div'
                                    xs={6} 
                                    sm={6} 
                                    md={6}>
                                    <FormGroup>
                                        <TextField
                                            id="name"
                                            type="text"
                                            label="Intitulé du menu*"
                                            name="name"
                                            placeholder="Exemple: Menu végan, Menu BeefCut, ..."
                                            fullWidth
                                            variant="outlined"
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            value={formProps.values.name}
                                            error={isSubmited && formProps.errors.name ? true : false}
                                            helperText={isSubmited && formProps.errors.name}
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid 
                                    item 
                                    component='div'
                                    xs={6} 
                                    sm={6} 
                                    md={6}>
                                    <TextField
                                        id="_TTCprice"
                                        name="_TTCprice"
                                        label="Prix TTC"
                                        placeholder="Exemple: 8.67"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        value={formProps.values._TTCprice}
                                        onChange={(e) => {
                                            HandleChangeOnTTCPrice(e, formProps.setFieldValue);
                                            formProps.handleChange(e);
                                        }}
                                        onBlur={formProps.handleBlur}
                                        error={isSubmited && formProps.errors._TTCprice ? true : false}
                                        helperText={isSubmited && formProps.errors._TTCprice}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment style={{}} position="end">
                                                    €
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        />
                                </Grid>
                                <Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <TextField
                                        id="description"
                                        type="text"
                                        label="Décrivez votre plat"
                                        name="description"
                                        fullWidth
                                        variant="outlined"
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values.description}
                                        placeholder="Exemple: Menu comprennent une entrée , un plat et un dessert au choix"
                                        error={isSubmited && formProps.errors.description ? true : false}
                                        helperText={isSubmited && formProps.errors.description}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                    />
                                </Grid>
                                <Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <Accordion expanded={expanded === 'panel1'} onChange={(ev, isExpanded) => {
                                        handleChange(ev, isExpanded, 'panel1');
                                    }}>
                                        <AccordionSummary
                                            expandIcon={<Typography><span role="img" aria-label="img">📷</span></Typography>}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            >
                                            <Typography
                                                variant="h6" 
                                                component="span">
                                                    Image principal
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <UploadImgZone 
                                                minHeight={350}
                                                minWidth={350}
                                                shape={formProps.values.displayShape}
                                                fileLoaded={(props.Menu as BaseItem)?.picturePrincipal}
                                                onFileChange={(fileEv) => setImagePrincipal(fileEv)}
                                                onGalleryChange={(files) => {}}
                                                titleGallery="Images secondaires"
                                                titlePrincipal="Image principale" />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                {/*<Grid
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <Accordion expanded={expanded === 'panel2'} onChange={(ev, isExpanded) => {
                                        handleChange(ev, isExpanded, 'panel2');
                                    }}>
                                        <AccordionSummary
                                        expandIcon={<Typography>🍽️</Typography>}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        >
                                            <Typography 
                                                variant="h4" 
                                                component="span">
                                                Contenu du menu
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ margin: "2vh 0" }}>
                                                <SearchBarDish
                                                    OnCategorySelected={OnCategorySelected}
                                                    OnChangeSearch={OnChangeSearch}
                                                    OnKeyDownEnter={OnKeyDownEnter}
                                                    dataSelect={cardCategories.map(category => ({value: category._id, name: category.name}))}
                                                label={"catégorie"} />                                                
                                            </div>
                                            {GetDishByCategoryLength > 0 && 
                                                (<VerticalList data={searchDishes}
                                                    onItemClick={(idDish: any) =>  {
                                                        //handleEditDish(idDish);
                                                        //setPageState(STATE_PAGE_MANAGER.PAGE_DISH);
                                                    }}
                                                    OnCreateClick={() => {
                                                        //setDishEdit(undefined);
                                                        //setPageState(STATE_PAGE_MANAGER.PAGE_DISH);
                                                    }}
                                                    OnDeleteClick={(idDish: any) => {}}
                                                    displayAddButton={false}
                                                ></VerticalList>)}
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div style={{ flex: 1 }}>
                                                    <Typography style={{ width: "100%", textTransform: "uppercase" }} variant="h6" align="center">Contenu du menu</Typography>
                                                    <div style={{ height: "250px" }}>
                                                        <DropZone OnDrop={OnDrop}
                                                            Sentence="Glissez les plats dans la zone pour les ajouter au menu">
                                                            <div>
                                                                {dishesInMenu.map((dish) => {
                                                                    return (
                                                                        <Chip
                                                                            key={dish.keyTile}
                                                                            avatar={<Avatar><img src={dish.picture} alt={"img"}></img></Avatar>}
                                                                            label={dish.title}
                                                                            onDelete={(e) => { handleDelete(e, dish.keyTile); }}
                                                                            color="secondary"
                                                                        />);
                                                                })}
                                                            </div>
                                                        </DropZone>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>*/}
                                {props.Menu && <Grid 
                                    item 
                                    component='div'
                                    alignContent="center"
                                    alignItems="center"
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                <FormControlLabel
                                        control={<IOSSwitch
                                            id="available"
                                            checked={formProps.values.available}
                                            name="available" />}
                                        label="Disponibilité"
                                        labelPlacement="start"
                                        onChange={formProps.handleChange}
                                    />
                                </Grid>}     
                                <Grid 
									item 
									component='div'
									xs={12} 
									sm={12} 
									md={12}>
									<Button 
										onClick={(event) => {
											setIsSubmited(true);
										}} 
										type="submit" 
										variant="contained" 
										color="secondary"
										fullWidth>
										{props.Menu ? "Editer" : "Créer "} {formProps.values.name === "" ? `menu ${props.index}` : formProps.values.name}
									</Button>
								</Grid>                         
                            </Grid>
                        </article>
                    </form>
                )}
            </Formik>
        </div>);
    }


};

export default ManageDigitalCard;