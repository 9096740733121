

import styled from 'styled-components';

export const YumButton = styled.button<{ disabled?: boolean, color?: string}>`
border: 0;
font-size: 100%;
letter-spacing: 1px;
text-transform: uppercase!important;
-webkit-appearance: button;
background-image: ${({ color }) => (color === undefined) ? "linear-gradient(135deg, #eaac7e 0%, #f47150 100%)" : `${color}`};
box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);
color: white;
border-radius: 35px;
min-width: 150px;
width: fit-content;
max-width: 200px;
min-height: 40px;
margin: 5px;
display: flex;
padding: 0.3rem 1em;
justify-content: center;
align-items: center;
cursor: ${({ disabled }) => disabled ? "cursor" : "pointer"};
outline: unset;
:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    opacity: ${({ disabled }) => disabled ? 1 : 0.9 };
}
`;