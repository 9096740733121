import React from "react";
import { TextField, Typography, CircularProgress, Tooltip } from "@material-ui/core";
import useStyles from "./styles";
import { MobileDetector } from "../../../../utils";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Variant } from "@material-ui/core/styles/createTypography";

interface Props {
    textValue: string,
    variant?: "inherit" | Variant | undefined,
    action: (newTextValue: string) => Promise<boolean>,
}

const YumChangeInput: React.FC<Props> = (props: Props) => {

    const classes = useStyles();

    const [isFocusing, setIsFocusing] = React.useState<boolean>(false);
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
    const [textEdit, setTextEdit] = React.useState<string>(props.textValue);

    function onChangeValueInput(event: React.ChangeEvent<HTMLInputElement>) {
        if (isEditing === false){
            return ;
        }
        setTextEdit(event.target.value);
    }

    /**
     * MAJ de le variable isEditing, Lorsque l'utilisateur clique 1 fois sur le champs 
     */
    function onClickToEdit() {
        setIsEditing(true);
    }

    /**
     * Fait appel l'action parametrer pour le change (voir les props), pour le change de l'input 
     */
    function callbackAction () {
        if(isEditing === false){
            return ;
        }
        if (textEdit.length < 1) {
            return ;
        }
        setIsRequesting(true);
        props.action(textEdit).then().finally(() => {
            setIsEditing(false);
            setIsFocusing(false);
            setTextEdit(props.textValue);
            setIsRequesting(false);
        });
    }

    /**
     * Lorsque l'utilisateur entre dans la div
     */
    function changeOnMouseOver() {
        setIsFocusing(true);
    }
    
    /**
     * Lorsque l'utilsateur sort de la div
     */
    function changeOnMouseOut() {
        setIsFocusing(false);
    }

    /**
     * Action du bouton de Validation en version mobile
     */
    function update() {
        callbackAction();
        changeOnMouseOut();
    }

    /**
     * Action du bouton de Cancel pour la version mobile
     */
    function cancel() {
        changeOnMouseOut();
    }

	return (
		<div 
            onMouseOver={changeOnMouseOver} 
            onMouseOut={changeOnMouseOut}
            className={classes.root}
        >
            {!isRequesting && !isFocusing && 
            <Typography
                variant={props.variant}>
                {props.textValue}
            </Typography>}

            {!isRequesting && isFocusing && 
            <TextField 
                value={textEdit} 
                onChange={onChangeValueInput} 
                onClick={onClickToEdit} 
                onMouseOut={callbackAction} 
                className={classes.textField}
            />}

            {((MobileDetector.Android() !== null) || (MobileDetector.iOS() !== null)) && (<div className={classes.divMobile}>
                {!isRequesting && isFocusing &&
                <div className={classes.btn}>
                    <Tooltip title="Valider la modification">
                        <CheckCircleOutlineIcon
                            style={{ marginLeft: "1vw"}} 
                                onClick={() => {
                                update();
                            }}
                        />
                    </Tooltip>
                </div>}

                {!isRequesting && isFocusing &&
                <div className={classes.btn}>
                    <Tooltip title="Annuler la modification">
                        <CancelOutlinedIcon
                            style={{ marginLeft: "1vw"}} 
                                onClick={() => {
                                cancel();
                            }}
                        />
                    </Tooltip>
                </div>}
			</div>)}

            {isRequesting && 
            <CircularProgress className={classes.circularProgress}/>}

		</div>
	);
}

export default YumChangeInput;

