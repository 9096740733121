import React, { Fragment, useContext, useEffect } from "react";
import { AppColorPersonnalization, AppState, ITrader, UserStateV2 } from "../../../types";
import { store } from "../../../contexts/auth";
import { connect } from "react-redux";
import { Action } from "redux";
import { GetPackForCurrentTraderThunk } from "../../../redux/thunks/user/thunks";
import { ThunkDispatch } from "redux-thunk";
import { PutColorPersonalization } from "../../../redux/reducers/applicationdata/actionCreator";

type RegisterProps = {
	userV2: UserStateV2;
	loadPackToState: (idTrader: string) => any;
	loadAppColor: (appColor: AppColorPersonnalization) => any;
};

/**
 * Composant permettant de charger l'ensemble des données nécessaire au ot déroulement du site et de les mettre dans les states
 */
const Startup: React.FC<RegisterProps> = (Props) => {
	const authcontext = useContext(store);

	useEffect(() => {
		LoadPackDataOfConnectedTrader();
	}, [authcontext.authObj.userProfile]);

	useEffect(() => {
		LoadAppColor();
	}, [authcontext.authObj.userProfile]);
	
	useEffect(() => {
		LoadUserDataOfTrader();
	}, []);

	/**
	 * Charge les données du pack de l'utilisateur actuellement connecté
	 */
	const LoadUserDataOfTrader = async () => {
		//console.log("Try to load user !", authcontext.authObj.userProfile);
		if (authcontext.authObj.userProfile !== {} || (authcontext.authObj.userProfile as ITrader)._id) {
			return;
		}
		try {
			console.log("Launch async get profil");
			await authcontext.authObj.getProfile();
		} catch (e) {
			console.log("erreur startup", e);
		}
	};

	/**
	 * Charge les données du pack de l'utilisateur actuellement connecté
	 */
	const LoadPackDataOfConnectedTrader = () => {
		//console.log("Try to load pack", authcontext.authObj, authcontext.authObj.userProfile == {});
		if (authcontext.authObj.userProfile === {} || !(authcontext.authObj.userProfile as ITrader)._id) {
			return;
		}
		//console.log("Launch thunk PACK");
		Props.loadPackToState(
			(authcontext.authObj.userProfile as ITrader)._id!
		);
	};

	/**
	 * Charge les données de parametrage de la couleur de l'utilisateur actuellement connecté
	 */
	const LoadAppColor = () => {
		//console.log("Try to load App color", authcontext.authObj);
		if (!(authcontext.authObj.userProfile as ITrader).AppSettings || authcontext.authObj.userProfile === {} || !(authcontext.authObj.userProfile as ITrader)._id) {
			return;
		}
		//console.log("Launch thunk App color");
		Props.loadAppColor((authcontext.authObj.userProfile as ITrader).AppSettings.Color);
	};
	return <Fragment></Fragment>;
};

const mapDispatchToProps = (
	dispatch: ThunkDispatch<AppState, void, Action>
) => {
	return {
		loadPackToState: (idTrader: string) => {
			dispatch(GetPackForCurrentTraderThunk(idTrader));
		},
		loadAppColor: (appColor: AppColorPersonnalization) => {
			dispatch(PutColorPersonalization(appColor));
		}
	};
};

export default connect(
	(state: AppState) => ({
		userV2: state.userV2,
	}),
	mapDispatchToProps
)(Startup);
