import React, { useContext, useEffect } from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem, Typography, Divider, Grid, CircularProgress } from "@material-ui/core";
import useStyles from "./styles";
import { ITrader } from "../../../types/entities";
import { TRADER_TYPE } from "../../../types/shared";
import { TVA_VALUES } from "../../../types/constantes/constantes";
import { UserState, UserInfosDataForm } from "../../../types";
import { EnumUtils } from "../../../utils";
import { APIManager } from "../../../services";
import { useSnackbar } from 'notistack';
import { store } from "../../../contexts/auth";
import { YumButton } from "../../../Lib/UI/YumButton";


interface InputValidators {
	data: {
		firstname: string;
		email: string;
		siret?: string;
		lastname: string;
		corporate_name?: string;
		mobile?: string;
		tva?: number;
		trader_type: string;
		iban?: string,
		rib?: string,
	},
	validator: {
		siret?: (data: string) => string | undefined;
		firstname?: (data: string) => string | undefined;
		lastname?: (data: string) => string | undefined;
		corporate_name?: (data: string) => string | undefined;
		trader_type?: (data: string) => string | undefined;
		mobile?: (data: string) => string | undefined;
		tva?: (data: string) => string | undefined;
		iban?: (data: string) => string | undefined;
		rib?: (data: string) => string | undefined;
	},
	errors: {
		siret?: string;
		firstname?: string;
		lastname?: string;
		corporate_name?: string;
		trader_type?: string;
		mobile?: string;
		tva?: number;
		iban?: string;
		rib?: string;
	}
}

interface Props {
	trader?: ITrader;
	userData: UserState;
}


const Layout: React.FC<Props> = ({ userData }: Props) => {

	const classes = useStyles();
	let authContext = useContext(store);
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setLoading] = React.useState(false);
	const [
		//submitted
		, setSubmitted] = React.useState(false);
	const [currentInfoTrader, setCurrentInfoTrader] = React.useState<{} | ITrader>(authContext.authObj.userProfile);

	const siretValidator = (data: string) => {
		if (data.length < 14) {
			return "Le siret doit contenir 14 caractères";
		}
		return undefined;
	}
	const firstnameValidator = (data: string) => {
		if (data.length < 2)
			return "Le prénom doit contenir au moins 2 caractères";
		return undefined;
	};
	const lastnameValidator = (data: string) => {
		if (data.length < 2)
			return "Le nom doit contenir au moins 2 caractères";
		return undefined;
	};

	const corporatenameValidator = (data: string) => {
		if (data === '') {
			return "Aucun n'as été renseigné";
		}
		return undefined;
	};
	const traderTypeValidator = (data: string) => {
		if (data === "") {
			return "Veuillez indique votre type d'etablissement";
		}
		return undefined;
	};
	const mobileValidator = (data: string) => {
		if (data === "") {
			return "Numéro de mobile incorrect";
		}
		return undefined;
	}
	const tvaValidator = (data: string) => {
		if (Number.isNaN(Number.parseFloat(data))) {
			return "tva incorrect";
		}
		if (Number.parseFloat(data) < 2.1) {
			return "tva incorrect";
		}
		return undefined;
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch (event.target.name) {
			case "siret":
				setFormData({ ...formData, data: { ...formData.data, siret: event.target.value } });
				break;
			case "firstname":
				setFormData({ ...formData, data: { ...formData.data, firstname: event.target.value } });
				break;
			case "lastname":
				setFormData({ ...formData, data: { ...formData.data, lastname: event.target.value } });
				break;
			case "corporate_name":
				setFormData({ ...formData, data: { ...formData.data, corporate_name: event.target.value } });
				break;
			case "mobile":
				setFormData({ ...formData, data: { ...formData.data, mobile: event.target.value } });
				break;
			case "iban":
				setFormData({ ...formData, data: { ...formData.data, iban: event.target.value } });
				break;
			case "rib":
				setFormData({ ...formData, data: { ...formData.data, rib: event.target.value } });
				break;
			default:
				break;
		}
	}

	const [formData, setFormData] = React.useState<InputValidators>({
		data: {
			//location?: COORDINATES_TYPE,
			email: currentInfoTrader ? (currentInfoTrader as ITrader).email : "",
			siret: currentInfoTrader && (currentInfoTrader as ITrader).siret ? (currentInfoTrader as ITrader).siret : "",
			iban: currentInfoTrader ? (currentInfoTrader as ITrader).iban : "",
			rib: currentInfoTrader ? (currentInfoTrader as ITrader).rib : "",
			trader_type: currentInfoTrader ? (currentInfoTrader as ITrader).trader_type : "",
			firstname: currentInfoTrader ? (currentInfoTrader as ITrader).firstname : "",
			lastname: currentInfoTrader ? (currentInfoTrader as ITrader).lastname : "",
			corporate_name: currentInfoTrader && (currentInfoTrader as ITrader).corporate_name ? (currentInfoTrader as ITrader).corporate_name : "",
			mobile: currentInfoTrader ? (currentInfoTrader as ITrader).mobile : "",
			tva: currentInfoTrader && (currentInfoTrader as ITrader).tva ? (currentInfoTrader as ITrader).tva : TVA_VALUES[0],
		},
		validator: {
			siret: siretValidator,
			firstname: firstnameValidator,
			lastname: lastnameValidator,
			corporate_name: corporatenameValidator,
			trader_type: traderTypeValidator,
			mobile: mobileValidator,
			tva: tvaValidator,
		},
		errors: {
		}
	});

	const handleChangeTraderType = (event: React.ChangeEvent<{ value: unknown }>) => {
		setFormData({ ...formData, data: { ...formData.data, trader_type: event.target.value as string } });
	}

	const handleChangeTva = (event: React.ChangeEvent<{ value: unknown }>) => {
		setFormData({ ...formData, data: { ...formData.data, tva: event.target.value as number } });
	}

	/* const Validate = () => {
		const { validator } = formData;
		const { data } = formData;
		const { errors } = formData;
		let error = false;

		const formErrors = Object.entries(validator).reduce((errorsMap, [name, validator]) => {
			if (validator) {
				(errors as any)[name] = validator((data as any)[name] as string);
				(errorsMap as any)[name] = (errors as any)[name];
			}
			return errorsMap;
		}, {});


		for (const [
			//key
			, value] of Object.entries(formErrors)) {
			if (value !== undefined) {
				error = true;
				break;
			}
		}
		// Si aucun probleme n'as été détecté
		if (!error) {
			return true;
		}
		setFormData({ ...formData, ...errors });
		return false;
	} */

	/**
	 * Soumission du panier
	 * @param event 
	 */
	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setFormData({ ...formData, errors: {} });
		setSubmitted(false);
		setLoading(true);

/* 		if (Validate() === false) {
			return;
		} */
		let usr: UserInfosDataForm = {
			corporate_name: formData.data.corporate_name && formData.data.corporate_name.length > 0 ? formData.data.corporate_name : undefined,
			trader_type: formData.data.trader_type,
			lastname: formData.data.lastname,
			firstname: formData.data.firstname,
			mobile: formData.data.mobile === '' ? undefined : formData.data.mobile,
			tva: formData.data.tva,
			siret: formData.data.siret && formData.data.siret.length > 0 ? formData.data.siret : undefined,
			/*iban: formData.data.iban,
			rib: formData.data.rib,*/
		};

		//console.log(usr);
		APIManager.getAccountServices().UpdateTraderInformations(usr).then((currentInfoTrader: ITrader) => {
			setFormData({ ...formData, data: { 
				email: currentInfoTrader ? currentInfoTrader.email : "",
				siret: currentInfoTrader && currentInfoTrader.siret ? currentInfoTrader.siret : "",
				iban: currentInfoTrader ? currentInfoTrader.iban : "",
				rib: currentInfoTrader ? currentInfoTrader.rib : "",
				trader_type: currentInfoTrader ? currentInfoTrader.trader_type : "",
				firstname: currentInfoTrader ? currentInfoTrader.firstname : "",
				lastname: currentInfoTrader ? currentInfoTrader.lastname : "",
				corporate_name: currentInfoTrader && currentInfoTrader.corporate_name ? currentInfoTrader.corporate_name : "",
				mobile: currentInfoTrader ? currentInfoTrader.mobile : "",
				tva: currentInfoTrader && currentInfoTrader.tva ? currentInfoTrader.tva : TVA_VALUES[0],
			 } });
			setSubmitted(true);
			enqueueSnackbar('Votre compte a été mise a jour avec succès :)', { variant: "info" });
		}).catch((err: any) => {
			// TODO : Affiché d'erreur sur front
			console.log("catch");
			console.log(err);
			enqueueSnackbar('La mise à jour a échoué, verifiez votre connexion internet', { variant: "error" });
			setSubmitted(false);
		}).finally(() => {
			setLoading(false);
		});

	}

	const getProfilConnected = async () => {
		try {
			await authContext.authObj.getProfile();
			setCurrentInfoTrader(authContext.authObj.userProfile);
		} catch (err) {
			enqueueSnackbar("Une erreur inconnue est survenue veuillez recharger la page ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		}
	}

	useEffect(() => {
		if (!isLoading || authContext.authObj.userProfile !== {}) {
			return;
		}
		// Récupère le pack du commercant connecté.
		getProfilConnected();		
	}, [authContext.authObj.userProfile]);

	return (
		<div className={classes.root}>
			{!isLoading &&
				(<form onSubmit={handleSubmit} className={classes.form}>
					<Typography
						className={classes.title}
						variant="subtitle1" 
						component="span" 
						align="left" 
						gutterBottom>Infos utilisateur</Typography>
					<Divider />
					<Grid container component="div" style={{ margin: "15px 0" }} alignItems="center">
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={3} lg={3}>
							<TextField
								disabled
								id="email"
								name="email"
								label="email"
								fullWidth
								value={formData.data.email}
								variant="outlined"
								onChange={handleChange}
							/>
						</Grid>
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={3} lg={3}>
							<TextField
								id="firstname"
								name="firstname"
								label="prénom"
								fullWidth
								className={classes.capitalize}
								value={formData.data.firstname}
								error={formData.errors.firstname ? true : false}
								helperText={formData.errors.firstname}
								variant="outlined"
								onChange={handleChange}
							/>
						</Grid>
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={3} lg={3}>
							<TextField
								label="nom"
								id="lastname"
								name="lastname"
								fullWidth
								className={classes.capitalize}
								value={formData.data.lastname}
								error={formData.errors.lastname ? true : false}
								helperText={formData.errors.lastname}
								variant="outlined"
								onChange={handleChange}
							/>
						</Grid>
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={3} lg={3}>
							<TextField
								id="mobile"
								name="mobile"
								label="mobile"
								fullWidth
								value={formData.data.mobile}
								error={formData.errors.mobile ? true : false}
								helperText={formData.errors.mobile}
								variant="outlined"
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Typography 
						className={classes.title}
						variant="subtitle1" 
						component="span" 
						align="left" 
						gutterBottom>Infos commerce</Typography>
					<Divider />
					<Grid container component="div" style={{ margin: "15px 0" }} alignItems="center">
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={6} lg={6}>
							<TextField
								id="siret"
								name="siret"
								label="siret"
								fullWidth
								value={formData.data.siret}
								error={formData.errors.siret ? true : false}
								helperText={formData.errors.siret}
								variant="outlined"
								onChange={handleChange}
							/>
						</Grid>
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={6} lg={6}>
							<TextField
								id="corporate_name"
								name="corporate_name"
								label="Nom de l'établissement"
								value={formData.data.corporate_name}
								error={formData.errors.corporate_name ? true : false}
								helperText={formData.errors.corporate_name}
								variant="outlined"
								onChange={handleChange}
								fullWidth
							/>
						</Grid>
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={6} lg={6}>
							<FormControl fullWidth
								margin="normal"
								variant="outlined"
								required
							>
								<InputLabel id="demo-simple-select-outlined-label" error={formData.errors.trader_type ? true : false}>Type d'etablissement</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={formData.data.trader_type}
									onChange={handleChangeTraderType}
									label="Type d'établissement"
									error={formData?.errors.trader_type ? true : false}
								>
									{EnumUtils.EnumToStrArray<string>(TRADER_TYPE).map((value: string, index: number) => {
										return (
											<MenuItem key={index} value={value}>{value}</MenuItem>
										);
									})}
								</Select>
								{formData.errors.trader_type
									&& (<div className="help-block">{formData.errors.trader_type}</div>)}
							</FormControl>
						</Grid>
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={6} lg={6}>
							<FormControl fullWidth
								margin="normal"
								variant="outlined"
								required
							>
								<InputLabel id="tva-select-label" error={formData.errors.tva ? true : false}>Tva applicable</InputLabel>
								<Select
									labelId="tva-select-label"
									id="tva-select"
									value={formData.data.tva}
									onChange={handleChangeTva}
									label="Tva applicable"
									required
									error={formData.errors.tva ? true : false}
								>
									{TVA_VALUES.map((value: number, index: number) => {
										return (
											<MenuItem key={index} value={value}>{value.toString()}%</MenuItem>
										);
									})}
								</Select>
								{formData.errors.tva
									&& (<div className="help-block">{formData.errors.tva}</div>)}
							</FormControl>
						</Grid>
					</Grid>
					{/*<Typography variant="h3" component="span" align="left" gutterBottom>Informations financière</Typography>
					<Divider />
					<Grid container component="div" style={{ margin: "15px 0" }} alignItems="center">
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={6} lg={6}>
							<TextField
								id="outlined-disabled"
								label="iban"
								value={formData.data.iban}
								error={formData.errors.iban ? true : false}
								helperText={formData.errors.iban}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item component='div' className={classes.inputMarge} xs={12} sm={6} md={6} lg={6}>
							<TextField
								id="outlined-disabled"
								label="rib"
								value={formData.data.rib}
								error={formData.errors.rib ? true : false}
								helperText={formData.errors.rib}
								variant="outlined"
								fullWidth
							/>
						</Grid>
					</Grid> */}
					<div style={{ display: "flex", justifyContent: "space-between", padding: "5px 15px" }}>
						<YumButton 
							onClick={(ev) => {
							ev.preventDefault();
							APIManager.getAccountServices().EmitResetPasswordMail(formData.data.email).then((res: boolean) => {
								if (!res) {
									throw new Error("Le mail n'as pas pu être envoyé, contactez le service client.");
								}
								enqueueSnackbar("Un email de réinitialisation de mot de passe a été envoyé.", { 
									variant: "info",
									anchorOrigin: {
										vertical: 'top',
										horizontal:'right',
									}
								});
							}).catch((err: Error) => { 
								enqueueSnackbar(err.message, { 
									variant: "error",
									anchorOrigin: {
										vertical: 'top',
										horizontal:'right',
									}
								});
							});					

						}}>
								Modifier votre mot de passe
						</YumButton>
						<YumButton 
							type="submit">
								Mettre à jour vos Informations
						</YumButton>
					</div>
				</form>)}
			{isLoading && (<CircularProgress color="primary" size={68} />)}
		</div>);
}

export default Layout;