import { combineReducers, createStore, applyMiddleware } from "redux";
import { CookReducer } from "./reducers/cook";
import { CustomerReducer } from "./reducers/customer";
import { EntitiesReducer } from "./reducers/entities";
import { BillingReducer } from "./reducers/billing";
import { UserV2Reducer } from "./reducers/userV2";
import { UserReducer } from "./user";
import { ApplicationDataReducer } from "./reducers/applicationdata";
import thunk from 'redux-thunk';
import { AppState } from "../types";

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers<AppState>({
  user: UserReducer,
  billing: BillingReducer,
  cook: CookReducer,
  customer: CustomerReducer,
  entities: EntitiesReducer,
  applicationdata: ApplicationDataReducer,
  userV2: UserV2Reducer,
});

const store = createStore(
  rootReducer, applyMiddleware(thunk)
);

export default store;
