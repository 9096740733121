export class MobileDetector {

	public static Android(): RegExpMatchArray | null  {
        return navigator.userAgent.match(/Android/i);
	}

	public static BlackBerry(): RegExpMatchArray | null  {
        return navigator.userAgent.match(/BlackBerry/i);
	}

    public static iOS(): RegExpMatchArray | null  {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	}

    public static Opera(): RegExpMatchArray | null  {
        return navigator.userAgent.match(/Opera Mini/i);
	}

    public static Windows(): RegExpMatchArray | null  {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
	}

    public static any(): RegExpMatchArray | null  {
        return MobileDetector.Windows() || MobileDetector.Android() || MobileDetector.iOS() || MobileDetector.BlackBerry() || MobileDetector.Opera();
	}
}
