import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
	'@global': {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: 'none',
		},
	},
	appBar: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	toolbar: {
		flexWrap: 'wrap',
	},
	toolbarTitle: {
		flexGrow: 1,
	},
	link: {
		margin: theme.spacing(1, 1.5),
	},
	heroContent: {
		padding: theme.spacing(8, 0, 6),
	},
	cardHeader: {
		backgroundColor:
			theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing(2),
	},
	comparaison: {
		textAlign: "center",
		'& table': {
			width: "100%",
		}
	},
	tabTitle: {
		padding: '10px',
	},
	featureTitle: {
		justifyContent: 'space-between',
		display: "flex",
		flexDirection: "row",
	},
	tabHeader: {
		'& th': {
			borderBottom: '2px solid black',
		}
	},
	navLink: {
		textDecoration: 'none',
		color: theme.palette.primary.main,
	}

}));

export default useStyles;
