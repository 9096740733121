import React from "react";
import Layout from "./Layout";
import { ITrader, AppState, UserState } from "../../types";
import { connect } from "react-redux";


interface Props {
	trader?: ITrader;
	userData: UserState;
}

const TraderSetting: React.FC<Props> = ({ trader, userData }: Props) => {
	return (
		<Layout trader={trader} userData={userData} />
	)
};

export default connect(
	(state: AppState) => ({
		userData: state.user
	}),
	{}
)(TraderSetting);
