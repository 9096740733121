import React, { Fragment, useEffect, useState } from "react";
import { useStyles, useStylesBackdrop } from "./styles";
import { UserInfos, BILLING_TYPE, TRADER_TYPE } from "../../../types";
import { Container, CssBaseline, Grid, Backdrop, CircularProgress, TextField, InputAdornment, IconButton, FormControl, InputLabel, Select, MenuItem, Checkbox } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { APIManager } from "../../../services";
import UserSignUp from "../../../types/userSignUp";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import LogoName from "../../../components/LogoName/LogoName";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { EnumUtils } from "../../../utils";
import { useSnackbar } from "notistack";
import NotifResendMail from "../../../components/Email/HandleEmail/NotifResendMail/NotifResendMail";
import { YumButton } from "../../../Lib/UI/YumButton";
import clsx from "clsx";


interface Props {
	pack: string | undefined;
	billing_type: BILLING_TYPE | null,
}

//const url_server = process.env.REACT_APP_BASE_URL;

const Layout: React.FC<Props> = ({ pack, billing_type }: Props) => {

	const classes = useStyles();
	const classesBakcdrop = useStylesBackdrop();
	const phoneRegExp = new RegExp("[0][0-9]{9}");
	const passwordRegexp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[#\-+=_\`\'\"<>\{\}\|é§£µùçû\^\[\]\\\/;:().@$!%*#?&])[A-Za-z\d#\-+=_\`\'\"<>\{\}\|é§£µùçû\^\[\]\\\/;:().@$!%*#?&]{8,}$/i;
	const strWrongInput = "Le format du champ est incorrect";

	/**
	 * Composant formulaire de souscription au services.
	 * @param props 
	 * @returns
	 */
	function FormComponent(props: any) {

		const [isSubmited, setIsSubmited] = useState<boolean>(false);
		const [showPassword, setShowPassword] = useState<boolean>(false);
		//const [agreement, setAgreement] = React.useState<boolean>(false);

		const formPack: string | undefined = props.pack;
		const formBillingType: BILLING_TYPE | null = props.type;
		const callBack: (userInfos: UserSignUp) => void = props.callback;

		const passwordValidator = (data: string) => {
			//Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:

			const matchArray = data.match(passwordRegexp);
			if (!matchArray)
				return false;
			return true;
		};
	
		const handleClickShowPassword = () => {
			setShowPassword(!showPassword);
		};

		return (
			<Fragment>
				<Typography variant="subtitle2" component="h2" style={{ margin: "2vh"}} gutterBottom> Saissisez vos informations et créez votre compte </Typography>
					<Formik
						initialValues={{ 
							email: "",
							lastname: "",
							firstname: "",
							mobile: undefined,
							password: "",
							traderType: "",
							agreement: false,
							optin: false
						}}
						validateOnChange={false}
						onSubmit={(values, { setSubmitting }) => {
							setIsSubmited(false);
							setSubmitting(false);

							if(!formBillingType) {
								return;
							}

							let userInfos: UserSignUp = {
								email: values.email,
								password: values.password,
								trader_type: values.traderType,
								lastname: values.lastname,
								firstname: values.firstname,
								mobile: values.mobile,
								pack: formPack,
								type_product: formBillingType.toString(),
								optin: values.optin,
							};

							callBack(userInfos);
						  }}
						validationSchema={Yup.object().shape({
							email: Yup.string().email(strWrongInput).required("Champ obligatoire"),
							lastname: Yup.string().min(2, "Le champs doit avoir au moins 2 charactères").required("Champ obligatoire"),
							firstname: Yup.string().min(2, "Le champs doit avoir au moins 2 charactères").required("Champ obligatoire"),
							mobile: Yup.string().matches(phoneRegExp, 'Le numéro de téléphone n\'est pas valide'),
							password: Yup.string().min(8).matches(passwordRegexp, "Votre mot de passe ne remplis pas les critères.").required("Champ obligatoire"),
							traderType: Yup.mixed().oneOf(EnumUtils.EnumToStrArray<string>(TRADER_TYPE)),
							agreement: Yup.boolean().oneOf([true], "Vous devez accepter les CGV et CGU"),
							optin: Yup.boolean(),
						})}
						>
						{props => {
							const {
							values,
							//touched,
							errors,
							//dirty,
							//isSubmitting,
							//isValidating,
							handleChange,
							handleBlur,
							handleSubmit,
							//handleReset
							} = props;
							return (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={1} alignItems="center" justify="center" >
									<Grid item component='div' xs={12} sm={4} md={4}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="firstname"
											label="Prénom"
											name="firstname"
											autoComplete="Firstname"
											autoFocus
											value={values.firstname}
											error={isSubmited && errors.firstname ? true : false}
											helperText={isSubmited ? errors.firstname : undefined}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									</Grid>
									<Grid item component='div' xs={12} sm={4} md={4}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="lastname"
											label="Nom"
											name="lastname"
											autoComplete="Lastname"
											value={values.lastname}
											onChange={handleChange}
											onBlur={handleBlur}
											error={isSubmited && errors.lastname ? true : false}
											helperText={isSubmited ? errors.lastname : undefined}
										/>
									</Grid>
									<Grid item component='div' xs={12} sm={4} md={4}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											id="mobile"
											label="Numero de mobile"
											name="mobile"
											placeholder="0642..."
											inputProps={
												{
													"maxLength": 10,
													"minLength": 10,
													"pattern": "[0-9]*",
												}
											}
											value={values.mobile}
											onChange={handleChange}
											onBlur={handleBlur}
											error={isSubmited && errors.mobile ? true : false}
											helperText={isSubmited ? errors.mobile : undefined}
										/>

									</Grid>
									<Grid item component='div' xs={12} sm={12} md={6}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="email"
											label="Email"
											name="email"
											autoComplete="email"
											value={values.email}
											type="email"
											onChange={handleChange}
											onBlur={handleBlur}
											error={isSubmited && errors.email ? true : false}
											helperText={isSubmited ? errors.email : undefined}
										/>
									</Grid>
									<Grid item component='div' xs={12} sm={12} md={6}>
										<FormControl fullWidth
											margin="normal"
											variant="outlined"
											required
										>
											<InputLabel id="traderType-label" error={errors.traderType ? true : false}>Type d'etablissement</InputLabel>
											<Select
												labelId="traderType-label"
												id="traderType"
												name="traderType"
												value={values.traderType}
												onChange={handleChange}
												onBlur={handleBlur}
												label="Type d'établissement"
												required
												error={isSubmited && errors.traderType ? true : false}
											>
												{EnumUtils.EnumToStrArray<string>(TRADER_TYPE).map((value: string, index: number) => {
													return (
														<MenuItem key={index} value={value}>{value}</MenuItem>
													);
												})}
											</Select>
											{isSubmited && errors.traderType
												&& (<div className="help-block">{errors.traderType}</div>)}
										</FormControl>
									</Grid>
									<Grid item component='div' xs={12} sm={12} md={12}>
										<Typography
											variant="body2"
											style={{ width: "100%"}} 
											align="center">Le mot de passe doit contenir au moins 8 caractères, une majuscule, une miniscule, un nombre et un caractère spécial</Typography>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											name="password"
											label="mot de passe"
											type={showPassword ? 'text' : 'password'}
											id="password"
											autoComplete="current-password"
											value={values.password}
											InputProps={{
												endAdornment: (
													<InputAdornment style={{}} position="end">
															{passwordValidator(values.password) && (<CheckCircleIcon style={{ color: "green" }} />)}
															{!passwordValidator(values.password) && (<CancelIcon style={{ color: "red" }} />)}
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={(event) => event.preventDefault()}
																>
																{showPassword ? <Visibility /> : <VisibilityOff />}
															</IconButton>
													</InputAdornment>
												),
											}}
											onChange={handleChange}
											onBlur={handleBlur}
											error={isSubmited && errors.password ? true : false}
											helperText={isSubmited ? errors.password : undefined}
										/>
									</Grid>
								</Grid>
								<div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
									<Checkbox
										checked={values.agreement}
										name="agreement"
										onChange={handleChange}
										onBlur={handleBlur}
										style={{ alignItems: "flex-start" }}
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
									<Typography component="p" variant="body1"  align="justify">En créant un compte vous acceptez les <span className={classes.cursorPointer} style={{ textDecoration: "underline" }} onClick={() => { 
										window.open(`https://www.yumscan.fr/mentions-legales/`, "condition général d'utilisation");
									}}> CGU</span>, et <span className={classes.cursorPointer}  style={{ textDecoration: "underline" }} onClick={() => { 
										window.open(`https://www.yumscan.fr/conditions-generales-de-vente/`, "condition général de vente");
									}}> CGV</span> de yumscan</Typography>
								</div>
								<div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
									<Checkbox
										checked={values.optin}
										name="optin"
										onChange={handleChange}
										onBlur={handleBlur}
										style={{ alignItems: "flex-start" }}
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
									<Typography component="p" variant="body1"  align="justify">J’accepte de recevoir les newsletters de <span>Yumscan</span></Typography>
								</div>
								{isSubmited && errors.agreement && (<Typography component="p" variant="subtitle2" align="justify" style={{ color: "red"}}>Vous devez accepter les CGV et CGU</Typography>)}
								<div className={classes.btnCreateAccount}>
									<YumButton 
									style={{ marginTop: 15 }} 
									onClick={(event) => {
										setIsSubmited(true)
									}} 
									type="submit">
										Créer un compte
									</YumButton>
								</div>						
							</form>
							);
						}}
					</Formik>
					<Typography variant="body2" component="h2" style={{ margin: "3vh"}} gutterBottom>Vous possédez déja un compte ? <NavLink to="/signin" >connectez vous</NavLink></Typography>
			</Fragment>
		)
	}
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [isSucess, setSucess] = useState<boolean>(false);
	const [userData, setUser] = useState<UserInfos | undefined>(undefined);

	useEffect(() => {
		if (pack && billing_type && isLoading) {
			setLoading(false);
		}
	}, [pack, billing_type])

	const signUp = (userInfos: UserSignUp) => {
		if (!userInfos.pack) {
			enqueueSnackbar(
				"Une erreur est survenue rechargez la page, ou contactez le service client si l'erreur persiste.", 
				{
					anchorOrigin: {
						horizontal: "right",
						vertical: "top"
					},
					variant: "error" }
			);
			return;
		}
		setLoading(true);
		
		APIManager.getAccountServices().signUp(userInfos).then((res) => {
			// TODO Mettre snack
			setUser(res);
			setSucess(true);
			enqueueSnackbar(
				"Votre compte doit être validé grâce au lien d'activation", 
				{ 
					variant: "info",
					anchorOrigin: {
						horizontal: "right",
						vertical: "top",
					}
				});
		}).catch((err: Error) => {
			// TODO Mettre snack
			setSucess(false);
			enqueueSnackbar(err.message, { 
				variant: "warning",
				anchorOrigin: {
					horizontal: "right",
					vertical: "top",
				}
			});
			console.log("erreur", err);
		}).finally(() => {
			setLoading(false);
		})
	}
	return (
		<Fragment>
			<CssBaseline />
			<Container
				className= {classes.container}
				component="div"
				maxWidth={false}>
				<section className={classes.header}>
					<div className={classes.logo} >
						<LogoName />
					</div>
					<div style={{ padding: "2vh 2vw"}}>
						<NavLink to="/signin" >
							<Typography component="p" variant="subtitle1">Se connecter</Typography>
						</NavLink>
					</div>
				</section>
				{!isSucess &&
				<section
					className={classes.titlesSection}>
					<Typography
						className={classes.titleAnnouncement}
						variant="h4"
						gutterBottom><strong>Prix unique</strong> et <strong>sans frais caché</strong></Typography>
					<Typography
						className={classes.titleAnnouncement}
						variant="h4"
						gutterBottom>Profitez de <strong>14</strong> jours d’essaie</Typography>
					<section
						className={clsx(classes.titlesSection, classes.marginDiv)}>
						<Typography
							variant="subtitle1"
							gutterBottom>Sans carte bancaire</Typography>
						<Typography
							variant="subtitle1"
							gutterBottom>Sans engagement</Typography>
						<Typography
							variant="subtitle1"
							gutterBottom>Sans frais d'installation</Typography>
					</section>
				</section>}
				<Container component="div" className={classes.root} maxWidth="md">
					{!isSucess && <FormComponent pack={pack} type={billing_type} callback={signUp} /> }
					{isSucess && userData?.email && (<NotifResendMail email={userData.email}/>) }
				</Container>
			</Container>
			<Backdrop className={classesBakcdrop.backdrop} open={isLoading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Fragment>
	);
};

export default Layout;
