import React from "react";
import Alert from '@material-ui/lab/Alert';

import {
	Button,
	CssBaseline,
	Link,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControlLabel,
	FormGroup,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	InputAdornment,
	Chip,
	Checkbox,
	Snackbar,
} from "@material-ui/core";
import useStyles from "./styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IOSSwitch } from "../../../components/IosSwitch/IosSwitch";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { DropzoneArea } from "material-ui-dropzone";
// import ScookNavBar from "../../../components/ScookNavBar/ScookNavBar";
import { 
	IEditableIngredient, 
	IDiscountSchedule, 
	UserState, 
	IDish, 
	AppState,
	IIngredient,
	DISPLAY_SHAPE_ITEM, 
// 	ICard 
} from "../../../types";
import { APIManager } from "../../../services";
import { CardServices, DishServices } from "../../../api";
import { connect } from "react-redux";
import AdvancedSchedule from "../../../components/AdvancedSchedule/AdvancedSchedule";


interface Props {
	userdata: UserState,
}

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="/">
				SCOOKS
        </Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const categoryDishes = ['Accompagnement', 'Boissons', 'Plat', 'Entrée', 'Dessert'];

const Layout: React.FC<Props> = ({ userdata }: Props) => {

	const classes = useStyles();

	// Images
	const [files, setFiles] = React.useState<File[]>([]);

	// Formulaire
	const [isAvailable, setAvailable] = React.useState(true);
	const [category, setCategory] = React.useState<string>(categoryDishes[0]);
	const [price, setPrice] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [ingredients, setIngredient] = React.useState<IEditableIngredient[]>([]);
	const [name, setName] = React.useState('');
	const [discount, setDiscount] = React.useState<IDiscountSchedule[]>([]);
	const [ingredientSearch, setIngredientSearch] = React.useState('');

	// Gestion des evenements du formulaire
	const [isError, setError] = React.useState<boolean>(false);
	const [isSucces, setSucces] = React.useState<boolean>(false);


	const handleDelete = (event: any) => {
		//console.log(event);
		console.info('You clicked the delete icon.');
	};

	const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
		setCategory(event.target.value as string);
	};

	const handleChangeIngredientSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIngredientSearch(event.target.value as string);
	};

	const handleChangeEditableIngredient = (event: React.ChangeEvent<HTMLInputElement>, data: boolean) => {
		console.log("is editable !");
		let indexArray = event.target.name;
		let realIndex: number = parseInt(indexArray, 10);
		if (isNaN(realIndex) || realIndex > ingredients.length) {
			throw Error("l'index du tableau n'existe pas ou est mal formaté !");
		}
		(ingredients[realIndex] as IEditableIngredient).editable = data;
		setIngredient([...ingredients]);
	}

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value as string);
	};

	const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPrice(event.target.value as string);
	};

	const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDescription(event.target.value as string);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			//console.log(ingredientSearch);
			let newIngredient: IEditableIngredient = {
				ingredient: {
					name: ingredientSearch,
					category: "test",
				},
				editable: false,
			}
			setIngredient([...ingredients, newIngredient] as IEditableIngredient[]);
			setIngredientSearch('');
		}
	}

	const handleAddSchedule = (item: IDiscountSchedule) => {
		setDiscount([...discount, item])
	}

	const handleSubmit = (event: React.FormEvent) => {
		//console.log(event.target);


		let api: CardServices = APIManager.getCardServices();
		let apiDish: DishServices = APIManager.getDishServices();

		let userLogged = userdata.userdata;
		setError(false);
		setSucces(false);


		if (!userLogged) {
			throw new Error("Vous n'êtes pas connecté.");
		}
		let dish: IDish = {
			name: name,
			description: description,
			priceHT: parseInt(price, 10),
			priceTTC: parseInt(price, 10),
			category: category as string,
			picturePrincipal: 'https://makitweb.com/demo/broken_image/images/noimage.png',
			trader: userLogged.id,
			ingredients: (ingredients as IEditableIngredient[]),
			available: isAvailable,
			discountSchedule: [],
			archived: false,
			displayShape: DISPLAY_SHAPE_ITEM.Square,
		};
		//console.log(dish);
		api.createDishSchedule(dish).then(async (dishNewlyCreated: IDish) => {
			let idCreated = dishNewlyCreated._id;
			let formData = new FormData();

			if (!dishNewlyCreated || !idCreated) {
				console.log("Le plat a été créer mais un probleme est apparue.");
				return;
			}
			console.log("Le plat a été créer");
			formData.append('files', files[0]);
			apiDish.UploadImg(idCreated, formData).then((r: boolean) => {
				console.log("resultat upload image");
				//console.log(r);
			});
		}).catch((val) => {
			console.log("Composant value fail:");
			//console.log(val);
			setError(true);
		})

	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setAvailable(event.target.checked);
	}

	const handleReductionClose = (key: number) => {
		let newdiscounts = discount.filter((val: IDiscountSchedule, keyTab: number) => {
			if (key === keyTab) {
				return false;
			}
			return true;
		});
		setDiscount([...newdiscounts])
	}

	function resetForm() {
		setAvailable(true);
		setCategory(categoryDishes[0]);
		setPrice('');
		setDescription('');
		setIngredient([]);
		setName('');
		setDiscount([]);
		setIngredientSearch('');

		// Gestion des evenements du formulaire
		setError(false);
		setSucces(false);
	}

	return (
		<React.Fragment>
			<CssBaseline />
			<form onSubmit={handleSubmit} className={classes.root}>
				{ /*<ScookNavBar displayLogo={true} /> */}
				<div className={classes.layout}>
					<Typography component="h1" variant="h3" align="center">
						Modification avancée d'un élément de la carte
					</Typography>
					<div className={classes.inputs}>
						<DropzoneArea
							acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
							dropzoneText={"Faites glisser les images de votre plat."}
							onChange={(files) => setFiles([...files])}
							maxFileSize={10000}
							filesLimit={1}
						/>
					</div>
					<Typography variant="h6">Informations concernant le plats</Typography>
					<div className={classes.inputs}>
						<FormGroup className={classes.firstGroup}>
							<TextField className={classes.firstGroupItem}
								id="outlined-basic"
								label="Intitulé du plat"
								variant="outlined"
								value={name}
								onChange={handleChangeName}
								InputProps={{
									className: classes.inputCustom
								}} />
							<FormControl className={classes.firstGroupItem} variant="outlined">
								<InputLabel id="demo-simple-select-outlined-label">Catégorie</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={category}
									onChange={handleChangeCategory}
									label="catégorie"
								>
									{categoryDishes.map((ingredient: string, index: number) => {
										return (
											<MenuItem key={index} value={ingredient}>{ingredient}</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControl className={classes.firstGroupItem} variant="outlined">
								<InputLabel htmlFor="outlined-adornment-amount">Prix TTC</InputLabel>
								<OutlinedInput
									id="outlined-adornment-amount"
									value={price}
									onChange={handleChangePrice}
									startAdornment={<InputAdornment position="start">€</InputAdornment>}
									labelWidth={40}
								/>
							</FormControl>
						</FormGroup>
					</div>
					<div className={classes.inputs + "" + classes.firstGroupItem}>
						<FormGroup>
							<TextField
								fullWidth
								id="outlined-textarea"
								value={description}
								onChange={handleChangeDescription}
								label="Description"
								placeholder="Description du plat "
								multiline
								variant="outlined"
							/>
							<TextField className={classes.inputs}
								value={ingredientSearch}
								onKeyDown={handleKeyDown}
								onChange={handleChangeIngredientSearch}
								placeholder="Tapez le nom de votre ingrédient puis entrée."
								fullWidth id="outlined-basic" label="Ingrédients" variant="outlined" />
						</FormGroup>
					</div>
					<div className={classes.chipsGroup + " " + classes.inputs}>
						{ingredients.map((ingredient: IEditableIngredient, index: number) => {
							return (
								<Chip
									key={index}
									label={(ingredient.ingredient as IIngredient).name}
									clickable
									color="secondary"
									onDelete={handleDelete}
									deleteIcon={<HighlightOffRoundedIcon />}
								/>
							);
						})}
					</div>
					<Accordion className={classes.inputs}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography variant="h6">Ingredients paramètres avancées</Typography>
						</AccordionSummary>
						<AccordionDetails className={classes.ingredientAdvanced}>
							<Typography>
								Cocher les ingredients pouvant être supprimé d'un plat lors d'une commande par defaut les ingrédients ne sont pas éditable
							</Typography>
							<div>
								{ingredients.map((item: IEditableIngredient, index: number) => {
									return (
										<FormControlLabel
											key={index}
											control={
												<Checkbox
													key={index}
													checked={item.editable as boolean}
													onChange={handleChangeEditableIngredient}
													name={index.toString(10)}
													color="primary" />
											}
											label={(item.ingredient as IIngredient).name}
										/>
									);
								})}
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion className={classes.inputs}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography variant="h6">Réductions paramètres avancées</Typography>
						</AccordionSummary>
						<AccordionDetails className={classes.AdvancedDiscounts}>
							<AdvancedSchedule data={discount} onItemClick={(key: number) => {
								console.log('La reduction ' + key + ' a été clickez!');
							}}
								onAdd={handleAddSchedule}
								onDelClick={handleReductionClose} />

						</AccordionDetails>
					</Accordion>
					<div className={classes.inputs}>
						<FormGroup>
							<FormControlLabel
								control={<IOSSwitch checked={isAvailable} onChange={handleChange} name="checkedB" />}
								label="Disponibilité"
								labelPlacement="start"
							/>
							<Button variant="contained" color="primary" onClick={handleSubmit}>
								Sauvegarder
							</Button>
						</FormGroup>
					</div>
				</div>
				<Copyright />
			</form>
			<Snackbar open={isSucces || isError} autoHideDuration={6000} onClose={resetForm}>
				<Alert onClose={resetForm} severity={isError ? "error" : (isSucces ? "success" : "success")}>
					{`le plat ${name} a été crée avec succès`}
				</Alert>
			</Snackbar>
			<Snackbar open={isError} autoHideDuration={6000} onClose={resetForm}>
				<Alert onClose={resetForm} severity="error">
					{`le plat ${name} n'a pas pu être crée. `}
				</Alert>
			</Snackbar>
		</React.Fragment>
	);
}

export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}),
	{}
)(Layout);

//export default Layout;
