import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	'@global': {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: 'none',
		},
	},
	footer: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			paddingTop: theme.spacing(6),
			paddingBottom: theme.spacing(4),
		},
	},
	titleGrid: {
		[theme.breakpoints.between('xs','sm')]: {
			textAlign: "center",
		},
	},
	navLink: {
		textDecoration: "none"
	},
	root: {
		backgroundColor: theme.palette.primary.main,
		//backgroundImage: "linear-gradient(to bottom, #ffffff, #f9f4fa, #f8e8f0, #f8dce0, #f6d1cc, #f4cabf, #f0c5b0, #eac0a2, #eabb99, #eab690, #eab187, #eaac7e)"
	}
}));

export default useStyles;
