import React from "react";
import Layout from "./Layout";
import { UserState } from "../../../types";
import UserSignUp from "../../../types/userSignUp";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

interface Props {
	userdata: UserState;
	nextStep?: () => void;
	traderInfos: UserSignUp | undefined;
}

const Payement: React.FC<Props> = ({nextStep, userdata, traderInfos} :Props) => {
	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

	return (
		<Elements stripe={stripePromise}>
			<Layout userdata={userdata} nextStep={nextStep} traderInfos={traderInfos} />
		</Elements>
	);
};

export default Payement;