import React, { Fragment, useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography } from "@material-ui/core";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { useSnackbar } from "notistack";
import { APIManager } from "../../../../services";
import { YumButton } from "../../../../Lib/UI/YumButton";


type NotifResendMailProps = {
	email: string
};

const NotifResendMail: React.FC<NotifResendMailProps> = ({ email }: NotifResendMailProps) => {
	//const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [resend, setResend] = useState<boolean>(false);

	/** Laisse tout les X secondes envoyer un mail */
	useEffect(() => {
		setInterval(function(){ 
			if (!resend) {
				setResend(true);
			}
		 }, 60000);
	}, []);

	const handleClick = (event: React.MouseEvent) => {
		event.preventDefault();

		if (resend){
			//todo ENVOIE DE MAIL
			APIManager.getAccountServices().EmitVerifEmail(email).then((res) => {
				if (!res) {
					throw new Error("Le mail n'as pas pu être envoyé, contacter le service client.");
				}
				enqueueSnackbar("Un email a vous a été envoyé", { 
					variant: "info", 
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}				 
				});
			}).catch((err: Error) => { 
				enqueueSnackbar(err.message, { 
					variant: "error",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
			}).finally(() => {
				// permet a l'utilisateur de pouvoir renvoyé le mail dans 1 minutes encore
				setResend(false);
			});	
		} else {
			enqueueSnackbar("Veuillez attendre au moins 1 minutes pour renvoyer un mail", { 
				variant: "warning", 
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
				 
			});
		}
	}

	return (
		<Fragment>
			<CssBaseline />
			<div style={{ display: "flex", flexDirection:"row", justifyContent: "center", alignItems: "baseline"}}>
				<Typography variant="subtitle1" component="h1" gutterBottom>Un e-mail de vérification a été envoyé </Typography>
				<DoneOutlineIcon style={{ marginLeft: "5px"}} color="primary" />
			</div>	
			<Typography variant="body1" component="h2" style={{ margin: "2vh"}} gutterBottom> Vous disposez de 24 heures pour valider votre compte en cliquant sur le lien envoyé à l'adresse mail : {email} </Typography>
			<Typography variant="body1" component="h5" style={{ textAlign: "center"}} gutterBottom>Certains mail peuvent parfois arriver en "Spam"</Typography>
			<Typography variant="body1" component="h6" style={{ margin: "2vh", textAlign: "center"}} gutterBottom> Vous n'avez reçu aucun mail après quelques minutes d'attente ?</Typography>
			<YumButton
				style={{ marginTop: 15 }}
				onClick={handleClick}>
				Renvoyer un mail
			</YumButton>
		</Fragment>
	);
}
export default NotifResendMail;
