import React, { Fragment, useEffect } from "react";
import useStyles from "./styles";
import { ICard, IQrcode } from "../../../types";
import { Chip, Container, CssBaseline } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { APIManager } from "../../../services";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { YumButton } from "../../../Lib/UI/YumButton";


interface Props {
	card: ICard;
	back: () => any;
}

//const url_server = process.env.REACT_APP_BASE_URL;

enum STATE_PAGE_CREATECARD {
	VALID_ACTION,
	SELECT_QRCODE,
}

const Layout: React.FC<Props> = ({ card, back }: Props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	//let authContext = useContext(store);

	const [pageState, setPageState] = React.useState<STATE_PAGE_CREATECARD>(STATE_PAGE_CREATECARD.SELECT_QRCODE);
	//const [currentCard, setCurrentCard] = React.useState<ICard | undefined>(undefined);
	const [qrcodes, setQrcodes] = React.useState<IQrcode[]>([]);
	const [selectedQrcode, setSelectedQrcode] = React.useState<IQrcode | undefined>(undefined);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
	
	const chooseQrcode = (qrcode: IQrcode) => {
		setSelectedQrcode(qrcode);
		setPageState(STATE_PAGE_CREATECARD.VALID_ACTION);
	}

	const updateQrcode = () =>  {
		let qrcodeAPI = APIManager.getQrCodeServices();

		if (selectedQrcode) {
			qrcodeAPI.UpdateDigitalCard(selectedQrcode?._id as string, card._id as string)
			.then(() =>  {
				enqueueSnackbar(`Le qrcode ${selectedQrcode?.name} est lié à la carte.`, { 
					variant: "success",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
				back();
			})
			.catch( (err) => {
				console.log(err);
				enqueueSnackbar(`Le qrcode ${selectedQrcode?.name} n'a pas pu etre lié la carte.`, { 
					variant: "error",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
				reset();
			})

		}
	}

	const reset = () => {
		setSelectedQrcode(undefined);
		setPageState(STATE_PAGE_CREATECARD.SELECT_QRCODE);
	}

	const actualizeQrcode = async () => {
        let qrcodeAPI = APIManager.getQrCodeServices();

        try
        {
            let qrcodesServer = await qrcodeAPI.GetAllQrCode();

            setQrcodes([...qrcodesServer]);

        } catch(err) {
			enqueueSnackbar(`Une erreur inconnue est survenue, si le problème persiste merci de contacter le service client.`, { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
        } finally {
            setIsLoading(false);
        };
    }

    useEffect(() => {
        if (!isLoading){
            return;
        }
        actualizeQrcode();
    }, []);

	return (
		<Fragment>
			<CssBaseline />
			<Container component="div" className={classes.root} maxWidth={false}>
				{<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
					<div onClick={() => {
						back();
					}} className={clsx(classes.backArrow,classes.makeLink)} >
						<ArrowBackIcon style={{ margin: "0 1vw" }}/>
						<Typography align="left" component="span" variant="subtitle1" gutterBottom>Retour</Typography>
					</div>
				</div>}
				<DynamicComponent />
			</Container>
		</Fragment>)

	function SelectQrcode(props: any) {
		return (
			<Container style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
				<Typography variant="subtitle1" component="span" style={{ width: "100%", textAlign: "center" }} gutterBottom> Selectionnez un qrcode pour y appliquer votre carte</Typography>
				<div style={{ maxHeight: "400px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
					{(qrcodes.length === 0) && "Aucun Qrcode disponible !"}
					{qrcodes.filter((qrcode) => qrcode.digitalCard !== card._id).map((qrcode) => 
					<Chip
						onClick={() => {
							chooseQrcode(qrcode);
						}}
						key={qrcode._id as string}
						style={{ margin: "10px"}}
						label={<div>
							<Typography 
								variant="body1" 
								component="span">
								{qrcode.name}
							</Typography>
						</div>}
						variant="outlined"
						className={classes.chip}
					/>)}
				</div>
			</Container>
		);
	}

	function QrcodeCreation(props: any) {
		return (
			<Fragment>
				<Typography 
					variant="subtitle1" 
					component="span" 
					style={{ width: "100%", textAlign: "center", fontWeight: "bold" }} 
					gutterBottom>Etes vous sûre de vouloir appliquer le qrcode "{selectedQrcode?.name}"" à la carte {card.name} ?"</Typography>
				{(selectedQrcode?.digitalCard  || selectedQrcode?.pdfCard) 
				&& <Typography 
					variant="body1" 
					component="span" 
					style={{ width: "100%", textAlign: "center" }} 
					gutterBottom>L'application du qrcode "{selectedQrcode?.name}" retirera la carte présente actuellement sur le qrcode pour la remplacer par la carte {card.name}</Typography>}
				<div  style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}>
					<YumButton
						onClick={() => {
							updateQrcode()
						}}>
						Oui
					</YumButton>
					<YumButton
						onClick={() => {
							reset();
						}}>
						Non
					</YumButton>
				</div>
			</Fragment>
		);
	}

	function DynamicComponent(props: any) {

		switch(pageState) {
			case STATE_PAGE_CREATECARD.VALID_ACTION:		
                return <QrcodeCreation />;
			case STATE_PAGE_CREATECARD.SELECT_QRCODE:
			default:
				return <SelectQrcode />;
        }
	}
};

export default Layout;
