/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CATEGORY_TYPE, ICategory } from '../../types';
import { CategoryServices } from '../../api';
import { APIManager } from '../../services';
import { CircularProgress } from '@material-ui/core';

//const filter = createFilterOptions<FilmOptionType>();
const filterCategory = createFilterOptions<CategoryOptionType>();


interface Props {
  onSelect: (category: ICategory) => any;
  onCreate: (categoryName: string) => any;
}

interface FilmOptionType {
  inputValue?: string;
  title: string;
  year?: number;
}

interface CategoryOptionType {
  inputValue?: string;
  title: string;
  category: ICategory;
}


const ComboBox: React.FC<Props> = ({ onSelect, onCreate }: Props) => {
  //const [value, setValue] = React.useState<FilmOptionType | null>(null);
  const [valueCategory, setValueCategory] = React.useState<CategoryOptionType | null>(null);
	const [basicCategories ,setBasicCategories] = React.useState<ICategory[] | undefined>(undefined);

  	/**
	 * Recupère l'ensemble des catégories basique d'un utilisateur.
	 */
	const getCategoriesUsed = async () =>  {
		try {
			let apiCategory: CategoryServices = APIManager.getCategoryServices();
			let basicsCategories = await apiCategory.GetAllCategories();

			setBasicCategories([...basicsCategories]);
		} catch (err) {
      setBasicCategories([]);
			console.log("Erreur de chargement des catégorie", err);
		} finally {
      
		}
	}

  useEffect(() => {
    if (basicCategories !== undefined){
      return;
    }
    getCategoriesUsed();
  }, []);

  return (
    <React.Fragment>
      {basicCategories && (
         <Autocomplete
         value={valueCategory}
         onChange={(event, newValue) => {
           if (typeof newValue === 'string') {
             setValueCategory({
               ...valueCategory,
               title: newValue,
               category: valueCategory?.category as ICategory
             });
           } else if (newValue && newValue.inputValue) {
             // Create a new value from the user input
             setValueCategory({
               ...valueCategory,
               title: newValue.inputValue,
               category: valueCategory?.category as ICategory
             });
             onCreate(newValue.inputValue);
             
           } else {
             if (newValue?.category._id) {
               onSelect(newValue.category);
             }
             setValueCategory(newValue);
           }
         }}
         filterOptions={(options, params) => {
           const filtered = filterCategory(options, params);
   
           // Suggest the creation of a new value
           if (params.inputValue !== '' 
           && params.inputValue.length > 1
           && !(basicCategories.find(x => x.name.toLowerCase() === params.inputValue.toLowerCase()))) {
             let cat: ICategory = {
               name: params.inputValue,
               category_type: CATEGORY_TYPE.USER,
             };
             
             filtered.push({
               inputValue: params.inputValue,
               title: `Créer la catégorie "${params.inputValue}"`,
               category: cat,
             });
           }
   
           return filtered;
         }}
         selectOnFocus
         clearOnBlur
         handleHomeEndKeys
         id="free-solo-with-text-demo"
         options={basicCategories.map((c) => { 
           return { category: c, title: c.name } as CategoryOptionType;
         })}
         getOptionLabel={(option) => {
           // Value selected with enter, right from the input
           if (typeof option === 'string') {
             return option;
           }
           // Add "xxx" option created dynamically
           if (option.inputValue) {
             return option.inputValue;
           }
           // Regular option
           return option.title;
         }}
         renderOption={(option) => option.title}
         style={{ width: 300 }}
         freeSolo
         renderInput={(params) => (
           <TextField 
               {...params} 
               label="Nom de votre catégorie" 
               margin="normal"
               fullWidth 
               placeholder="Pizza🍕, Bento🍱, Formule" 
               variant="outlined" />
         )}
       />
      )}
      {!basicCategories && (<CircularProgress />)}
    </React.Fragment>
   
  );
}

export default ComboBox;

