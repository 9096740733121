import React, { Fragment, useEffect } from "react";
import { YumButton } from "../../Lib/UI/YumButton";


interface Props {
}

const AddToHomeScreen: React.FC<Props> = (Props) => {
	//const classes = useStyles();

	const code = React.useRef<HTMLButtonElement>(null);

	/* const handleClose = (val: boolean, isSucess?: boolean, data?: any) => {

	}; */

	const handlePrompt = (event: React.MouseEvent) => {

		console.log("prompt");
		console.log(event);
		console.log(code.current);
		{/* <SpringModal openModal={true} eventClose={handleClose} canclose={true}>
			<div>
				<Container component="div" maxWidth={false} className={classes.root}>
					zdzdzd
				</Container>
			</div>
		</SpringModal> */}

		let deferredPrompt: any;

		window.addEventListener('beforeinstallprompt', (e: any) => {
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			e.preventDefault();
			// Stash the event so it can be triggered later.
			deferredPrompt = e;
			// Update UI to notify the user they can add to home screen
				//(code.current as HTMLButtonElement).style.display = 'block';
				console.log("Before click");

				console.log("Click here");
				// hide our user interface that shows our A2HS button
				// Show the prompt
				deferredPrompt.prompt();
				// Wait for the user to respond to the prompt
				deferredPrompt.userChoice.then((choiceResult:any) => {
					if (choiceResult.outcome === 'accepted') {
						console.log('User accepted the A2HS prompt');
					} else {
						console.log('User dismissed the A2HS prompt');
					}
					deferredPrompt = null;
				});
		
				(code.current as HTMLButtonElement).addEventListener('click', (e: any) => {
					console.log("Click here");
					// hide our user interface that shows our A2HS button
					// Show the prompt
					deferredPrompt.prompt();
					// Wait for the user to respond to the prompt
					deferredPrompt.userChoice.then((choiceResult:any) => {
						if (choiceResult.outcome === 'accepted') {
							console.log('User accepted the A2HS prompt');
						} else {
							console.log('User dismissed the A2HS prompt');
						}
						deferredPrompt = null;
					});
				});
		 });
	}

	useEffect(() => {
		if (!code.current  || code.current == null) {
			console.log("il y a une erreur");
			return;
		}
	
		window.addEventListener('beforeinstallprompt', (e: any) => {
			// TODO DECOMMENTER L'ensemble du code
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			//e.preventDefault();
			// Stash the event so it can be triggered later.
			let deferredPrompt: any = e;
			// Update UI to notify the user they can add to home screen
				//(code.current as HTMLButtonElement).style.display = 'block';
		
				(code.current as HTMLButtonElement).addEventListener('click', (e: any) => {
					// hide our user interface that shows our A2HS button
					// Show the prompt
					deferredPrompt.prompt();
					// Wait for the user to respond to the prompt
					deferredPrompt.userChoice.then((choiceResult:any) => {
						if (choiceResult.outcome === 'accepted') {
							console.log('User accepted the A2HS prompt');
						} else {
							console.log('User dismissed the A2HS prompt');
						}
						deferredPrompt = null;
					});
				});
		 });
	 }, [code])

	return (
		<Fragment>
			<YumButton 
				onClick={handlePrompt}
				ref={code}>
					INSTALLER L'APP
			</YumButton>

			{/* {MobileDetector.iOS() === null && (<div>
				toto
			</div>)}
			{MobileDetector.iOS() !== null && (<div>
				tata
			</div>)} */}

		</Fragment>
	);
};

export default AddToHomeScreen;
