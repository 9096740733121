import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import { APIManager } from "../../services";
import { ICategoryLink, IDish } from "../../types";
import Autocomplete, { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	rootPaper: {
        padding: "10px",
        margin: "5px 0px",
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        alignItems: "center",
        width: "100%",
    },
    rootDetail: {
		display: "flex", 
        flexDirection: "row",
		alignItems: "center",
		justifyContent: "center", 
        width: "100%",
    },
	space: {
		margin: "5px",
	},
	rootActions: {
		display: "flex", 
        flexDirection: "row",
		alignItems: "center",
		justifyContent: "center", 
	}
}));

export interface ITransfertDishProps {
    cardId: string;
    currentCategoryId: string;
    dishId: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void
}

const TransfertDish: React.FC<ITransfertDishProps> = ({ cardId, dishId, isOpen, setIsOpen, currentCategoryId }: ITransfertDishProps) => {
	const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
  
    const [categories, setCategories] = React.useState<ICategoryLink[] | undefined>(undefined);
    const [selectedCategory, setSelectedCategory] = React.useState<ICategoryLink | undefined>(undefined);
    const [dish, setDish] = React.useState<IDish | undefined>(undefined);

    async function GetAllCategory() {
        const  rawCategories = await APIManager.getCategoryServices().GetCategoriesFromCard(cardId);
        let processedCategories: ICategoryLink[] = [];

        rawCategories.forEach(element => {
            processedCategories.push(element);

            if (element.subCategory)
                (element.subCategory as ICategoryLink[]).forEach((sub) => processedCategories.push(sub));
        });
        console.log("Categories from card", categories, processedCategories)
        setCategories(processedCategories);
    }


    useEffect(() => {
        if (categories)
            return;
        GetAllCategory()
    }, []);

    useEffect(() => {
        if (selectedCategory)
            return;
        const defaultvalue = categories?.find(c => c._id === currentCategoryId);
        console.log("Default value :", defaultvalue);
        if (defaultvalue)
            setSelectedCategory(defaultvalue)
    }, [categories]);

    const handleChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) => {
        const changedCategeory = categories?.find(c => c._id === event.target.value);
        if (changedCategeory)
            setSelectedCategory(changedCategeory)
    }

    const OnClick = async () => {
        if (!selectedCategory)
            return;
            
        try {
            let dish = await APIManager.getDishServices().Transfert(dishId, selectedCategory?._id!);
            enqueueSnackbar(`Votre plat à été correctement transféré vers la catégorie ${selectedCategory!.name}`, { 
                variant: "success",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal:'right',
                }
            });
            setIsOpen(false);

        } catch (e) {
            enqueueSnackbar(`Votre plat n'a pas été correctement transféré vers la catégorie ${selectedCategory!.name}`, { 
                variant: "warning",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal:'right',
                }
            });
        }
       
    }

    return(
        <Fragment>
          	<Dialog
				open={isOpen}
				onClose={() => { setIsOpen(false) }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography
						variant="subtitle1"
						component="span"
						gutterBottom>
						<strong>transférer votre plat dans une autre categorie</strong>
					</Typography>
					</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    <FormControl fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                    >
                        <InputLabel id="category-label">Catégorie de votre carte</InputLabel>
                        <Select
                            labelId="traderType-label"
                            id="traderType"
                            name="traderType"
                            value={selectedCategory?._id!}
                            onChange={handleChange}
                            label="Catégories"
                            required>
                            {categories?.map((cat: ICategoryLink, index: number) => {
                                return (
                                    <MenuItem key={index} value={cat._id!}>{cat.name}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { setIsOpen(false) }} variant="contained" color="secondary">
						Annuler
					</Button>
					<Button 
						onClick={OnClick} 
						variant="contained"
						color="primary" autoFocus>
						Transferer
					</Button>
				</DialogActions>
			</Dialog>
        </Fragment>);
};

export default TransfertDish;

