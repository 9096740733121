import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		diplay: "flex",
		flexDirection: "column",
		padding: "2vh 2vw",
		"& article": {
			margin: "3vh 0"
		}
	},
	FiberManualRecord:  {
		marginLeft: 5, 
		fontSize: "1.2rem",
		color: "green",
	},
	chipRoot: {
		display: "flex",
		flexDirection: "row",
		padding: "10px",
		justifyContent: "space-between",
		alignItems: "center",
		"& span": {
			marginLeft: "5px",
			textTransform: "capitalize",
		},
	},
	chipsDisplay: {
		minHeight: "30px",
		margin: "0px 10px",
	},
	m_10_vertical: {
		margin: "10px 0px"
	},
	title: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		"& img": {
			width: "100%",
			maxWidth: "45px",
			margin:"0 5px"
		}
	},
	quickStart: {
		textAlign: "center",
		height: "100%",
		padding: "10vh 0",
		[theme.breakpoints.between('xs', 'sm')]: {
			padding: "5vh 0",
		},
	},
	StepQuickStart : {
		display: "flex", 
		justifyContent: "center", 
		alignItems: "center", 
		flexDirection: "column", 
		height: "100%",
	},
	highlightsentences : {
		color : theme.palette.primary.main,
	},
	quickNavSection: {
		display: "flex",
		flexDirection: "row",
		margin: "4vh 10px",
		alignItems: "center",
	},
	backArrow: {
		margin: "3vh 0px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "inherit",
		alignItems: "flex-end",
		width: "100%",
	},
	makeLink: {
		textDecoration: "underline",
		"&:hover": {
			cursor: "pointer",
		}
	},
}));

export {
	useStyles,
}
