import React, { Fragment, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { AuthRoutes } from "./routes/authRoutes";
import AuthCheck from "../utils/authcheck";
import PackRegistration from "../pages/PackRegistration";
import { CircularProgress } from "@material-ui/core";


//const Landing = lazy(() => import('../pages/Landing/Landing'));
const QrCodeRouter = lazy(() => import('../pages/QrcodeRouter'));
const ValidEmail = lazy(() => import('../pages/ValidEmail'));
//const CGU = lazy(() => import('../pages/Legal/CGU'));
//const CGV = lazy(() => import('../pages/Legal/CGV'));
const PrivacyPolicy = lazy(() => import('../pages/Legal/PrivacyPolicy'));
//const PackRegistration = lazy(() => import('../pages/PackRegistration'));
//const ShowOffers = lazy(() => import('../pages/ShowOffers'));
const PayementStatement = lazy(() => import('../pages/PayementStatement'));
const PasswordForgot = lazy(() => import('../pages/PasswordForgot'));
const SignIn = lazy(() => import('../pages/Login/index'));
const RessourceError = lazy(() => import('../pages/Error/RessourceError'));
const MobileCardView = lazy(() => import( "../pages/Preview/MobileCardView"));


export default function GlobalRoutes() {

	return (
		<Fragment>
			<AuthCheck />
			<Suspense fallback={
				<div style={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center"}}>
					<CircularProgress color="inherit" size="lg"/>
				</div>}>
				<Switch>
					<Route exact path="/router" component={QrCodeRouter} />
					{/* <Route path="/legal/condition-general-utilisation" component={CGU} exact /> */}
					{/* <Route path="/legal/condition-general-vente" component={CGV} exact /> */}
					<Route exact path="/legal/privacy-policy" component={PrivacyPolicy} />
					<Route exact path="/verification/mail" component={ValidEmail} />
					<Route exact path="/verification/password/reset" component={PasswordForgot} />
					<Route exact path="/signin" component={SignIn} />
					<Route exact path="/signup" component={PackRegistration} />
					{/*<Route path="/show/offers" component={ShowOffers} />*/}
					<Route exact path="/statement/payementStatement" component={PayementStatement} />
					<Route exact path="/preview/digitalcard" component={MobileCardView} />

					{/* Ensembles des routes pour la partie digital */}
					{/*
						<Route path="/carddisplay" component={CardDisplay} />
						<Route path="/quickstart" component={QuickStart} />
						<Route path="/pricing" component={Pricing} />
					*/}
					{/* Ensembles des routes authentifié */}					
					<AuthRoutes />
					<Route path="*" component={RessourceError} /> 
					{/* TODO MAKE ERROR PAGE <Route path="*" /> */}
				</Switch>
			</Suspense>
		</Fragment>

	);
};
