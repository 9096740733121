import {
	AAuthApi,
	IQrcode,
	RESTVerb,
	// 	IQrcode,
	//TraderCardInfos,
} from "../../types";
// import { EnumUtils } from "../../utils";
import httpStatus from "http-status";

export class ScanServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

	/**
	 * Récupère l'ensemble de la carte pour un utilisateur
	 * @param userid
	 */
	public QrCodeIsScanned(idQrcode: string, source: number, idCustomer?: string,): Promise<IQrcode> {
		return this.JsonNoAuthRequest(
			RESTVerb.POST,
			`scan/qrcodes/${idQrcode}`,
			{
				idCustomer: idCustomer,
				source: source
			}
		).then(async (res: Response) => {
			//console.log(res.status);
			//console.log(httpStatus.FOUND);
			if (res.status === httpStatus.FOUND) {
				let result = await res.json();
				//console.log(result);
				return result as IQrcode;
			}
			throw new Error(res.status.toString());
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}
}
