import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { APIManager } from "../../../services";
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useSnackbar } from "notistack";
import { YumButton } from "../../../Lib/UI/YumButton";


type RegisterProps = {
};

const Layout: React.FC<RegisterProps> = () => {
	//const classes = useStyles();
	const stripe = useStripe();
	const elements = useElements();
	const { enqueueSnackbar } = useSnackbar();


	const [isRequesting, setIsRequesting] = React.useState<boolean>(false);

	const handleClick = (event: React.MouseEvent) => {

		// Block native form submission.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}
		setIsRequesting(true);
		APIManager.getBillingService().ManageBilling().then((url) => {
			window.location.href = url;
		}).catch((err: Error) => {
			//let msg = '';
			
			enqueueSnackbar("Une erreur inconnue est survenue veuillez ré-essayez ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error", 
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
				 
			});
		}).finally(() => {
			setIsRequesting(false);
		});

	}

	return (
		<Fragment>
			<CssBaseline />
			<YumButton
				disabled={isRequesting} 
				onClick={handleClick}>
				Gérer mon abonnement
			</YumButton>
		</Fragment>
	);
}
export default Layout;
