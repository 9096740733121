import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	cropContainer: {
		position: 'relative',
		width: '100%',
		height: 400,
		background: '#333',
		[theme.breakpoints.up('sm')]: {
		  height: 600,
		},
	  },
	  cropButton: {
		flexShrink: 0,
		marginLeft: 16,
	  },
	  controls: {
		padding: 16,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		[theme.breakpoints.up('sm')]: {
		  flexDirection: 'row',
		  alignItems: 'center',
		},
	  },
	  sliderContainer: {
		display: 'flex',
		flex: '1',
		alignItems: 'center',
	  },
	  sliderLabel: {
		[theme.breakpoints.down('xs')]: {
		  minWidth: 65,
		},
	  },
	  slider: {
		padding: '22px 0px',
		marginLeft: 16,
		[theme.breakpoints.up('sm')]: {
		  flexDirection: 'row',
		  alignItems: 'center',
		  margin: '0 16px',
		},
	  },

}));

export default useStyles;