import { AAuthApi, IStripeBilling, RESTVerb } from "../../types";
import { UserPayementMethod } from "../../types/userSignUp";
import * as httpStatus from 'http-status';


export class BillingServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

	/**
	 * Récupère le status du commerçant.
	 * @param idTrader 
	 */
	public BillingSuscriptionProduct(idTrader: string , idPack: string, idProduct?: string): Promise<{ sessionId: string }> {
		return this.JsonAuthRequest(RESTVerb.POST, `billing/create-checkout-session`, { idTrader: idTrader, product: idProduct, idPack: idPack }).then(async (response: Response) => {

			if (response.status === httpStatus.NOT_FOUND) {
				throw new Error("Contactez le service client.");
			}
			if (response.status === httpStatus.BAD_GATEWAY) {
				throw new Error("Vérifiez vos informations dans la rubrique \"Mon abonnement\".");
			}
			if (response.status === httpStatus.BAD_REQUEST) {
				let error = await response.json();

				throw new Error(`Une erreur interne est survenue veuillez reessayer. ${error.error.message}`);
			}
			let result = await response.json();

			return result as { sessionId: string };
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Souscris a un abonnement gratuit
	 * @param idTrader 
	 */
	 public BillingSuscriptionFreeProduct(idTrader: string , idPack: string, idProduct?: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.POST, `billing/free/create-checkout-session`, { idTrader: idTrader, product: idProduct, idPack: idPack }).then(async (response: Response) => {

			if (response.status === httpStatus.NOT_FOUND) {
				throw new Error("Contactez le service client.");
			}
			if (response.status === httpStatus.BAD_GATEWAY) {
				throw new Error("Vérifiez vos informations dans la rubrique \"Mon abonnement\".");
			}
			if (response.status === httpStatus.BAD_REQUEST) {
				let error = await response.json();

				throw new Error(`Une erreur interne est survenue veuillez reessayer. ${error.error.message}`);
			}

			return true;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère le status du commerçant.
	 * @param idTrader 
	 */
	public ManageBilling(): Promise<string> {
		return this.JsonAuthRequest(RESTVerb.POST, `billing/customer-portal`).then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				//console.log("reponse", response);
				throw new Error(response.status.toString());
			}
			let result = await response.json();

			return result.url as string;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère le status du commerçant.
	 * @param idTrader 
	 */
	public getSessionCustomer(sessionId: string): Promise<string> {
		return this.JsonAuthRequest(RESTVerb.GET, `billing/checkout-session/${sessionId}`).then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();

			return result.url as string;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met a jour la méthode de paiement du consommateur
	 * @param idTrader 
	 */
	public updatePayementMethod(payementMethod: UserPayementMethod): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.PUT, `billing/payement/method`, payementMethod).then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(response.status.toString());
			}
			return true;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Renvoie les données de facturation du compte commercant connecté.
	 */
	public getBillingInformations(): Promise<IStripeBilling> {
		return this.JsonAuthRequest(RESTVerb.GET, `billing/informations`).then(async (response: Response) => {
			if (response.status !== httpStatus.FOUND) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();
			return result as IStripeBilling;
		}).catch((err) => {
			
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère sur stripe le prix d'un pack sur une durée (Annuel ou Mensuel)
	 * @param user 
	 */
	 public GetPriceOfPacks(idPrice: string): Promise<string> {
		return this.JsonAuthRequest(RESTVerb.POST, `billing/price/product`, {stripe_price: idPrice}).then(async (response: Response) => {
			console.log("res: ", response);
			if (response.status === httpStatus.OK) {
				let result = await response.json();
				return result;
			} else if (response.status === httpStatus.NO_CONTENT) {
				return [];
			} else {
				throw new Error("Conflict ! ");
			}
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}
}
