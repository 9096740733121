import {
	AAuthApi,
	IDish,
	RESTVerb,
} from "../../types";
//import { EnumUtils } from "../../utils";
import httpStatus from "http-status";

export class DishServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = `${serverURL}/dish`;
	}

	/**
	 * Crée un plat
	 * @param dish 
	 */
	public CreateDish(dish: IDish): Promise<IDish> {
		return this.JsonAuthRequest(RESTVerb.POST, '/', dish).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as IDish;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Crée un plat
	 * @param dish 
	 */
	 public CreateDishFromForm(dish: FormData): Promise<Boolean> {
		console.log("form data", dish);
		return fetch(`${this.baseUrl}/create/form`, {
			method: "POST",
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: dish,
		}).then(async (res: Response) => {
			if (res.status === httpStatus.CREATED) {
				return true;
			} else if (res.status === httpStatus.PARTIAL_CONTENT) {
				return false;
			} else {
				throw new Error("Le serveur a rencontré une erreur inconnue");
			}
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Upload image for a dish
	 * @param dish 
	 */
	public UploadImg(idDish: string, file: FormData): Promise<boolean> {
		return fetch(`${this.baseUrl}/upload/${idDish}`, {
			method: 'POST',
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: file,
		}).then((res) => {
			return (res.status === httpStatus.OK);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble des plats pour un commerçant.
	 * @param idTrader identifiant du commerçant.
	 */
	public GetAllDishByTrader(idTrader: string): Promise<IDish[]> {
		return this.JsonNoAuthRequest(RESTVerb.GET, `/all/${idTrader}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as IDish[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Duplique un plat dans une carte
	 * @param idTrader identifiant du commerçant.
	 */
	 public Duplicate(idDish: string, idCard: string): Promise<IDish> {
		return this.JsonAuthRequest(RESTVerb.POST, `duplicate/${idDish}/${idCard}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.ACCEPTED) {
				throw new Error("Impossible de dupliqué le plat");
			}
			return result as IDish;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Transfert un plat vers une autre catégorie
	 * @param idTrader identifiant du commerçant.
	 */
	 public Transfert(idDish: string, categoryLinkIdTo: string): Promise<IDish> {
		return this.JsonAuthRequest(RESTVerb.POST, `/transfertdishtocategory/${categoryLinkIdTo}/${idDish}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.ACCEPTED) {
				throw new Error(`Impossible to transfert code response ${res.status}`);
			}
			return result as IDish;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble des catégories d'un restaurateur
	 */
	public GetDishById(idDish: string): Promise<IDish> {
		return this.JsonNoAuthRequest(RESTVerb.GET, `/${idDish}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as IDish;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met à jour le plat 
	 * @param dish 
	 */
	public uploadDishSchedule(idDish: string, dish: IDish): Promise<IDish> {
		//console.log(JSON.stringify(dish));
		//console.log("token " + this.token);
		return this.JsonAuthRequest(RESTVerb.PUT, `${idDish}`, dish).then(async (res: Response) => {

			if (res.status !== httpStatus.OK) {
				throw new Error("Le plat n'as pas été mis a jour");
			}
			let result = await res.json();

			//console.log(result);
			return result as IDish;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Supression d'un plat de toutes les cartes et de la base de données
	 */
	 public deleteDishCascade(idDish: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.DELETE, `/byid/${idDish}`).then(async (res: Response) => {
			//let result = await res.json();
			console.log("Status delete dish !",res.status);
			if (res.status === httpStatus.INTERNAL_SERVER_ERROR) {
				throw new Error("Impossible de joindre le serveur");
			}
			if (res.status !== httpStatus.OK) {
				return false;
			}
			return true;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	public GetAllDishSortedByCategory(): any {
		throw new Error("Not implemented");
	}
}
