//export { IUser } from "./user.interface.NotUsed";
//export { ITrader as IScooker } from "./scooker.interface.NOTUSED";

export * from "./card.interface";
export * from "./category.interface";
export * from "./categoryLink.interface";
export * from "./customer.interface";
export * from "./dishes.interface";
export * from "./ingredient.interface";
export * from "./menu.interface";
export * from "./pdfCard.interface";
export * from "./qrcode.interface";
export * from "./role.interface";
export * from "./scan.interface";
export * from "./scanStat.interface";
export * from "./subAccount.interface";
export * from "./trader.interface";
export * from "./traderStat.interface";
export * from "./stripeBilling.interface";
export * from "./admin.interface";
export * from "./packYum.interface";
