import { ICategory } from "..";

export * from "./enum";
export * from "./idiscountSchedule";
export * from "./itemCard.interface";
export * from "./coordinates.interface";

export interface TileData {
	picture: string,
	keyTile: string,
	title?: string,
	price?: number,
}

export interface UserInfosDataForm {
	firstname: string;
	lastname: string;
	corporate_name?: string;
	mobile?: string;
	tva?: number;
	trader_type: string;
	iban?: string,
	rib?: string,
	siret?: string,
}

export interface APIError {
	error: string;
}

export type FrontError = {
	message?: string,
	code?: number,
	data?: any
}

export interface APIQueryResult<T> {
	data?: T,
}

export interface GroupedCategory {
	category: ICategory,
	subCategories: ICategory[] | undefined,
}
