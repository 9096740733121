import React from "react";
import Layout from "./Layout";
import { UserState, AppState } from "../../types";
import { connect } from "react-redux";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


interface Props {
	userData: UserState;
}

const BillingPayement: React.FC<Props> = ({ userData }: Props) => {
	//const location = useLocation();
	//const billingQuery = queryString.parse(location.search);


	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

	//let session = billingQuery.sessionid;
	//console.log(session);
	return (
		<Elements stripe={stripePromise}>
			<Layout />
		</Elements>
	)

};

export default connect(
	(state: AppState) => ({
		userData: state.user
	}),
	{
	}
)(BillingPayement);
