import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	circularProgress: {
		width: "15px",
	},
	textField: {
		height: "100%", 
		display:"flex", 
		justifyContent: "center", 
		alignItems: "center"
	},
	btn: {
		height: "100%", 
		display:"flex", 
		justifyContent: "center", 
		alignItems: "center", 
		flexDirection: "row"
	},
	divMobile: {
		display:"flex"
	},
	root: {
		display:"flex", 
		alignItems: "center", 
		flexDirection:"row", 
		justifyContent: "center"
	}
}));

export default useStyles;
