import React, { Fragment, useEffect, useContext } from "react";

import {
	Container,
	Snackbar,
	Typography,
	Divider,
	Grid,
	CircularProgress
} from "@material-ui/core";
import useStyles from "./styles";

import TraderSetting from "../../TraderSetting";
import { ITrader, IPackYum } from "../../../types";
// import clsx from "clsx";


import { Alert, AlertTitle } from "@material-ui/lab";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { APIManager } from "../../../services";
import { store } from "../../../contexts/auth";
import TipsManager from "../../../components/TipsManager";


interface Props {
}

const Layout: React.FC<Props> = () => {
	const classes = useStyles();
	let authContext = useContext(store);

	const [isSucces, setSucess] = React.useState(false);
	const [isError, setError] = React.useState(false);
	const [succesMsg, setSucessMsg] = React.useState<string>('Le pdf a été télécharger avec sucess');
	const [errorMsg, setErrorMsg] = React.useState<string>('Le téléchargement a rencontré une erreur, contacter le service client');
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [currentPack, setCurrentPack] = React.useState<IPackYum | undefined>(undefined);
	const [trader, setTrader] = React.useState<ITrader | undefined>(undefined);
	//authContext.authObj.userProfile === {} ? undefined : (authContext.authObj.userProfile) as ITrader

	const handlingClosePopUp = () => {
		setSucess(false);
		setError(false);
		setSucessMsg('Le pdf a été télécharger avec sucess');
		setErrorMsg('Le téléchargement a rencontré une erreur, contacter le service client');
	}

	/*
		useEffect(() => {
			console.log("Setting stack use effect !");
			if (!trader || !currentPack) {
				console.log("trader not in setting stack", trader);
				return;
			}
			console.log("Set loading in setting pack 1st effet false in setting stack because trader has been updated");
			setIsLoading(false);
		}, [currentPack]);*/

	useEffect(() => {
		if (!trader) {
			//console.log("trader not set in Setting Stack for pack", trader);
			return;
		}
		let apiAccount = APIManager.getAccountServices();

		//console.log("Log trader", trader);
		apiAccount.getPackForTrader(trader._id as string).then((pack) => {
			//console.log(pack);
			setCurrentPack(pack);
		}).catch((err) => {
			console.log(err);
		}).finally(() => {
			//console.log("Set loading in setting pack 2nd effet false in setting stack because trader has been updated");
			setIsLoading(false);
		});
	}, [trader]);

	useEffect(() => {
		//console.log("Lancer a chaque update ou mount du composant setting stack");
		authContext.authObj.getProfile().then((val) => {
			setTrader(authContext.authObj.userProfile as ITrader);
			//console.log(authContext.authObj.userProfile);
		}).catch((err) => {
			console.log("Err lors du chargement", err);
			setIsLoading(false);
		})
	}, [authContext.authObj]);

	return (
		<Fragment>
			<Container className={classes.root} maxWidth="lg" component="main">
				{!isLoading && trader &&
					(
						<Fragment>
							<div style={{ width: "100%" }}>
								<TipsManager />
								<Typography 
									variant="subtitle1" 
									component="h2" 
									gutterBottom 
									align="left" 
									className={classes.title}> Détails compte</Typography>
								<Divider />
								<Grid container style={{ display: "flex", flexDirection: "row", padding: "10px 0", justifyContent: "space-around" }}>
									<Grid item component='div' xs={12} sm={12} md={4}>
										<div style={{ flexDirection: "row", display: "flex", alignItems: "baseline" }}>
											<Typography variant="subtitle1" component="span" gutterBottom style={{ display: "flex", alignItems: "end" }}>
												Statut compte : {trader.verified ?
													(
														<Fragment>
															<Typography variant="body1" component="span" style={{ marginLeft: 5 }}>Vérifié </Typography>
															<VerifiedUserIcon style={{ marginLeft: 5 }} />
														</Fragment>
													) :
													(
														<Fragment>
															<Typography variant="body1" component="span" style={{ marginLeft: 5 }}>Non Vérifié </Typography>
															<RemoveCircleIcon style={{ marginLeft: 5 }} />
														</Fragment>
													)}
											</Typography>
										</div>
									</Grid>
									<Grid item component='div' xs={12} sm={12} md={4}>
										<div style={{ flexDirection: "row", display: "flex", alignItems: "baseline" }}>
											<Typography variant="subtitle1" component="span" gutterBottom style={{ display: "flex", alignItems: "center" }}>
												Statut abonnement : {trader.authorized ?
													(
														<Fragment>
															<Typography variant="body1" component="span" style={{ marginLeft: 5 }}>Actif</Typography>
															<FiberManualRecordIcon style={{ marginLeft: 5, fontSize: "1.2rem", color: "green" }} />
														</Fragment>
													) :
													(
														<Fragment>
															<Typography variant="body1" component="span" style={{ marginLeft: 5 }}>Non Actif</Typography>
															<FiberManualRecordIcon style={{ marginLeft: 5, fontSize: "1.2rem", color: "red" }} />
														</Fragment>
													)}
											</Typography>
										</div>
									</Grid>
									<Grid item component='div' xs={12} sm={12} md={4}>
										<Typography style={{ textTransform: "uppercase" }} variant="subtitle1" component="span" gutterBottom>abonnement : {currentPack?.name}</Typography>
									</Grid>
								</Grid>
								<div style={{ display: "flex", flexDirection: "row", padding: "10px 0", justifyContent: "space-around" }}>
								</div>
							</div>
							<TraderSetting trader={trader} />
							{/*<div>
								<Typography variant="subtitle1">Supprimer mon compte</Typography>
							</div>*/}
						</Fragment>
					)}
				{isLoading && (<div style={{ width: "100%" }}>
					(<CircularProgress color="primary" size={68} />
				</div>)}
			</Container>
			<Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={isSucces || isError} autoHideDuration={6000} onClose={handlingClosePopUp}>
				<Alert variant="filled" onClose={handlingClosePopUp} severity={isError ? "error" : "success"}>
					<AlertTitle>Statut du téléchargement</AlertTitle>
					{isError ? errorMsg : succesMsg}
				</Alert>
			</Snackbar>
		</Fragment >
	);
};


export default Layout;
