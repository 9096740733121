import {
	AAuthApi,
	ICard,
	RESTVerb,
	IDish,
	IMenu,
	//ICategory,
	GroupedCategory,
} from "../../types";
//import { EnumUtils } from "../../utils";
import httpStatus from "http-status";

export class CardServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}
	
	public createDishScheduleNew(form: FormData, idCard: string): Promise<boolean> {

		return fetch(`${this.baseUrl}/card/dishschedule/${idCard}`, {
			method: 'POST',
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: form,
		}).then(async (res) => {

			if (res.status !== httpStatus.CREATED  && res.status !== httpStatus.PARTIAL_CONTENT ) {
				throw new Error(res.status.toString());
			}

			console.log(res);
			if (res.status === httpStatus.CREATED) {
				return true;
			}
			return false;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	public createCard(cardName: string): Promise<ICard> {
		return this.JsonAuthRequest(RESTVerb.POST, 'card/new/empty', { name: cardName }).then(async (res: Response) => {

			if (res.status === httpStatus.CREATED) {
				let result = await res.json();
				return result as ICard;
			}
			throw new Error(`${res.status}`);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}


	public createDishSchedule(dishSchedule: IDish): Promise<IDish> {
		return this.JsonAuthRequest(RESTVerb.POST, 'card/dishschedule', dishSchedule).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.CREATED) {
				throw new Error("le resultat n'est pas correct: " + JSON.stringify(result))
			}
			return result as IDish;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	public createMenuScheduleForm(form: FormData, idCard: string): Promise<boolean> {

		return fetch(`${this.baseUrl}/card/menuschedule/${idCard}`, {
			method: 'POST',
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: form,
		}).then(async (res) => {
			
			if (res.status !== httpStatus.CREATED  && res.status !== httpStatus.PARTIAL_CONTENT ) {
				throw new Error(res.status.toString());
			}

			if (res.status === httpStatus.CREATED) {
				return true;
			}
			return false;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Update review link
	 * @param dish 
	 */
	public updateReviewLink(idCard: string, reviewLink: string): Promise<ICard> {
		return this.JsonAuthRequest(RESTVerb.PATCH, `card/do-update/review/${idCard}`,  {
			reviewLink: reviewLink,
		}).then(async (res) => {
			let result = await res.json();

			return result as ICard;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Update review link
	 * @param dish 
	 */
	public updateAnnoucement(idCard: string, announcement: string): Promise<ICard> {
		return this.JsonAuthRequest(RESTVerb.PATCH, `card/do-update/announce/${idCard}`,  {
			announcement: announcement,
		}).then(async (res) => {
			let result = await res.json();

			return result as ICard;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Update template
	 * @param dish 
	 */
	public updateTemplate(idCard: string, idTemplate: number): Promise<ICard> {
		return this.JsonAuthRequest(RESTVerb.PATCH, `card/do-update/template/${idCard}`,  {
			template: idTemplate,
		}).then(async (res) => {
			let result = await res.json();

			return result as ICard;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}


	/**
	 * Upload Pdf for a qrcode
	 * @param dish 
	 */
	public UploadPDF(idQrcode: string, file: FormData): Promise<boolean> {
		return fetch(`${this.baseUrl}/card/uploadpdf/${idQrcode}`, {
			method: 'POST',
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: file,
		}).then((res) => {
			return (res.status === httpStatus.OK);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Crée des menus et ajoutes ces reductions à la carte.
	 * @param menuSchedule 
	 */
	public createMenuSchedule(menuSchedule: IMenu): Promise<IMenu> {
		//console.log(JSON.stringify(menuSchedule));

		return this.JsonAuthRequest(RESTVerb.POST, 'card/menuschedule', menuSchedule).then(async (res: Response) => {
			//console.log(res.status === httpStatus.CREATED);
			if (res.status !== httpStatus.CREATED) {
				throw new Error("Le contenu attendu n'est pas le bon");
			}
			let result = await res.json();

			//console.log(result);
			return result as IMenu;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Recupère le menu avec ces reductions depuis un Id.
	 * @param idMenu Identifiant du menu
	 * @param idTrader Identifiant du commercant
	 */
	/*public GetMenuWithScheduleById(idMenuSchedule: string, idTrader: string): Promise<IMenuSchedule> {
		return this.AuthRequest(RESTVerb.GET, `card/menuschedule/byid/${idMenuSchedule}/${idTrader}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as IMenuSchedule;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}*/

	/**
	 * Récupère un plat avec ces réductions pour un identifiant donnée.
	 * @param idDishSchedule Identifiant du plat avec réduction.
	 * @param idTrader Identifant du commercant.
	 */
	/*public GetDishWithScheduleById(idDishSchedule: string, idTrader: string): Promise<IMenuSchedule> {
		return this.AuthRequest(RESTVerb.GET, `card/dishschedule/byid/${idDishSchedule}/${idTrader}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as IMenuSchedule;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}*/

	/**
	 * Récupère un plat avec ces réductions pour un identifiant donnée.
	 * old version now deplace to category service !! 
	 * @param idDishSchedule Identifiant du plat avec réduction.
	 * @param idTrader Identifant du commercant.
	 */
	public GetCategoriesFromCard(idCard: string): Promise<GroupedCategory[]> {
		return this.JsonAuthRequest(RESTVerb.GET, `card/categories/${idCard}`).then(async (res: Response) => {
			if (res.status === httpStatus.INTERNAL_SERVER_ERROR) {
				throw new Error(`${httpStatus.INTERNAL_SERVER_ERROR}`);
			}
			let result = await res.json();

			return result as GroupedCategory[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère un plat avec ces réductions pour un identifiant donnée.
	 * @param idDishSchedule Identifiant du plat avec réduction.
	 * @param idTrader Identifant du commercant.
	 */
	 public deleteCategorieFromCard(idCard: string, idCategory: string): Promise<ICard> {
		return this.JsonAuthRequest(RESTVerb.DELETE, `card/category/${idCategory}/card/${idCard}`).then(async (res: Response) => {
			if (res.status === httpStatus.OK) {
				let result = await res.json();

				return result as ICard;
			}

			throw new Error(`${res.status}`);
			
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble des cartes pour un commerçant.
	 * @param idTrader identifiant du commerçant.
	 */
	public GetAllCards(idTrader: string): Promise<ICard[]> {
		return this.JsonAuthRequest(RESTVerb.GET, `card/all/${idTrader}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as ICard[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Supprime la banniere d'une carte du commercant.
	 * @param idTrader 
	 */
	 public RemoveBannerFromCard(idCard: string): Promise<boolean> {

		return this.JsonAuthRequest(RESTVerb.DELETE,`card/banner/${idCard}`).then(async (res) => {

			if (res.status === httpStatus.INTERNAL_SERVER_ERROR  || res.status === httpStatus.CONFLICT ) {
				throw new Error(res.status.toString());
			}

			if (res.status === httpStatus.OK) {
				return true;
			}
			return false;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met a jour la banniere d'une carte du commercant
	 * @param idTrader 
	 */
	 public async UpdateBannerForCard(form: FormData, idCard: string): Promise<ICard> {

		return fetch(`${this.baseUrl}/card/do-update/banner/${idCard}`, {
			method: 'PATCH',
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: form,
		}).then(async (res) => {
			console.log(res);

			if (res.status !== httpStatus.OK) {
				throw new Error(res.status.toString());
			}
			return await res.json() as ICard;

		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère la carte d'un commerçant.
	 * @param idCard Identifiant de la carte à récupérer.
	 */
	 public GetOneCard(idCard: string): Promise<ICard> {
		return this.JsonAuthRequest(RESTVerb.GET, `card/one/${idCard}`).then(async (res: Response) => {

			if (res.status !== httpStatus.OK) {
				throw new Error(res.status.toString());
			}
			let result = await res.json();

			return result as ICard;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}
	

	/**
	 * Suppression d'un plat de la carte 
	 */
	public deleteDishFromCard(idDish: string, idCard: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.DELETE, `card/dish/${idDish}/card/${idCard}`).then(async (res: Response) => {

			//console.log(result);
			//console.log(res.status);
			if (res.status === httpStatus.CONFLICT) {
				return false;
			} else if  (res.status === httpStatus.OK) {
				return true;
			}
			throw new Error(`${res.status}`)
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Suppresion d'un menu de la carte 
	 */
	public deleteMenuFromCard(idMenu: string, idCard: string): Promise<ICard> {
		return this.JsonAuthRequest(RESTVerb.DELETE, `card/menu/${idMenu}/card/${idCard}`).then(async (res: Response) => {
			let result = await res.json();

			//console.log(result);
			//console.log(res.status);
			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as ICard;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Supression du plat de la carte et de la bdd
	 */
	public deleteDishCascadeFromCard(idDish: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.DELETE, `card/cascade/dish/${idDish}`).then(async (res: Response) => {
			//let result = await res.json();

			console.log("Status delete dish !",res.status);
			if (res.status !== httpStatus.OK) {
				return false;
			}
			return true;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Supression du menu de la carte et de la bdd
	 */
	public deleteMenuCascadeFromCard(idMenu: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.DELETE, `card/cascade/menu/${idMenu}`).then(async (res: Response) => {
			//let result = await res.json();

			//console.log(res.status);
			if (res.status !== httpStatus.OK) {
				return false;
			}
			return true;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Supression du menu de la carte et de la bdd
	 */
	 public deleteCard(idCard: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.DELETE, `card/one/${idCard}`).then(async (res: Response) => {
			if (res.status === httpStatus.OK) {
				return true;
			} else if (res.status === httpStatus.PARTIAL_CONTENT) {
				return false;
			}
			throw new Error(`${res.status}`);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met a jour l'ordre des categories contenu dans la carte
	 * @param categoriesOrdered nouvelle ordre des catégories
	 * @param idCard Identifiant de la carte a modifié
	 * @returns Resultat de la modification
	 */
	public setCategoriesOrder(categoriesOrdered: string[], idCard: string): Promise<boolean> {
		return this.JsonAuthRequest(
			RESTVerb.PATCH, 
			`card/setcategoriesorder/${idCard}`, 
			{ categorieOrder: categoriesOrdered }
		).then(async (res: Response) => {
			if (res.status === httpStatus.OK) {
				return true;
			} else if (res.status === httpStatus.CONFLICT) {
				return false;
			}
			throw new Error(`${res.status}`);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère les plats d'un restaurateur en fonction d'une catégorie
	 */
	/*public GetAllDishByCategorie(
		categorieSelected: DISH_CATEGORIE | undefined
	): Array<IDishSchedule> {
		throw new Error("Not implemented");
	}*/

	/*public GetAllDishSortedByCategory(): any {
		throw new Error("Not implemented");
	}

	public async CreateMenu(
		menu: IMenuSchedule,
		userid: string
	): Promise<boolean> {
		throw new Error("Not implemented");
	}*/

	/**
	 * Changement du nom de la card et retoune la card
	 * @param idCard L'id de la Card à modifier
	 * @param newNameCard Le nouveau nom de la card
	 * @returns La card en entier ou bien undefined
	 */
	public changeNameCard(idCard: string, newNameCard: string): Promise<ICard | undefined> {
		return this.JsonAuthRequest(RESTVerb.PATCH, `card/update/name/${idCard}`, { name: newNameCard }).then(async (res: Response) => {
			if (res.status === httpStatus.OK) {
				let result = await res.json();
				return result;
			}
			else if (res.status === httpStatus.CONFLICT) {
				return undefined;
			}
			throw new Error(`${res.status}`);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}
}
