import { IPackYum, ITrader } from '../../../types';
import { SET_CURRENT_PACK, SET_USERINFOS } from './actionTypes';

export const setUserInformations = (user: ITrader) => ({
	type: SET_USERINFOS,
	payload: {
        ...user,
	},
});

export const setCurrentPackData = (pack: IPackYum) => ({
	type: SET_CURRENT_PACK,
	payload: {
        ...pack,
	},
});