import React
, { Fragment,
useEffect }//, { useEffect } 
from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { 
  ICard,
  //CATEGORY_TYPE, 
  ICategory, ICategoryLink } from '../../types';
import ComboBox from '../autocomp/ComboBox';
//import { Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
//import * as Yup from "yup";
import { CircularProgress, TextField } from '@material-ui/core';
import { APIManager } from '../../services';
import { useSnackbar } from 'notistack';
import { YumButton } from '../../Lib/UI/YumButton';
import { StarHalf } from '@material-ui/icons';
//import { CategoryServices } from '../../api';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


interface Props {
  idCard: string,
  onCreateCallback: (newCardUpdated: ICard) => void,
  isOpen: boolean;
  callback : (status: boolean) => void,
}

const CardReviewHandlerModal: React.FC<Props> = ({ idCard, onCreateCallback, callback, isOpen }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [reviewLink, setReviewLink] = React.useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleClickOpen = () => {
    callback(true);
  };
  const handleClose = () => {
    callback(false);
  };


  const updateReviewLink = async () => {

    return APIManager.getCardServices().updateReviewLink(idCard, reviewLink!).then(async (isSuccess) => {
      let card = await APIManager.getCardServices().GetOneCard(idCard);

      enqueueSnackbar(`Le lien a été mis a jour.`, { 
        variant: "success",
        anchorOrigin: {
          vertical: 'top',
          horizontal:'right',
        }
      });
      onCreateCallback(card);

    }).catch((err) => {
      enqueueSnackbar(`Le lien n'a pas pu être crée`, { 
        variant: "error",
        anchorOrigin: {
          vertical: 'top',
          horizontal:'right',
        }
      });
    }).finally(() => {
      handleClose();
    });
  } 

  const GetReviewLink = async () => {
    let card = await APIManager.getCardServices().GetOneCard(idCard);

    setReviewLink(card?.reviewLink);
    setIsLoading(false);
  }

  useEffect(() => {
    if (reviewLink || !idCard)
      return;
    GetReviewLink();
  }, [idCard]);

  return (
    <React.Fragment>
      <YumButton
        style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "15px" }}
        onClick={handleClickOpen}
        >
        <Typography 
          component="span" 
          variant="body1" 
          style={{ marginRight: "5px" }}>Avis</Typography>
        <StarHalf />
      </YumButton>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            variant="h6"
            component="span"
            gutterBottom
            >
            Saisissez le lien vers la plateforme d'avis
            </Typography>
        </DialogTitle>
        <DialogContent dividers>
            {isLoading && <CircularProgress />}
            {!isLoading &&
            <Fragment>
              <Typography
                  variant="body2"
                  component="span"
                  gutterBottom
                  >
                  Contactez le service client si vous souhaitez etre aidé afin d'obtenir votre lien de redirection d'avis
                  </Typography>
              <TextField
                  id="description"
                  type="text"
                  label="URL avis"
                  name="description"
                  placeholder="https://search.google.com/local/writereview?placeid=XXXXXXXXXXXXXXX"
                  fullWidth
                  variant="outlined"
                  onChange={(event) => { 
                    setReviewLink(event.target.value);
                  }}
                  value={reviewLink}/>
            </Fragment>
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Annuler
          </Button>
          <Button 
            autoFocus 
            onClick={() => {
              if (!reviewLink)
                return;
              updateReviewLink();
            }} 
            variant="outlined" 
            color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CardReviewHandlerModal;
