import { 
	//createStyles, 
	makeStyles, 
	//Theme 
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	text: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center",
		justifyContent: "center",
		alignItems: "center"
	},
	bouton: {
		width: "100%", 
		display: "flex",
		justifyContent: "flex-end"
	}
}));

export {
	useStyles,
}