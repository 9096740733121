import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row'
	},
	secondWord: {
		color: 'black'
	},
	link: {
		textDecoration: 'none',
		color: 'initial',
	}
}));

export default useStyles;