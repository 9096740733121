import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import { useStyles } from "./styles";
//import Footer from "../Footer";
import Navbar from "../Navbar/Navbar";


type RegisterProps = {
	children: React.ReactElement;
};

const LandingNormalPage: React.FC<RegisterProps> = ({ children }: RegisterProps) => {
	const classes = useStyles();

	return (
		<div style={{ height: "auto" }} className={classes.root}>
			<Navbar />
			<CssBaseline />
			<Container className={classes.main} maxWidth="lg" component="main">
				{children}
			</Container>
			{/*<Footer />*/}
		</div>
	);
}
export default LandingNormalPage;
