import React, { Fragment, useEffect, useState } from "react";
import { IPackYum, BILLING_TYPE } from "../../types";
import Layout from "./Layout";
import { useLocation } from "react-router-dom";


interface Choosen {
	pack: IPackYum;
	billing_type: BILLING_TYPE;
}

interface Props {
	nextStep: () => void;
}

const PackRegistration: React.FC<Props> = ({ nextStep} :Props) => {
	//const [packs, setPacks] = useState<IPackYum[] | undefined>(undefined);
	const [isLoading, SetIsLoading] = useState<boolean>(true);
	const [pack, setPack] = useState<string | undefined>(undefined);
	const [billing, setBillingType] = useState<BILLING_TYPE | null>(null);

	//const [choice, setChoice] = React.useState<{ pack: IPackYum, billing_type: BILLING_TYPE } | undefined>(undefined);
	const location = useLocation();

/* 	const choose = (choosen: { pack: IPackYum, billing_type: BILLING_TYPE }) => {
		//setChoice(choosen);
	} */

/* 	const getPack = async () => {
		try {
			let packsFromBack: IPackYum[] | undefined = await APIManager.getPackServices().GetAllPacks();

			if (!packsFromBack) {
				return;
			}
			setPacks([...packsFromBack]);
		} catch (err){
			console.log(err);
		} finally {
			SetIsLoading(false);
		}
	} */

	useEffect(() => {
		if (isLoading) {
			//getPack();
			const query = new URLSearchParams(location.search);
			const packId = query.get("pack");
			const billing_type = query.get("type");
			const choosedBilling = billing_type === BILLING_TYPE.YEARLY ? BILLING_TYPE.YEARLY : (billing_type === BILLING_TYPE.MONTHLY ? BILLING_TYPE.MONTHLY : undefined);

			if (!packId || !choosedBilling) {
				// Lien non valide redirection vers pack d'essaie
			} else {
				setPack(packId);
				setBillingType(choosedBilling);
			}
			SetIsLoading(false);
		}
	}, []);

	/*if (!errorLoading) {
		return (<Layout choosen={choosen} nextStep={nextStep} packs={packs} />);
	}*/
	return (
	<Fragment>
		<Layout pack={pack} billing_type={billing} />
	</Fragment>)
};

export default PackRegistration;