import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'initial',
		minHeight: '100vh',
		padding: "initial"
	},

	/** Nav section */
	navSection: {
		display: "flex",
		flexDirection: "column",
		padding: "3vh 2vh",
		backgroundColor: "#F5F6FA",
		justifyContent: "space-arround",
		[theme.breakpoints.between('xs', 'sm')]: {
			display: "none",
		},
	},

	navSectionResponsive: {
		width: "100%",
		padding: "15px",
		display: "none",
		[theme.breakpoints.between('xs', 'sm')]: {
			display: "inline-table",
		},
		"& main": {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
		}
	},

	itemNav: {
		"&$selected": {
			backgroundColor: "red",
			color: "white"
		},
		"&$selected:hover": {
			backgroundColor: "purple",
			color: "white"
		},
		"&:hover": {
			backgroundColor: "blue",
			color: "white"
		}
	},

	/** Home section */
	homeSection: {
		padding: "15px 15px",
		display: 'flex',
		flexDirection: "column",
		height: "100%",
	},
	userDetailsSection: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		flexWrap: "wrap"
	},
	userStatus: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		"& svg": {
			fontSize: "2rem",
			marginLeft: "10px"
		}
	},
	userStatusValid: {
		color: "#2592F9"
	},
	userStatusFail: {
		color: "#ca2323"
	},
	userAbonnementStatus: {
		textAlign: "center",
		padding: "15px",
		borderRadius: "12px",
		'& div': {
			display: "flex",
			marginTop: "5px",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "center",
		},
		[theme.breakpoints.down('xs')]: {
			margin: "3vh 0",
			flex: "auto"
		},
	},
	quickStart: {
		textAlign: "center",
		height: "100%",
		padding: "10vh 0",
		[theme.breakpoints.between('xs', 'sm')]: {
			padding: "5vh 0",
		},
	},
	StepQuickStart : {
		display: "flex", 
		justifyContent: "center", 
		alignItems: "center", 
		flexDirection: "column", 
		height: "100%",
	},

	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},

	chipsDisplay: {
		minHeight: "30px",
		margin: "0px 10px",
	},

	cursorPointer : {
		'&:hover': {
			cursor: 'pointer',
	   },    
	},

	highlightsentences : {
		color : theme.palette.primary.main,
	},
}));

const useStylesBackDrop = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
	}),
);

export {
	useStyles,
	useStylesBackDrop
};
