import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
	link: {
		textDecoration: 'none',
		marginLeft: "5px",
		marginRight: "5px",
		color: "white",
		fontWeight: 'bold',
	},
	logo: {
		display: "flex",
		flexDirection: "row",
		padding: "5px",
	}

}));

export default useStyles;