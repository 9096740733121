import React, { Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";

import { IDiscountSchedule } from "../../types";
import { CssBaseline, Container, Paper, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import { DateTimeUtils, Time } from "../../utils";

interface Props {
	data: IDiscountSchedule[],
	onDelClick: (key: number) => void,
	OnItemClick: (key: number) => void,
}

const ScheduleList: React.FC<Props> = ({ data, OnItemClick, onDelClick }: Props) => {
	const classes = useStyles();
	// const defaultAddPicture = "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/OOjs_UI_icon_add.svg/600px-OOjs_UI_icon_add.svg.png";

	const displayTime = (date: Date) => {
		let time: Time = DateTimeUtils.getTime(date);

		return `${time.heure}:${time.minutes}`;
	}

	const formatDate = (date: Date) => {
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	}

	function PeriodValidity(props: any) {
		const discount: IDiscountSchedule = props.discount;


		if (!discount.start || !discount.end) {
			return (
				<Typography
					variant="subtitle1" 
					gutterBottom>
					<span>tous les jours de l'année</span>
				</Typography>
			);
		}
		return (
		<Typography
			variant="subtitle1" 
			gutterBottom>
			du <span className={classes.highlight}> {formatDate(discount.start)}</span>  au <span className={classes.highlight}>{formatDate(discount.end)}</span>
		</Typography>);
	}

	const displayDays = (days: Number) => {
		return DateTimeUtils.getDaysSelected(days as number).map((x) => x.substring(0, 3)).join(',');
	}

	return (
		<Fragment>
			<CssBaseline />
			<Container component="div" className={classes.root} maxWidth={false}>
				<div className={classes.subRoot}>
					{data.map((discount: IDiscountSchedule, index: number) => {
						return (
							<Paper elevation={6} onClick={() => OnItemClick(index)} className={classes.item} key={index}>
								<div className={classes.delRow} >
									<IconButton onClick={() => onDelClick(index)} className={classes.delButton} aria-label="concel">
										<CancelIcon />
									</IconButton>
								</div>
								<Typography
									variant="subtitle1" 
									gutterBottom>
									<span className={classes.highlight}>{discount.amount}%</span> valable de <span className={classes.highlight}>{displayTime(discount.startHours)}</span> a <span className={classes.highlight}>{displayTime(discount.endHours)}</span>
								</Typography>
								<PeriodValidity discount={discount} />
								<Typography
									variant="subtitle1" 
									gutterBottom>
									tous le(s) <span className={classes.highlight}>{displayDays(discount.days)}</span>
								</Typography>
							</Paper>
						);
					})}
				</div>
			</Container>
		</Fragment>

	);
};

export default ScheduleList;
