import { APIManager } from '../../../services';
import { IPackYum, ITrader } from '../../../types';
import { setCurrentPackData, setUserInformations } from '../../reducers/userV2/actionCreator';

export const getUserInformationThunk = () => {
  return (dispatch: any) => {
    APIManager.getAccountServices().getTraderInfos().then((data: ITrader) => {
      console.log("fetch user informations : ", data);
      dispatch(setUserInformations(data));
    }).catch((e) => {
      console.log(e);
    });
  };
};


export const GetPackForCurrentTraderThunk = (idTrader: string) => {
  return async (dispatch: any) => {
    APIManager.getAccountServices().getPackForTrader(idTrader)
    .then((data: IPackYum) => {
      //console.log("********** THUNK PACK USER **********")
      //console.log("data -> ", data);
      dispatch(setCurrentPackData(data));
    }).catch((e) => {
      console.log(e);
    });
  };
};
