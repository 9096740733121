import React, { Fragment, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import useStyles from "./styles";
import { CssBaseline, Button, Dialog, Tooltip, IconButton } from "@material-ui/core";
import { IMG_CONSTANTES } from "../../types/constantes/constantes";
import Resizer from 'react-image-file-resizer';
import { useSnackbar } from "notistack";
import CropImage from "../CropImage";
import { CanvasUtils } from "../../utils";
import { DISPLAY_SHAPE_ITEM } from "../../types";


interface Props {
	shape: DISPLAY_SHAPE_ITEM;
	minHeight: number;
	minWidth: number;
	fileLoaded?: string;
	galleryLoaded?: File[];
	onFileChange: (file: File | null) => void;
	onGalleryChange: (files: File[]) => void;
	titlePrincipal: string;
	titleGallery: string;
}

//const IMG_MINIMUM: number = 350;

const UploadImgZone: React.FC<Props> = ({ minHeight, minWidth, titleGallery, shape, fileLoaded, galleryLoaded, onFileChange, onGalleryChange }: Props) => {
	const classes = useStyles();
	const inputRef = React.useRef<HTMLInputElement>(null);
    const { enqueueSnackbar } = useSnackbar();
	// Images
	const [file, setFile] = React.useState<File | null | undefined>(null);
	const [fileToEdit, setFileToEdit] = React.useState<File | null>(null);
	const [isCropOpen, setIsCropOpen] = React.useState<boolean>(false);
	//const [gallery, setGallery] = React.useState<File[]>([]);

	const handleFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileEvent = e.target.files?.item(0);
		//let formData = new FormData();

		console.log("SHape is :", shape)
		if (fileEvent) {
			// TODO RESIZE IMAGE
			// KEEP PROPORTIONS !
			/*function resize(width, height, maxWidth, maxHeight) {
				var ratio = Math.min(maxWidth / width, maxHeight / height);
				var newWidth = ratio * width;
				var newHeight = ratio * height;
			
				console.log(newWidth + ' ' + newHeight); // Test
			
				// Process resizing...
			}*/
			try {
				let imgData = await CanvasUtils.createImage(URL.createObjectURL(fileEvent));

				console.log("ratio img : ", (imgData.width / imgData.height));
				// Verifie si le ratio est a 1
				if ((imgData.width / imgData.height) >= 1 && (imgData.width / imgData.height)  < 1.1) {
					// si oui, pas besoin de crop
					setIsCropOpen(false);
					resize(fileEvent, (resizedFile) => {
						setFile(resizedFile);
						onFileChange(resizedFile);
					})
				} else {
					// Si non, besoin d'un crop
					setFileToEdit(fileEvent);
					setIsCropOpen(true);
				}
			} catch (e) {
				console.log(e);
				setFileToEdit(fileEvent);
				setIsCropOpen(true);
			}

			/*try {
                Resizer.imageFileResizer(
				fileEvent,
                450,
                320,
                'PNG',
                100,
                0,
                uri => {
                    console.log(uri);
					setFile(uri as File);
					onFileChange(uri as File);
                },
                'file',
                300,
                300,
                );
            }   catch(err) {
				//setFile(fileEvent);
				//onFileChange(fileEvent);
				enqueueSnackbar(`Impossible de redéfinir la taille de l'image téléchargé.`, { 
                    variant: "success",
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal:'right',
                    }
                });
            }*/
			
		}
	}

	function callBackCroppedImg(croppedFile: File | null): void {

		setFileToEdit(null);
		handleClose();
		
		if (croppedFile === null) {
			enqueueSnackbar(`Impossible de découper l'image pour une qualité optimal, si l'erreur persiste contactez le service client dans la rubrique "contact".`, { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			return;
		}
		resize(croppedFile, (resizedFile) => {
			setFile(resizedFile);
			onFileChange(resizedFile);
		});

	}	

	function resize(fileEvent: File, callback: (newFile: File) => void): void {
		try {
			Resizer.imageFileResizer(
			fileEvent,
			300,
			shape === DISPLAY_SHAPE_ITEM.Square ? 300 : 150,
			'PNG',
			100,
			0,
			uri => {
				console.log(uri);
				callback(uri as File);
				//setFile(uri as File);
				//onFileChange(uri as File);
			},
			'file',
			300,
			shape === DISPLAY_SHAPE_ITEM.Square ? 300 : 150,
			);
		}   catch(err) {
			//setFile(fileEvent);
			//onFileChange(fileEvent);
			callback(fileEvent);
			enqueueSnackbar(`L'optimisation de votre imae a echoué, pour un affichage optimal veuillez re-télécharger celle-ci`, { 
				variant: "warning",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		}
	}

	const getItemSize = () => {
		let withSquare = { width: 250, height: 250 };
		let withRectangle = { width: 300, height: 150 };

		if (shape === DISPLAY_SHAPE_ITEM.Square)
			return withSquare;
		return withRectangle;
	}

	const getImageForDish = useMemo(() => {
		if (file !== null && file !== undefined) {
			return URL.createObjectURL(file);
		} else if (fileLoaded && file === null) {
			return fileLoaded;
		} else  {
			return IMG_CONSTANTES.DEFAULT_IMG;
		}
	}, [file]);

	const handleClose = () => {
		setIsCropOpen(false);
	}

	return (
		<Fragment>
			<CssBaseline />
			<div className={classes.rootContainer}>
				<section className={classes.rootPincipalImg}>
					<div className={classes.m_10} style={{ display:"flex", flexDirection: "row", justifyContent: "space-between", alignItems: "baseline"}}>
						<Typography
							variant="subtitle2"
							component="h3" 
							gutterBottom>
							Visuel principal
						</Typography>
						<Tooltip title="Supprimer l'image">
							<IconButton
							    color="secondary"
								aria-label="delete"
								onClick={(event) => {
									console.log("Click on delete item !");
									setFile(undefined);
									console.log("file after!", file);

									onFileChange(null);
								}}>
								<DeleteForeverRoundedIcon color="secondary"  />
							</IconButton>
						</Tooltip>
								
					</div>
					<div style={{ display: "flex", justifyContent: "center"}}>
						<div className={classes.imgContainer} style={getItemSize()} >
							{!file && (<div className={classes.imgBackgroundMinimumRequierement}>
								<Typography variant="h6">{`${minHeight} x ${minWidth}`} Minimum </Typography>
							</div>)}
							<img alt="img" style={{ width: "100%", height: "100%" }} src={getImageForDish} />
						</div>
					</div>
					<div className={classes.m_10}>
						<Button color="secondary" onClick={() => {
							if (inputRef.current) {
								inputRef.current.click();
							}
						}} style={{ width: "100%", alignSelf: "flex-end" }} variant="contained" size="large">Télèchargez votre image</Button>
					</div>
				</section>
				{/*<section>
					<Typography
						variant="h4"
						component="h3">
						Galleries
					</Typography>	
				</section>
				<section style={{ width: "100%" }}>
					<DropzoneArea
						acceptedFiles={['image/*']}
						dropzoneText={"Faites glisser les images secondaire de votre plat."}
						onChange={(files) => onGalleryChange([...files])}
						maxFileSize={100000}
						filesLimit={3}
					/>
				</section>*/}
				<input style={{ display: "none" }} ref={inputRef} accept="image/*" onChange={handleFileSelected} type="file" />
			</div>
			<Dialog fullScreen open={isCropOpen} onClose={handleClose}>
			{/*<div className={classes.App}>
				<div className={classes.cropContainer}>
					<Cropper
					image={getImageForDish()}
					crop={crop}
					zoom={zoom}
					aspect={1 / 1}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
					/>
				</div>
				<div className={classes.controls}>
					<Slider
					value={zoom}
					min={1}
					max={3}
					step={0.1}
					aria-labelledby="Zoom"
					onChange={(e, zoom) => setZoom(zoom as number)}
					className={classes.slider}
					/>
				</div>
    		</div>*/}
				<CropImage cropSize={shape === DISPLAY_SHAPE_ITEM.Large ? 3 / 2 : undefined} file={fileToEdit} onValidCropImage={callBackCroppedImg} onCancel={handleClose}/>
      		</Dialog>

		</Fragment>
	);
};

export default UploadImgZone;
