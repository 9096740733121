import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	link: {
		color: 'black',
	},

	makeLink: {
		textDecoration: "underline",
		"&:hover": {
			cursor: "pointer",
		}
	},

	backArrow: {
		margin: "3vh 0px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "inherit",
		alignItems: "flex-end",
		width: "100%",
	},

	removeOnXs: {
		[theme.breakpoints.up('xs')]: {
			marginTop: "2vh",
			display: "none"
		},
	},

	root: {
		display: 'flex',
		flexDirection: 'row',
		width: 'initial',
		height: '100%',
	},
	backgroungAnnouncement: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		alignItems: 'center',
		backgroundImage: "linear-gradient(135deg, #eaac7e 0%, #f47150 100%)",

		[theme.breakpoints.between('md', 'xl')]: {
			backgroundColor: theme.palette.primary.main,
			flexGrow: 1,
		},
	},
	announcement: {
		color: "white",
		maxWidth: "500px",
		[theme.breakpoints.between('xs', 'sm')]: {
			display: "none"
		},
	},
	logo: {
		position: 'absolute',
		top: '3%',
		left: '2%',
		border: 'white 1px solid',
		padding: '5px',
		borderRadius: '5px',
		backgroundColor: 'white'
	},
	signin: {
		flexGrow: 5, /* default 0 */
		[theme.breakpoints.between('xs', 'sm')]: {
			flexGrow: 1
		},
	},
	signInRoot: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		alignItems: 'center',
		height: '100%',
		[theme.breakpoints.between('xs', 'sm')]: {
			margin: "5vh 0"
		},		
	},
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	error: {
		color: "red",
	},

	formValidationMail: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxWidth: "600px"
	},
	HighlightLine: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
	},
}));

const useStylesBackdrop = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
	}),
);

export {
	useStyles,
	useStylesBackdrop
}
