import { FETCH_APP_DATA, PUT_QRCODE, SET_COLOR } from "./actionTypes";
import { AppColorPersonnalization, IQrcode } from "../../../types";
import { APIManager } from "../../../services";

export const PutQrcode = (qrcode: IQrcode[]) => ({
	type: PUT_QRCODE,
	payload: {
        qrcode: qrcode,
	},
});

export const PutColorPersonalization = (color: AppColorPersonnalization) => ({
	type: SET_COLOR,
	payload: color,
});

//synchronous action creator
const fetchAppSettinsColorSuccess = (appSettingColor: AppColorPersonnalization) => ({
    type: FETCH_APP_DATA,
    payload: { appSettingColor }
});

/*asynchronous thunk action creator
  calls the api, then dispatches the synchronous action creator
*/
export const fetchAppSettinsColor =  () => {
    return async (dispatch: any) => {
        try {
            let AppSettingColors = await APIManager.getAccountServices().GetColorSettings();

            dispatch(fetchAppSettinsColorSuccess(AppSettingColors)) //store first five posts
        }
        catch(e){
            console.log("FAILED TO GET APPCOLORSETTINGS");
            console.log(e)
        }
    }
}