import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

let theme = createMuiTheme({
	typography: {
		fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
		h1: {
			fontFamily: ["Nunito Sans", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "3.5rem",
			fontWeight: "bold",
			paddingTop: '1%',
			paddingBottom: '1%',
		},
		h2: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "3rem",
			fontWeight: "bold",
		},
		h3: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "2.5rem",
			fontWeight: "bold",
		},
		h4: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "2.2rem",
		},
		h5: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "1.9rem",
		},
		h6: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "1.7rem",
		},
		subtitle1: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "1.5rem",
			lineHeight: 1,
		},
		subtitle2: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "1.3rem",
			lineHeight: 1,
		},
		/**
		 * Texte principal
		 */
		body1: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "1rem",
		},
		body2: {
			fontFamily: ["Nunito Sans", "Fredoka One", "Lato", "Open Sans", "Roboto"].join(","),
			fontSize: "0.9rem",
		},
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				html: {
					backgroundColor: '#fff',
				},
			},
		},
	},
	props: {},
	palette: {
		primary: {
			main: "#f47150",
			light: "#000",
			dark: "#eaac7e",
		},
		secondary: {
			main: "#003366",
			light: "#fff"
		},
		background: {
			default: "#fff",
		}
	}
});


export default responsiveFontSizes(theme);
//#eaac7e

//main: "#9970FF",
