import { 
	//AApi, 
	RESTVerb, ICategory, AAuthApi, 
	//CATEGORY_TYPE, 
	ICategoryLink, 
	ICategoryOrganization} from "../../types";
import * as httpStatus from 'http-status';

export class CategoryServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl =  `${serverURL}/category`;
	}

	/**
	 * Récupère l'ensemble des catégories enregistré dans la bdd
	 * @param user 
	 */
	public GetAllCategories(): Promise<ICategory[]> {
		return this.JsonNoAuthRequest(RESTVerb.GET, "/").then(async (response: Response) => {
			let result = await response.json();
			if (!result || response.status !== httpStatus.OK) {
				throw new Error("Une erreur est survenue");
			}
			//console.log(response);
			return result as ICategory[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble des catégories enregistré pour l'utilisateur
	 * @param user 
	 */
	public GetAllCategoriesForTrader(idTrader: string): Promise<ICategory[]> {
		return this.JsonNoAuthRequest(RESTVerb.GET, `/${idTrader}`).then(async (response: Response) => {
			let result = await response.json();
			if (!result || response.status !== httpStatus.OK) {
				throw new Error("Une erreur est survenue");
			}
			//console.log(response);
			return result as ICategory[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	public GetCategoriesFromCard(idCard: string): Promise<ICategoryLink[]> {
		return this.JsonNoAuthRequest(RESTVerb.GET, `/card/find/onecard/${idCard}`).then(async (response: Response) => {
			if (response.status === httpStatus.CONFLICT) {
				throw new Error("Une erreur est survenue");
			}
			let result = await response.json();

			//console.log(response);
			return result as ICategoryLink[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Créer une catégorie pour un utilisateur et la lui lie.
	 * @param user 
	 */
	 public CreateUserCategory(newCategory: ICategoryLink, idCard: string, ref?: string): Promise<ICategoryLink> {
		return this.JsonAuthRequest(RESTVerb.POST, `user/new/category`, {
			name: newCategory.name,
			idCard: idCard,
			description: newCategory.description,
			ref: ref,
		}).then(async (response: Response) => {
			if (response.status !== httpStatus.CREATED) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();
			return result as ICategoryLink;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Créer une catégorie pour un utilisateur et la lui lie.
	 * @param user 
	 */
	public CreateUserSubCategory(newCategory: ICategoryLink, idCard: string, parentCategory: string, ref?: string): Promise<ICategoryLink> {
		return this.JsonAuthRequest(RESTVerb.POST, `user/new/subcategory`, {
			name: newCategory.name,
			idCard: idCard,
			subCategory: parentCategory,
			description: newCategory.description,
			ref: ref,
		}).then(async (response: Response) => {
			if (response.status !== httpStatus.CREATED) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();
			return result as ICategoryLink;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Recupere l'organisation d'une carte
	 * @param user 
	 */
	 public GetCategoryOrganization(idCategory: string, idCard: string): Promise<ICategoryOrganization> {
		return this.JsonAuthRequest(RESTVerb.GET, `/organize/${idCard}/${idCategory}`)
		.then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();
			return result as ICategoryOrganization;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Réorganise la carte
	 * @param user 
	 */
	 public setOrganization(idCategory: string, idCard: string, organization: string[]): Promise<Boolean> {
		return this.JsonAuthRequest(RESTVerb.POST, `/organize/${idCard}/${idCategory}`, {
			organization: organization,
		})
		.then(async (response: Response) => {
			return response.status === httpStatus.OK;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Créer une catégorie pour un utilisateur et la lui lie.
	 * @param user 
	 */
	 public updateDescriptionAndName(idCategory: string, name: string, description?: string): Promise<ICategoryLink> {
		return this.JsonAuthRequest(RESTVerb.PATCH, `/user/basicdata/${idCategory}`, {
			name: name,
			description: description,
		}).then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();
			return result as ICategoryLink;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

}
