import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import useStyles from "./styles";
import { AppState, UserState } from "../../types";
import { CssBaseline } from "@material-ui/core";

import { LogOutUser } from "../../redux/user";
import { storageservice, APIManager } from "../../services";


interface Props {
	userdata: UserState;
	displayLogo?: boolean;
	logOut: () => any;
}

const ScookNavbar: React.FC<Props> = ({ userdata, displayLogo, logOut }: Props) => {
	const classes = useStyles();

	const handleLogOut = (event: React.MouseEvent) => {
		logOut();
		storageservice.deleteAll();
		APIManager.clearTokenAPIS();
	}

	/**
	 * Renvoie les éléments à affichés lorsque l'utilisateur est connecté
	 */
	function LoggedDisplay(props: any) {
		return (
			<Fragment>
				<div className={classes.menu}>
					<Typography>Bonjour {userdata.userdata?.email}</Typography>
					<NavLink className={classes.link} to="/createmenu">Dashboard</NavLink>
					<NavLink className={classes.link} onClick={handleLogOut} to="/">Déconnexion</NavLink>
				</div>
			</Fragment>
		);
	}

	/**
	 * Renvoie les éléments à affichés lorsque l'utilisateur n'est pas connecté
	 */
	function UnLoggedDisplay(props: any) {
		return (
			<div>
				<Button color="inherit">
					<NavLink to="/signup">Sign Up</NavLink>
				</Button>
				<Button color="inherit">
					<NavLink to="/signin">Sign In</NavLink>
				</Button>
			</div>
		);
	}

	/**
	 * Renvoie les bonnes options selon l'utilisateur connecté
	 * @param props
	 */
	function Options(props: any) {
		const isLoggedIn: boolean = props.isLoggedIn;
		const isDisplayLogo: boolean = props.isDisplayLogo;


		if (isLoggedIn) {
			return <LoggedDisplay className={isDisplayLogo && ({ alignSelf: "flex-end" })} />;
		}
		return <UnLoggedDisplay className={isDisplayLogo && ({ alignSelf: "flex-end" })} />;
	}

	return (
		<Fragment>
			<CssBaseline />
			<AppBar position="static" color="default" className={classes.root}>
				<Toolbar className={classes.toolbar}>
					{displayLogo && (<Typography className={classes.title} variant="h6" color="inherit" noWrap>
						YumScan
					</Typography>)}
					<div><Options isLoggedIn={userdata.isAuthentified} /></div>
				</Toolbar>
			</AppBar>
		</Fragment>
	);
};

export default connect(
	(state: AppState) => ({
		restaurants: state.entities.restaurants || [],
		userdata: state.user,
	}),
	{
		logOut: LogOutUser,
	}
)(ScookNavbar);
