import { DAYS } from "../types";
const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export interface Time {
	heure: number,
	minutes: number,
	secondes: number
}
export class DateTimeUtils {
	public static addDays(days: number): Date {
		var date = new Date();
		date.setDate(date.getDate() + days);
		return date;
	}

	public static getTime(date: Date): Time {
		return {
			heure: date.getHours(),
			minutes: date.getMinutes(),
			secondes: date.getSeconds(),
		};
	}


	public static getDaysSelected(days: number): string[] {
		let selected = [];

		for (var flag in DAYS) {
			var val = DAYS[flag] as unknown as number;

			if ((days & val) === val) {
				selected.push(flag);
			}
		}
		return selected;
	}

	/**
	 * Give days differences between two date
	 * @param dateToCompare 
	 * @param dateInFuture 
	 * @returns 
	 */
	public static dateDiffInDays(dateToCompare: Date, dateInFuture: Date) {
	  // Discard the time and time-zone information.
	  const utc1 = Date.UTC(dateToCompare.getFullYear(), dateToCompare.getMonth(), dateToCompare.getDate());
	  const utc2 = Date.UTC(dateInFuture.getFullYear(), dateInFuture.getMonth(), dateInFuture.getDate());
	
	  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
	}

	/**
	 * Transform date to date DD/MM/YYYY format
	 * @param d 
	 * @returns 
	 */
	public static formattedDate(d = new Date) {
		if (!d) {
			return '';
		}
		return [d.getDate(), d.getMonth()+1, d.getFullYear()]
		.map(n => n < 10 ? `0${n}` : `${n}`).join('/');
	}
}
