import React, { Fragment } from "react";
import { Container, Typography, Breadcrumbs } from "@material-ui/core";
import useStyles from "./styles";
import Contact from "../../../components/Contact/Contact";


interface Props {
}

const ContactStack: React.FC<Props> = (Props) => {
    const classes = useStyles();
    
	return (
		<Fragment>
			<Container className={classes.root} maxWidth="lg" component="main">
				<div className={classes.navSection}>
					<Breadcrumbs aria-label="breadcrumb">
						{/*<Link color="inherit" href="/" onClick={() => { }}>*/}
							<Typography
								component="span"
								variant="body2"
							>
								Nous contacter
							</Typography>
						{/*</Link>*/}
					</Breadcrumbs>
				</div>
				<div style={{ width: "100%" }}>
					<Contact />
				</div>
			</Container>
		</Fragment >
	);
};

export default ContactStack;
