import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	minValues: {
		minHeight: "175px",
		maxHeight: "200px",
		minWidth: "175px",
		maxWidth: "200px",
		position: 'relative',
		margin: "10px",
		zIndex: 0,

		'&:hover': {
			cursor: "grab",
		}
	},
	contentRoot: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		alignItems: 'center'
	},
	makeBackground: {
		height: '100%',
		width: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundColor: 'rgb(0,0,0,0.2)',
		zIndex: 1,
	},
	title: {
		alignSelf: 'end',
		width: '100%',
		position: 'relative',
		zIndex: 2,
		color: 'white'
	},
	price: {
		alignSelf: 'center',
		width: '100%',
		position: 'relative',
		zIndex: 2,
		color: 'white',
		fontWeight: 'bold',
	},
	tagline: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
	}
}));

export default useStyles;