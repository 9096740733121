import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'initial',
		minHeight: '100%',
		paddingTop: "3vh"
	},

	/** Nav section */
	navSection: {
		display: "flex",
		flexDirection: "row",
		marginBottom: "3vh"
	},
}));

export default useStyles;
