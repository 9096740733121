import React, { useEffect, Fragment } from "react";
import { useStyles, useColorlibStepIconStyles } from "./styles";

import { 
	//BILLING_TYPE, IPackYum, 
	UserState } from "../../../types";
import {
	// Container,
	CssBaseline,
	Stepper,
	Step,
	StepLabel,
	StepConnector,
	Typography,
	StepIconProps,
	withStyles,
} from "@material-ui/core";

import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';

import clsx from 'clsx';
import Register from "../../Register";
import Creation from "../Creation";
import Payement from "../Payement";

import LogoName from "../../../components/LogoName/LogoName";
import UserSignUp from "../../../types/userSignUp";
import PackRegistration from "../../PackRegistration";


function getSteps() {
	return [
		"Inscrivez vous !",
		"Remplissez le contenu de votre carte",
		"Definissez vos mode de paiement",
		"Generer vos QR Code",
		"Profitez de scook",
	];
}

interface Props {
	userdata: UserState;
}

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
			backgroundImage:
				'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
		},
	},
	completed: {
		'& $line': {
			backgroundImage:
				'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
		},
	},
	line: {
		height: 5,
		maxWidth: '5px',
		marginLeft: '10px',
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
		minHeight: '30px',
	},
})(StepConnector);


const Layout: React.FC<Props> = ({ userdata }: Props) => {
	const classes = useStyles();
	//const [open, setOpen] = React.useState(false);
	const [activeStep, setActiveStep] = React.useState(userdata.isAuthentified ? 2 : 0);
	const steps = getSteps();

	useEffect(() => {
		setActiveStep(userdata.isAuthentified ? 1 : 0);
	}, []);

	function ColorlibStepIcon(props: StepIconProps) {
		const classes = useColorlibStepIconStyles();
		const { active, completed } = props;

		const icons: { [index: string]: React.ReactElement } = {
			1: <SettingsIcon />,
			2: <GroupAddIcon />,
			3: <VideoLabelIcon />,
		};

		return (
			<div
				className={clsx(classes.root, {
					[classes.active]: active,
					[classes.completed]: completed,
				})}
			>
				{icons[String(props.icon)]}
			</div>
		);
	}

	/*   const addItem = () => {
	  console.log("cliquer pour ouverture modal !!");
	  setOpen(true);
	}; */

	/*   const addMenu = () => {
	  console.log("adding menu");
	}; */

	/*   const handleClose = () => {
	  setOpen(false);
	}; */

	const handleNext = () => {
		console.log("Handle next ");
		setActiveStep((prevActiveStep) => {
			//console.log(prevActiveStep + 1);
			return prevActiveStep + 1;
		});
	};

	/*   const handleBack = () => {
	  setActiveStep((prevActiveStep) => prevActiveStep - 1);
	}; */

	/*   const handleImageDownload = () => {}; */

	function DynamicComponent(props: any) {
		const currentStep: number = props.currentStep;

		const [userSignUpInfos, 
			//setUserSignUpInfos
		] = React.useState<UserSignUp | undefined>(undefined);
		//const [choice, setChoice] = React.useState<{ pack: IPackYum, billing_type: BILLING_TYPE } | undefined>(undefined);

		switch (currentStep) {
			case 0:
				return <PackRegistration nextStep={handleNext} />;
			case 1:
				return <Payement userdata={userdata} nextStep={handleNext} traderInfos={userSignUpInfos} />;
			case 2:
				return <Creation userData={userdata} nextStep={handleNext} />; // TODO : decommenter dans la prochaine version full digital
			default:
				return <Register nextStep={handleNext} />;
		}
	}
	function getStepContent(step: number) {
		switch (step) {
			case 0:
				return `Augmentez votre productivité`;
			case 1:
				return `Votre moyen de paiement`;
			case 2:
				return `Ajustez votre offres avec nos modules`;
			case 3:
				return `Créer et remplissez votre carte`;
			default:
				return 'Digitaliser sont restaurant n\'a jamais été aussi simple';
		}
	}

	/* 	let liClasses = classNames({
			line: classes.connectorLine,
		}); */

	return (
		<Fragment>
			<CssBaseline />
			<div className={classes.root}>
				<div className={classes.backgroundStepper}>
					<div className={classes.logo} >
						<LogoName />
					</div>
					<div className={classes.speech}>
						<Typography variant="h2" component="h2">{getStepContent(activeStep)}</Typography>
					</div>
					<div className={classes.stepper}>
						<Stepper activeStep={activeStep} className={classes.stepperContent} orientation="vertical" connector={<ColorlibConnector />}>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel StepIconComponent={ColorlibStepIcon}>
										<Typography variant="h4" component="h4">{label}</Typography>
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</div>
				</div>
				<div className={classes.content}>
					<DynamicComponent currentStep={activeStep} />
				</div>
			</div>
		</Fragment>
	);
};


// STEPPER CODE
/*
<div className={classes.stepperContent}>
					
					<Stepper
						className={classes.stepper}
						connector={<StepConnector className={liClasses} />}
						activeStep={activeStep}
						orientation="vertical"
					>
						{steps.map((label, index) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</div>
				<Divider className={classes.divider} orientation="vertical" flexItem /> 
		*/

export default Layout;
