import React from "react";

import {
    Grid,
    TextField,
	Typography,
} from "@material-ui/core";

interface Props {
}

function handleChangeName(event: any) {
    
}

function handleChangeDescription() {
}

function handleChangePrice() {
}

function handleChangeCategory() {
}

const DescriptionForm: React.FC<Props> = () => {

	return (
    <React.Fragment>
        <Typography variant="h6" gutterBottom>
        Dish description
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Name"
            fullWidth
            autoComplete="dish-name"
            //defaultValue={}
            onChange={handleChangeName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="description"
            name="decription"
            label="Description"
            fullWidth
            autoComplete="dish description"
            //defaultValue={this.state.description}
            onChange={handleChangeDescription}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="price"
            name="price"
            label="Price"
            fullWidth
            autoComplete="dish price"
            //defaultValue={this.state.price}
            onChange={handleChangePrice}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="category"
            name="category"
            label="Category"
            fullWidth
            autoComplete="dish category"
            //defaultValue={this.state.Category}
            onChange={handleChangeCategory}
          />
        </Grid>
      </Grid>
    </React.Fragment>
	);
}

export default DescriptionForm;
