import React, { Fragment, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useStyles } from "./styles";
import { TextField, Typography } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { APIManager } from "../../../services";
import { YumButton } from "../../../Lib/UI/YumButton";

type PasswordResetProps = {
};

const PasswordReset: React.FC<PasswordResetProps> = () => {
	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [isSended, setisSended] = useState<boolean>(false);
	const [email, setEmail] = useState<string | undefined>(undefined);

	return (
		<Fragment>
			<CssBaseline />
			{!isSended && (
			<Fragment>
				<Typography component="h1" variant="subtitle1" gutterBottom>réinitialisez votre mot de passe</Typography>
				<Typography component="h1" variant="body1" gutterBottom>Saisissez l'adresse email utilisée lors de votre inscription </Typography>
				<Formik
					initialValues={{ 
						email: "",
					}}
					validateOnChange={false}
					onSubmit={(values, { setSubmitting }) => {
						setIsSubmited(false);
						setSubmitting(false);

						APIManager.getAccountServices().EmitResetPasswordMail(values.email).then((res: boolean) => {
							if (!res) {
								throw new Error("Le mail n'as pas pu être envoyé, contactez le service client.");
							}
							enqueueSnackbar("Un email de réinitialisation de mot de passe a été envoyé.", { 
								variant: "info",
								anchorOrigin: {
									vertical: 'top',
									horizontal:'right',
								}
							});
							setisSended(true);
							setEmail(values.email);
						}).catch((err: Error) => { 
							enqueueSnackbar(err.message, { 
								variant: "error",
								anchorOrigin: {
									vertical: 'top',
									horizontal:'right',
								}
							});
						});						
					}}
					validationSchema={Yup.object().shape({
						email: Yup.string().email("Votre email est au mauvais format").required("Champ obligatoire"),
					})}
					>
					{props => {
						const {
						values,
						//touched,
						errors,
						//dirty,
						//isSubmitting,
						//isValidating,
						handleChange,
						handleBlur,
						handleSubmit,
						//handleReset
						} = props;
						return (
						<form className={classes.formValidationMail} onSubmit={handleSubmit}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email"
								name="email"
								autoComplete="email"
								value={values.email}
								type="email"
								onChange={handleChange}
								onBlur={handleBlur}
								error={isSubmited && errors.email ? true : false}
								helperText={isSubmited ? errors.email : undefined}
							/>
							<div style={{ width: "100%", display: "flex", justifyContent: "flex-end"}}>
								<YumButton 
									style={{ marginTop: 15 }} 
									onClick={(event) => {
										setIsSubmited(true);
									}} 
									type="submit">
									Soumettre
								</YumButton>
							</div>							
						</form>
						);
					}}
				</Formik>
			</Fragment>)}
			{isSended && 
			(<Fragment>
				<Typography component="h1" variant="body1" gutterBottom>Un mail de réinitialisation de mot de passe a été envoyé à l'adresse {email}, <br /> vous disposez de 30 minutes pour réinitialiser votre mot de passe.</Typography>
			</Fragment>) }
		</Fragment>
	);
}
export default PasswordReset;
