import React, { Fragment, useCallback } from "react";
import { AppState, UserStateV2, YUMSCAN_FEATURES } from "../../../types";
import { connect } from "react-redux";
import { FlagUtil } from "../../../utils/flagsUtils";

type ScopedAreaProps = {
	userV2: UserStateV2;
	area: YUMSCAN_FEATURES;
	children: React.ReactElement;
};

/**
 * Affiche le composant parent l'utilisateur connecté est dans le scope 
 */
const ScopedArea: React.FC<ScopedAreaProps> = (props) => {
	
	const shouldBeDisplayed = useCallback(() => {
		if (!props.userV2.currentPack) {
			return false;
		}
		return FlagUtil.has(props.userV2.currentPack.features, props.area);
	},[props.userV2.currentPack])

	return <Fragment>{shouldBeDisplayed() && props.children}</Fragment>;
};

export default connect(
	(state: AppState) => ({
		userV2: state.userV2,
	}),
	{}
)(ScopedArea);
