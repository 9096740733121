import React, { useEffect } from "react";
import QRCode from "easyqrcodejs";
// import Layout from "./Layout";
// import { AppState, UserState } from "../../types";
// import { connect } from "react-redux";
/*
interface Props {
	userdata: UserState;
}

const Dashboard: React.FC<Props> = ({ userdata }: Props) => (
	<Layout userdata={userdata} />
);

export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}),
	null
)(Dashboard);
*/
function Qrcode() {
	const code = React.createRef<HTMLDivElement>();

	useEffect(() => {
		new QRCode(code.current, { text: "https://github.com/ushelp/EasyQRCodeJS" });
	}, [code]);

	return (
		<div className="Qrcode">
			<header className="App-header">

				<div ref={code}></div>
			</header>
		</div>
	);
}

export default Qrcode;