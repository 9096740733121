import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'initial',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: '30px',
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "white",
		borderRadius: "15px",
	},
	/*link: {
		textDecoration: 'none',
		color: 'inherit',
	},*/

	textRoot: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center",
		marginBottom: "3vh"
	},
	
	packRoot: {
		padding: "40px 30px",
		borderRadius: "15px",
		minHeight: "550px",
		position: "relative",
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around"
	},
	promo: {
		color: "black",
		fontWeight: "bold",
		position: "absolute", 
		padding: "5px", 
		right: "15px", 
		top:"15px",
		border: "2px dashed",
		borderColor: theme.palette.primary.main,
		borderRadius: "5px"
	},
	priceMonthly : {
		display: "flex", 
		flexDirection: "row", 
		justifyContent: "center", 
		alignItems: "baseline",
		marginTop: "10px",
	},
	link: {
		textDecoration: 'none',
		color: 'inherit',
		width: "100%",
	},
	HighlightLine: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
	},
}));

export default useStyles;
