import { 
	//AApi, 
	AAuthApi, 
	RESTVerb, 
} from "../../types";

import * as httpStatus from 'http-status';


export class ContactServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

	/**
	 * Send Anonyme and Auth Contact Message
	 * 
	 */
	public Contact(email: string, subject: string, message: string, mobile: string, name?: string): Promise<boolean> {
		return this.JsonNoAuthRequest(RESTVerb.POST, "contact/instant/messaging",
			//(this.token ?  "contact/instant/messaging/auth" : "contact/instant/messaging"),
			{
				email: email,
				subject: subject,
				message: message,
				name: name,
				mobile: mobile,
			}).then(async (res: Response) => {
			if (res.status === httpStatus.OK) {
				return true;
			}
			return false;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	public ContactAuth(email: string, subject: string, message: string,mobile: string, name?: string): Promise<boolean> {
		return this.JsonAuthRequest (RESTVerb.POST, "contact/instant/messaging/auth",
			{
				name: name,
				email: email,
				subject: subject,
				mobile: mobile,
				message: message
			}).then(async (res: Response) => {

			if (res.status === httpStatus.OK) {
				return true;
			}
			return false;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

}
