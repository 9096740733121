import React, { Fragment, useEffect, useContext  } from "react";
import useStyles from "./styles";
import { ICard, ComposantMode, ITrader, IQrcode, CARD_ITEM_TYPE } from "../../../types";
import { Chip, CircularProgress, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fade, Grid, IconButton, Modal, Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { CardServices } from "../../../api";
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import { APIManager } from "../../../services";
import { AlertTitle } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { store } from "../../../contexts/auth";
import clsx from "clsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CreateDigitalCard from "../CreateDigitalCard";
import ManageCategories from "../ManageCategories";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ManageQrcodeDigital from "../ManageQrcodeDigital";
import YumChangeInput from "../../../components/Lib/UI/YumChangeInput"
import { YumButton } from "../../../Lib/UI/YumButton";


interface Props {
	backToDefault: () => void
}

//const url_server = process.env.REACT_APP_BASE_URL;

enum STATE_PAGE_MANAGER {
	CREATE_CARD,
	PAGE_DISH,
	EDIT_DISH,
	CREATDE_MENU,
	EDIT_MENU,
	CATEGORY_MANAGER_UPDATE,
	CATEGORY_MANAGER_CREATE,
	HOME,
}

const Layout: React.FC<Props> = ({ backToDefault }: Props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	let authContext = useContext(store);
	const [selectedCard, setSelectedCard] = React.useState<ICard | undefined>(undefined);

	const [qrcodesOwned, setQrcodesOwned] = React.useState<IQrcode[] | undefined>(undefined);
	const [cards, setCards] = React.useState<ICard[]>([]);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	/** State déterminant si l'on est entrain d'effectuer une reqûête sur la liste des cartes */
	const [isListCardsRequesting, setListCardsRequesting] = React.useState<boolean>(false);

	const [pageState, setPageState] = React.useState<STATE_PAGE_MANAGER>(STATE_PAGE_MANAGER.HOME);
	
	/**
	 * Récupère la liste des cartes de l'utilisateur et l'affiche
	 * @returns 
	 */
	 function GetCardDataAndUpdateList() {
		let apiCard: CardServices = APIManager.getCardServices();
		let userId = (authContext.authObj.userProfile as ITrader)._id as string;

		setListCardsRequesting(true);
		return apiCard.GetAllCards(userId).then((cardsReceived) => {
			setCards([...cardsReceived]);
		}).finally(() => {
			setListCardsRequesting(false);
		});
	}

	/**
	 * Récupère les plats, les menus, et les cartes de l'utilisateur connecté
	 */
	const getDataForCardDishMenus = async () => {
		try {
			await GetCardDataAndUpdateList();
			
		} catch (Error) {
			console.log("Error !:", Error);
			enqueueSnackbar("La liste des plats n'a pas pu charger,rechargez la page ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		}	
	}


	const actualizeQrcode = async () => {
		let apiQrcode = APIManager.getQrCodeServices();

		try
		{
			let qrcodesServer = await apiQrcode.GetAllQrCode();

			setQrcodesOwned([...qrcodesServer]);

		} catch(err) {
			setQrcodesOwned([]);
			enqueueSnackbar(`La récupération des qrcode a échoué. Si le probleme persiste contactez le service client.`, { 
				variant: "warning",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			console.log("error loading ", err);
		}
	}

	function actionChangeCardName(index: number, id: string, newCardName: string) : Promise<boolean> {
		let newStateCard = [...cards];

		return new Promise((resolve, reject) => {
			APIManager.getCardServices().changeNameCard(id, newCardName)
			.then((result) =>{ 
				if (result === undefined) {
					resolve(false);
				}
				else {
					newStateCard[index] = result; 
					setCards([...newStateCard]); 
					resolve(true);
				} })
			.catch((except) => { reject(except)});
		});
	}

	useEffect(() => {
		if (qrcodesOwned) {
			return;
		}
		actualizeQrcode();
	}, [qrcodesOwned]);

	useEffect(() => {

		if (!authContext.authState || !isLoading) {
            return;
        }
		getDataForCardDishMenus();
		setIsLoading(false);
	}, [authContext.authState]);


	return (
		<Fragment>
			<CssBaseline />
			<Container component="div" className={classes.root} maxWidth={false}>
				{pageState === STATE_PAGE_MANAGER.HOME && <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
					<div onClick={() => {
						backToDefault();
					}} className={clsx(classes.backArrow,classes.makeLink)} >
						<ArrowBackIcon style={{ margin: "0 1vw" }}/>
						<Typography align="left" component="span" variant="subtitle1" gutterBottom>Retour</Typography>
					</div>
				</div>}
				<DynamicComponent />
			</Container>
		</Fragment>)

	function Home(props: any) {

		const handleDeleteCard = (card: ICard) => {
			APIManager.getCardServices().deleteCard(card._id as string)
			.then((res) => {
				if (res) {
					enqueueSnackbar(`La suppression de la carte ${card.name} a été réalisé.`, { 
						variant: "success",
						anchorOrigin: {
							vertical: 'top',
							horizontal:'right',
						}
					});
				} else {
					enqueueSnackbar(`La suppression de la carte ${card.name} a été partiellement réalisé.`, { 
						variant: "warning",
						anchorOrigin: {
							vertical: 'top',
							horizontal:'right',
						}
					});
				}
				getDataForCardDishMenus();
			})
			.catch((err) => {
				enqueueSnackbar(`La suppression de la carte ${card.name} n'a pas pu être effectué.`, { 
					variant: "error",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
			})
		}

		const [open, setOpen] = React.useState(false);
		const [openDialogDelete, setOpenDialogDelete] = React.useState(false);

		const [cardToSelectQrcode, setCardToSelectQrcode] = React.useState<ICard | undefined>(undefined);

		const handleOpen = () => {
			setOpen(true);
		};

		const handleClose = () => {
			setOpen(false);
		};

		return (
			<Fragment>
				<div className={classes.createGroup}>
					<Typography className={clsx(classes.tagline, classes.highlight)} component="h5" variant="h6"> Mes Cartes digitales </Typography>
					{!isListCardsRequesting && (
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
						<div style={{ margin: "3vh 1vw"}}>
							<YumButton
								onClick={() => {
									setPageState(STATE_PAGE_MANAGER.CREATE_CARD);
								}}>
								Créer une carte +
							</YumButton>
						</div>
						<Grid container spacing={4} alignItems="center" justify="flex-start" >
							{cards.map((card, index) => (
								<Grid
									key={card._id as string}
									item
									style={{ display: "flex", justifyContent: "center", alignItems: "center"}}
									component='div'
									xs={12} 
									sm={6} 
									md={4}>
									<Paper elevation={4} style={{ padding: "10px 15px", width: "280px", position: "relative" }}>
										
										<YumChangeInput 
											variant="subtitle1"
											textValue={card.name} 
											action={(newCardName) => { return actionChangeCardName(index, card._id as string, newCardName);}}/>

										<IconButton color="secondary" onClick={(event) => {
											setCardToSelectQrcode(card);
											setOpenDialogDelete(true); 
										}}
											style={{ position: "absolute", right: "0px", top: "0px", zIndex: 2 }}
											aria-label="settings">
											<DeleteForeverIcon />
										</IconButton>
										<Divider style={{ margin: "5px 0" }} />
										<div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }} >
											<Typography component="span" variant="body1">Nombre de menu </Typography>
											<Typography component="span" variant="body2"> {card.items.filter((item) => item.type === CARD_ITEM_TYPE.MENU).length}</Typography>
										</div>
										<div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}} >
											<Typography component="span" variant="body1">Nombre de plats </Typography>
											<Typography component="span" variant="body2"> {card.items.filter((item) => item.type === CARD_ITEM_TYPE.DISH).length}</Typography>
										</div>
										{qrcodesOwned && <div style={{ display: "flex", flexDirection: "column" }} >
											<Typography component="span" variant="body1" align="left">Qrcodes lié </Typography>
											<div>
												{qrcodesOwned.filter((qr) => (qr.digitalCard as string) === card._id).map((qr) => 
												<Chip key={qr._id as string} label={qr.name} />
												)}
												{qrcodesOwned.filter((qr) => (qr.digitalCard as string) === card._id).length === 0 
												&& <Typography 
														variant="body2" 
														component="span"
														align="center"
														style={{ marginLeft: "5px"}}>
														 Aucun qrcode n'est lié a cette carte
													</Typography>}
											</div>
											
										</div>}
										<Divider style={{ margin: "5px 0" }} />
										<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "1vh 1vw" }}>
											<YumButton
												onClick={() => {
													setSelectedCard(card);
													setPageState(STATE_PAGE_MANAGER.CATEGORY_MANAGER_UPDATE);
												}}
												style={{ margin: "5px 0"}}>
												Modifier Carte
											</YumButton>
											<YumButton
												style={{ margin: "5px 0"}}
												onClick={() => {
													window.open(`/preview/digitalcard?it=${card.trader}&ic=${card._id as string}`, card.name);
												}}>
												Visualiser
											</YumButton>
											<YumButton
												onClick={() => {
													handleOpen();
													setCardToSelectQrcode(card);
												}}
												style={{ margin: "5px 0"}}>
												Modifier Qrcodes
											</YumButton>
										</div>
									</Paper>
									
								</Grid>
							))}
						</Grid>					
						{cards.length === 0 && 
							<Typography
								variant="body1"
								component="span" gutterBottom>
								Vous n'avez aucune carte.
							</Typography>}
					</div>)}
					{isListCardsRequesting && (<div><CircularProgress /></div>)}
				</div>
				{cardToSelectQrcode && <Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
					timeout: 500,
					}}
				>
					<Fade in={open}>
						<ManageQrcodeDigital back={() => {
							actualizeQrcode();
							handleClose();
							}} card={cardToSelectQrcode}/>
					</Fade>
				</Modal>}
				<Dialog
					open={openDialogDelete}
					onClose={() => { setOpenDialogDelete(false) }}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"Suppression définitive de la carte"}</DialogTitle>
					<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Voulez vous vraiment supprimer la carte {cardToSelectQrcode?.name} ? Toutes suppression sera définitive.
					</DialogContentText>
					</DialogContent>
					<DialogActions>
						<YumButton onClick={() => { setOpenDialogDelete(false) }}>
							Annuler
						</YumButton>
						<YumButton onClick={() => {
							if (cardToSelectQrcode) {
								handleDeleteCard(cardToSelectQrcode);
								setCardToSelectQrcode(undefined);
							}
							
							setOpenDialogDelete(false); 
							}}  autoFocus>
							Supprimer
						</YumButton>
					</DialogActions>
				</Dialog>		
				</Fragment>
		);
	}

	function backArrow(){
		getDataForCardDishMenus();
		setPageState(STATE_PAGE_MANAGER.HOME);
	}

	function DynamicComponent(props: any) {

		switch(pageState) {
			case STATE_PAGE_MANAGER.CATEGORY_MANAGER_UPDATE:
				return <ManageCategories card={selectedCard as ICard} back={() => {
					backArrow();
				}} mode={ComposantMode.Update} />
			case STATE_PAGE_MANAGER.CATEGORY_MANAGER_CREATE:
				return <ManageCategories card={selectedCard as ICard} back={() => {
					backArrow();
				}} mode={ComposantMode.Create} />
			case STATE_PAGE_MANAGER.CREATE_CARD:
				return <CreateDigitalCard back={() => {
					backArrow();
				}} next={(card: ICard) => {
					setSelectedCard(card);
					setPageState(STATE_PAGE_MANAGER.CATEGORY_MANAGER_CREATE);
				}}/>
            case STATE_PAGE_MANAGER.HOME:
            default:
                return <Home />;
        }
	}
};

export default Layout;
