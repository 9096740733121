import React, { useEffect } from "react";
import Tips from "../../Lib/UI/Tips";
import { TipsData, TipsToDisplay } from "../../types/dal/tips";
import { APIManager } from "../../services";
import { useLocation } from "react-router-dom";
import { CircularProgress, Container } from "@material-ui/core";

type Props = {
};

const TipsManager: React.FC<Props> = (Props) => {
    
    const [currentTips, setCurrentTips] = React.useState<Array<TipsData>>([]);
    const [isLoading, setLoading] = React.useState<boolean>(true);

    let location = useLocation();

    function closeTipsAction(indexToDelete : number) {
        let tmp = currentTips.filter((item, index) => index !== indexToDelete); 
        setCurrentTips([...tmp]);
    }

    useEffect(() => {

        if (!isLoading) {
			return;
		}

        if (isLoading) {
            let currentLocation = "/" + location.pathname.split("/")[location.pathname.split("/").length -1];
            APIManager.getTipsService().GetTipsFromPage(currentLocation).then((result: TipsToDisplay) => {
                setCurrentTips(result.tips);
                setLoading(false);
            }).catch((err: Error) => {
                console.log(err);
                setLoading(false);
            });
        }

	}, []);
	
	return (
		<Container component="div" maxWidth={false}>
            {isLoading && <CircularProgress style={{height: "25px", width:"25px"}} />}
            {!isLoading 
            && currentTips.map((item, index) => <Tips key={index} tips={item} action={() => { closeTipsAction(index) }} />)}
		</Container>
	);
}

export default TipsManager;
