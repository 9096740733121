import { LOGIN_USER, LOGGOUT_USER, SET_USERDATA } from "./actionTypes";
import { ReduxAction } from "./types";
import { UserState, UserInfos } from "../../types";

const initialState: UserState = {
	userdata: undefined,
	isAuthentified: false,
	role: undefined,
};

export default (state = initialState, action: ReduxAction<any>) => {
	switch (action.type) {
		case LOGIN_USER:
			/*APIManager.setTokenAPIS(action.payload.token); // initialisation du token pour l'api Manager
			storageservice.setData("user", action.payload); // enregistrement du token dans le navigateur
			storageservice.setData("usertype", UserType.Scooker); // enregistrement du type d'utilisateur*/
			return {
				...state,
				userdata: action.payload.userdata as UserInfos,
				isAuthentified: true,
				role: action.payload.role,
			};
		case LOGGOUT_USER:
			/*storageservice.removeByKey("user");
			storageservice.removeByKey("usertype");
			APIManager.setTokenAPIS('');*/
			return {
				...state,
				userdata: undefined,
				isAuthentified: false,
				role: undefined,
			}
		case SET_USERDATA:
			return {
				...state,
				userinfos: action.payload.trader,
			}
		default:
			return state;
	}
};
