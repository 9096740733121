/*export namespace IMG {


    export namespace Landing {
        
 
    }   
}*/

const linkDigitalOcean: string =  `${process.env.REACT_APP_DIGITAL_OCEAN_URL}`;

export const IMG_LOGO = `${linkDigitalOcean}/landing/logo.png`;

export const IMG_LOGO_WRITED = `${linkDigitalOcean}/landing/logo_write.jpeg`;

export const IMG_COVID = `${linkDigitalOcean}/landing/covid.jpeg`;

export const IMG_ECOLOGIE = `${linkDigitalOcean}/landing/ecologie.jpeg`;

export const IMG_PRO_CHR = `${linkDigitalOcean}/landing/chef.jpeg`;

export const IMG_PRO_DIGITAL = `${linkDigitalOcean}/landing/digital.jpeg`;

export const IMG_LOCKED = `${linkDigitalOcean}/landing/locked.jpeg`;

export const IMG_PRESENTATION_LANDING_APP = `${linkDigitalOcean}/landing/presentation_app.png`;

export const IMG_STEPPER_LANDING_APP = `${linkDigitalOcean}/landing/stepper.png`;

export const IMG_1 = `${linkDigitalOcean}/landing/screen_1.png`;

export const IMG_2 = `${linkDigitalOcean}/landing/handscan.png`;

export const IMG_3 = `${linkDigitalOcean}/landing/bar_terasse.jpg`;

export const IMG_4 = `${linkDigitalOcean}/landing/credit_card.jpg`;

export const IMG_5 = `${linkDigitalOcean}/landing/screen_steps.png`;

export const IMG_6 = `${linkDigitalOcean}/landing/for_who_3.jpg`;

export const IMG_7 = `${linkDigitalOcean}/landing/for_who_4.jpg`;