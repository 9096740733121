import { BillingState } from '../../../types';
import * as ACTION_TYPES from './action_types';

export const initialState: BillingState = {
	billing: undefined,
  };

export async function getInitialState(): Promise<BillingState> {

	return initialState;
}

export const BillingReducer = (state = initialState, action: { type: string, payload?: any }) => {
	switch (action.type) {
		case ACTION_TYPES.SET_STRIPE_BILLIING:
			return {
				...state,
				billing: action.payload
			}
		default:
			return state
	}
}