import { IPackYum, IPdfCard, ITrader } from "../entities";
import { UserType } from "..";

export interface UserInfos {
	id: string;
	userinfos: ITrader;
	email: string;
	token: string;
	type: UserType;
}

/**
 * Retourne les informations sur la carte et le commerçant
 */
export interface TraderCardInfos {
	pack: IPackYum,
	idTrader: string,
	pdfPath?: IPdfCard,
}


export interface UserRoleAuth {
	// Flags sur les droits
	rights: number;
}


export * from "./abstracts";
export * from "./interfaces";
