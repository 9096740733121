import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'initial',
		minHeight: '100%',
		paddingTop: "3vh"
	},

	/** Nav section */
	navSection: {
		display: "flex",
		flexDirection: "row",
		marginBottom: "3vh"
	},

	itemNav: {
		"&$selected": {
			backgroundColor: "red",
			color: "white"
		},
		"&$selected:hover": {
			backgroundColor: "purple",
			color: "white"
		},
		"&:hover": {
			backgroundColor: "blue",
			color: "white"
		}
	},

	/** Home section */
	homeSection: {
		padding: "15px 15px",
	},


	/** Nav section */

	/** Quick nav section */
	quickNavSection: {
		display: "flex",
		flexDirection: "row",
		margin: "4vh 10px",
		alignItems: "center",
	},

	card: {
		textAlign: "center",
		height: "320px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: "10px 0",
		zIndex: 0,
		position: "relative"
	},

	cardTitle: {

	},
	cardImageContainer: {
		paddingTop: "10px",
		"& img": {
			maxWidth: "130px",
		}
	},
	description: {

	},

	backgroundIndisponible: {
		height: '100%',
		width: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: 'rgb(0,0,0,0.4)',
	},

	m_10_vertical: {
		margin: "3vh 0px"
	},
}));

export default useStyles;
