/*import auth0 from 'auth0-js'
import history from './history';*/
import { 
//	UserInfos, 
	ITrader, UserType } from '../types';
import { StorageServiceManager } from '../services/storageManager';
import { APIManager } from '../services';

export default class Auth {

	userProfile: ITrader | {} = {}

	private instanceStorage = StorageServiceManager.getStorageService();


	/**
	 * Log user from data stored in storage service.
	 */
	login = () => {
		let token = this.getAccessToken();
		if (token) {
			//console.log("Set token ");
			APIManager.setTokenAPIS(token); // initialisation du token pour l'api Manager
		}
		return this.getProfile();
	}

	handleAuth = (data: { token: string, userinfos: ITrader, type: UserType }) => {
		this.instanceStorage.setData('access_token', data.token, true);
		this.instanceStorage.setData('user_infos', data.userinfos, true);
		this.instanceStorage.setData('type', data.type, true);

		//console.log("Set token ", data.token);
		APIManager.setTokenAPIS(data.token); // initialisation du token pour l'api Manager

		return this.getProfile();
	}

	getAccessToken = () => {
		if (this.instanceStorage.getData<string>('access_token')) {
			const accessToken = this.instanceStorage.getData<string>('access_token')
			return accessToken;
		} else {
			return null
		}
	}
	

	getProfile = async () => {
		let accessToken = this.getAccessToken()
		if (accessToken) {
			//console.log(" TOKEN GET PROFILE " + APIManager.getAccountServices().getToken());
			let traderInfos = await APIManager.getAccountServices().getTraderInfos();
			if (traderInfos) {
				this.userProfile = traderInfos;
				//this.instanceStorage.setData('user_infos', { ...traderInfos}, true);
			}
		}
		return this.userProfile;
	}


	logout = () => {
		this.instanceStorage.removeByKey('access_token');
		this.instanceStorage.removeByKey('user_infos');
		this.instanceStorage.removeByKey('type');
		this.instanceStorage.deleteAll();
		APIManager.clearTokenAPIS();
	}

	isAuthenticated = async () => {
		let res = false;

		try {
			res = await APIManager.getAccountServices().ValideToken();
		} catch (error) {
			console.error(error);
			// expected output: ReferenceError: nonExistentFunction is not defined
			// Note - error messages will vary depending on browser
		};
		return res;
	}

}