import React, { Fragment, PropsWithChildren, useCallback } from "react";
import { AppState, UserStateV2, YUMSCAN_FEATURES } from "../../../types";
import { connect } from "react-redux";
import { FlagUtil } from "../../../utils/flagsUtils";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

type SortableElementProps = {
	OnSortEnd:({oldIndex, newIndex}: any) => void;
};

/**
 * Affiche le composant parent l'utilisateur connecté est dans le scope 
 */
const SortableItem: React.FC<SortableElementProps> = (props) => {

	const SortableList = SortableContainer(({children}: React.PropsWithChildren<{}>) => <ul style={{ width: "100%"}}>{children}</ul>);
	const SortableListItem = SortableElement(({value}: {value: React.ReactElement}) => value);

	const getChilds = () => {
		console.log("CHildren of sortableItem", props.children);
		if (!props.children)
			return [];
		return React.Children.map(props.children as React.ReactElement, (child) => child);
	}

	return <SortableList onSortEnd={props.OnSortEnd}>
		{getChilds().map((comp , index) => <SortableListItem key={`item-${index}`}  index={index} value={comp} />)}
	</SortableList>;
};

export default SortableItem;
