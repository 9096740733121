import { AAuthApi, UserInfos, UserType, RESTVerb, IPackYum, IPdfCard, ITrader, UserInfosDataForm, FrontError, AppColorPersonnalization, ApplicationDataState } from "../../types";
import { User } from "../../types/user";
import { UserSignUp, UserSignUpWithPayement } from "../../types/userSignUp";
import * as httpStatus from 'http-status';
//import { Console } from "console";

export class AccountServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

	public async ValideToken(token?: string): Promise<boolean> {
		return fetch(`${this.baseUrl}/login/validetoken`, {
			method: "GET",
			headers: {
				"Authorization": `Token ${token ? token : this.token}`,
			},
		}).then(async (res: Response) => {
			let result = await res.json();

			if (result && res.status === httpStatus.OK) {
				return true
			}
			return false;
		}).catch((err) => {
			return false;
		});
	}

	/**
	 * Log l'utilisateur
	 * @param user User informations to loggin
	 */
	public signIn(user: User, type: UserType): Promise<UserInfos> {
		return fetch(`${this.baseUrl}/login/${type === UserType.Trader ? "trader" : "customer"}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user),
		}).then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(`${response.status}`);
			}
			let result = await response.json();

			return {
				...result,
				type: UserType.Trader,
			} as UserInfos;
		}).catch((err) => {
			console.log(err);
			throw new Error(err);
		})
	}

	/**
	 * Enregistrement d'une utilisateur
	 * @param user 
	 */
	public signUp(user: UserSignUp): Promise<UserInfos> {
		//console.log("Stringify", JSON.stringify(user));
		return fetch(`${this.baseUrl}/trader/create`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user),
		}).then(async (response: Response) => {
			let result: any = undefined
			if (response.status === httpStatus.CONFLICT) {
				result = await response.json() as FrontError;
				// renvoie l'erreur avec sont identifiant
				throw new Error("Un compte à déjà été crée avec cet email.");
			} else if (response.status === httpStatus.INTERNAL_SERVER_ERROR) {
				// Potentiellement mettre un GUID coté api pour identifié le bug :)
				throw new Error("Une erreur inconnue est survenue, veuillez ré-essayer ou contactez le service client si l'erreur persiste.");
			} else if (response.status === httpStatus.NO_CONTENT) {
				// Potentiellement mettre un GUID coté api pour identifié le bug :)
				throw new Error("Le lien fournis n'est pas valide. Veuillez contactez le service client si l'erreur persiste.");
			} else {
				result = await response.json();
				if (result === undefined) {
					throw new Error(JSON.stringify({
						message: "Une erreur inconnue est survenue, veuillez vous déconnecter puis vous reconnecter.",
					}));
				}
				return {
					id: result._id,
					...result,
					type: UserType.Trader,
				} as UserInfos;
			}

		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Enregistrement d'une utilisateur avec son moyen de paiement.
	 * @param user 
	 */
	public signUpWithPayement(user: UserSignUpWithPayement): Promise<UserInfos> {
		return fetch(`${this.baseUrl}/trader/createwithpayement`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user),
		}).then(async (response: Response) => {
			let result: any = undefined
			if (response.status === httpStatus.CONFLICT) {
				result = await response.json() as FrontError;
				// renvoie l'erreur avec sont identifiant
				throw new Error("Un compte à déjà été crée avec cet email ou ce siret.");
			} else if (response.status === httpStatus.INTERNAL_SERVER_ERROR) {
				// Potentiellement mettre un GUID coté api pour identifié le bug :)
				throw new Error("Une erreur inconnue est survenue, veuillez ré-essayer ou contactez le service client si l'erreur persiste.");
			} else {
				result = await response.json();
				if (!result) {
					throw new Error(JSON.stringify({
						message: "Une erreur inconnue est survenue, veuillez vous déconnecter puis vous reconnecter.",
					}));
				}
				return {
					...result,
					type: UserType.Trader,
				} as UserInfos;
			}

		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère le pack definis pour le commerçant.
	 * @param idTrader 
	 */
	public getPackForTrader(idTrader: string): Promise<IPackYum> {
		return this.JsonAuthRequest(RESTVerb.GET, `trader/pack/${idTrader}`).then(async (response: Response) => {
			if (response.status !== httpStatus.FOUND) {
				throw new Error("Impossible de trouver le pack");
			}
			let result = await response.json();

			//console.log(response);
			return result as IPackYum;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	public getTraderInfos(): Promise<ITrader> {
		return this.JsonAuthRequest(RESTVerb.GET, `trader/infos/status`).then(async (response: Response) => {
			if (response.status !== httpStatus.FOUND) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();

			return result as ITrader;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Définis si la limit de qrcode crée a été atteinte pour l'utilisateur
	 */
	public qrcodeLimitExceed(): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.GET, `trader/qrcode/limit`).then(async (response: Response) => {
			if (response.status === httpStatus.OK) {
				return false;
			}
			if (response.status === httpStatus.NO_CONTENT) {
				return true;
			}
			throw new Error(response.status.toString());
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère le status du commerçant.
	 * @param idTrader 
	 */
	public isAuthorizedTrader(idTrader: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.GET, `/trader/status/${idTrader}`).then(async (response: Response) => {
			if (response.status === httpStatus.OK) {
				return true;
			} else if (response.status === httpStatus.UNAUTHORIZED) {
				return false;
			}
			throw new Error(`${response.status === httpStatus.CONFLICT ? 'conflict' : "bad_request"}`)
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère le premier pdf d'un commercant
	 * @param idTrader 
	 */
	public getPdfForTrader(idTrader: string): Promise<IPdfCard | undefined> {
		return this.JsonAuthRequest(RESTVerb.GET, `trader/pdf/${idTrader}`).then(async (response: Response) => {
			let result = await response.json();

			if (result && response.status === httpStatus.OK) {
				return result as IPdfCard;
			}
			return undefined;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}


	/**
	 * Récupère le status du commerçant.
	 * @param idTrader 
	 */
	public UpdateTraderInformations(userInfos: UserInfosDataForm): Promise<ITrader> {
		return this.JsonAuthRequest(RESTVerb.PUT, `trader/infos`, userInfos).then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();

			return result as ITrader;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère le status du commerçant.
	 * @param idTrader 
	 */
	 public UpdateAppSettingsForTraderByID(newColor: AppColorPersonnalization): Promise<AppColorPersonnalization> {
		return this.JsonAuthRequest(RESTVerb.PUT, `trader/app-color-settings`, newColor)
		.then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(response.status.toString());
			}
			let yumscanAppSettings = await response.json();

			console.log("json response ", yumscanAppSettings);
			return yumscanAppSettings.Color as AppColorPersonnalization;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère les parametres d'application d'un commerçant.
	 * @param idTrader 
	 */
	 public GetColorSettingsByTrader(idTrader: string): Promise<AppColorPersonnalization> {
		return this.JsonAuthRequest(RESTVerb.GET, `trader/app-color/${idTrader}`)
		.then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();

			return (result as ApplicationDataState).Color;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère les couleurs de l'application du commerçant connecté
	 * @param idTrader 
	 */
	 public GetColorSettings(): Promise<AppColorPersonnalization> {
		return this.JsonAuthRequest(RESTVerb.GET, `trader/app-color`)
		.then(async (response: Response) => {
			if (response.status !== httpStatus.OK) {
				throw new Error(response.status.toString());
			}
			let result = await response.json();

			return (result as ApplicationDataState).Color;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met a jour les données propre a l'application d'un commerçant.
	 * @param idTrader 
	 */
	 public UpdateAppSettingsAnnouncement(announcement: string | undefined): Promise<boolean> {

		return this.JsonAuthRequest(
			RESTVerb.PATCH, 
			`trader/appsettings/announcement`,
			{ 
				announcement: announcement,
			}
		).then(async (res) => {
			console.log(res);
			
			if (res.status === httpStatus.OK) {
				return true;
			} else if (res.status === httpStatus.CONFLICT) {
				return false;
			} else {
				throw new Error(res.status.toString());
			}
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met a jour les données propre a l'application d'un commerçant.
	 * @param idTrader 
	 */
	 public UpdateAppSettingsInformations(form: FormData): Promise<boolean> {

		return fetch(`${this.baseUrl}/trader/appsettings/infos`, {
			method: 'PUT',
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: form,
		}).then(async (res) => {
			console.log(res);
			console.log("http status created", httpStatus.CREATED);
			console.log("http status created", httpStatus.PARTIAL_CONTENT);

			if (res.status !== httpStatus.CREATED  && res.status !== httpStatus.PARTIAL_CONTENT ) {
				throw new Error(res.status.toString());
			}

			console.log(res);
			if (res.status === httpStatus.CREATED) {
				return true;
			}
			return false;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met a jour le logo du commercant
	 * @param idTrader 
	 */
	 public async UpdateLogo(form: FormData): Promise<ITrader> {

		return fetch(`${this.baseUrl}/trader/logo`, {
			method: 'PATCH',
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: form,
		}).then(async (res) => {
			console.log(res);

			if (res.status !== httpStatus.OK) {
				throw new Error(res.status.toString());
			}
			return await res.json() as ITrader;

		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Supprime le logo du commercant.
	 * @param idTrader 
	 */
	 public RemoveAppSettingsInformationsLogo(): Promise<boolean> {

		return this.JsonAuthRequest(RESTVerb.GET,`trader/appsettings/remove/logo`).then(async (res) => {

			if (res.status === httpStatus.INTERNAL_SERVER_ERROR  || res.status === httpStatus.CONFLICT ) {
				throw new Error(res.status.toString());
			}

			if (res.status === httpStatus.OK) {
				return true;
			}
			return false;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}


	/**
	 * Récupère le status du commerçant.
	 * @param idTrader 
	 */
	public VerifEmail(tokenEmail: string): Promise<boolean> {
		return this.JsonNoAuthRequest(RESTVerb.POST, `trader/verif/approve`, { token: tokenEmail }).then(async (response: Response) => {
			if (response.status === httpStatus.OK) {
				return true;
				// Email as été vérifié
			}
			if (response.status === httpStatus.UNAUTHORIZED) {
				// Le lien a expiré
				return false;
			}
			throw new Error(response.status.toString());
		}).catch((err) => {
			// Erreur serveur
			console.log(err);
			throw err;
		});
	}


	/**
	 * Vérifie la validité du token de modification et renvoie un acess token de modification du password
	 * @param idTrader 
	 */
	public VerifResetPasswordToken(tokenEmail: string): Promise<{ acessToken: string } | undefined> {
		return this.JsonNoAuthRequest(RESTVerb.POST, `trader/verif/password/approve`, { token: tokenEmail }).then(async (response: Response) => {
			//console.log("Verify tokan ! Http status reset password :", response.status);
			if (response.status === httpStatus.OK) {
				let result = await response.json();
				
				return result as { acessToken: string };
				// Email as été vérifié
			}
			if (response.status === httpStatus.UNAUTHORIZED) {
				// Le lien a expiré
				return undefined;
			}
			throw new Error(response.status.toString());
		}).catch((err) => {
			// Erreur serveur
			console.log(err);
			throw err;
		});
	}

	/**
	 * Emet un mail de verification pour l'utilisateur concerné
	 * @param idTrader 
	 */
	public EmitVerifEmail(email: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.POST, `trader/verif/email`, { email: email }).then(async (response: Response) => {
			if (response.status === httpStatus.BAD_REQUEST) {
				return false;
				// L'utilisateur a déjà été vérifié
			}
			if (response.status === httpStatus.OK) {
				// Le mail a été envoyé 
				return true;
			}
			throw new Error(response.status.toString());
		}).catch((err) => {
			// Le mail n'a pas pu etre envoyé
			console.log(err);
			throw err;
		});
	}

	/**
	 * Emet un mail de changement de mot de passe pour l'utilisateur
	 * @param email email du commercant pour qui mettre a jour son mot de passe 
	 */
	public EmitResetPasswordMail(email: string): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.POST, `trader/emit/reset/password`, { email: email }).then(async (response: Response) => {
			if (response.status === httpStatus.CONFLICT) {
				return false;
				// L'email n'as pas pu etre envoyé 
			}
			if (response.status === httpStatus.OK) {
				// Le mail a été envoyé 
				return true;
			}
			throw new Error(response.status.toString());
		}).catch((err) => {
			// Le mail n'a pas pu etre envoyé
			console.log(err);
			throw err;
		});
	}

	/**
	 * Changer le mot de passe d'un utilisateur pour un nouveau
	 * @param newPassword Nouveau mot de passe de l'utilisateur
	 */
	public ResetPassword(newPassword: string, accesToken: string ): Promise<boolean> {
		return this.JsonAuthRequest(RESTVerb.POST, `trader/verif/password/reset`, { password: newPassword, token: accesToken }).then(async (response: Response) => {
			//console.log("Reset password ! Http status reset password : ", response.status);
			if (response.status === httpStatus.UNAUTHORIZED) {
				return false;
				// L'email n'as pas pu etre envoyé 
			}
			if (response.status === httpStatus.OK) {
				// Le mail a été envoyé 
				return true;
			}
			throw new Error(response.status.toString());
		}).catch((err) => {
			// Le mail n'a pas pu etre envoyé
			console.log(err);
			throw err;
		});
	}
}
