// React
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { DefaultTheme } from "./components/themes";

// Material UI
import { StylesProvider, CssBaseline, ThemeProvider } from "@material-ui/core";

// Service
import { UserInfos, UserType, AppState, UserState } from "./types";
import { LoginUser, LogOutUser } from "./redux/user";
import GlobalRoutes from "./router";
import { AuthProvider } from "./contexts/auth";
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from "./router/wrappers/ErrorBoundary";
//import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";


interface Props {
	userdata: UserState;
	logUser: (user: UserInfos, type: UserType) => any;
	logOut: () => any;
}

const App: React.FC<Props> = ({ logUser, logOut, userdata }: Props) => {

	/* function CookieConsentDisplay() {
		return (
		<React.Fragment>
			<CookieConsent
				buttonText="Accepter les cookies"
				cookieName="Yumscan_Functional_Cookies"
				style={{
					background: "rgba(0,0,0,0.85)",
					color: "white",

				}}
				buttonStyle={{
					background: "#eaac7e",
					color: "white",
					fontWeight: "bolder",
					textShadow: "2px 2px #945d0b",
					padding: "15px",
					borderRadius: "4px",
				}}
				>
				<Typography variant="h6" style={{ color: "white" }} component="span" gutterBottom>
					Yumscan utilise des cookies pour améliorer votre expérience utilisateur.{" -- "}
					<Link to="/legal/privacy-policy" target="_blank" rel="noopener noreferrer">
						<Typography variant="subtitle1"  style={{ color: "#eaac7e", textDecoration: "none" }} component="span" color="textSecondary">
							Charte de confidentialité
						</Typography>
					</Link>
				</Typography>
				
			</CookieConsent>
		</React.Fragment>
		)
	} */

	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={DefaultTheme}>
				<CssBaseline />
				<BrowserRouter>
					<ErrorBoundary>
						<AuthProvider>
							<SnackbarProvider maxSnack={8}>
								<GlobalRoutes />
							</SnackbarProvider>
						</AuthProvider>
					</ErrorBoundary>
					{/*(getCookieConsentValue("Yumscan_Functional_Cookies") !== "true") && (<CookieConsentDisplay />)*/}					
				</BrowserRouter>
			</ThemeProvider>
		</StylesProvider>
	);
};

export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}), {
	logUser: LoginUser,
	logOut: LogOutUser,
})(App);
