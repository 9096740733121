import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: 'initial',
		height: "100%",
		flex: 1,
		flexDirection: 'column',
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 'initial'
		},
		backgroundColor: 'white',
		padding: '10px',
		border: '5px dashed #d4d4d4',
		borderRadius: '3px',
		position: "relative",
	},
	description: {
		color: "#d4d4d4",
		position: "absolute",
		marginLeft: "auto",
		marginRight: "auto",
		left: "0",
		right: "0",
		textAlign: "center",
		fontWeight: "bold",
		textTransform: "uppercase",
		zIndex: 0,
	}
}));

export default useStyles;
