import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
        display: "flex", 
        flexDirection:"column", 
        justifyContent: "center", 
        alignItems: "center",
    },
    subRoot: {
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        flexDirection: "column"
    },

    imgContainer: {
		position: "relative",
		zIndex: 0,
        "& img": {
            width: "100%", 
            height: "100%",
        }
	},

    imgBackgroundMinimumRequierement: {
		height: "100%",
		backgroundColor: 'rgb(0,0,0,0.4)',
		position: "absolute",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		fontSize: "2em",
		fontWeight: "bold",
	},

    deleteIconDiv: {
        position: "absolute",
        top: "-25px",
        right: "-25px",
        backgroundColor: theme.palette.secondary.main,
        zIndex: 10000,
        borderRadius: 25,
        color: "white",
    }
}));

export default useStyles;
