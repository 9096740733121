import { IStorageService } from "../types";

export class LocalStorageService implements IStorageService {
	deleteAll(): void {
		localStorage.clear();
		sessionStorage.clear();
	}

	removeByKey(key: string): boolean {
		if (localStorage.getItem(key)) {
			localStorage.removeItem(key);
		}
		if (sessionStorage.getItem(key)) {
			localStorage.removeItem(key);
		}
		return true;
	}

	removeById(id: number): boolean {
		if (localStorage.length >= id && sessionStorage.length >= id) return false;
		let key = localStorage.key(id);
		let keySession = sessionStorage.key(id);
		if (key) localStorage.removeItem(key);
		if (keySession) sessionStorage.removeItem(keySession);
		return true;
	}

	setData(key: string, data: any, persist?: boolean): void {
		if (persist !== undefined && persist) {
			localStorage.setItem(key, JSON.stringify(data));
		}
		sessionStorage.setItem(key, JSON.stringify(data));
	}

	getData<T>(key: string): T | undefined {
		let obj: string | null = localStorage.getItem(key);
		if (!obj) {
			obj = sessionStorage.getItem(key);
		};
		if (!obj) return undefined;
		try {
			return JSON.parse(obj) as T;
		} catch (err) {
			console.log(err);
			//this.deleteAll();
			return undefined;
		}
	}
}
const storageservice: IStorageService = new LocalStorageService();

export { storageservice };
