import React, { Fragment } from "react";
import useStyles from "./styles";

import { UserState } from "../../../../types";
import { Container, CssBaseline, FormGroup, Grid, TextField } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";

import { StripeCardNumberElement } from "@stripe/stripe-js";
import UserSignUp from "../../../../types/userSignUp";
import { useStripe, useElements, CardNumberElement, CardElement } from "@stripe/react-stripe-js";


interface Props {
	userdata: UserState,
	traderInfos: UserSignUp | undefined;
	nextStep?: () => void;
}

const Layout: React.FC<Props> = ({userdata, traderInfos, nextStep } :Props) => {
	const classes = useStyles();
	const stripe = useStripe();
	const elements = useElements();

	const useOptions = () => {
		const options = {
			style: {
				base: {
					color: "#424770",
					letterSpacing: "0.025em",
					fontFamily: "Source Code Pro, monospace",
					"::placeholder": {
						color: "#aab7c4"
					},
					fontSize: '18px',
				},
				invalid: {
					color: "#9e2146"
				}
			}
		}

		return options;
	};
	const options = useOptions();
	const [cardOwnerName, 
		//setCardOwnerName
	] = React.useState<string | undefined>(undefined);

	const handleSubmit = async (ev: any) => {
		// We don't want to let default form submission happen here, which would refresh the page.
		ev.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}
		try {
			const cardElement = elements.getElement(CardNumberElement);

			if (cardElement === null) {
			}
			
			const { error: tokenError, 
				//token 
			} = await stripe.createToken(cardElement as StripeCardNumberElement, {
				name: `test-token`
			});

			if (tokenError) {
				throw tokenError.message;
			}

			const { 
				//paymentMethod, 
				error } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement as StripeCardNumberElement,
				billing_details: {
					name: cardOwnerName,
				},
			});

			if (error) {
				throw error;
			}

/* 			let data: UserPayementMethod = {
				token: (token as Token).id,
				payment_method_id: (paymentMethod as PaymentMethod).id,
				codePromo: undefined
			} */

/* 			const userData = await APIManager.getBillingService().updatePayementMethod(data).then((infos: boolean) => {
				console.log("Billing updated !");
				if (nextStep) {
					nextStep();
				}
			}).catch((err) => {
				console.log("error fail");
			}); */

			/*await props.loginCoach({
				email: props.coachValues.username,
				password: props.coachValues.password
			});

			props.history.push("/");*/
		} catch (error) {
			console.log(error);
			alert("ERREUR ");
		}
	};

	return (
		<Fragment>
			<CssBaseline />
			<Container component="div" className={classes.root} maxWidth={false}>
				<Typography className={classes.firstSentence} variant="h3">
					<span className={classes.highlight} >{userdata.userdata?.userinfos.firstname}</span>, pour que l'établissement "<span className={classes.highlight}>{userdata.userdata?.userinfos.corporate_name}</span>"
				</Typography>
				<Typography className={classes.firstSentence} variant="h3">
					puisse profiter pleinement de l'offre <span className={classes.highlight}>XXX</span>
				</Typography>
				<Typography style={{ margin: "10px 0" }} variant="h5">Ajout tes informations de paiement</Typography>
				<Grid container spacing={5} alignItems="center" justify="center" >
					{/*<Grid item xs={12} sm={6} md={6}>
						<div style={{ display: "flex", flexDirection: "column", padding: "20px" }}>
							<Typography className={classes.toptittle} variant="h4" gutterBottom>Récapitulatif de votre offre</Typography>						
							<Typography>pack Yum PDF</Typography>
							<Typography>7.99€</Typography>
							<div>
								<Typography>Espace de gestion de vos QRCodes</Typography>
								<Typography>Une carte au format PDF</Typography>
								<Typography>Scan Illimité</Typography>
								<Typography>Espace de gestion de compte </Typography>
							</div>
							<FormControl component="fieldset">
								<FormLabel component="legend">Periode d'encaissement</FormLabel>
								<RadioGroup aria-label="gender" name="gender1">
									<FormControlLabel value="female" control={<Radio />} label="Annuel" />
									<FormControlLabel value="male" control={<Radio />} label="Mensuel" />
								</RadioGroup>
							</FormControl>
						</div>							
					</Grid>
					<Divider /> */}
					<Grid item xs={12} sm={6} md={6}>						
						<form style={{ display: "flex", flexDirection: "column", padding: "10px" }} onSubmit={handleSubmit}>
							<Typography className={classes.toptittle} variant="h4" gutterBottom>Adresse de facturation</Typography>
							<div style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
								<TextField fullWidth id="outlined-basic" label="Adresse 1" variant="outlined" />
								<TextField fullWidth id="outlined-basic" label="Adresse 2" variant="outlined" />
								<FormGroup style={{display: "flex", flexDirection: "row"}}>
									<TextField fullWidth id="outlined-basic" label="Ville" variant="outlined" />
									<TextField fullWidth id="outlined-basic" label="Pays" variant="outlined" />
								</FormGroup>
							</div>				
							<Typography className={classes.toptittle} variant="h4" gutterBottom>mode de paiement</Typography>
							<CardElement
								options={options}
								onReady={() => {
									console.log("CardElement [ready]");
								}}
								onChange={event => {
									console.log("CardElement [change]", event);
								}}
								onBlur={() => {
									console.log("CardElement [blur]");
								}}
								onFocus={() => {
									console.log("CardElement [focus]");
								}}
								/>
							<Typography className={classes.toptittle} variant="h4" gutterBottom>Promotion</Typography>
							<div style={{ display: "flex", flexDirection: "row", padding: "10px" }}>
								<TextField fullWidth id="outlined-basic" style={{marginRight: "15px"}} label="code promo" variant="outlined" />
								<Button variant="contained" color="secondary">Appliquer</Button>
							</div>
							{/*<label>
								Card number
								<CardNumberElement
									options={options}
									onReady={() => {
									}}
									onChange={event => {
									}}
									onBlur={() => {
									}}
									onFocus={() => {
									}}
								/>
							</label>
							<label>
								Expiration date
								<CardExpiryElement
									options={options}
									onReady={() => {
									}}
									onChange={event => {
									}}
									onBlur={() => {
									}}
									onFocus={() => {
									}}
								/>
							</label>
							<label>
								CVC
								<CardCvcElement
									options={options}
									onReady={() => {
									}}
									onChange={event => {
									}}
									onBlur={() => {
									}}
									onFocus={() => {
									}}
								/>
								</label>*/}
							<Button type="submit" variant="contained" color="secondary"style={{ marginTop: "10px"}} disabled={!stripe}>
								S'abonner
							</Button>
						</form>
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
};

export default Layout;
