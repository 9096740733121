import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { TipsData } from "../../../types/dal/tips";

interface Props {
    tips: TipsData,
    action: () => void,
}

const Tips: React.FC<Props> = ({ tips, action }) => {

  
    return(
        <React.Fragment>
            <Alert 
                onClose={tips.persistance ? undefined : () => {action();} }
                style={{ marginBottom: "15px" }} 
                severity={tips.severity}>
                <AlertTitle><strong>{tips.title}</strong></AlertTitle>
                {tips.description}
            </Alert>
        </React.Fragment>
    );
};

export default Tips;
