import React, { useEffect } from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import QRCode from "easyqrcodejs";
import clsx from "clsx";

interface Props {
	url?: string,
	tagline?: string;
}


const QrcodePrintable: React.FC<Props> = ({ url, tagline }: Props) => {
	const classes = useStyles();
	const qrcodeRef = React.createRef<HTMLDivElement>();

	const [qrCodeState, setQrcodeState] = React.useState<boolean>(false);

	useEffect(() => {
		if (!qrCodeState && qrcodeRef.current && url) {

			new QRCode(qrcodeRef.current, {
				text: url,
				width: 300,
				height: 300,
				logo: "/logo_write.jpeg"
			});
			setQrcodeState(true);
		}
	}, [url, qrcodeRef]);

/* 	const QrCodeSizeRegular = () => {
		let jsxArray: JSX.Element[] = [];
		let url = '';
		let canvas;

		if (qrcodeRef.current && (canvas = qrcodeRef.current.getElementsByTagName('canvas')).length > 0) {
			url = (canvas[0] as HTMLCanvasElement).toDataURL("image/png");
		}
		for(let i = 0; i < 9 && url !== '' ; i++) {
			jsxArray.push(
				<img src={url} style={{ width: "100%"}} alt="img" />
			);
		}
		return jsxArray;
	} */

	return (
	<React.Fragment>
		<CssBaseline />
		<Container component="div" className={clsx(classes.root/*, "page-break" */)} maxWidth={false}>
			{tagline && (<Typography component="h1" variant="h2" gutterBottom>{tagline}</Typography>)}
			<div className={classes.Main}>
				<Typography className={classes.ScanMe} component="h1" variant="h1" gutterBottom>Scannez-moi</Typography>
				<div>
					{url && (<div ref={qrcodeRef}></div>)}
				</div>
				<Typography component="h1" variant="h5" gutterBottom>Scan le Qrcode à partir de <strong>l'appareil photo de ton smartphone</strong> ou directement sur <strong>www.yumscan.app</strong></Typography>
				<Typography component="h1" variant="h1" gutterBottom>Bonne dégustation</Typography>
			</div>
		</Container>
		{/*<Grid 
			container 
			spacing={1} 
			alignItems="center" 
			justify="center" >
			{qrcodeRef.current && QrCodeSizeRegular().map((elementJsx) => {
				return (
					<Grid 
						item 
						component='div'
						xs={3} 
						sm={3} 
						md={3}>
							{elementJsx}
					</Grid>
				)
			})}
		</Grid>*/}
	</React.Fragment>
	);
}
export default QrcodePrintable;
