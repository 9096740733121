import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: '100%',
		alignItems: "flex-start",
		justifyContent: "center",
		position: "relative",
		padding: "0px 10%"
	},
	scookNavbar: {
		position: 'absolute',
		top: "3%",
		right: "2%",
	},
	AddSection: {
		flex: 1,
	},
	ListSection: {
		flex: 4,
	},
	rows: {
		flexDirection: "row",
		flex: 1,
	},
	card: {
		maxWidth: 345,
		minHeight: 200,
		minWidth: 200,
		padding: 10,
		backgroundImage: `url("https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/OOjs_UI_icon_add.svg/1024px-OOjs_UI_icon_add.svg.png")`
	},
	test: {
		maxWidth: '1200px',
		overflow: "hidden",
		'&::-webkit-scrollbar': {
			width: '0.4em'
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgb(0 0 0)',
			outline: '1px solid slategrey'
		}
	},
	createGroup: {
		marginBottom: '20px'
	},
	title: {
		textAlign: "center",
	},
	header: {
		marginTop: "150px",
		marginBottom: "150px",
	},
	link: {
		textDecoration: "none",
		marginTop: "15px",
		marginBottom: "15px",
	},
	addIconButton: {
		width: "100%",
		height: "100%",
	},
	body: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		marginTop: "50px",
		marginBottom: "50px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	buttonDiv: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	stepperContent: {
		flex: 1,
		backgroundColor: "red",
		alignItems: "center",
		justifyContent: "center",
	},
	stepper: {
		height: "100%",
		padding: 30,
		backgroundColor: "green",

	},
	content: {
		flex: 4,
	},
	tagline: {
		margin: '15px 0',
		textAlign: 'start',
	},
}));

export default useStyles;
