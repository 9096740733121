import React from "react";
import Layout from "./Layout";
import { AppState, UserState } from "../../types";
import { connect } from "react-redux";

interface Props {
  userdata: UserState;
}

const QuickStart: React.FC<Props> = ({ userdata }: Props) => (
  <Layout userdata={userdata} />
);

export default connect(
  (state: AppState) => ({
    userdata: state.user,
  }),
  null
)(QuickStart);
