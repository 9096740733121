import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'initial',
		height: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
	},
	img : {
		width: "100%",
	},
	link: {
		textDecoration: 'none',
		color: 'inherit',
		width: "100%"
	},
	HighlightLine: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
	},
	section: {
		margin: "5vh 0",
		height: "auto"
	},
	packRoot: {
		padding: "40px 30px",
		borderRadius: "15px",
		minHeight: "550px",
		position: "relative",
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around"
	},
	promo: {
		color: "black",
		fontWeight: "bold",
		position: "absolute", 
		padding: "5px", 
		right: "15px", 
		top:"15px",
		border: "2px dashed",
		borderColor: theme.palette.primary.main,
		borderRadius: "5px"
	},
	priceMonthly : {
		display: "flex", 
		flexDirection: "row", 
		justifyContent: "center", 
		alignItems: "baseline",
		marginTop: "10px",
	},

	logo :  {
		width: "100%", 
		maxWidth: "70px",
		[theme.breakpoints.up('xs')]: {
			maxWidth: "45px",
		},
		[theme.breakpoints.up('sm')]: {
			maxWidth: "55px",
		},
	},

	/** Section tagline */
	containerTaglineDescription: {
		/*display: "flex",
		flexDirection: "row",
		alignItems: "center",*/
		'& div:first-child': {
			flex: 1,
			order: 2,
			[theme.breakpoints.between('xs', 'sm')]: {
				flex: 'initial',
				order: 'initial',
			},
		},
		'& div:first-child img': {
			maxWidth: "400px",
		},
		'& div:last-child': {
			textAlign: "center",
			flex: 1,
			order: 1,
			'& h3': {
				marginBottom: "5vh",
			},
			'& h2': {
				marginBottom: "2vh",
			},
			[theme.breakpoints.between('xs', 'sm')]: {
				flex: 'initial',
				order: 'initial',
				margin: "25px 0",

			},
		}
	},
	sectiontagline: {
		minHeight: "90vh",
		display: 'flex',
		flexDirection: 'column',
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
	},

	/** Section pour qui ? */
	ForWhoImgDiv: {
		"& img": {
			maxWidth: "600px"
		},
		textAlign: "center",
		padding: "10px",
	},
	Pad_15_vertical: {
		padding: "0 15px",
	},
	order_inverse: {
		[theme.breakpoints.between('xs', 'sm')]: {
			flexDirection: "column",
		},
		'& div:first-child': {	
			[theme.breakpoints.between('xs', 'sm')]: {
				flex: 1,
				order: 2,
			},	
		},
		'& div:last-child': {			
			[theme.breakpoints.between('xs', 'sm')]: {
				flex: 1,
				order: 1,
			},			
		}
	},


	/** Section Covid-19 */
	sectionCovidDirective: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		'& div': {
			marginBottom: "15px"
		},
		'& div img': {
			maxWidth: "100px",
		}
	},

	/** Section C'est quoi */
	sectionWhatIsIt: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
	},
	sectionWhatListImg: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around"
	},
	sectionWhatImg: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center",
		padding: "15px",
		height: "100%"
	},

	badge: {
		backgroundColor: "red",
		display: "inline-block",
    	minWidth: "16px", /* pixel unit */
    	padding: "35px 50px", /* pixel unit */
    	borderRadius: "50%",
    	fontSize: "75px",
    	textAlign: "center",
    	background: "#1779ba",
    	color: "#fefefe",
	},

	/* Section Usage*/
	sectionUsage: {
		display: "flex",
		flexDirection: "column",
		textAlign: "center"
	},

	faqAccordion: {
		display: "flex", 
		flexDirection: "row", 
		width:"100%", 
		justifyContent: "space-between",
		alignItems: "center"
	},

	AccordionDiv: {
		margin: "2vh 0",
		padding: "10px",
		borderRadius: "5px"
	},

	roundedImg: {
		borderRadius: "12px", 
		overflow: "hidden",
	}
}));

export default useStyles;
