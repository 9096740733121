import React, { useEffect } from "react";

import {
	Button,
	CssBaseline,
	Link,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControlLabel,
	FormGroup,
	TextField,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
} from "@material-ui/core";
import useStyles from "./styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IOSSwitch } from "../../../components/IosSwitch/IosSwitch";
import ScookNavBar from "../../../components/ScookNavBar/ScookNavBar";
import { IDish, UserState, IDiscountSchedule, TileData, ICategoryLink, 
	//ICategory 
} from "../../../types";
import VerticalList from "../../../components/VerticalList";
import { DishServices } from "../../../api";
import { APIManager } from "../../../services";
import AdvancedSchedule from "../../../components/AdvancedSchedule/AdvancedSchedule";
import SearchTable from "../../../components/SearchTable/SearchTable";

interface Props {
	userData: UserState,
}

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="/">
				SCOOKS
        </Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const categoryDishes = ['Toutes', 'Accompagnement', 'Boissons', 'Plat', 'Entrée', 'Dessert'];


const Layout: React.FC<Props> = ({ userData }: Props) => {

	const classes = useStyles();

	const [isAvailable, setAvailable] = React.useState(true);
	const [price, setPrice] = React.useState('');
	const [calculatePrice, setCalculatePrice] = React.useState(0);
	// Liste des plats que possède le commerçcant
	const [dishesOfCurrentTrader, setDishesOfCurrentTrader] = React.useState<IDish[]>([]);
	// Liste des plats constitutif du menu
	const [dishesInMenu, setDishesInMenu] = React.useState<TileData[]>([]);
	// Liste des plats dans la recherche
	const [dishesFromSearch, setDishesFromSearch] = React.useState<IDish[]>([]);
	const [name, setName] = React.useState('');
	const [discount, setDiscount] = React.useState<IDiscountSchedule[]>([]);

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value as string);
	};

	const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPrice(event.target.value as string);
	};

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setAvailable(event.target.checked);
	}

	const handleAddSchedule = (item: IDiscountSchedule) => {
		setDiscount([...discount, item])
	}

	const handleReductionClose = (key: number) => {
		let newdiscounts = discount.filter((val: IDiscountSchedule, keyTab: number) => {
			if (key === keyTab) {
				return false;
			}
			return true;
		});
		setDiscount([...newdiscounts])
	}

	const OnKeyDownEnter = (searchText: string) => {
		let newDish = dishesOfCurrentTrader.filter((dish) => {
			if (dish.name.indexOf(searchText) !== -1) {
				return true;
			}
			return false;
		});
		setDishesFromSearch([...newDish]);
	}

	const OnChangeSearch = (searchText: string) => {
		//console.log(searchText);
		if (searchText === '') {
			setDishesFromSearch([...dishesOfCurrentTrader]);
			return;
		}
		let newDish = dishesOfCurrentTrader.filter((dish) => {
			if (dish.name.indexOf(searchText) !== -1) {
				return true;
			}
			return false;
		});
		setDishesFromSearch([...newDish]);
	}

	const OnCategorySelected = (category: string) => {
		let newDish = dishesFromSearch.filter((dish) => {
			if (category.indexOf('Toutes') !== -1) {
				return true;
			}
			if ((dish.category as ICategoryLink).name.indexOf(category) !== -1) {
				return true;
			}
			return false;
		});
		setDishesFromSearch([...newDish]);
	}

	const OnRowSelected = (id: string, checked: boolean) => {

		if (!checked) {
			// Enlève des plats dans le menu le plat selectionné
			//let ind: number = -1;
			let newDishInMenu = dishesInMenu.filter((x, i) => {
				if (x.keyTile === id) {
					//ind = i;
					return false;
				}
				return true;
			});
			//newDishInMenu.splice(index, 1)
			setDishesInMenu([...newDishInMenu]);
		} else {
			//ajout le plat sélectionné dans la liste des plat du menu
			let newDishInMenu = dishesOfCurrentTrader.filter((x) => {
				if (x._id && x._id === id) {
					return true;
				}
				return false;
			});

			if (newDishInMenu && newDishInMenu.length > 0) {
				let tile: TileData = {
					picture: newDishInMenu[0].picturePrincipal as string,
					keyTile: newDishInMenu[0]._id as string,
					title: newDishInMenu[0].name,
					price: newDishInMenu[0].priceHT,
				}
				setDishesInMenu([...dishesInMenu, tile]);
			}
		}
		let priceAlacarte = 0;
		dishesInMenu.forEach((x) => {
			if (x.price) {
				priceAlacarte += x.price;
			}
		});
		setCalculatePrice(priceAlacarte);
	}

	/* 	const onItemClick = () => {
			console.log("Open modal informations plat ! ");
		} */
	useEffect(() => {
		console.log("composant monté");
		let api: DishServices = APIManager.getDishServices();

		if (!userData.userdata) {
			console.log("userdata vide");
			return;
		}
		//console.log(userData.userdata.id);

		api.GetAllDishByTrader(userData.userdata.id).then((dishes: IDish[]) => {
			//console.log(dishes);
			setDishesOfCurrentTrader([...dishesOfCurrentTrader, ...dishes]);
			setDishesFromSearch([...dishesFromSearch, ...dishes]);
		}).catch((err) => {
			console.log(err);
		});

		/*api.GetCard(userData.userdata.id).then((card: ICard) => {
			console.log("a effectué appel a card api avec succes");
			console.log(card);
			if (card.menus && card.menus.length > 0
				&& card.menus[0].id && userData.userdata?.id) {
				api.GetMenuWithScheduleById(card.menus[0].id, userData.userdata.id).then((res) => {
					console.log("a effectué appel a menu schedule api avec succes");
					console.log(res);

				}).catch((err) => {
					console.log("une erreur c'ets produite lors de l'appel a schedule api");
					console.log(err);
					throw err;
					//afficher snack ca c'est mal passé
				});
			} else {
				console.log("erreur dans le check");
				console.log(card.menus);
			}

		}).catch((err) => {
			console.log("une erreur c'est produite lors de l'appel au card api");
			console.log(err);
			//afficher snack ca c'est mal passé
		});*/

	}, [userData, dishesFromSearch, dishesOfCurrentTrader]);

	return (
		<React.Fragment>
			<CssBaseline />
			<div className={classes.root}>
				<ScookNavBar displayLogo={true} />
				<div className={classes.layout}>
					<Typography component="h1" variant="h3" align="center">
						Création avancée d'un menu de la carte
					</Typography>
					<Typography variant="h6">Plats ajoutés votre menu</Typography>
					<div className={classes.test}>
						<VerticalList
							data={dishesInMenu}
							onItemClick={() => { console.log("ouvrir modale edit plat") }}
							OnCreateClick={() => { console.log("ouvrir de creation edit plat") }}
							displayAddButton={false}
						></VerticalList>
					</div>
					<Typography variant="h6">Informations concernant le menu</Typography>
					<div className={classes.inputs}>
						<FormGroup className={classes.firstGroup}>
							<TextField
								id="outlined-basic"
								label="Intitulé du menu"
								variant="outlined"
								value={name}
								onChange={handleChangeName}
								InputProps={{
									className: classes.inputCustom
								}} />
							<div className={classes.calculatedPrice}>
								<Typography>Prix totale des articles a la carte :</Typography>
								<Typography variant="h3">{calculatePrice}€</Typography>
							</div>
						</FormGroup>
					</div>
					<div className={classes.inputs}>
						<FormGroup className={classes.firstGroup}>
							<FormControl variant="outlined">
								<InputLabel htmlFor="outlined-adornment-amount">Prix HT</InputLabel>
								<OutlinedInput
									id="outlined-adornment-amount"
									value={price}
									onChange={handleChangePrice}
									startAdornment={<InputAdornment position="end">€</InputAdornment>}
									labelWidth={40}
								/>
							</FormControl>
							<FormControl variant="outlined">
								<InputLabel htmlFor="outlined-adornment-amount">Prix TTC</InputLabel>
								<OutlinedInput
									id="outlined-adornment-amount"
									value={price}
									onChange={handleChangePrice}
									startAdornment={<InputAdornment position="end">€</InputAdornment>}
									labelWidth={40}
								/>
							</FormControl>
						</FormGroup>
					</div>
					<div className={classes.inputs}>
						<FormControlLabel
							control={<IOSSwitch checked={isAvailable} onChange={handleChange} name="checkedB" />}
							label="Disponibilité"
							labelPlacement="start"
						/>
					</div>
					<Typography variant="h6">Ajouter un plat concernant le plats</Typography>
					<div>
						<SearchTable
							OnCategorySelected={OnCategorySelected}
							OnChangeSearch={OnChangeSearch}
							OnKeyDownEnter={OnKeyDownEnter}
							OnSelected={OnRowSelected}
							dataSelect={categoryDishes}
							label={"catégories"}
							data={dishesFromSearch} />
					</div>
					<Accordion className={classes.inputs}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography variant="h6">Appliquer des réductions</Typography>
						</AccordionSummary>
						<AccordionDetails className={classes.AdvancedDiscounts}>
							<AdvancedSchedule data={discount} onItemClick={(key: number) => {
								console.log('La reduction ' + key + ' a été clickez!');
							}}
								onAdd={handleAddSchedule}
								onDelClick={handleReductionClose} />
						</AccordionDetails>
					</Accordion>
					<FormGroup>
						<Button variant="contained" color="secondary">
							Créer
						</Button>
					</FormGroup>
				</div>
				<Copyright />
			</div>
		</React.Fragment>
	);
}

export default Layout;
