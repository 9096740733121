import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import useStyles from "./styles";
import { CssBaseline, Paper, InputBase, Divider, Badge, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

interface Props {
	OnKeyDownEnter: (searchText: string) => void;
	OnChangeSearch: (searchText: string) => void;
	OnCategorySelected: (category: any) => void;
	dataSelect: { value: any, name: string }[];
	label: string;
}

const SearchBarDish: React.FC<Props> = ({ OnChangeSearch, OnKeyDownEnter, OnCategorySelected, dataSelect, label }: Props) => {
	const classes = useStyles();

	const [searchText, setSearchText] = React.useState<string>('');
	const [category, setCategory] = React.useState<any>(dataSelect.length > 0 ? dataSelect[0].value : '');


	const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
		setCategory(event.target.value as any);

		//console.log("value catégory onchange : " + event.target.value as string);
		//console.log(`catégory of onchange : ${category}`);
		OnCategorySelected(event.target.value as any);
	};

	const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value as string);
		//console.log("value target onchange : " + event.target.value as string);
		//console.log(`search of onchange : ${searchText}`);
		OnChangeSearch(event.target.value as string);
	};

	const handleFocus = (event: React.FocusEvent) => {
		//console.log(event.target);
		//console.log(`search of focus : ${searchText}`);
		OnChangeSearch(searchText);
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key !== 'Enter') {
			return;
		}
		OnKeyDownEnter(searchText);
	}

	return (
		<Fragment>
			<CssBaseline />
			<Paper component="div" className={classes.root}>
				<Badge color="primary"
					overlap="circle"
					variant="dot"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					className={classes.logo}>
					<Typography variant="h3" color="primary" noWrap>Yum</Typography>
					<Typography variant="h3" noWrap>Scan</Typography>
				</Badge>
				<InputBase
					className={classes.input}
					placeholder="Trouver un de vos plat à partir de son nom"
					inputProps={{ 'aria-label': 'Rechercher' }}
					value={searchText}
					onChange={handleChangeSearch}
					onKeyDown={handleKeyDown}
					onFocus={handleFocus}
					onFocusCapture={handleFocus}
				/>
				<IconButton type="submit" className={classes.iconButton} aria-label="search">
					<SearchIcon />
				</IconButton>
				<Divider className={classes.divider} orientation="vertical" />
				<FormControl variant="outlined">
					<InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						value={category}
						onChange={handleChangeCategory}
						label="catégorie"
					>
						{dataSelect.map((value, index: number) => {
							return (
								<MenuItem key={index} value={value.value}>{value.name}</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Paper>
		</Fragment>
	);
};

export default SearchBarDish;
