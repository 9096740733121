import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: '100%',
		alignItems: "center",
		justifyContent: "center",
		padding: "10px"
	},
	firstSentence: {
		display: "flex",
		flexDirection: "row",
		textAlign: "center"
	},
	highlight: {
		color: theme.palette.primary.main,
	},
	toptittle: {
		width: "100%",
		BorderBottom: "1px solid black",
		textAlign: "center",
	}
}));

export default useStyles;
