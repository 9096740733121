import React, { useContext, useEffect, useState } from "react";
import { CssBaseline, Typography, Grid, Paper, Container, Divider, Switch } from "@material-ui/core";
import useStyles from "./styles";
import { UserState, AppState, IPackYum, ITrader } from "../../types";
import { APIManager } from "../../services";
import { connect } from "react-redux";
import StripeCheckoutButton from "../StripeCheckoutButton";
import { store } from "../../contexts/auth";


interface Props {
	userdata: UserState,
	Advanced: boolean,
	OnClose: () => void,
	OnOpen: () => void,
}

/*let packDataYumPdf = [
	{
		title: 'YumPDF',
		description: 'Une carte aux format pdf',
		price: {
			amount: 7.99,
			currency: "€",
			frequency: "mois",
		},
		promotion: true,
		features: [ 'Carte PDF', '2 Qrcodes', 'Changement de carte illimité', 'Espace de gestion', 'Scans illimité', 'Support 7/7j', ],
		data_button: {
			type_product: BILLING_TYPE.MONTHLY,
			text_button: "Forfait mensuel"
		}
	},
	{
		title: 'YumPDF',
		description: 'Une carte aux format pdf',
		price: {
			amount: 95.00,
			currency: "€",
			frequency: "an",
		},
		promotion: true,
		features: [ 'Carte PDF', '2 Qrcodes', 'Changement de carte illimité', 'Espace de gestion', 'Scans illimité', 'Support 7/7j', ],
		data_button: {
			type_product: BILLING_TYPE.YEARLY,
			text_button: "Forfait annuel"
		}
	},
];*/

const Layout: React.FC<Props> = ({ userdata, Advanced, OnClose, OnOpen }: Props) => {

	const classes = useStyles();
	const [packs, setPacks] = useState<IPackYum[] | undefined>(undefined);
	const [isLoading, SetIsLoading] = useState<boolean>(true);
	const [idTrader, setIdTrader] = useState<string | undefined>(undefined);
	const [periode, setPeriode] = useState<boolean>(true);

	let authContext = useContext(store);
	//const { enqueueSnackbar } = useSnackbar();



	const getPack = async () => {
		try {
			let packsFromBack: IPackYum[] | undefined = await APIManager.getPackServices().GetAllPacks();
			//let priceOfPack = await APIManager.getBillingService().GetPriceOfPacks("price_1JKihPCTTiHMcsTM1sbzBfz6");

			if (!packsFromBack) {
				return;
			}
			setPacks([...packsFromBack]);
			//console.log(packsFromBack);
			//console.log(priceOfPack);
		} catch (err){
			console.log(err);
		} finally {
			SetIsLoading(false);
		}
	}
	
	const getUserInformations = async () => {
		try {
			await authContext.authObj.getProfile();

			console.log("Setting trader !")
			setIdTrader((authContext.authObj.userProfile as ITrader)._id);
		} catch(err) {
			console.log("Err lors du chargement", err);
		} 
	};

	const handleChange = (event: any) => {
		setPeriode(event.target.checked);
	};

	function getProductFromBilling(pack: IPackYum) {
		if (!packs) {
			return undefined;
		}
		return periode ? pack.price.yearly : pack.price.monthly;
	}

	/* async function getPriceOfPack(price: string) {
		if (!packs) {
			return undefined;
		}
		//let priceNumber = periode ? price.yearly : price.monthly;
		//console.log(priceNumber);
		//let priceOfPack2 = 0;
		let priceOfPack = await APIManager.getBillingService().GetPriceOfPacks(price);
		
		if (priceOfPack) {
			return priceOfPack;
		}

		return undefined;
	} */

	/* const subscribeFreeCompte = (idPack:string, idProduct: string) => {

		APIManager.getBillingService().BillingSuscriptionFreeProduct(idTrader as string, idPack,  idProduct).then(() => {
			enqueueSnackbar("test ca marche ", {
				variant: "warning",
				anchorOrigin: {
					vertical: "top",
					horizontal: "right",
				}
			});
		}).catch((err: Error) => {
			enqueueSnackbar(err.message, {
				variant: "warning",
				anchorOrigin: {
					vertical: "top",
					horizontal: "right",
				}
			});
			console.log(err);
		});
	} */

	useEffect(() => {
		//console.log("userdata display offer ! ", userdata.userdata);
		if (isLoading) {
			
			getPack();
			SetIsLoading(false);
		}
	}, [userdata]);

	useEffect(() => {
		//console.log("userdata display offer for trader ! ", userdata.userdata);
		if (!idTrader) {
			getUserInformations();
		}
	}, [userdata.userdata]);
	
	return (
		<React.Fragment>
			<CssBaseline />
			<Container component="div" maxWidth={false} className={classes.root}>
				<div className={classes.textRoot}>
					<Typography variant="subtitle1" component="p" gutterBottom>
						<span color="primary">{userdata.userdata?.userinfos.firstname}</span>, ta période d'essai est  <span className={classes.HighlightLine}>terminé</span>
					</Typography>
					<Typography variant="body1" component="span" gutterBottom>
						Tu as apprécie les fonctionnalitées ? choisis l'offre qui te convient le mieux
					</Typography>
					{/* <Typography variant="h6" component="p" gutterBottom>
						N'oubliez surtout pas tes -25% sur 6 mois avec le code promo : <span className={classes.HighlightLine}>STOPCOVID</span>
					</Typography> */}
					<Typography variant="subtitle2" component="span" gutterBottom>
						Tu pourras à tout moment te désabonner dans la rubrique "Paramètres offre"
					</Typography>
				</div>

				<Grid component="label" container alignItems="center" justify="center" spacing={1}>
					<Grid item>Mensuel</Grid>
					<Grid item>
						<Switch checked={periode} onChange={handleChange} name="periode" color="primary" />
					</Grid>
					<Grid item>Annuel</Grid>
				</Grid>

				<Grid container direction={"row"} spacing={2} alignItems="center" justify="center">

					{packs?.filter(x => x.name.toLowerCase().indexOf("trial") <= -1).map((item) => {
						return(
							<Grid item xs={12} sm={12} md={6} key={item.name}>
								<Paper elevation={6} className={classes.packRoot}>
									{/* <div className={classes.promo}>
										<Typography>Promo</Typography>
									</div> */}
									<div>
										<Typography variant="subtitle2" component="p">{ periode ? "Annuel" : "Mensuel"}</Typography>
									</div>
									<Divider />
									<div style={{ textAlign: "center", margin: "2vh 0px"}}>
										<Typography color="primary" variant="subtitle1" component="h1" gutterBottom>{item.name}</Typography>
									</div>							
									<section style={{ display: "flex", flexDirection: "column" }}>							
										<div style={{ textAlign: "center", margin: "10px 0px"}}>
											{item.metadata.features.map((feature) => (
												<div key={feature} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", margin: "1vh 0"}}>
													<Typography component="span" variant="body2" align="center" key={feature}>
														{feature}
													</Typography>
												</div>
											))}
										</div>
									</section>
									<div>
										<section className={classes.priceMonthly}>
											{/* <Typography style={{ textDecoration: "line-through", color: "red", marginRight: "1vw"}} component="h2" variant="h3" color="textPrimary">
												7.99€*
											</Typography> */}

											{/*item.name === "yumpdf" && periode && 
												<Typography style={{ textDecoration: "line-through", color: "red", marginRight: "1vw"}} component="h2" variant="h2" color="textPrimary">
													Gratuit
												</Typography>*/
											}
											{item.name === "Basic Digital" && periode && 
												<Typography style={{ textDecoration: "line-through", color: "red", marginRight: "1vw"}} component="h2" variant="subtitle1" color="textPrimary">
													118.80€*
												</Typography>
											}
											{item.name === "Smart Digital" && periode && 
												<Typography style={{ textDecoration: "line-through", color: "red", marginRight: "1vw"}} component="h2" variant="subtitle1" color="textPrimary">
													238.80€*
												</Typography>
											}
											{item.name === "Premium Digital" && periode && 
												<Typography style={{ textDecoration: "line-through", color: "red", marginRight: "1vw"}} component="h2" variant="subtitle1" color="textPrimary">
													478.80€*
												</Typography>
											}

											{/* <Typography component="h2" variant="h2" color="textPrimary">
												{getPriceOfPack(item.price)}
												{periode ? getPriceOfPack(item.price.yearly) : getPriceOfPack(item.price.monthly)}
												5.99€*
											</Typography> */}

											{item.name === "yumpdf" && 
												<Typography component="h2" variant="subtitle1" color="textPrimary">
													{periode ? "Gratuit" : "Gratuit"}
												</Typography>
											}
											
											{item.name === "Basic Digital" && 
												<Typography component="h2" variant="subtitle1" color="textPrimary">
													{periode ? "99.00€*" : "9.90€*"}
												</Typography>
											}

											{item.name === "Smart Digital" && 
												<Typography component="h2" variant="subtitle1" color="textPrimary">
													{periode ? "199.00€*" : "19.90€*"}
												</Typography>
											}

											{item.name === "Premium Digital" && 
												<Typography component="h2" variant="subtitle1" color="textPrimary">
													{periode ? "399.00€*" : "39.90€*"}
												</Typography>
											}

											<Typography variant="subtitle1" color="textSecondary">
												{ periode ? "/an" : "/mois"}
											</Typography>
										</section>
										{/* <Typography variant="subtitle1" component="p">soit -2€/mois pendant 6 mois</Typography> */}

										{periode && item.name !== "yumpdf" && <Typography variant="body1" component="p">soit 2 mois offerts</Typography>}
									</div>
									<Typography variant="body1" component="p">*Abonnement sans engagement.</Typography>
									<div style={{ display:"flex", justifyContent:"center", marginTop: "15px"}}>
									{!isLoading &&
										(<StripeCheckoutButton
											color="primary"
											text="Choisir ce forfait"
											idTrader={idTrader}
											pack={item}
											priceId={getProductFromBilling(item)} />)}
									{/*!isLoading && 
									(item.metadata?.isFree === true) &&
										(<Button 
											color="primary"
											variant="contained"
											onClick={() => { subscribeFreeCompte(item._id as string, item.product.monthly); }}
											>                    
											Choisir ce forfait
										</Button>)*/}
									</div>
								</Paper>
							</Grid>
						);
					})}

				</Grid>
			</Container>
		</React.Fragment>
	);
}

export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}),
	{}
)(Layout);

//export default Layout;
