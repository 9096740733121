import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		diplay: "flex",
		flexDirection: "column",
		width: "100%",
		heigh: "100%",
		padding: "2vh 2vw",
		"& article": {
			margin: "3vh 0"
		}
	},

	formElement: {
		"& article": {
			margin: "3vh 0",
			width: "100%",
			"& section": {
				margin: "1vh 0",
			}
		}
	},

	formButton: {
		display: "flex", 
		alignItems: "center",
		justifyContent: "center",
		"& button": {
			minWidth: "100px"
		}
	},
	formButtonLeft: {
		justifyContent: "start"
	},
	formButtonRight: {
		justifyContent: "end"
	},

	FiberManualRecord:  {
		marginLeft: 5, 
		fontSize: "1.2rem",
		color: "green",
	},
	p_10: {
		padding: "1vh 1vw"
	},
	m_2_v: {
		margin: "2vh 0"
	},
	chipRoot: {
		display: "flex",
		flexDirection: "row",
		padding: "10px",
		justifyContent: "start",
		alignItems: "center",
		flexWrap: "wrap",
		"& span": {
			marginLeft: "5px",
			textTransform: "capitalize",
		},
	},
	chipsDisplay: {
		minHeight: "30px",
		margin: "10px 10px",
	},
	m_10_vertical: {
		margin: "10px 0px"
	},
	title: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		"& img": {
			width: "100%",
			maxWidth: "45px",
			margin:"0 5px"
		}
	},
}));

export {
	useStyles,
}
