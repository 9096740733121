import { AAuthApi, RESTVerb } from "../../types";
import * as httpStatus from 'http-status';
import { TipsToDisplay } from "../../types/dal/tips";

export class TipsServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

    /**
     * Récupere les tips pour une page données
	 */
	public GetTipsFromPage(pathname: string): Promise<TipsToDisplay> {
		return this.JsonAuthRequest(RESTVerb.GET, `tips${pathname}`).then(async (res: Response) => {
			let result = await res.json();

			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as TipsToDisplay;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}
	
}
