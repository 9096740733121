import React, { Fragment, useEffect } from "react";
import { Typography, Container, Grid, Breadcrumbs, Link, Card, CardContent, CardActions, Snackbar } from "@material-ui/core";
import useStyles from "./styles";
import { APIManager } from "../../../services";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";

import { ITrader, IPdfCard, ICard, YUMSCAN_FEATURES, AppState, UserStateV2 } from "../../../types";
import { useSnackbar } from "notistack";
import Auth from "../../../utils/auth";
import LinkCardManage from "../../../components/LinkCardManage/LinkCardManage";
import DigitalCard from "../../DigitalCard";
import { CircularProgress } from '@material-ui/core';
import { YumButton } from "../../../Lib/UI/YumButton";
import { useLocation } from "react-router-dom";
import ScopedArea from "../../../components/v2/ScopedArea/ScopedArea";
import { connect } from "react-redux";
import TipsManager from "../../../components/TipsManager";


interface Props {
	authContext: {
		authState: boolean;
		profileState: any;
		handleUserLogin: () => void;
		handleUserLogout: () => void;
		handleUserAddProfile: (profile: {} | ITrader) => void;
		handleUserRemoveProfile: () => void;
		authObj: Auth;
	},
	userV2: UserStateV2;
}

//const url_server = process.env.REACT_APP_BASE_URL;

const Layout: React.FC<Props> = ({ authContext, userV2 }: Props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		event.preventDefault();
		console.info('You clicked a breadcrumb.');
	}

	const [selected, setSelected] = React.useState<number>(0);
	const [isLoading, setIsLoading] = React.useState(true);
	let query = useQuery();

	useEffect(() => { autoSelectCardOption(); }, []);

	const autoSelectCardOption = () => {

		if (query.get("option") === "digital") {
			setSelected(2);
		} else if(query.get("option") === "pdf"){
			setSelected(1);
		}
	}
	// A custom hook that builds on useLocation to parse
	// the query string for you.
	function useQuery() {
		const { search } = useLocation();
	
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	function CardStackHomComponent(props: any) {

		const [isSucces, setSucess] = React.useState(false);
		const [isError, setError] = React.useState(false);
		const [cards, setCards] = React.useState<ICard[] | undefined>(undefined);

		const [succesMsg, setSucessMsg] = React.useState<string>('Le pdf a été télécharger avec sucess');
		const [errorMsg, setErrorMsg] = React.useState<string>('Le téléchargement a rencontré une erreur, contacter le service client');

		//const inputRef = React.useRef<HTMLInputElement>(null);

		/*const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files?.item(0);
			let apiCard: CardServices = APIManager.getCardServices();
			let formData = new FormData();

			if (!file) {
				setError(true);
				return;
			}
			console.log(file.type);
			if (file.type !== "application/pdf") {
				setErrorMsg("Le fichier ajouté n'est pas un pdf");
				setError(true);
				return;
			}
			// Check taille du pdf max pour ne pas saturé le serveur
			formData.append('files', file);
			apiCard.UploadPDF(formData).then((res: boolean) => {
				if (res) {
					setSucess(true);
					setError(false);
					if (authContext.authObj.userProfile !== {}){
						console.log("reload page !");
						GetPdfAsync((authContext.authObj.userProfile as ITrader)._id as string);
					}
				} else {
					setError(true);
					setSucess(false)
				}
			}).catch((err) => {
				console.log(err);
				setError(true);
			});
		}*/

		const handlingClosePopUp = () => {
			setSucess(false);
			setError(false);
			setSucessMsg('Le pdf a été télécharger avec sucess');
			setErrorMsg('Le téléchargement a rencontré une erreur, contacter le service client');
		}

		const getAllCard = async () => {
			try {
				let cards = await APIManager.getCardServices().GetAllCards((authContext.profileState as ITrader)._id as string);

				setCards([...cards]);
			} catch (err) {
				setCards([]);
			}
		}

		useEffect(() => {
			if (authContext.profileState === {} || cards){
				return;
			}
			getAllCard();
		}, [authContext.profileState, cards]);

		return (
			<React.Fragment>
				<div className={classes.quickNavSection}>
				</div>
				<Grid container alignContent="center" alignItems="center" spacing={2}>
					<ScopedArea area={YUMSCAN_FEATURES.Pdf}>
						<Grid item component='div' xs={12} sm={6} md={4} lg={3}>
							<Card elevation={5} className={classes.card}>
								<CardContent>
									<div className={classes.cardTitle}>
										<Typography variant="subtitle1">Mes cartes au format PDF</Typography>
									</div>
									<div className={classes.cardImageContainer}>
										<div>
											<PictureAsPdfIcon style={{ fontSize: 40 }} />
										</div>
										<Typography variant="body1">{pdfLink ? "Une carte PDF est déja disponible" : "Aucune carte n'a été téléchargé"}
											{pdfLink ? (<DoneAllIcon style={{ color: "green" }} />) : (<ClearIcon style={{ color: "red" }} />)}</Typography>
									</div>
									<div className={classes.description}>
										<Typography variant="body1">Cliquez sur <strong>"Mes cartes PDF"</strong> pour gérer la liaison entre vos qrcodes et vos cartes pdf. </Typography>
									</div>
								</CardContent>
								<CardActions style={{ justifyContent: "center" }}>
									<YumButton 
										onClick={() => {
											setSelected(1);
										}}>
											Mes cartes PDF
									</YumButton>
								</CardActions>
							</Card>
						</Grid>
					</ScopedArea>
					<ScopedArea area={YUMSCAN_FEATURES.Digital}>
						<Grid item component='div' xs={12} sm={6} md={4} lg={3}>
							<Card elevation={5} className={classes.card}>
								{cards && <Fragment><CardContent>
									<div className={classes.cardTitle}>
										<Typography variant="subtitle1">Mes cartes digitales</Typography>
									</div>
									<div className={classes.cardImageContainer}>
										<div>
											<RestaurantMenuIcon style={{ fontSize: 40 }} />
										</div>
										<Typography variant="body1">Vous avez {cards.length} carte{cards.length > 1 ? 's' : ''}</Typography>
									</div>
									<div className={classes.description}>
										<Typography variant="body1">Gérez l'ensemble de vos cartes et des éléments de celle-ci en cliquant sur <strong>"Mes cartes digitales"</strong> </Typography>
									</div>
								</CardContent>
								<CardActions style={{ justifyContent: "center" }}>
									<YumButton 
										onClick={() => {
											setSelected(2);
										}}>
											Mes cartes digitales
									</YumButton>
								</CardActions></Fragment>}
								{!cards && <CircularProgress />}
							</Card>
						</Grid>
					</ScopedArea>				
					
					{/*<Grid item component='div' xs={12} sm={6} md={4} lg={3}>
						<Card elevation={5} className={classes.card}>
							<CardContent>
								<div className={classes.cardTitle}>
									<Typography variant="h4">Mes menus 100% digitalisés</Typography>
								</div>
								<div className={classes.cardImageContainer}>
									<div>
										<MenuBookIcon style={{ fontSize: 40 }} />
									</div>
									<Typography>Vous avez X menus dans votre carte</Typography>
								</div>
								<div className={classes.description}>
									<Typography>X menus disponibles</Typography>
									<Typography>X menus non disponibles</Typography>
								</div>
							</CardContent>
							<CardActions style={{ justifyContent: "center" }}>
								<Button color="secondary" variant="contained" size="small">Gérer mes menus ?</Button>
							</CardActions>
							<div className={classes.backgroundIndisponible}>
								<Typography variant="h2" style={{ color: "white", textTransform: "uppercase" }}>bientôt disponible</Typography>
							</div>
						</Card>
					</Grid>
					<Grid item component='div' xs={12} sm={6} md={4} lg={3}>
						<Card elevation={5} className={classes.card}>
							<CardContent>
								<div className={classes.cardTitle}>
									<Typography variant="h4">Facturation</Typography>
								</div>
								<div className={classes.cardImageContainer}>
									<div>
										<ReceiptIcon style={{ fontSize: 40 }} />
									</div>
									<Typography>Actifs/ Non actif</Typography>
								</div>
								<div className={classes.description}>
									<Typography>Emettez et gérez les factures de vos clients</Typography>
								</div>
							</CardContent>
							<CardActions style={{ justifyContent: "center" }}>
								<Button color="secondary" variant="contained" size="small">Gerer mes factures</Button>
							</CardActions>
							<div className={classes.backgroundIndisponible}>
								<Typography variant="h2" style={{ color: "white", textTransform: "uppercase" }}>bientôt disponible</Typography>
							</div>
						</Card>
					</Grid>
					<Grid item component='div' xs={12} sm={6} md={4} lg={3}>
						<Card elevation={5} className={classes.card}>
							<CardContent>
								<div className={classes.cardTitle}>
									<Typography variant="h4">Paiement</Typography>
								</div>
								<div className={classes.cardImageContainer}>
									<div>
										<ClearIcon style={{ fontSize: 40 }} />
									</div>
									<Typography>Module de paiement non actif</Typography>
								</div>
								<div className={classes.description}>
									<Typography>Cette fonctionnalité sera bientot disponible :)</Typography>
								</div>
							</CardContent>
							<CardActions style={{ justifyContent: "center" }}>
								<Button color="secondary" variant="contained" size="small">Gerer mes modes de paiement</Button>
							</CardActions>
							<div className={classes.backgroundIndisponible}>
								<Typography variant="h2" style={{ color: "white", textTransform: "uppercase" }}>bientôt disponible</Typography>
							</div>
						</Card>
							</Grid>*/}
				</Grid>
				<Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={isSucces || isError} autoHideDuration={6000} onClose={handlingClosePopUp}>
					<Alert variant="filled" onClose={handlingClosePopUp} severity={isError ? "error" : "success"}>
						<AlertTitle>Statut du téléchargement</AlertTitle>
						{isError ? errorMsg : succesMsg}
					</Alert>
				</Snackbar>
			</React.Fragment>
		)
	}

	function DynamicComponent(props: any) {
		const selectedComponent: number = props.selectedComponent;

		switch (selectedComponent) {
			case 0:
				return <CardStackHomComponent />;
			case 1:
					return <LinkCardManage back={props.back} />;
			case 2:
				return <DigitalCard back={props.back} />;
			default:
				return <CardStackHomComponent />;
		}
	}

	const [pdfLink, setPdfLink] = React.useState<string | undefined>(undefined);


	const GetPdfAsync = async (idTrader: string) => {
		//console.log("Chargement carte pdf")
		await APIManager.getAccountServices().getPdfForTrader(idTrader).then((pdf: IPdfCard | undefined) => {
			if (pdf) {
				setPdfLink(pdf.pdfPath);
			} else {
				console.log("Vous ne possedez aucun pdf");
			}
		}).catch((err) => {
			console.log(err);
			enqueueSnackbar("Impossible de charger le pdf pour l'utilisateur connecté", { variant: "warning" });
		}).finally(() => {
			setIsLoading(false);
		})
	}

	useEffect(() => {

		if (!isLoading || authContext.authObj.userProfile === {}){
			// si définis 0 appel
			return;
		}
		GetPdfAsync((authContext.authObj.userProfile as ITrader)._id as string);
	}, [authContext.authObj.userProfile, isLoading]);



	function backToDefaultPage() {
		setSelected(0);
	}

	return (
		<Fragment>
			<Container className={classes.root} maxWidth={false} component="main">
				<div className={classes.navSection}>
					<Breadcrumbs aria-label="breadcrumb">
						<Link color="inherit" href="/" onClick={handleClick}>
							<Typography
								component="span"
								variant="body2"
							>
								Ma carte
							</Typography>
						</Link>
					</Breadcrumbs>
				</div>
				<TipsManager />
				{!isLoading && (<DynamicComponent selectedComponent={selected} back={backToDefaultPage}/>)}
				{isLoading &&
					(<div>
						<Skeleton variant="text" />
						<div style={{ marginTop: "10vh"}}>
							<Skeleton variant="rect" width={210} height={118} />
							<Skeleton variant="rect" width={210} height={118} />
							<Skeleton variant="rect" width={210} height={118} />
						</div>
						
					</div>)}
			</Container>
		</Fragment >
	);
};

export default connect(
	(state: AppState) => ({
		userV2: state.userV2, 
	}),
	{

	}
)(Layout);
