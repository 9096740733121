import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		display: "flex",
		flexDirection: "column",
	},
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		margin: "15px 0",
		alignItems: "flex-end"
	},
	inputMarge: {
		padding: "5px",
	},
	capitalize: {
		textTransform: "capitalize",
	},
	title : {
		textTransform: "uppercase",
		margin: "15px 0",
		color: theme.palette.primary.main,
		fontWeight: "bold",
	}
}));

export default useStyles;
