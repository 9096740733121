import 'dotenv/config';

/*
const strNotNull = makeValidator((x) => {
  if (x) return x.toLowerCase();
  else throw new Error("Excepted a link not empty");
});

cleanEnv(process.env, {
  REACT_APP_BASE_URL: str(),
  REACT_APP_STRIPE_PUBLIC_KEY: str(),
});*/

export default process.env;
