import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	importantSentence: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	details: {
		padding: '10px',
		display: 'flex',
		flexDirection: 'column'
	},
	frequences: {

	},
	frequenceDetails: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: "baseline",
	},
	horraires: {

	},
	margin: {
		margin: theme.spacing(1),
	},
	textField: {
	},
	horraireDetail: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		fontSize: '35px',
	},
	title: {
		fontWeight: 'bold',
		margin: '10px 0px',
	},
	days: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	}
}));

export default useStyles;