import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "10px",
        display: "flex", 
        flexDirection: "column",
    },
	rootPaper: {
        padding: "10px",
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-evenly", 
        alignItems: "center",
    },
    rootDetail: {
		display: "flex", 
        flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
        padding: "2vh 1vw"     
    },
    img: {
        width: "100%",
        maxWidth: "200px"
    },
}));

export default useStyles;