import React, { Fragment } from "react";
//import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import { CssBaseline, Badge } from "@material-ui/core";

interface Props {
}

const LogoName: React.FC<Props> = () => {
	const classes = useStyles();

	return (
		<Fragment>
			<CssBaseline />
			<div className={classes.root}>
				<a href="https://www.yumscan.fr" className={classes.link}>
					<Badge color="primary"
						overlap="circle"
						variant="dot"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}>
						<Typography variant="h2" color="primary" noWrap>Yum</Typography>
						<Typography className={classes.secondWord} variant="h2" noWrap>Scan</Typography>
					</Badge>
				</a>
			</div>
		</Fragment>
	);
};

export default LogoName;
