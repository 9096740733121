import React, { Fragment } from "react";
import useStyles from "./styles";

import { TileData } from "../../types";
import { CssBaseline, Container } from "@material-ui/core";
import ItemInfo from "../ItemInfo/ItemInfo";
/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
/*
interface Props {
	DishPapers: TileData[];
	maxHeight?: number;
	maxWidth?: number;
}*/

interface Props {
	data: TileData[],
	onItemClick: (id: string) => void,
	OnCreateClick?: () => void,
	OnDeleteClick?: (id: string) => void,
	displayAddButton: boolean
}

const VerticalList: React.FC<Props> = ({ data, displayAddButton, onItemClick, OnCreateClick, OnDeleteClick }: Props) => {
	const classes = useStyles();
	const defaultAddPicture = "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/OOjs_UI_icon_add.svg/600px-OOjs_UI_icon_add.svg.png";

	return (
		<Fragment>
			<CssBaseline />
			<Container component="div" className={classes.root} maxWidth={false}>
				<div className={classes.subRoot}>
					{(displayAddButton
						&& <ItemInfo
							key={"-1"}
							keyTile={"-1"}
							picture={defaultAddPicture}
							onItemClick={OnCreateClick}>
						</ItemInfo>)}
					{data.map((tile: TileData, index: number) => {
						return (
							<ItemInfo
								key={tile.keyTile}
								keyTile={tile.keyTile}
								picture={tile.picture}
								title={tile.title}
								price={tile.price}
								onItemClick={onItemClick}
								onItemDelete={OnDeleteClick}
							></ItemInfo>
						);
					})}
				</div>
			</Container>
		</Fragment>

	);
};

export default VerticalList;
