import React, { Fragment, useEffect } from "react";

import { 
	//IDish, ICard, IMenu, 
	IDiscountSchedule, ICategory, 
	//ICategoryLink 
} from "../../../types";
import { APIManager } from "../../../services";
import {
	Container,
	Typography,
	Chip,
	AppBar,
	Divider,
	Paper,
	IconButton,
} from "@material-ui/core";
import useStyles from "./styles";

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import StickyCart from "../../../components/StickyCart/StickyCart";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
//import { IMG_CONSTANTES } from "../../../types/constantes/constantes";

/*
type InputsForm = {
	card: DISH_CATEGORIE;
	submitted: boolean;
};*/

/* interface itemCard<T> {
	categoryType: CategoryfilterValues,
	data: T[],
} */

interface Props {
	idTrader: string;
}

/** 
 * Représente l'ensemble des plats 
 * et menus a afficher 
 * */
/* interface ItemData {
	description?: string;
	picture: string;
	price: number;
	name: string;
	id: string;
	type: TypeItem;
} */

/**
 * Type d'item
 */
/* enum TypeItem {
	MENU,
	DISH
} */

/* enum CategoryfilterValues {
	ALACARTE,
	MENUS,
	ENTREE,
	DISH,
	DESSERT,
	ACCOMPAGNEMENT,
	ALL
} */

enum TypeDataInCategory {
	IMENU,
	IDISH,
}

interface DataInCategory {
	name: string;
	price: number;
	description: string;
	picturePrincipal: string;
	id: string;
	schedule: IDiscountSchedule[];
	type: TypeDataInCategory;
}


interface ItemCategoryData {
	category: ICategory;
	item: DataInCategory[];
}

//const url_server = process.env.REACT_APP_BASE_URL;

const Layout: React.FC<Props> = ({ idTrader }: Props) => {

	const classes = useStyles();
	// const cardAPI = APIManager.getCardServices();

	//const [categorySelected, setCategorySelected] = React.useState<CategoryfilterValues>(CategoryfilterValues.ALACARTE);
	// const [submitted, setSubmitted] = React.useState(false);
	const [isLogged, 
		//setIsLogged
	] = React.useState(true);

	const [traderName, 
		//setTraderName
	] = React.useState("Starbuck");
	const [itemShowed, 
		//setItemShowed
	] = React.useState<ItemCategoryData[]>([]);
	const [categoryDishes, setCategoryDishes] = React.useState<ICategory[]>([]);

	/*function applySchedule(price: number, schedule: IDiscountSchedule[]): number {
		let today = new Date();

		schedule.find((discount) => {
			if (discount.start && discount.start >= today
				&& discount.end && today <= discount.end)
		})
	}*/

/* 	function DynamicDisplayComponent(props: any) {
		const data: ItemCategoryData = props.item;

		if (!data.item || data.item.length === 0)
			return null;
		return (
			<Fragment>
				<div className={classes.categoryName} id={data.category._id} >
					<Typography variant="h4" align="left" color="textPrimary" gutterBottom>{data.category.name}</Typography>
					<Divider />
				</div>
				<Grid container spacing={3} alignItems="center">
					{data.item.map((item: DataInCategory) => {
						return (
							<Grid item
								style={{ display: "flex", flexDirection: "column" }}
								key={item.id as string}
								xs={12} sm={6} md={4}>
								<Paper className={classes.minValues} style={{}} elevation={3}>
									<div className={classes.coverContainer}>
										<img className={classes.cover} src={item.picturePrincipal} alt={"img"} ></img>
										<div className={classes.makeBackground}>
											<Typography variant="h6" style={{ fontFamily: 'Amatic SC', color: "white", fontWeight: "bold" }} align="center">{item.name}</Typography>
										</div>
										<div className={classes.ImgAddBackground}>
											<RemoveCircleRoundedIcon style={{ fontSize: "20px", marginRight: "10px" }} />
											<Typography variant="subtitle2" component="span" align="center">0</Typography>
											<AddCircleRoundedIcon style={{ fontSize: "20px", marginLeft: "10px" }} />
										</div>
									</div>
								</Paper>
								<div className={classes.descriptionContainer} >
									<Typography className={classes.description} gutterBottom>{item.description}</Typography>
								</div>
								<div className={clsx(classes.navHeaderBottom)}>
									<Typography variant="subtitle2" component="div" color="primary">
										{Number.parseFloat(item.price.toString()).toFixed(2)}€
									</Typography>
									<Typography variant="subtitle2" component="div" style={{ textDecoration: "line-through" }} color="secondary">
										{Number.parseFloat(item.price.toString()).toFixed(2)}€
									</Typography>
								</div>
							</Grid>
						);
					})}
				</Grid>
			</Fragment>
		);
	} */

	function DynamicDisplayComponentUber(props: any) {
		const data: ItemCategoryData = props.item;

		if (!data.item || data.item.length === 0)
			return null;
		return (
			<Fragment>
				<div className={classes.categoryName} id={data.category._id} >
					<Typography variant="subtitle1" align="left" style={{ fontFamily: 'Amatic SC' }} color="textPrimary" gutterBottom>{data.category.name}</Typography>
				</div>
				<Divider />
				<div style={{ width: "100%", padding: "10px" }}>
					{data.item.map((item: DataInCategory) => {
						return (
							<Paper elevation={3} key={item.id as string} style={{ display: "flex", flexDirection: "row", margin: "10px 0 20px 0" }}>
								<div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
									<div style={{ border: "1px solid #e0e0e0", borderRadius: "5px", width: "100px" }}>
										<img style={{ width: "100%", borderRadius: "5px" }} src={item.picturePrincipal} alt={"img"} ></img>
									</div>
								</div>
								<div style={{ flex: 2, padding: "5px 10px" }}>
									<Typography align="center" style={{ fontWeight: "bold" }} gutterBottom>{item.name}</Typography>
									<div style={{ width: "100%", height: "50px", textOverflow: "ellipsis", overflow: "hidden" }}>
										<Typography style={{ color: "grey", fontSize: "0.8rem" }} gutterBottom>{item.description}</Typography>
									</div>
									<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
										<div style={{ display: "flex", flexDirection: "row" }}>
											<Typography style={{ fontFamily: 'Amatic SC', fontSize: "1.3rem", fontWeight: "bold", marginRight: "10px" }} variant="subtitle2" component="div" color="primary">
												{Number.parseFloat(item.price.toString()).toFixed(2)}€
											</Typography>
											<Typography variant="subtitle2" component="div" style={{ textDecoration: "line-through", fontFamily: 'Amatic SC', fontSize: "1.3rem", fontWeight: "bold" }} color="secondary">
												{Number.parseFloat(item.price.toString()).toFixed(2)}€
											</Typography>
										</div>
										<div className={classes.addBtn}>
											<RemoveCircleRoundedIcon style={{ fontSize: "20px", marginRight: "10px" }} />
											<Typography variant="subtitle2" component="span" align="center">0</Typography>
											<AddCircleRoundedIcon style={{ fontSize: "20px", marginLeft: "10px" }} />
										</div>
									</div>
								</div>
							</Paper>
						);
					})}
				</div>
			</Fragment >
		);
	}

	/**
	 * Soumission du panier
	 * @param event 
	 */
	/* 	function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		
		}
	 */

	useEffect(() => {
		APIManager.getCategoryServices().GetAllCategoriesForTrader(idTrader).then((categories: ICategory[]) => {
			setCategoryDishes([...categories]);
			/*APIManager.getCardServices().GetAllCards(idTrader).then((card: ICard) => {

				//console.log(card);
				//console.log(categories);
				let itemToShow: ItemCategoryData[] = [];
				categories.forEach((category) => {
					let tmp: DataInCategory[] = [];

					if (category.name.toLowerCase() === "menu" && card.menus && card.menus.length > 0) {
						tmp = (card.menus as IMenu[]).map((menu) => {
							return {
								name: menu.name,
								price: menu.priceTTC,
								description: menu.description ? menu.description : "",
								picturePrincipal: menu.picturePrincipal === IMG_CONSTANTES.DEFAULT_IMG ? menu.picturePrincipal : `${url_server}/${menu.picturePrincipal}`,
								id: menu._id as string,
								schedule: menu.discountSchedule,
								type: TypeDataInCategory.IMENU
							} as DataInCategory;
						});
					} else {
						tmp = (card.dishes as IDish[]).filter((dish) => {
							if (((dish.category as ICategoryLink).category as ICategory).name === category.name) {
								return true;
							}
							return false;
						}).map((dish) => {
							return {
								name: dish.name,
								price: dish.priceTTC,
								description: dish.description ? dish.description : "",
								picturePrincipal: dish.picturePrincipal === IMG_CONSTANTES.DEFAULT_IMG ? dish.picturePrincipal : `${url_server}/${dish.picturePrincipal}`,
								id: dish._id as string,
								schedule: dish.discountSchedule,
								type: TypeDataInCategory.IDISH
							} as DataInCategory;
						});

					}
					itemToShow.push({
						category: category,
						item: tmp,
					});
				})
				console.log("itemtoshow");
				//console.log(itemToShow);
				if (itemToShow.length > 0) {
					setItemShowed([...itemShowed, ...itemToShow]);
				}
			}).catch((err) => {
				console.log(err);
			});*/
		}).catch((err) => {
			console.log(err);
		});
	}, [idTrader, itemShowed])

	return (
		<Fragment>
			<AppBar elevation={0} position="sticky" color="default" className={classes.headerRoot}>
				<Container maxWidth="lg" className={classes.root}>
					<div className={classes.header}>
						<div>
							{isLogged && (
								<IconButton color="secondary" aria-label="settings">
									<PersonOutlineOutlinedIcon />
								</IconButton>)}
						</div>
						<div className={classes.IdTraderContainer}>
							<div style={{ width: "50px" }}>
								<img style={{ width: "100%" }} src={"https://www.freepnglogos.com/uploads/starbucks-coffee-logo-vector-png-23.png"} alt={"starbucks-coffee-logo-vector-png-23.png"} />
							</div>
							<Typography variant="h2" style={{ fontFamily: 'Amatic SC' }} align="center" color="primary">{traderName}</Typography>
						</div>
						<div>
							{isLogged && (
								<IconButton color="secondary" aria-label="settings">
									<MenuIcon />
								</IconButton>)}
						</div>
					</div>
					{ /** Affichage des catégories */}

					<div className={classes.navHeader}>
						<div className={classes.navHeaderRoot}>
							<div className={classes.navHeaderSubRoot}>
								{categoryDishes.map((category, index) => {
									return (
										<Chip key={index} className={classes.chipsItemNav} label={category.name} component="a" href={`#${category._id}`} clickable />
									);
								})}
							</div>
						</div>
					</div>
				</Container>
			</AppBar>
			<div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
				{ /** Affichage du logo du commercant ou du logo YumScan */}
				{itemShowed.map((item) => {
					return (
						<DynamicDisplayComponentUber key={item.category._id} item={item} />
					);
				})}
			</div>
			<StickyCart />
			{/*<div className={classes.stickycart}>
						</div> */}
		</Fragment >

	);
}

export default Layout;