import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	appBar: {
		top: 'auto',
		bottom: 0,
		textAlign: "center",
	},
}));

export default useStyles;