import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	form: {
		maxWidth: "600px", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	root: {
		padding: '20px',
		textAlign: 'center',
		minHeight: "100%",
		width: "100%"
	},
	subRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	logoName: {
		marginBottom: "3vh",
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.between('md', 'xl')]: {
			display: "none",
		},
	}
}));

const useStylesBackdrop = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
	}),
);

export {
	useStylesBackdrop,
	useStyles,
}