import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';

const useStyles = makeStyles((theme) => ({
	rootPaper: {
        padding: "10px",
        margin: "5px 0px",
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        alignItems: "center",
        width: "100%",
    },
    rootDetail: {
		display: "flex", 
        flexDirection: "row",
		alignItems: "center",
		justifyContent: "center", 
        width: "100%",
    },
	space: {
		margin: "5px",
	},
	rootActions: {
		display: "flex", 
        flexDirection: "row",
		alignItems: "center",
		justifyContent: "center", 
	}
}));

interface IListItemProps {
    id: string;
    title: string;
    price: number;
    onItemDoubleClick?: (id: string) => void,
	onItemDelete?: (id: string) => void,
	onDuplicate?: (id: string) => void,
	onTransfert?: (id: string) => void,
	disabled?: boolean,
}

const DishListItem: React.FC<IListItemProps> = ({ onItemDoubleClick, onItemDelete, onDuplicate, onTransfert, id, price, title, disabled }: IListItemProps) => {
	const classes = useStyles();
  
    const [isOpenDialogDelete, setIsOpenDialogDelete] = React.useState<boolean>(false);

    const handleItemClick = (event: any) => {
		console.log("ID !", id);
		if (!disabled && onItemDoubleClick) {
			onItemDoubleClick(id);
		}
	}

	const handleDuplicateClick = (event: any) => {
		if (!disabled && onDuplicate) {
			onDuplicate(id);
		}
	}

	const handleTransfertClick = (event: any) => {
		if (!disabled && onTransfert) {
			onTransfert(id);
		}
	}

    return(
        <Fragment>
            <Paper 
                elevation={4}
                key={`${id}_key`}
                component="li"
                onDoubleClick={handleItemClick}
                className={classes.rootPaper}>
					<div className={classes.rootDetail}>
						<div className={classes.space}><Typography component="h4" variant="subtitle2">{title}</Typography></div>
                    	<div className={classes.space}><Typography component="h4" variant="subtitle1">{price}€</Typography></div>
					</div>
                   	<div className={classes.rootActions}>
					   {!disabled && onTransfert &&
						<Tooltip title="Transferer">
							<IconButton
								className={classes.space} 
								color="secondary" onClick={(e) => handleTransfertClick(e)}
								aria-label="settings">
								<MoveToInboxIcon />
							</IconButton>
						</Tooltip>}
						{!disabled && onItemDoubleClick &&
						<Tooltip title="Editer le plat">
							<IconButton
								className={classes.space} 
								color="secondary" onClick={(e) => handleItemClick(e)}
								aria-label="settings">
								<EditIcon />
							</IconButton>
						</Tooltip>}
						{!disabled && onDuplicate &&
					   	<Tooltip title="Dupliquer le plat">
							<IconButton
								className={classes.space}
								color="secondary" onClick={handleDuplicateClick}
								aria-label="settings">
								<FileCopyIcon />
							</IconButton>
						</Tooltip>}
						{!disabled && onItemDelete && 
						<Tooltip title="Supprimer le plat">
							<IconButton
								className={classes.space}
								color="secondary" onClick={(event) => setIsOpenDialogDelete(true)}
								aria-label="settings">
								<CancelIcon />
							</IconButton>
						</Tooltip>}
					</div>                              
            </Paper>
            <Dialog
				open={onItemDelete !== undefined && isOpenDialogDelete}
				onClose={() => { setIsOpenDialogDelete(false) }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography
						variant="subtitle1"
						component="span"
						gutterBottom>
						<strong>Suppréssion définitive</strong> de l'élement <strong>"{title}"</strong>
					</Typography>
					</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Typography
						variant="body1"
						component="span"
						gutterBottom>
							Vous êtes sur le point de supprimer définitivement l'élément <strong>"{title}"</strong> de votre carte aucune récupération ne sera possible, êtes-vous sûre de vouloir éffectuer cette action ?
					</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { setIsOpenDialogDelete(false) }} variant="contained" color="secondary">
						Annuler
					</Button>
					<Button 
						onClick={() => {
							if (onItemDelete) {
								onItemDelete(id); 
							}
							setIsOpenDialogDelete(false);
						}} 
						variant="contained"
						color="primary" autoFocus>
						Supprimer
					</Button>
				</DialogActions>
			</Dialog>

        </Fragment>);
};

export default DishListItem;
