import { Dish, Command } from '../../../types';

export const RESET_CART = 'RESET_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const SET_RESTAURANT = 'SET_RESTAURANT';
export const UPDATE_COMMANDS = 'UPDATE_COMMANDS';

export const addToCart = (dish: Dish, Commentaire?: string) => ({
	type: ADD_TO_CART,
	payload: { dish, Commentaire },
  });
  
  
  export const updateCart = (cart: Command) => ({
	type: UPDATE_CART,
	payload: cart,
  });
  
  
  export const resetCart = () => ({
	type: RESET_CART,
  });
  
  
  export const setRestaurant = (nom: string) => ({
	type: SET_RESTAURANT,
	payload: nom,
  });
    
  export const updateCommands = (commands: Command[]) => ({
	type: UPDATE_COMMANDS,
	payload: commands,
  });