import React from "react";
import useStyles from "./styles";
import { IQrcode } from "../../types";
import { CssBaseline, FormControlLabel, TextField, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { APIManager } from "../../services";
import { IOSSwitch } from "../IosSwitch/IosSwitch";
import { YumButton } from "../../Lib/UI/YumButton";


interface Props {
	onCreate?: (qrcode: IQrcode) => void;
	onError?: (err: Error) => void
	IdCard?: string,
}

interface NewQrCode {
	activate: boolean;
	name: string;
}



const QuickCreateQrCode: React.FC<Props> = ({ onCreate, onError, IdCard }: Props) => {
	const classes = useStyles();
	const [newQrcode, setNewQrcode] = React.useState<NewQrCode>({ activate: true, name: '' });

	const createNewQrcode = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		let qrcodeAPI = APIManager.getQrCodeServices();
		let qrcodeToCreate: IQrcode = {
			...newQrcode,
		}

		if (IdCard) {
			qrcodeToCreate.digitalCard = IdCard;
		}
		console.log("QRCODE SENDED :", qrcodeToCreate);
		qrcodeAPI.CreateQrCode(qrcodeToCreate).then((qrcodeCreated) => {
			if (onCreate) {
				onCreate(qrcodeCreated);
			}
		}).catch((err: Error) => {
			if (onError) {
				onError(err);
			}
		});
	}

	return (
		<div className={classes.root}>
			<CssBaseline />
			<FormControlLabel
				control={<IOSSwitch
					checked={newQrcode.activate}
					onChange={(event) => {
						setNewQrcode({
							...newQrcode, activate: event.target.checked
						})
					}}
					name="checkedB" />}
				label="Disponibilité"
				labelPlacement="start"
				style={{ marginRight: "1vw" }}
			/>
			<TextField
				id="outlined-basic"
				label="Nom du qrcodes"
				variant="outlined"
				onChange={(event) => {
					setNewQrcode({ ...newQrcode, name: event.target.value as string });
				}}
				value={newQrcode.name}
				style={{ marginRight: "1vw" }}
			/>
			<YumButton 
				onClick={createNewQrcode}
				style={{ marginRight: "1vw" }}>
				<Typography>Créer votre QrCode</Typography>
				<AddIcon />
			</YumButton>
		</div>
	);
};

export default QuickCreateQrCode;
