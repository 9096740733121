import React, { Fragment, useContext } from "react";
import { Typography, Button, FormControl, Select, MenuItem, CircularProgress, Backdrop, Grid, InputAdornment, Checkbox } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import { UserSignUp } from "../../../types/userSignUp";

import { UserType, UserInfos, TRADER_TYPE, IPackYum, BILLING_TYPE } from "../../../types";
import { useStyles, useStylesBackdrop } from "./styles";

import { EnumUtils } from "../../../utils";
import { Redirect } from "react-router-dom";
import LogoName from "../../../components/LogoName/LogoName";
import { store } from "../../../contexts/auth";

import { useSnackbar } from 'notistack';
import { APIManager } from "../../../services";

interface InputValidators {
	data: {
		firstname: string;
		lastname: string;
		email: string;
		password: string;
		confirmpassword: string;
		siret: string;
		corporatename: string;
		mobile: string;
		tva: number;
		traderType: string;
		pack: string;
	},
	validator: {
		firstname?: (data: string) => string | undefined;
		lastname?: (data: string) => string | undefined;
		email?: (data: string) => string | undefined;
		password?: (data: string) => string | undefined;
		confirmpassword?: (data: string) => string | undefined;
		siret?: (data: string) => string | undefined;
		corporatename?: (data: string) => string | undefined;
		traderType?: (data: string) => string | undefined;
		mobile?: (data: string) => string | undefined;
		tva?: (data: string) => string | undefined;
		pack?: (data: string) => string | undefined;
	},
	errors: {
		firstname?: string;
		lastname?: string;
		email?: string;
		password?: string;
		confirmpassword?: string;
		siret?: string;
		corporatename?: string;
		traderType?: string;
		mobile?: string;
		tva?: number;
		pack?: string;
	}
}

interface RegisterProps {
	logUser(user: UserInfos, type: UserType): any,
	nextStep?: () => void;
	choice?:  { pack: IPackYum, billing_type: BILLING_TYPE }
};

const Layout: React.FC<RegisterProps> = ({ logUser, nextStep, choice }: RegisterProps) => {
	const classes = useStyles();
	const classesBakcdrop = useStylesBackdrop();
	const { enqueueSnackbar } = useSnackbar();

	//const [loadPack, setLoadPack] = React.useState<boolean>(false);
	const [isLoading, setLoading] = React.useState(false);

	/*const [firstname, setFirstname] = React.useState('');
	const [lastname, setLastname] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [confirmpassword, setConfirmPassword] = React.useState('');
	const [siret, setSiret] = React.useState('');
	const [corporatename, setCorporate] = React.useState('');*/
	const [submitted, setSubmitted] = React.useState(false);
	//const [traderType, setTraderType] = React.useState('');
	const [redirect, setRedirect] = React.useState(false);
	//const [choicePack, setPack] = React.useState<IPackYum[]>([]);
	const [agreement, setAgreement] = React.useState<boolean>(false);

	let authContext = useContext(store);


	const firstnameValidator = (data: string) => {
		if (data.length < 2)
			return "Le prénom doit contenir au moins 2 caractères";
		return undefined;
	};
	const lastnameValidator = (data: string) => {
		if (data.length < 2)
			return "Le nom doit contenir au moins 2 caractères";
		return undefined;
	};
	const emailValidator = (data: string) => {
		return undefined;
	};
	const passwordValidator = (data: string) => {
		//Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
		const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/i;

		const matchArray = data.match(pattern);
		if (!matchArray)
			return "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une miniscule et un nombre";
		return undefined;
	};
	const confirmpasswordValidator = (data: string) => {
		//console.log(formData.data);
		//console.log("data:" + data);
		//console.log("password:" + formData.data.password);
		return undefined;

		/*if (data !== formData.data.password)
			return "le mot de passe de confirmation n'est pas similaire au mot de passe";
		return undefined;*/
	};
	const siretValidator = (data: string) => {
		const pattern = /[0-9]{14}/i;

		const matchArray = data.match(pattern);
		if (!matchArray)
			return "Le numero de siret est incorrect";
		return undefined;
	};
	const corporatenameValidator = (data: string) => {
		if (data === '') {
			return "Aucun n'as été renseigné";
		}
		return undefined;
	};
	const traderTypeValidator = (data: string) => {
		if (data === "") {
			return "Veuillez indique votre type d'etablissement";
		}
		return undefined;
	};
	const tvaValidator = (data: string) => {
		if (Number.isNaN(Number.parseFloat(data))) {
			return "tva incorrect";
		}
		if (Number.parseFloat(data) < 2.1) {
			return "Tva applicable ne peut etre a 0";
		}
		return undefined;
	};
	const packValidator = (data: string) => {
		if (data === "") {
			return "Veuillez indique votre Pack";
		}
		return undefined;
	};

	const [formData, setFormData] = React.useState<InputValidators>({
		data: {
			firstname: "",
			lastname: "",
			email: "",
			password: "",
			confirmpassword: "",
			siret: "",
			corporatename: "",
			traderType: "",
			mobile: "",
			tva: 20,
			pack: choice?.pack._id as string,
		},
		validator: {
			firstname: firstnameValidator,
			lastname: lastnameValidator,
			email: emailValidator,
			password: passwordValidator,
			confirmpassword: confirmpasswordValidator,
			siret: siretValidator,
			corporatename: corporatenameValidator,
			traderType: traderTypeValidator,
			tva: tvaValidator,
			pack: packValidator,
		},
		errors: {
		}
	});

	/*const handleChangeFirstname = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFirstname(event.target.value);
	}

	const handleChangeLastname = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastname(event.target.value);
	}

	const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	}

	const handleChangePass = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	}

	const handleChangeConfirmPass = (event: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmPassword(event.target.value);
	}

	const handleChangeSiret = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSiret(event.target.value);
	}

	const handleChangeCorporateName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCorporate(event.target.value);
	}*/

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch (event.target.name) {
			case "firstname":
				setFormData({ ...formData, data: { ...formData.data, firstname: event.target.value } });
				break;
			case "lastname":
				setFormData({ ...formData, data: { ...formData.data, lastname: event.target.value } });
				break;
			case "email":
				setFormData({ ...formData, data: { ...formData.data, email: event.target.value } });
				break;
			case "password":
				setFormData({ ...formData, data: { ...formData.data, password: event.target.value } });
				break;
			case "confirmpassword":
				setFormData({ ...formData, data: { ...formData.data, confirmpassword: event.target.value } });
				break;
			case "siret":
				setFormData({ ...formData, data: { ...formData.data, siret: event.target.value } });
				break;
			case "corporatename":
				setFormData({ ...formData, data: { ...formData.data, corporatename: event.target.value } });
				break;
			case "mobile":
				setFormData({ ...formData, data: { ...formData.data, mobile: event.target.value } });
				break;
			default:
				break;
		}
	}

	const handleChangeTraderType = (event: React.ChangeEvent<{ value: unknown }>) => {
		setFormData({ ...formData, data: { ...formData.data, traderType: event.target.value as string } });
		//setTraderType(event.target.value as string);
	}

/* 	const handleChangeTva = (event: React.ChangeEvent<{ value: unknown }>) => {
		setFormData({ ...formData, data: { ...formData.data, tva: event.target.value as number } });
		//setTraderType(event.target.value as string);
	} */

	/* 	const handleChangePack = (event: React.ChangeEvent<{ value: unknown }>) => {
			setFormData({ ...formData, data: { ...formData.data, pack: event.target.value as string } });
			//setTraderType(event.target.value as string);
		} */

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setFormData({ ...formData, errors: {} });
		setSubmitted(false);
		setLoading(true);

		// Si le formulaire n'est pas valide
		if (Validate() === false) {
			enqueueSnackbar('Un des champs de saisie est incorrect', { variant: "warning" });
			setLoading(false);
			return;
		}

		// Si le consentement au CGU n'est pas validé
		if (!agreement) {
			enqueueSnackbar('Sans votre accord avec nos CGU, il est impossible de créer un compte', { variant: "warning" });
			setLoading(false);
			return;
		}

		console.log("Va envoyé le resultat !");
		let usr: UserSignUp = {
			email: formData.data.email,
			password: formData.data.password,
			siret: formData.data.siret,
			corporate_name: formData.data.corporatename,
			trader_type: formData.data.traderType,
			lastname: formData.data.lastname,
			firstname: formData.data.firstname,
			mobile: formData.data.mobile === '' ? undefined : formData.data.mobile,
			tva: formData.data.tva,
			pack: formData.data.pack,
			type_product: choice?.billing_type.toString() as string,
		};

		//console.log(usr);
		APIManager.getAccountServices().signUp(usr).then((infos: UserInfos) => {

			logUser(infos, UserType.Trader);
			authContext.authObj.handleAuth(infos);
			authContext.handleUserLogin();
			//console.log(authContext.authObj.userProfile);
			authContext.handleUserAddProfile(authContext.authObj.userProfile);

			//APIManager.setTokenAPIS(infos.token); // initialisation du token pour l'api Manager
			//storageservice.setData("user", infos, false); // enregistrement du token dans le navigateur
			//storageservice.setData("usertype", UserType.Trader, false); // enregistrement du type d'utilisateur
			if (nextStep === undefined) {
				setRedirect(true);
			} else {
				enqueueSnackbar(`Bienvenue ${infos.userinfos.firstname}`, { variant: "success" });
				nextStep();
			}
			setSubmitted(true);
		}).catch((err: Error) => {
			enqueueSnackbar(`Erreur : ${err.message}`, { variant: "error" });
			setSubmitted(false);
		}).finally(() => {
			setLoading(false);
		})
	}

	const Validate = () => {
		const { validator } = formData;
		const { data } = formData;
		const { errors } = formData;
		let error = false;

		const formErrors = Object.entries(validator).reduce((errorsMap, [name, validator]) => {
			if (validator) {
				(errors as any)[name] = validator((data as any)[name] as string);
				(errorsMap as any)[name] = (errors as any)[name];
			}
			return errorsMap;
		}, {});


		for (const [
			//key
			, value] of Object.entries(formErrors)) {
			if (value !== undefined) {
				error = true;
				break;
			}
		}
		console.log("Is there error in the form ", error);
		// Si aucun probleme n'as été détecté
		if (!error) {
			return true;
		}
		setFormData({ ...formData, ...errors });
		return false;
	}

/* 	const getPacks = async () => {
		try {
			let packs = await APIManager.getPackServices().GetAllPacks();

			console.log("Charge les packs !");
			console.log(packs);
			setPack([...packs]);
			let defaultpack = packs.find(x => x.name === "yums free");
			if (defaultpack && defaultpack._id) {
				setFormData({ ...formData, data: { ...formData.data, pack: defaultpack._id } });
			}
			setLoadPack(true);

		} catch (err) {
			console.log(err);
		} 
	} */

	//useEffect(() => {
		/*if (!loadPack && choicePack.length == 0) {
			getPacks();
		}*/
		/*APIManager.getPackServices().GetAllPacks().then((packs: IPackYum[]) => {
			console.log("Charge les packs !");
			console.log(packs);
			let defaultpack = packs.find(x => x.name === "yums free");
			if (defaultpack && defaultpack._id) {
				setFormData({ ...formData, data: { ...formData.data, pack: defaultpack._id } });
			}
			loadPack = true;
		}).catch((err) => {
			console.log(err);
		});*/
	//}, [loadPack])

	return (
		<Fragment>
			<CssBaseline />
			{submitted && redirect && (<Redirect to='/' />)}
			<Container component="main" className={classes.root} maxWidth={false}>
				<div className={classes.logoName}>
					<LogoName />
				</div>
				<div className={classes.subRoot}>
					<Typography variant="subtitle1" component="h2">Digitalisez votre restaurant <br />et booster vos ventes</Typography>
					<Typography variant="body1" component="span">Les champs précedés d'une asterix sont obligatoire</Typography>
					<form onSubmit={handleSubmit} className={classes.form}>
						<Grid container spacing={1} alignItems="center">
							<Grid item component='div' xs={12} sm={4} md={4}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="firstname"
									label="Prénom"
									name="firstname"
									autoComplete="Firstname"
									autoFocus
									value={formData.data.firstname}
									error={formData.errors.firstname ? true : false}
									helperText={formData.errors.firstname}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item component='div' xs={12} sm={4} md={4}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="lastname"
									label="Nom"
									name="lastname"
									autoComplete="Lastname"
									value={formData.data.lastname}
									onChange={handleChange}
									error={formData.errors.lastname ? true : false}
									helperText={formData.errors.lastname}
								/>
							</Grid>
							<Grid item component='div' xs={12} sm={4} md={4}>
								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									id="mobile"
									label="Numero de mobile"
									name="mobile"
									inputProps={
										{
											"maxLength": 10,
											"minLength": 10,
											"pattern": "[0-9]*",
										}
									}
									autoComplete="numéro de mobile"
									value={formData.data.mobile}
									onChange={handleChange}
									error={formData.errors.mobile ? true : false}
									helperText={formData.errors.mobile}
								/>
							</Grid>
							<Grid item component='div' xs={12} sm={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email"
									name="email"
									autoComplete="email"
									defaultValue={formData.data.email}
									type="email"
									onChange={handleChange}
									error={formData.errors.email ? true : false}
									helperText={formData.errors.email}
								/>
							</Grid>
							<Grid item component='div' xs={12} sm={12} md={12}>
								<Typography>Le mot de passe doit contenir au moins 8 caractères, une majuscule, une miniscule et un nombre</Typography>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="password"
									label="mot de passe"
									type="password"
									id="password"
									autoComplete="current-password"
									defaultValue={formData.data.password}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												{formData.validator.password && !formData.validator.password(formData.data.password) && (<CheckCircleIcon style={{ color: "green" }} />)}
												{formData.validator.password && formData.validator.password(formData.data.password) && (<CancelIcon style={{ color: "red" }} />)}
											</InputAdornment>
										),
									}}
									onChange={handleChange}
									error={formData.errors.password ? true : false}
									helperText={formData.errors.password}
								/>
							</Grid>
							<Grid item component='div' xs={12} sm={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="confirmpassword"
									label="Confirmation de mot de passe"
									type="password"
									id="confirmpassword"
									autoComplete="current-password"
									defaultValue={formData.data.confirmpassword}
									onChange={handleChange}
									error={formData.errors.confirmpassword ? true : false}
									helperText={formData.errors.confirmpassword}
								/>
							</Grid>
							<Grid item component='div' xs={12} sm={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="siret"
									label="Siret"
									name="siret"
									inputProps={
										{
											"maxLength": 14,
											"minLength": 14,
											"pattern": "[0-9]*",
										}
									}
									autoComplete="Siret"
									defaultValue={formData.data.siret}
									onChange={handleChange}
									error={formData.errors.siret ? true : false}
									helperText={formData.errors.siret}
								/>
							</Grid>
							<Grid item component='div' xs={12} sm={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="corporatename"
									label="Dénomination social"
									name="corporatename"
									autoComplete="corporatename"
									inputProps={
										{
											"minLength": 1,
										}
									}
									defaultValue={formData.data.corporatename}
									onChange={handleChange}
									error={formData.errors.corporatename ? true : false}
									helperText={formData.errors.corporatename}
								/>
							</Grid>
							{/*<Grid item component='div' xs={12} sm={6} md={6}>
								<FormControl fullWidth
									margin="normal"
									variant="outlined"
									required
								>
									<InputLabel id="tva-select-label" error={formData.errors.tva ? true : false}>Tva applicable</InputLabel>
									<Select
										labelId="tva-select-label"
										id="tva-select"
										value={formData.data.tva}
										onChange={handleChangeTva}
										label="Tva applicable"
										required
										error={formData.errors.tva ? true : false}
									>
										{TVA_VALUES.map((value: number, index: number) => {
											return (
												<MenuItem key={index} value={value}>{value.toString()}%</MenuItem>
											);
										})}
									</Select>
									{formData.errors.tva
										&& (<div className="help-block">{formData.errors.tva}</div>)}
								</FormControl>
									</Grid>*/}
							<Grid item component='div' xs={12} sm={12} md={12}>
								<FormControl fullWidth
									margin="normal"
									variant="outlined"
									required
								>
									<InputLabel id="demo-simple-select-outlined-label" error={formData.errors.traderType ? true : false}>Type d'etablissement</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={formData.data.traderType}
										onChange={handleChangeTraderType}
										label="Type d'établissement"
										required
										error={formData?.errors.traderType ? true : false}
									>
										{EnumUtils.EnumToStrArray<string>(TRADER_TYPE).map((value: string, index: number) => {
											return (
												<MenuItem key={index} value={value}>{value}</MenuItem>
											);
										})}
									</Select>
									{formData.errors.traderType
										&& (<div className="help-block">{formData.errors.traderType}</div>)}
								</FormControl>
							</Grid>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<Checkbox
									checked={agreement}
									onChange={(event, checked) => { setAgreement(checked); }}
									style={{ alignItems: "flex-start" }}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
								<Typography align="justify">Créer un compte signifie que vous êtes consentant avec notre politique de confidentialité, de service et nos conditions d'utilisation général</Typography>
							</div>

							{/*<Grid item component='div' xs={12} sm={12} md={12}>
								<FormControl fullWidth
									margin="normal"
									variant="outlined"
									required
								>
									<InputLabel id="demo-simple-select-outlined-label" error={formData.errors.pack ? true : false}>Yum Pack</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={formData.data.pack}
										onChange={handleChangePack}
										label="Pack"
										required
										error={formData?.errors.pack ? true : false}
									>
										<MenuItem key={-1} value="">Aucun selection</MenuItem>
										{choicePack.map((pack: IPackYum, index: number) => {
											return (
												<MenuItem key={index} value={pack._id as string}>{pack.name}</MenuItem>
											);
										})}
									</Select>
									{formData.errors.pack
										&& (<div className="help-block">{formData.errors.pack}</div>)}
								</FormControl>
									</Grid>*/}
						</Grid>
						<Button style={{ marginTop: 15 }} type="submit" fullWidth variant="contained" color="secondary">
							Créer un compte
						</Button>
					</form>
				</div>
			</Container>
			<Backdrop className={classesBakcdrop.backdrop} open={isLoading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Fragment>
	);
}

export default Layout;
