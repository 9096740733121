import React
//, { useEffect } 
from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { 
  //CATEGORY_TYPE, 
  ICategory, ICategoryLink } from '../../types';
import ComboBox from '../autocomp/ComboBox';
//import { Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
//import * as Yup from "yup";
import { TextField } from '@material-ui/core';
import { APIManager } from '../../services';
import { useSnackbar } from 'notistack';
import { YumButton } from '../../Lib/UI/YumButton';
//import { CategoryServices } from '../../api';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


interface Props {
  parentCategory?: ICategoryLink | undefined,
  idCard: string,
  onCreateCallback: (newCategory: ICategoryLink, parent?: string) => void,
  isOpen: boolean;
  callback : (status: boolean) => void,
}

const ModalCreateCategory: React.FC<Props> = ({ parentCategory, idCard, onCreateCallback, callback, isOpen }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  //const [open, setOpen] = React.useState(isOpen);
  //const [isSubmited, setIsSubmited] = React.useState(false);
  const [
    //isBasicCategory
    , setIsBasicCategory] = React.useState(false);
  const [categoryNameSelected, setCategoryNameSelected] = React.useState<string | undefined>(undefined);
  const [categoryDescription, setCategoryDescription] = React.useState<string | undefined>(undefined);
	const [refCategory ,setRefCategory] = React.useState<string | undefined>(undefined);

  const handleClickOpen = () => {
    callback(true);
  };
  const handleClose = () => {
    callback(false);
  };

  const createCategorie = (categoryName: string) => {
    setCategoryNameSelected(categoryName);
    setRefCategory(undefined);
    setIsBasicCategory(false);
  }

  const selectCategorie = (category: ICategory) => {
    // Empêche les doublons dans la catégories courrantes
    /*let NoDoublecategories = _.unionBy(categories, [category], '_id');

    setCurrentCategories([...NoDoublecategories]);
    enqueueSnackbar(`La catégorie "${category.name}" a été ajouté a votre carte`, { 
      variant: "success",
      anchorOrigin: {
        vertical: 'top',
        horizontal:'right',
      }
    });*/

    console.log("select contains :", category);
    setCategoryNameSelected(category.name);
    setCategoryDescription(category.description);
    setRefCategory(category._id);
    setIsBasicCategory(true);
  }

  const createNewCategorie = () => {
   /* let existingCategory = categories.find((x) => x.name.toLowerCase() === categoryNameSelected?.toLowerCase());

    if(isBasicCategory && existingCategory) {
      onCreate(existingCategory);
      return;
    }*/

    let newCategory: ICategoryLink = {
      name: categoryNameSelected as string,
      description: categoryDescription ? categoryDescription : undefined,
      idCard: idCard,
      trader: '',
    };

    APIManager.getCategoryServices().CreateUserCategory(newCategory, idCard, refCategory).then((newCategories) => {
      enqueueSnackbar(`La catégorie "${categoryNameSelected}" a été créée`, { 
        variant: "success",
        anchorOrigin: {
          vertical: 'top',
          horizontal:'right',
        }
      });
      console.log("new categorie :", newCategories);
      onCreateCallback(newCategories);

    }).catch((err) => {
      enqueueSnackbar(`La catégorie "${categoryNameSelected}" n'a pas pu être crée`, { 
        variant: "error",
        anchorOrigin: {
          vertical: 'top',
          horizontal:'right',
        }
      });
    }).finally(() => {
      handleClose();
    });
  }

  const createNewSubCategorie = () => {

    let newCategory: ICategoryLink = {
      name: categoryNameSelected as string,
      description: categoryDescription ? categoryDescription : undefined,
      idCard: idCard,
      trader: '',
    };

    APIManager.getCategoryServices().CreateUserSubCategory(newCategory, idCard, parentCategory?._id as string, refCategory).then((newCategories) => {
      enqueueSnackbar(`La sous-catégorie "${categoryNameSelected}" a été créée`, { 
        variant: "success",
        anchorOrigin: {
          vertical: 'top',
          horizontal:'right',
        }
      });
      onCreateCallback(newCategories, parentCategory?._id as string);

    }).catch((err) => {
      enqueueSnackbar(`La sous-catégorie "${categoryNameSelected}" n'a pas pu être crée`, { 
        variant: "error",
        anchorOrigin: {
          vertical: 'top',
          horizontal:'right',
        }
      });
    }).finally(() => {
      handleClose();
    });
  }

  

  return (
    <React.Fragment>
      <YumButton
        style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "15px" }}
        onClick={handleClickOpen}
        >
        <Typography 
          component="span" 
          variant="body1" 
          style={{ marginRight: "5px" }}>Créer une catégorie</Typography>
        <AddIcon />
      </YumButton>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography
            variant="h5"
            component="span"
            gutterBottom
            >
            Ajoutez une {parentCategory ? "sous-catégorie à la catégorie" : "catégorie"} <br />
            <Typography 
              variant="h5"
              component="span">
              {parentCategory ? `"${parentCategory?.name}"` : ""}
            </Typography>
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
            <ComboBox 
              onCreate={createCategorie}
              onSelect={selectCategorie}/>
            <TextField
                id="description"
                type="text"
                label="Description"
                name="description"
                placeholder="Évasion, ..."
                fullWidth
                variant="outlined"
                onChange={(event) => { 
                  setCategoryDescription(event.target.value);
                }}
                value={categoryDescription}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Annuler
          </Button>
          <Button 
            autoFocus 
            onClick={() => {
              if (parentCategory) {
                console.log("Creation sous categorie ")
                createNewSubCategorie();
              } else {
                console.log("CReation categorie normal");
                createNewCategorie();
              }
            }} 
            variant="outlined" 
            color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ModalCreateCategory;
