import { Container, CssBaseline, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { YumButton } from "../../../Lib/UI/YumButton";
import BillingPayement from "../../../pages/BillingPayement";
import { AppState, IStripeBilling, TRADER_PAYEMENT_STATUS, UserInfos } from "../../../types";
import useStyles from "./styles";

interface Props {
    billingdata: IStripeBilling | undefined;
    userdata: UserInfos | undefined;
}

const PopUpUnpaid: React.FC<Props> = ({ billingdata, userdata }: Props) => {

	const classes = useStyles();

    function DynamicContent(props: any) {

        console.log("billing :", props.status);
        switch (props.status) {
            case TRADER_PAYEMENT_STATUS.Unpaid:
                return <Fragment>
                    <Typography
                        component="h2"
                        variant="body1"  
                        className={classes.margin}
                        gutterBottom>
                        Il semblerait qu'une de vos facture n'ai pas été payée pensez à regulariser votre situation au plus vite pour continuer a utiliser notre service
                    </Typography>
                </Fragment>;
            default:
                return <Fragment>
                    <Typography
                        component="h2"
                        variant="body1"  
                        className={classes.margin}
                        gutterBottom>
                        Votre compte a été momentanné suspendu en raison d'un impayé, pensez a regularisé votre situation pour pouvoir de nouveau utilisé les services yumscan.
                    </Typography>
                </Fragment>;                
        }
    }

    return (
        <Fragment>
            <CssBaseline />
            <Container component="div" maxWidth="md" className={classes.root}>
                <Typography 
                    component="h1"
                    variant="subtitle1"
                    className={classes.HighlightLine}                       
                    gutterBottom>
                        {userdata?.userinfos.firstname},
                </Typography>
                <DynamicContent status={billingdata?.status}/>
                <Typography 
                    component="h1"
                    variant="body2"
                    className={classes.margin}
                    gutterBottom>
                    N'hesitez pas a nous contacter si vous pensez que cela est dû a une erreur.
                </Typography>
                <div className={clsx(classes.margin, classes.buttonCointainer)}>
                    <BillingPayement />
                    <YumButton>Nous contacter</YumButton>
                </div>
            </Container>
        </Fragment>
    );  
}

export default connect(
	(state: AppState) => ({
		billingdata: state.billing.billing,
        userdata: state.user.userdata,
	}),
    {}
)(PopUpUnpaid);
