import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Input, Paper, Radio, Tooltip, Typography } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import { DISPLAY_SHAPE_ITEM } from "../../types/shared/itemCard.interface";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "10px",
        display: "flex", 
        flexDirection: "column",
        width: "100%",
    },
	rootPaper: {
        padding: "10px",
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-evenly", 
        alignItems: "center",
        width: "100%",
    },
    rootDetail: {
		display: "flex", 
        flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",        
    },
    img: {
        width: "100%",
        maxWidth: "125px"
    },
	space: {
		margin: "5px",
	},
	rootActions: {
		display: "flex", 
        flexDirection: "row",
		alignItems: "center",
		justifyContent: "center", 
	}
}));

interface IListItemProps {
    onChange: (value: DISPLAY_SHAPE_ITEM) => void;
    defaultValue: DISPLAY_SHAPE_ITEM;
}


const HandlerDisplayTypeItem: React.FC<IListItemProps> = ({ onChange, defaultValue }: IListItemProps) => {
	const classes = useStyles();

    const [displayType, setDisplayType] = React.useState<DISPLAY_SHAPE_ITEM>(defaultValue);

    const handleChange = (value: DISPLAY_SHAPE_ITEM) => {
        setDisplayType(value);
        onChange(value);
    };  

    return(
        <div className={classes.root}>
            <div>
                <Typography align="center" variant="subtitle2" gutterBottom> Choissisez un format d'affichage pour votre plat</Typography>
            </div>
            <div className={classes.rootPaper}>
                <div 
                    className={classes.rootDetail}>
                    <div>
                        <img className={classes.img} src="https://www.onlygfx.com/wp-content/uploads/2018/08/6-grunge-brush-stroke-square-frame-5.png" />
                    </div>                <Radio
                        checked={displayType === DISPLAY_SHAPE_ITEM.Square}
                        onChange={() => handleChange(DISPLAY_SHAPE_ITEM.Square)}
                        value={DISPLAY_SHAPE_ITEM.Square}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                        /> 
                </div>
                <div 
                    className={classes.rootDetail}>
                    <div>
                        <img className={classes.img} src="https://www.onlygfx.com/wp-content/uploads/2018/08/6-grunge-brush-stroke-rectangle-frame-6.png" />
                    </div>
                    <Radio
                        checked={displayType === DISPLAY_SHAPE_ITEM.Large}
                        onChange={() => handleChange(DISPLAY_SHAPE_ITEM.Large)}
                        value={DISPLAY_SHAPE_ITEM.Large}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                        /> 
                </div>
            </div>

        </div>);
};

export default HandlerDisplayTypeItem;
