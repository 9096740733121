import React, { Fragment, useContext, useEffect } from "react";
import {
	Container,
	Typography,
	Divider,
	CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import { ITrader, IPackYum, AppState, UserState, IStripeBilling, TRADER_PAYEMENT_STATUS } from "../../../types";
import { APIManager } from "../../../services";
import BillingPayement from "../../BillingPayement";
import { connect } from "react-redux";
import { store } from "../../../contexts/auth";
import { useSnackbar } from "notistack";
import DisplayOffers from "../../../components/DisplayOffers";
import SpringModal from "../../../components/SpringModal";
import { DateTimeUtils } from "../../../utils";
import { YumButton } from "../../../Lib/UI/YumButton";
import TipsManager from "../../../components/TipsManager";


interface Props {
	userstate: UserState;
}

const Layout: React.FC<Props> = ({ userstate }: Props) => {
	const classes = useStyles();
	let authContext = useContext(store);
	const { enqueueSnackbar } = useSnackbar();

	const [
		//isError
		, setError] = React.useState<boolean>(false);
	const [currentPack, setCurrentPack] = React.useState<IPackYum | undefined>(undefined);
	const [currentBilling, setCurrentBilling] = React.useState<IStripeBilling | undefined>(undefined);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [suscribe, setSuscribe] = React.useState<boolean>(false);



	const GetPackForConnectedTrader = async (idTrader: string) => {

		try {
			let pack = await APIManager.getAccountServices().getPackForTrader(idTrader);
			
			setCurrentPack(pack);
		} catch (err) {
			console.log("Recupération pack", err);
			enqueueSnackbar("Une erreur inconnue est survenue veuillez recharger la page ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			setError(true);
		} finally {
			setIsLoading(false);
		}
	}

	const GetBillingForConnectUser = async () => {

		try {
			let billing = await APIManager.getBillingService().getBillingInformations();
			
			setCurrentBilling(billing);
		} catch (err) {
			console.log("Recupération pack", err);
			enqueueSnackbar("Une erreur inconnue est survenue veuillez recharger la page ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			setError(true);
		} finally {
			setIsLoading(false);
		}
	}

	const GetProfilConnected = async () => {
		try {
			await authContext.authObj.getProfile();
		} catch (err) {
			enqueueSnackbar("Une erreur inconnue est survenue veuillez recharger la page ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		}
	}

	useEffect(() => {
		if (!isLoading || authContext.authObj.userProfile !== {}) {
			return;
		}
		// Récupère le pack du commercant connecté.
		GetProfilConnected();		
	}, [authContext.authObj.userProfile]);

	useEffect(() => {
		if (!isLoading || authContext.authObj.userProfile === {}) {
			return;
		}
		// Récupère le pack du commercant connecté.
		GetPackForConnectedTrader((authContext.authObj.userProfile as ITrader)._id as string);		
	}, [ authContext.authObj.userProfile, isLoading ]);

	useEffect(() => {
		if (!isLoading || authContext.authObj.userProfile === {}) {
			return;
		}
		// Récupère le pack du commercant connecté.
		GetBillingForConnectUser();		
	}, [ authContext.authObj.userProfile, isLoading ]);

	function IsTrialing(): boolean  {
		if (!currentBilling) {
			return false;
		}		
		return (currentBilling.status === TRADER_PAYEMENT_STATUS.Trialing);
	}

	function IsFree(): boolean  {
		if (!currentPack) {
			return false;
		}		
		return currentPack.metadata?.isFree;
	}

	function subscribeInverse() {
		setSuscribe(!suscribe);
	}

	return (
		<Fragment>
			<Container className={classes.root} maxWidth="lg" component="main">
				{!isLoading && 
					(<div style={{ width: "100%" }}>
						<TipsManager />
						<section>
							<Typography
								className={classes.title}
								variant="subtitle1" 
								component="h2" 
								gutterBottom
								align="left" 
								>
								Détails de votre abonnement
							</Typography>
							<Divider />
							<div className={classes.subRoot} >
								<div>
									<Typography 
										variant="body1" 
										component="h2"
										className={classes.mg_1v}
										gutterBottom>
										Nom de votre abonnement : <strong className={classes.uppercaseText}>{currentPack?.name}</strong>
									</Typography>
									<Typography 
										variant="body1" 
										component="h2"
										className={classes.mg_1v}
										gutterBottom>
										Limite de création de Qrcodes : <strong className={classes.uppercaseText}>{currentPack?.qrCodeLimit}</strong>
									</Typography>
									{IsTrialing() &&
										<Typography 
											variant="body1" 
											component="h2"
											className={classes.mg_1v}
											gutterBottom>
										Votre période d'essaie prendra fin le {DateTimeUtils.formattedDate(new Date(currentBilling?.trial_end_date as Date))}
										</Typography>
									}
								</div>
							</div>
						</section>
						<section className={classes.mg_1v}>
							<Typography
								className={classes.title}
								variant="subtitle1" 
								component="h2" 
								gutterBottom 
								align="left" 
								>
								Gestion de votre abonnement
							</Typography>
							<Divider />
							<div className={classes.subRoot} >
								{!IsTrialing() && !IsFree() &&
									<BillingPayement />}
								{(IsTrialing() || IsFree()) &&
									<YumButton 
										onClick={subscribeInverse}>                    
										s'abonner dès maintenant
									</YumButton>}					
							</div>								
						</section>										
					</div>)}
				{isLoading && 
					(<div className={classes.billingDiv}>
						<CircularProgress 
							color="primary" 
							size={50} />
					</div>)}
			</Container>
			<SpringModal openModal={suscribe} eventClose={() => subscribeInverse()} canclose={true}>
				<DisplayOffers 
					Advanced={false} 
					OnClose={() => { console.log("premier implementation") }} 
					OnOpen={() => { console.log("premier implementation") }} />
            </SpringModal>
		</Fragment >
	);
};


export default connect(
	(state: AppState) => ({
		userstate: state.user
	}),
	{
	}
)(Layout);
