import { CircularProgress, IconButton, Tooltip, Typography } from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import React, { useMemo } from "react";
import { YumButton } from "../../Lib/UI/YumButton";
import { IMG_CONSTANTES } from "../../types/constantes/constantes";
import useStyles from "./styles";

interface Props {
	OnDragOver?: () => void;
	OnDrop?: (data: string) => void;
    DeleteImage: () => Promise<any>;
    UploadImage: (file: File) => Promise<any>;
	sentence?: string;
    imageUrl?: string;
    dimension: { height: number, width: number };
    textButton: string;
}


const ImageDirectUpload: React.FC<Props> = (Props) => {

    const [isRequesting, setRequesting] = React.useState<boolean>(false);

	const classes = useStyles();    
    const refInputImgUpload = React.useRef<HTMLInputElement>(null);

	/* const OnDragOverHandle = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		if (Props.OnDragOver) {
			Props.OnDragOver();
		}
	} */

	/* const OnDropHandle = (event: React.DragEvent<HTMLDivElement>) => {
        if (Props.OnDrop) {
		    Props.OnDrop(event.dataTransfer.getData("text"));
        }
	} */

    const HandleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileEvent = e.target.files?.item(0);

		if (!fileEvent) {
			return;
		}
        setRequesting(true);

        Props.UploadImage(fileEvent)
        .finally(() => {
            setRequesting(false);
        });;
    }

    const HandleClickToUpload = (event: React.MouseEvent<any>) => {
        if (!refInputImgUpload.current || isRequesting){
            return;
        }
        refInputImgUpload.current.click();
    };

    const HandleDeleteImage = (event: React.MouseEvent<any>) => {
        setRequesting(true);

        Props.DeleteImage()
        .finally(() => {
            setRequesting(false);
        });
    }

    const GetRightUrlToDisplay = useMemo(() => {

        if (Props.imageUrl) {
			return Props.imageUrl;
		}
		return IMG_CONSTANTES.DEFAULT_IMG;

    }, [Props.imageUrl]);

	return (
		<React.Fragment>
            <div className={classes.root}>
                {isRequesting && <CircularProgress color="primary" size={25} />}
                {!isRequesting && (<div
                    className={classes.subRoot}>
                    <div
                        style={{ 
                            maxHeight: Props.dimension.height, 
                            maxWidth: Props.dimension.width 
                        }}
                        className={classes.imgContainer}>
                        {!Props.imageUrl 
                        && (<div
                                style={{ 
                                    maxHeight: Props.dimension.height, 
                                    maxWidth: Props.dimension.width 
                                }} 
                                className={classes.imgBackgroundMinimumRequierement}>
                            <Typography 
                                variant="subtitle1">
                                {`${Props.dimension.height} x ${Props.dimension.width}`} Minimum 
                            </Typography>
                        </div>)}
                        <img
                            style={{ 
                                maxHeight: Props.dimension.height, 
                                maxWidth: Props.dimension.width 
                            }}   
                            alt="Logo" 
                            src={GetRightUrlToDisplay} />
                        <div
                            className={classes.deleteIconDiv}>
                            <Tooltip  
                                title="Supprimer le logo">
                                <IconButton
                                    style={{ zIndex: 100000}}
                                    color="inherit"
                                    onClick={HandleDeleteImage}
                                    aria-label="delete logo"
                                    disabled={isRequesting}>
                                    <DeleteForever color="inherit"  />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div>
                        <YumButton
                            style={{ alignSelf: "center" }}
                            disabled={isRequesting}
                            onClick={(e) => { HandleClickToUpload(e);}}>
                            {Props.textButton}
                        </YumButton>
                    </div>            
                </div>)}               
                <input 
                    style={{ display: "none" }} 
                    ref={refInputImgUpload} 
                    accept="image/*" 
                    onChange={HandleFileSelected} 
                    type="file" />
            </div>
        </React.Fragment>
	);
}

export default ImageDirectUpload;
