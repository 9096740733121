import React, { Fragment } from "react";

import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import { TileData } from "../../types";
import CancelIcon from '@material-ui/icons/Cancel';
import { CssBaseline, CardContent, Card, CardActions, IconButton, Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent, Button } from "@material-ui/core";

interface Props extends TileData {
	minVal?: number,
	onItemClick?: (id: string) => void,
	onItemDelete?: (id: string) => void,
}

const ItemInfo: React.FC<Props> = ({ picture, title, price, keyTile, onItemClick, onItemDelete }: Props) => {
	const classes = useStyles();

	const [isOpenDialogDelete, setIsOpenDialogDelete] = React.useState<boolean>(false);

	const backgroundInitial = (picture: string) => {
		let css: React.CSSProperties = {
			backgroundImage: `url("${picture}")`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '100%',
			backgroundSize: 'cover',
			WebkitBackgroundSize: 'cover',
			MozBackgroundSize: 'cover',
			OBackgroundSize: 'cover',
		};

		if (onItemClick) {
			css.cursor = "pointer";
		}
		return css;
	}

	const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (onItemClick) {
			onItemClick(keyTile);
		}
	}

	const OnDragStart = (event: React.DragEvent<HTMLDivElement>) => {
		event.dataTransfer.setData("text/plain", keyTile);
	}

	return (
		<Fragment>
			<CssBaseline />
			<Card onDragStart={OnDragStart} elevation={6} draggable className={classes.minValues}>
				{onItemDelete && (<IconButton color="secondary" onClick={(event) => setIsOpenDialogDelete(true)}
					style={{ position: "absolute", right: "5px", top: "5px", zIndex: 2, cursor: "pointer" }} aria-label="settings">
					<CancelIcon />
				</IconButton>)}
				<CardContent onClick={handleClick} className={classes.contentRoot} style={backgroundInitial(picture)}>
					<div className={keyTile !== "-1" ? classes.makeBackground : ''}></div>
					{price && (<div className={classes.price}>
						<Typography component="h4" variant="h6">{price}€</Typography>
					</div>)}
					{title && (<CardActions>
						<Typography component="h4" variant="subtitle1" className={classes.title}>{title}</Typography>
					</CardActions>)}
				</CardContent>
			</Card>
			<Dialog
				open={onItemDelete !== undefined && isOpenDialogDelete}
				onClose={() => { setIsOpenDialogDelete(false) }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography
						variant="subtitle1"
						component="span"
						gutterBottom>
						<strong>Suppréssion définitive</strong> de l'élement <strong>"{title}"</strong>
					</Typography>
					</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Typography
						variant="body1"
						component="span"
						gutterBottom>
							Vous êtes sur le point de supprimer définitivement l'élément <strong>"{title}"</strong> de votre carte aucune récupération ne sera possible, êtes-vous sûre de vouloir éffectuer cette action ?
					</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => { setIsOpenDialogDelete(false) }} variant="contained" color="secondary">
						Annuler
					</Button>
					<Button 
						onClick={() => {
							if (onItemDelete) {
								onItemDelete(keyTile); 
							}
							setIsOpenDialogDelete(false);
						}} 
						variant="contained"
						color="primary" autoFocus>
						Supprimer
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};

export default ItemInfo;
