import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: 'initial',
		height: '100%',
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	backgroundStepper: {
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		flexGrow: 1,
		alignItems: 'center',
		maxWidth: '500px',
		padding: '15px',
	},
	logo: {
		position: 'absolute',
		top: '3%',
		left: '2%',
		border: 'white 1px solid',
		padding: '5px',
		borderRadius: '5px',
		backgroundColor: 'white'
	},
	speech: {
		color: 'white',
	},
	content: {
		flexGrow: 4,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		alignItems: 'center',
		height: '100%',
	},
	stepperContent: {
		backgroundColor: "inherit",
	},
	stepper: {
		padding: 10,
		backgroundColor: "inherit",
	},
	stepperTag: {
		color: 'White',
	},

	card: {
		maxWidth: 345,
		minHeight: 200,
		minWidth: 200,
		padding: 10,
		backgroundImage: `url("https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/OOjs_UI_icon_add.svg/1024px-OOjs_UI_icon_add.svg.png")`
	},
	title: {
		textAlign: "center",
	},
	header: {
		marginTop: "150px",
		marginBottom: "150px",
	},
	link: {
		textDecoration: "none",
		marginTop: "15px",
		marginBottom: "15px",
	},
	buttonContainer: {
		width: "100%",
		height: "100%",
	},
	body: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		marginTop: "50px",
		marginBottom: "50px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	buttonDiv: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},

	connectorLine: {
		minHeight: "100%"
	},
	divider: {
		border: `1px solid grey`,
		width: 'fit-content',
		'& svg': {
			margin: theme.spacing(1.5),
		},
		'& hr': {
			margin: theme.spacing(0, 0.5),
		},
	},
	tagline: {
		fontSize: '4rem',
		paddingTop: "100px",
		paddingBottom: "40px",
	},
}));


const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
	},
});

export {
	useStyles,
	useColorlibStepIconStyles,
} 
