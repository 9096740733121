import React, { Fragment } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
	KeyboardTimePicker,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { CssBaseline, Container, FormControl, Select, InputLabel, MenuItem, 
	//OutlinedInput, FormHelperText, 
	InputAdornment, FormGroup, TextField, 
	//Grid 
} from "@material-ui/core";

import useStyles from "./styles";
import { IDiscountSchedule, DAYS } from "../../types";
import ScheduleList from "../ScheduleList";
import clsx from "clsx";
import { EnumUtils } from "../../utils";
import { Formik } from "formik";
import * as Yup from "yup";

interface Props {
	data: IDiscountSchedule[],
	onDelClick: (key: number) => void,
	onItemClick: (key: number) => void,
	onAdd: (item: IDiscountSchedule) => void;
}

enum ChoiceDateToUpdate {
	STARTDATE,
	ENDDATE,
	STARTHOUR,
	ENDHOURS,
}

interface DaysMap {
	key: string,
	value: number
}

const AdvancedSchedule: React.FC<Props> = ({ data, onDelClick, onItemClick, onAdd }: Props) => {
	const classes = useStyles();

	const [selectedDateStart, setSelectedDateStart] = React.useState<Date | null>(new Date());
	const [
		//selectedDateEnd
		, setSelectedDateEnd] = React.useState<Date | null>(null);
	const [
		//selectedEndSchedule
		, setSelectedEndSchedule] = React.useState<Date | null>(
		new Date('2014-08-18T20:30:00'),
	);
	const [
		//selectedStartSchedule
		, setSelectedStartSchedule] = React.useState<Date | null>(
		new Date('2014-08-18T18:30:00'),
	);
	//const [discountAmount, setDiscountAmount] = React.useState<number>(0);
	const [days, 
		//setDays
	] = React.useState<number>(DAYS.All);
	const [isSubmited, setIsSubmited] = React.useState<boolean>(false);


	const handleDateChange = (date: Date | null, choice: ChoiceDateToUpdate) => {
		switch (choice) {
			case ChoiceDateToUpdate.STARTDATE:
				setSelectedDateStart(date);
				break;
			case ChoiceDateToUpdate.ENDDATE:
				setSelectedDateEnd(date);
				break;
			case ChoiceDateToUpdate.STARTHOUR:
				setSelectedStartSchedule(date);
				break;
			case ChoiceDateToUpdate.ENDHOURS:
				setSelectedEndSchedule(date);
				break;
			default:
				break;
		}
	};


/* 	const handleDays = (event: React.ChangeEvent<{ value: unknown }>) => {
		let daysTarget = event.target.value;
		let daysSelected = parseInt(daysTarget as string, 10);

		if (isNaN(daysSelected)) {
			setDays(daysSelected);
		}
	} */


/* 	const handleDiscountAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
		let amount = event.target.value;
		let amountNb = parseFloat(amount);

		// 
		if (!isNaN(amountNb)) {
			setDiscountAmount(amountNb);
		}
	} */

	const getAllDays = () => {
		let enumNames = EnumUtils.getKeys(DAYS);
		let enumValues: number[] = [];
		for (var log in DAYS) {
			if (!isNaN(Number(log))) {
				enumValues.push(Number(log));
			}
		}
		if (enumNames.length !== enumValues.length) {
			return [];
		}
		return enumNames.map((key, index) => {
			return {
				key: key,
				value: enumValues[index],
			};
		}) as DaysMap[];
	}

	/* 	const traduction = (trad: string[]) => {
			let days = EnumUtils.EnumToStrArray<string>(DAYS);
	
			if (trad.length !== days.length) {
				return days;
			}
		} */

/* 	const handleAddSchedule = (event: React.MouseEvent) => {
		if (!selectedStartSchedule || !selectedEndSchedule) {
			return;
		}
		let discount: IDiscountSchedule = {
			start: selectedDateStart ? selectedDateStart : undefined,
			end: selectedDateEnd ? selectedDateEnd : undefined,
			startHours: selectedStartSchedule,
			endHours: selectedEndSchedule,
			days: days,
			amount: discountAmount,
		};
		onAdd(discount);
	} */

	return (
		<Fragment>
			<CssBaseline />
			<Container component="div" className={classes.root} maxWidth={false}>
				<div>
					{data.length > 0 && <ScheduleList data={data} OnItemClick={onItemClick}
						onDelClick={onDelClick}
					/>}
				</div>
				{data.length === 0 &&
				<div style={{ margin:"1vh 0", width: "100%"}}>
					<Typography 
						component="span"
						align="center"
						variant="h6"
						gutterBottom>
							Aucune réduction n'a été définis sur votre plat
					</Typography>
				</div>}
				<Typography 
					component="span"
					align="center"
					className={classes.importantSentence}
					variant="h6"
					gutterBottom>
					Par défaut si aucune fréquence n'est fournis lors de la création d'une réduction, la réductions est appliquée tout les jours et sur une période indéfinis.
				</Typography>
				<Formik 
                    initialValues={{ 
						start: selectedDateStart,
						end: null,
						startHours: new Date('2014-08-18T18:30:00'),
						endHours: new Date('2014-08-18T20:30:00'),
						days: days,
						discountAmount: 0,
                    }}
                    validateOnChange={false}
                    validationSchema={Yup.object().shape({
						start: Yup.date().min(selectedDateStart),
						end: Yup.date().min(selectedDateStart).optional(),
						startHours: Yup.date().required(),
						endHours: Yup.date().required(),
						days: Yup.number().oneOf(getAllDays().map((value: DaysMap, index: number) => value.value)),
						discountAmount: Yup.number().min(0.01).max(90),
                    })}
                    onSubmit={(values) => {
						//console.log("lancement de handle submit !");
						/*let discount: IDiscountSchedule = {
							start: values.start ? values.start  : undefined,
							end: selectedDateEnd ? selectedDateEnd : undefined,
							startHours: values.startHours,
							endHours: values.startHours,
							days: values.days,
							discount: values.discountAmount,
						};

						onAdd(discount);*/
                    }}>
                    {formProps => (
						<div className={classes.details}>
							<div className={classes.frequences}>
								<Typography variant="h4" className={classes.title}>Fréquences</Typography>
								<div className={classes.frequenceDetails}>
									<Typography>Réductions valable du</Typography>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date-picker-inline"
										label="Date de  début"
										name="start"
										className={clsx(classes.margin, classes.textField)}
										onChange={(date) => { 
											handleDateChange(date, ChoiceDateToUpdate.STARTDATE);
											formProps.setFieldValue('start', date as Date, true);
										}}
										value={formProps.values.start}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										error={isSubmited && formProps.errors.start ? true : false}
										helperText={isSubmited && formProps.errors.start}
									/>
									<span>au</span>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date-picker-inline"
										label="Date de fin"
										name="end"
										className={clsx(classes.margin, classes.textField)}
										onChange={(date) => { 
											handleDateChange(date, ChoiceDateToUpdate.ENDDATE);
											formProps.setFieldValue('end', date as Date, true);
										}}
										value={formProps.values.end}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										error={isSubmited && formProps.errors.end ? true : false}
										helperText={isSubmited && formProps.errors.end}
									/>
									<Typography>le(s) jour(s) suivante</Typography>
									<FormControl
										margin="normal"
										variant="outlined">
										<InputLabel id="demo-simple-select-outlined-label">Jours disponible</InputLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="days"
											value={formProps.values.days}
											onChange={formProps.handleChange}
											label="catégorie"
											name="days"
											error={isSubmited && formProps.errors.days ? true : false}
										>
											{getAllDays().map((value: DaysMap, index: number) => {
												return (
													<MenuItem key={index} value={value.value}>{value.key}</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</div>
							</div>
							<div className={classes.days}>
		
							</div>
							<div className={classes.horraires}>
								<Typography variant="h4" className={classes.title}>Horraires</Typography>
								{/*<Grid container spacing={4} alignItems="center" justify="center" >
                                    <Grid 
                                        item 
                                        component='div'
                                        xs={12} 
                                        sm={6} 
                                        md={6}>
										
									</Grid>
								</Grid>*/}
								<div className={classes.horraireDetail}>
									<KeyboardTimePicker
										margin="normal"
										id="time-picker-start"
										label="Heure de début"
										name="startHours"
										className={clsx(classes.margin, classes.textField)}
										onChange={(date) => { 
											handleDateChange(date, ChoiceDateToUpdate.STARTHOUR);
											formProps.setFieldValue('startHours', date as Date, true);
										}}
										value={formProps.values.startHours}
										KeyboardButtonProps={{
											'aria-label': 'change time',
										}}
										error={isSubmited && formProps.errors.startHours ? true : false}
										helperText={isSubmited && formProps.errors.startHours}
									/>
									<KeyboardTimePicker
										margin="normal"
										id="time-picker-end"
										label="Heure de fin"
										className={clsx(classes.margin, classes.textField)}
										name="endHours"
										onChange={(date) => { 
											handleDateChange(date, ChoiceDateToUpdate.ENDHOURS);
											formProps.setFieldValue('endHours', date as Date, true);
										}}
										value={formProps.values.endHours}
										KeyboardButtonProps={{
											'aria-label': 'change time',
										}}
										error={isSubmited && formProps.errors.endHours ? true : false}
										helperText={isSubmited && formProps.errors.endHours}
									/>
									<FormGroup className={clsx(classes.margin, classes.textField)}>
										<TextField
											id="discountAmount"
											name="discountAmount"
											label="Pourcentage de la remise"
											placeholder="20"
											type="number"
											InputLabelProps={{
												shrink: true,
											}}
											fullWidth
											value={formProps.values.discountAmount}
											onChange={formProps.handleChange}
											onBlur={formProps.handleBlur}
											error={isSubmited && formProps.errors.discountAmount ? true : false}
											helperText={isSubmited && formProps.errors.discountAmount}
											InputProps={{
												endAdornment: (
													<InputAdornment style={{}} position="end">
														%
													</InputAdornment>
												),
											}}
											variant="outlined"
											/>
									</FormGroup>
								</div>
							</div>
							<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
								<Button 
									variant="contained" 
									color="primary"
									onClick={(e) => {
										e.preventDefault();
										setIsSubmited(true);
										formProps.handleSubmit();
									}}>
									Ajouter une réduction
								</Button>
							</div>							
						</div>
					)}
					</ Formik>
				{/*<div className={classes.details}>
					<div className={classes.frequences}>
						<Typography variant="h4" className={classes.title}>Fréquences</Typography>
						<div className={classes.frequenceDetails}>
							<Typography>Réductions valable du</Typography>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								margin="normal"
								id="date-picker-inline"
								label="Date de  début"
								className={clsx(classes.margin, classes.textField)}
								value={selectedDateStart}
								onChange={date => handleDateChange(date, ChoiceDateToUpdate.STARTDATE)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
							/>
							<span>au</span>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								margin="normal"
								id="date-picker-inline"
								label="Date de fin"
								className={clsx(classes.margin, classes.textField)}
								value={selectedDateEnd}
								onChange={date => handleDateChange(date, ChoiceDateToUpdate.ENDDATE)}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
							/>
							<Typography>le(s) jour(s) suivante</Typography>
							<FormControl
								margin="normal"
								variant="outlined">
								<InputLabel id="demo-simple-select-outlined-label">Jours disponible</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={days}
									onChange={handleDays}
									label="catégorie"
								>
									{getAllDays().map((value: DaysMap, index: number) => {
										return (
											<MenuItem key={index} value={value.value}>{value.key}</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</div>
					</div>
					<div className={classes.days}>

					</div>
					<div className={classes.horraires}>
						<Typography variant="h4" className={classes.title}>Horraires</Typography>
						<div className={classes.horraireDetail}>
							<KeyboardTimePicker
								margin="normal"
								id="time-picker-start"
								label="Heure de début"
								className={clsx(classes.margin, classes.textField)}
								value={selectedStartSchedule}
								onChange={date => handleDateChange(date, ChoiceDateToUpdate.STARTHOUR)}
								KeyboardButtonProps={{
									'aria-label': 'change time',
								}}
							/>
							<KeyboardTimePicker
								margin="normal"
								id="time-picker-end"
								label="Heure de fin"
								className={clsx(classes.margin, classes.textField)}
								value={selectedEndSchedule}
								onChange={date => handleDateChange(date, ChoiceDateToUpdate.ENDHOURS)}
								KeyboardButtonProps={{
									'aria-label': 'change time',
								}}
							/>

							<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
								<OutlinedInput
									id="outlined-adornment-weight"
									value={discountAmount}
									onChange={handleDiscountAmount}
									endAdornment={<InputAdornment position="end">%</InputAdornment>}
									aria-describedby="outlined-weight-helper-text"
									inputProps={{
										'aria-label': 'weight',
										min: 0.1,
										max: 100
									}}
									labelWidth={0}
								/>
								<FormHelperText id="outlined-weight-helper-text">Pourcentage de la remise</FormHelperText>
							</FormControl>
						</div>
					</div>
					<Button variant="contained" onClick={handleAddSchedule} color="primary">
						Ajouter une réduction
					</Button>
								</div>*/}
				
			</Container>
		</Fragment>
	);
};

export default AdvancedSchedule;
