import { Command } from '../../../types';

export interface CookState {
    commands: Command[]
}

const initialState: CookState = {
  commands: [],
};

const cookReducer = (state: CookState = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
};

export default cookReducer;
