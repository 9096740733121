import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		diplay: "flex",
		flexDirection: "column",
		"& article": {
			margin: "3vh 0"
		}
	},
	formElement: {
		"& article": {
			margin: "3vh 0",
			width: "100%",
			"& section": {
				margin: "1vh 0",
			}
		}
	},
	tagline: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
	}
}));

export {
	useStyles,
}
