import React, { Fragment, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { User } from "../../../types/user";
import { UserType, UserInfos } from "../../../types";
import { APIManager } from "../../../services";
import { Redirect } from "react-router-dom";
import { useStyles, useStylesBackdrop } from "./styles";
import LogoName from "../../../components/LogoName/LogoName";
import { Backdrop, CircularProgress, IconButton, InputAdornment } from "@material-ui/core";
import { store } from "../../../contexts/auth";
import ResendMail from "../../../components/Email/HandleEmail/ResendMail/ResendMail";
import httpStatus from "http-status";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import PasswordReset from "../../../components/Password/PasswordReset/PasswordReset";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from "clsx";
import { YumButton } from "../../../Lib/UI/YumButton";

type RegisterProps = {
	logUser: (user: UserInfos, type: UserType) => any;
};


const Layout: React.FC<RegisterProps> = ({ logUser }: RegisterProps) => {
	const classes = useStyles();
	const classesBakcdrop = useStylesBackdrop();
	const [isLoading, setLoading] = React.useState(false);
	const [isNotYetVerfied, setIsNotYetVerfied] = React.useState(false);
	const [resetPassword, setResetPassword] = React.useState(false);
	const [redirect, setRedirect] = React.useState(false);

	return (
		<Fragment>
			<CssBaseline />
			<div className={classes.root}>
				<div className={classes.backgroungAnnouncement}>
					<div className={classes.logo} >
						<LogoName />
					</div>
					<Typography 
						className={classes.announcement} 
						style={{ color: 'black' }} 
						component="h2" 
						variant="h5">
						Non mais allo ?
					</Typography>
					<Typography className={classes.announcement} component="h2" variant="h6">
						Augmentez vos ventes et être productif n'a jamais été aussi facile
					</Typography>
				</div>
				<div className={classes.signin}>
					<Container component="div" className={classes.signInRoot} maxWidth={false}>
						{(resetPassword || isNotYetVerfied) && (
						<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
							<div onClick={() => {
								setResetPassword(false);
								setIsNotYetVerfied(false);
							}} className={clsx(classes.backArrow,classes.makeLink)} >
								<ArrowBackIcon style={{ margin: "0 1vw" }}/>
								<Typography align="left" component="span" variant="subtitle1" gutterBottom>Retour à la connexion</Typography>
							</div>
						</div>)}
						{!resetPassword && isNotYetVerfied && (<ResendMail />)}
						{!resetPassword && !isNotYetVerfied && (<FormSignIn />)}
						{resetPassword && (<PasswordReset />)}
					</Container>
					{redirect && (<Redirect to='/home' />)}
					<Backdrop className={classesBakcdrop.backdrop} open={isLoading}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</div>
			</div>
		</Fragment>
	);

	/**
	 * Formulaire de connexion
	 * @param props Props du composant functionnel
	 * @returns
	 */
	function FormSignIn(props: any) {
		const [submitted, setSubmitted] = React.useState(false);
		const [showPassword, setShowPassword] = React.useState<boolean>(false);
		const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
		//const [loginError, setloginError] = React.useState<boolean>(false);	

		const { enqueueSnackbar } = useSnackbar();
		let loginError = true;

		let authContext = useContext(store);
	
		const handleClickShowPassword = () => {
			setShowPassword(!showPassword);
		};

		return (
			<Fragment>
				<CssBaseline />
				<DirectionsRunIcon className={classes.removeOnXs} style={{ marginBottom: '10px', fontSize: '3em' }} />
				{/*submitted && redirect && (<Redirect to='/home' />)*/}
				<Typography 
					style={{ marginBottom: '20px' }} 
					component="h1" 
					variant="body1">YumScan est une communauté, pensez à faire un tour dans la rubrique <span className={classes.HighlightLine}>"recommandations"</span> dans contact pour nous faire part de vos idées.</Typography>
				<Avatar
					style={{ backgroundColor: "black"}}>
					<LockOutlinedIcon />
				</Avatar>
				<Formik
						initialValues={{ 
							email: "",
							password: "",
						}}
						validateOnChange={false}
						onSubmit={async (values, { setSubmitting }) => {
							setSubmitted(false);
							setSubmitting(false);
							setLoading(true);
							setIsRequesting(true);
							loginError = false;

							let user: User = { email: values.email, password: values.password };

							await APIManager.getAccountServices().signIn(user, UserType.Trader).then((infos: UserInfos) => {
								
								authContext.authObj.handleAuth({ token: infos.token, userinfos: infos.userinfos, type: infos.type }).then((val) => {
									authContext.handleUserLogin();
									//console.log(authContext.authObj.userProfile);
									authContext.handleUserAddProfile(authContext.authObj.userProfile);
									logUser(infos, UserType.Trader); // Appel du store pour mettre a jour la connexion
									setLoading(false);
									setRedirect(true);
								}).catch((err: any) => {
									// TODO : Affiché d'erreur sur front
									console.log(err)
									loginError = true;
									//setloginError(true);
								});
					
							}).catch((err: Error) => {
								// TODO : Affiché d'erreur sur front
								setSubmitted(false);

								loginError = true;
								//setloginError(true);

								if (err.message.indexOf(httpStatus.FORBIDDEN.toString()) > -1) {
									setIsNotYetVerfied(true);
								} else {
									let message = err.message.indexOf(httpStatus.UNAUTHORIZED.toString()) > -1 ? "Login ou mot de passe incorrect." : "Une erreur est survenue veuillez contacter le service client";
									enqueueSnackbar( message, {
										variant: "error",
										anchorOrigin: {
											horizontal: "right",
											vertical: "top",
										}
									})
								}
								
							}).finally(() => {
								setLoading(false);
								setIsRequesting(false);
							});

						}}

						validationSchema={Yup.object().shape({
							email: Yup.string().email("Le format de l'email est incorrect").required("Champ obligatoire"),
							password: Yup.string().required("Champ obligatoire"),
						})}
						>
						{props => {
							const {
							values,
							//touched,
							//errors,
							//dirty,
							//isSubmitting,
							//isValidating,
							handleChange,
							//handleBlur,
							handleSubmit,
							//handleReset
							} = props;
							return (
							<form  style={{ maxWidth: "500px", display: "flex", flexDirection: "column"}} onSubmit={handleSubmit}>
								{!isRequesting &&
								<Fragment>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="email"
										label="Email"
										name="email"
										autoComplete="email"
										autoFocus
										value={values.email}
										onChange={handleChange}
										error={submitted && values.email === '' ? true : false}
										helperText={!submitted && values.email === '' ? "Un login est nécessaire" : undefined}
									/>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										name="password"
										label="mot de passe"
										id="password"
										autoComplete="current-password"
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment style={{}} position="end">									
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															onMouseDown={(event) => event.preventDefault()}
															>
															{showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
												</InputAdornment>
											),
										}}
										value={values.password}
										onChange={handleChange}
										error={submitted && values.password === '' ? true : false}
										helperText={!submitted && values.password === '' ? "Le mot de passe est requis" : undefined}
									/>
									{submitted && loginError && (
										<Typography 
											variant="subtitle1" 
											component="span"
											style={{ color: "red" }}
											gutterBottom>
												Le mot de passe ou le login sont incorrect
										</Typography>
									)}
									{/*<FormControlLabel
										control={<Checkbox value="remember" color="primary" />}
										label="Remember me"
									/>*/}
									<div
										style={{
											display:"flex",
											alignItems: "center",
											justifyContent: "center",
											margin: "1vh 0", width: "100%"}}>
										<YumButton 
											onClick={(event) => {
												event.preventDefault();
												setSubmitted(true);
												handleSubmit();
											}}
											type="submit"
											disabled={isRequesting || isLoading}>Connexion</YumButton>
									</div>

									<Grid container spacing={1}>
										<Grid item xs>
										
											<Typography 
												onClick={() => {
													setResetPassword(true);
												}}
												className={classes.makeLink}
												variant="body2" 
												component="span">
													Mot de passe oublié ?
											</Typography>
										</Grid>
										<Grid item>
											<a href='/signup?pack=617c5b6f1d9b5711c00d1395&type=monthly' className={classes.link}>
												<Typography 
													variant="body2" 
													component="span">
													Vous n'avez pas de compte ?
												</Typography>
											</a>
										</Grid>
									</Grid>
								</Fragment>}
								{isRequesting &&
								<div style={{ width: "100%", height:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
									<CircularProgress size={75}/>
								</div>
								}
							</form>
							);
						}}
					</Formik>
			</Fragment>
		);
	}
}
export default Layout;
