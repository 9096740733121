import React, { Fragment, useEffect } from "react";

import {
	Typography,
	Button,
	Container,
	Grid,
	Breadcrumbs,
	Card,
	CardContent,
	CardActions,
	CardHeader,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Checkbox
} from "@material-ui/core";
import useStyles from "./styles";

import { useSnackbar } from 'notistack';

import { Skeleton } from "@material-ui/lab";

import { ITrader } from "../../../types";
import CheckoutBillingButton from "../../../components/CheckoutBillingButton";
import { NavLink } from "react-router-dom";

interface Props {
	trader?: ITrader;
}

/* enum Frequency {
	YEARS,
	MONTHLY,
} */

//const url_server = process.env.REACT_APP_BASE_URL;

const Layout: React.FC<Props> = ({ trader }: Props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const priceMonthly = 7.99;
	const priceYearly = 95;
	const productIDMonthly = "price_1IAyVYG8ZvWcWnS4xLA0vhqj";
	const productIDAnnual = "price_1IB5GxG8ZvWcWnS41xSSA3H7";

	//const [pdfLink, setPdfLink] = React.useState<string | undefined>(undefined);
	//const [packs, setPack] = React.useState<IPackYum[]>([]);
	const [price, setPrice] = React.useState<number>(priceMonthly);
	const [billingFrequency, setBillingFrenquency] = React.useState<string>('monthly');
	const [isLoading, 
	//	setIsLoading
	] = React.useState<boolean>(false);
	const [agreement, setAgreement] = React.useState<boolean>(false);
	const [productId, setProductId] = React.useState<string>(productIDMonthly);



	useEffect(() => {
		/*if (!trader) {
			console.log("trader not set in Card Stack for pdf", trader);
			return;
		}

		APIManager.getPackServices().GetAllPacks().then((packs: IPackYum[]) => {
			console.log(packs);
			setPack([...packs]);
		}).catch((err) => {
			console.log("Erreur chargement pack!");
			console.log(err);
		})*/
		//console.log("test process :", process.env.REACT_APP_BASE_URL, process.env.YUMPDF_MONTHLY, process.env.YUMPDF_YEAR)
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let selected = event.target.value;

		setBillingFrenquency((event.target as HTMLInputElement).value);
		if (selected === "monthly") {
			setProductId(productIDMonthly);
			setPrice(priceMonthly);
		} else if (selected === "annual") {
			setProductId(productIDAnnual);
			setPrice(priceYearly);
		}
	};

	return (
		<Fragment>
			<Container className={classes.root} maxWidth={false} component="main">
				<div className={classes.navSection}>
					<Breadcrumbs aria-label="breadcrumb">
						<NavLink color="inherit" to="/home" >
							<Typography
								component="span"
								variant="h5"
							>
								Accueil
							</Typography>
						</NavLink>
						<Typography
							component="span"
							variant="h5"
						>
							Choix Abonnement
						</Typography>
					</Breadcrumbs>
				</div>
				<Container style={{ height: "100%", width: "100%" }} maxWidth="lg" component="div">
					<Grid container spacing={5} alignItems="center" justify="center">
						<Grid item xs={12} sm={6} md={3}>
							<Card style={{ minHeight: "330px" }}>
								<CardHeader
									title="Yum PDF"
									titleTypographyProps={{ align: 'center' }}
									className={classes.cardHeader}
								/>
								<CardContent>
									<div className={classes.cardPricing}>
										<Typography component="h2" variant="subtitle1" color="textPrimary">
											{price}€
										</Typography>
										<Typography variant="h6" color="textSecondary">
											/{billingFrequency === "monthly" ? "mois" : "an"}
										</Typography>
									</div>
									{billingFrequency === "monthly" && (<div style={{ marginBottom: "15px" }}>
										<Typography component="p" align="center" variant="subtitle1" color="textPrimary" gutterBottom>
											soit {priceYearly}€ pour un abonnement annuellement
										</Typography>
									</div>)}
									{billingFrequency === "annual" && (<div style={{ marginBottom: "15px" }}>
										<Typography component="p" align="center" variant="subtitle1" color="textPrimary" gutterBottom>
											Reduction possible avec un coupon Yumscan
										</Typography>
									</div>)}
									<ul>
										<Typography component="li" variant="subtitle1" align="center">Carte PDF</Typography>
										<Typography component="li" variant="subtitle1" align="center">Support 24h/24</Typography>
										<Typography component="li" variant="subtitle1" align="center">2 Qrcodes*</Typography>
									</ul>
								</CardContent>
								<CardActions>
									<div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
										{agreement && (<CheckoutBillingButton productId={productId} />)}
										{!agreement && (<Button variant="contained" color="primary" onClick={() => {
											enqueueSnackbar('cochez "Je suis d\'accord avec les CGU et CGV" pour souscrire', { variant: "warning" })
										}}>
											Souscrire à l'abonnement
										</Button>)}
									</div>
								</CardActions>
							</Card>
						</Grid>
					</Grid>
					<div>
						<Typography variant="subtitle1" component="h6" gutterBottom>Cycle de paiement</Typography>
						<FormControl style={{ margin: "0 10px" }} component="fieldset">
							<RadioGroup style={{ flexDirection: "row" }} aria-label="Billing cycle" name="Billing cycle" value={billingFrequency} onChange={handleChange}>
								<FormControlLabel value="monthly" control={<Radio />} label="Mensuel" />
								<FormControlLabel value="annual" control={<Radio />} label="Annuel" />
							</RadioGroup>
						</FormControl>
					</div>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<Checkbox
							checked={agreement}
							onChange={(event, checked) => { setAgreement(checked); }}
							style={{ alignItems: "flex-start" }}
							inputProps={{ 'aria-label': 'primary checkbox' }}
						/>
						<Typography align="justify">Je suis d'accord avec les CGU et CGV</Typography>
					</div>
				</Container>
				{isLoading &&
					(<div>
						<Skeleton variant="text" />
						<Skeleton variant="rect" width={210} height={118} />
					</div>)}
			</Container>
		</Fragment >
	);
};


export default Layout;
