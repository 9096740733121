import { ReduxAction } from "./types";
import { IPackYum, ITrader, UserStateV2 } from "../../../types";
import { SET_CURRENT_PACK, SET_USERINFOS } from "./actionTypes";

const initialState: UserStateV2 = {
	data: undefined,
	currentPack: undefined,
};

export default (state = initialState, action: ReduxAction<any>) => {
	switch (action.type) {
		case SET_USERINFOS:
			return {
				...state,
				data: action.payload as ITrader | undefined,
			};
		case SET_CURRENT_PACK:
			//console.log("reducer payload SET CURRENT PACK -> ", action.payload);
			return {
				...state,
				currentPack: action.payload as IPackYum,
			};
		default:
			return state;
	}
};
