import { ColorResult } from "react-color";
import { UserInfos, UserRoleAuth } from "./api";
import { IPackYum, IStripeBilling, ITrader } from "./entities";

export * from "./api";
export * from "./services";
export * from "./entities";
export * from "./shared";

export interface Dish {
	Image?: string;
	Name: string;
	Price: number;
	Ingredients: string[];
	Description?: string;
	IDDish: string;
	IDRestaurant: string;
}

export interface Dishes {
	Dish: Dish;
	Commentaire?: string;
}

export interface Command {
	IDCommand?: string | undefined;
	Status?: boolean;
	NameRestaurant?: string;
	Description?: string;
	Dishes: Dishes[];
	Commentaire?: string;
}

export interface User {
	IDRestaurant?: string;
	Login: string;
	Name: string;
}

export interface Restaurant {
	Name: string;
	IDRestaurant?: string;
}

export interface ReducerData {
	payload?: any;
	type: string;
}

export interface CustomerState {
	commands: Command[];
	cart: { Dishes: { dish: Dish; commentaire?: string }[] };
	restaurant: string;
}

export interface CookState {
	commands: Command[];
}

export interface EntitiesState {
	restaurants?: Restaurant[];
	dishes?: Dish[];
	isLoading: boolean;
	error?: string;
}

export enum UserType {
	Trader = 0,
	Customer = 1,
	SubAccount = 2,
}

export interface UserState {
	userdata?: UserInfos;
	isAuthentified: boolean;
	role?: UserRoleAuth;
}

export interface BillingState {
	billing?: IStripeBilling;
}

export interface AppColorPersonnalization {
	/**
	 * Couleur du text
	 */
	TextColor?: ColorResult;

	/**
	 * Couleur des titres et bouton d'action
	 */
	PrincipalColor: ColorResult;

	/**
	 * Couleur des sous-titres
	 */
	SecondaryColor: ColorResult;
	/**
	 * Dégradé de couleur
	 */
	ColorGradient: {
		Stage1: ColorResult;
		Stage2: ColorResult;
	}
}

export interface ApplicationDataState {
	Color: AppColorPersonnalization;
}


export interface UserStateV2 {
	data?: ITrader;
	currentPack?: IPackYum;
}

// ApplicationStateGlobal
export interface AppState {
	user: UserState;
	cook: CookState;
	billing: BillingState;
	customer: CustomerState;
	entities: EntitiesState;
	applicationdata: ApplicationDataState;
	userV2: UserStateV2;
}

/*
// Namespace des in
export namespace ApplicationState {
  // UserState
}
*/
