import React, { Fragment, useEffect, useCallback  } from "react";
import useStyles from "./styles";

import { TileData, UserState, 
	//ICard, 
	IDish, IMenu, ComposantMode } from "../../../types";
import { Container, CssBaseline, Snackbar } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
import { CardServices } from "../../../api";

import VerticalList from "../../../components/VerticalList";

import Alert from '@material-ui/lab/Alert';


import SpringModal from "../../../components/SpringModal";
import { APIManager } from "../../../services";
import ScookNavBar from "../../../components/ScookNavBar/ScookNavBar";
import DishCrud from "../../../components/DishCrud";
import MenuCrud from "../../../components/MenuCrud";
import { IMG_CONSTANTES } from "../../../types/constantes/constantes";


interface Props {
	userData: UserState,
	nextStep?: () => void,
}

const url_server = process.env.REACT_APP_BASE_URL;

const Layout: React.FC<Props> = ({ userData, nextStep }: Props) => {
	const classes = useStyles();

	const [dishMode, setDishMode] = React.useState<ComposantMode>(ComposantMode.Create);
	const [dishEdit, setDishEdit] = React.useState<IDish | undefined>(undefined);

	const [
		//menuMode
		, setMenuMode] = React.useState<ComposantMode>(ComposantMode.Create);
	const [
		//menuEdit
		, setMenuEdit] = React.useState<IMenu | undefined>(undefined);

	const [openDish, setOpenDish] = React.useState(false);
	const [openMenu, setOpenMenu] = React.useState(false);
	const [dishes, setDishes] = React.useState<IDish[]>([]);
	const [tileDish, setTileDish] = React.useState<TileData[]>([]);
	const [tileMenu, setTileMenu] = React.useState<TileData[]>([]);
	const [menus, setMenus] = React.useState<IMenu[]>([]);

	// Gestion des evenements de la modal
	const [isError, 
		//setError
	] = React.useState<boolean>(false);
	const [isSucces, 
		//setSucces
	] = React.useState<boolean>(false);

	//const steps = getSteps();

	/*   const addItem = () => {
	  console.log("cliquer pour ouverture modal !!");
	  setOpen(true);
	}; */

	/* 	const addMenu = () => {
			console.log("adding menu");
		}; */

	const handleClose = (val: boolean, isSucess?: boolean, data?: any) => {
		setOpenDish(val);
		setDishEdit(undefined);
		setDishMode(ComposantMode.Create);
		setMenuEdit(undefined);
		setMenuMode(ComposantMode.Create);
		if (userData.userdata) {
			dishListUpdate();
		}
	};

	const handleCloseMenu = (val: boolean, isSucess?: boolean, data?: any) => {

		setOpenMenu(val);
		if (userData.userdata) {
			menuListUpdate();
		}
	};

	const initDishList = useCallback((dishArray: IDish[]) => {
		let tile: TileData[] = dishArray.map((dish) => {

			return {
				keyTile: dish._id as string,
				picture: dish.picturePrincipal === IMG_CONSTANTES.DEFAULT_IMG ? dish.picturePrincipal : `${url_server}/${dish.picturePrincipal}`,
				title: dish.name,
				price: dish.priceHT,
			} as TileData;
		});
		setDishes([...dishes, ...dishArray]);
		setTileDish([...tileDish, ...tile]);
	}, [dishes, tileDish])

	const initMenuList = useCallback((menuArray: IMenu[]) => {
		let tile: TileData[] = menuArray.map((menu) => {
			return {
				keyTile: menu._id as string,
				picture: menu.picturePrincipal === IMG_CONSTANTES.DEFAULT_IMG ? menu.picturePrincipal : `${url_server}/${menu.picturePrincipal}`,
				title: menu.name,
				price: menu.priceHT,
			} as TileData;
		});
		setMenus([...menus, ...menuArray]);
		setTileMenu([...tileMenu, ...tile]);
	}, [menus, tileMenu]);

	/**
	 * Met a jour la liste des plats
	 */
	function menuListUpdate() {
		//let api: CardServices = APIManager.getCardServices();

		if (!userData.userdata) {
			return;
		}
		/*api.GetAllCards(userData.userdata.id).then((card: ICard | undefined) => {
			console.log("Essaye de mettre a jour la carte des menus ");
			//console.log(card);
			if (!card || !card.menus) {
				return;
			}
			let newItems = (card.menus as IMenu[]).filter((menu) => {
				let diffTab = menus.find(currentMenus => currentMenus._id === menu._id);
				// Si l'element est dans le tableau de menus
				if (diffTab) {
					return false;
				}
				return true;
			});
			//console.log(newItems);
			if (newItems) {
				initMenuList(newItems);
			}
		}).catch((err) => {
			console.log("une erreur c'est produite");
			console.log(err);
			//afficher snack ca c'est mal passé
		});*/
	}

	function dishListUpdate() {
		//let api: CardServices = APIManager.getCardServices();

		//console.log(userData);
		if (!userData.userdata) {
			return;
		}
		/*api.GetAllCards(userData.userdata.id).then((card: ICard | undefined) => {
			console.log("Essaye de mettre a jour la carte des plats");
			//console.log(card);
			if (!card || !card.dishes) {
				return;
			}
			let newItems = (card.dishes as IDish[]).filter((dish) => {
				let diffTab = dishes.find(currentDishes => currentDishes._id === dish._id);

				// Si l'element est dans le tableau de dishes
				if (diffTab) {
					return false;
				}
				return true;
			});
			//console.log(newItems);
			if (newItems) {
				initDishList(newItems);
			}
		}).catch((err) => {
			console.log("une erreur c'est produite");
			console.log(err);
			//afficher snack ca c'est mal passé
		});*/
	}

	function deleteDish(idDish: string) {
		let api: CardServices = APIManager.getCardServices();

		api.deleteDishCascadeFromCard(idDish).then((res) => {
			console.log("delete dish !! :");
			//console.log(res);
			if (res) {
				dishListUpdate();
			}
		}).catch((err) => {
			console.log(err);
		});
	}

	function deleteMenu(idMenu: string) {
		let api: CardServices = APIManager.getCardServices();

		api.deleteMenuCascadeFromCard(idMenu).then((res) => {
			//console.log(res);
			if (res) {
				menuListUpdate();
			}
		}).catch((err) => {
			console.log(err);
		});
	}

	const handleEditDish = (id: string) => {
		let dish = dishes.find(x => x._id === id);

		if (dish) {
			console.log("Edit mode !");
			//console.log(dish);
			setDishEdit(dish);
			setDishMode(ComposantMode.Update);
			setOpenDish(true);
		} else {
			console.log("ERREUR DANS LA RECUP DU PLAT");
		}
	}

	useEffect(() => {
		console.log("composant monté");
		//let api: CardServices = APIManager.getCardServices();

		if (!userData.userdata) {
			console.log("userdata vide");
			return;
		}
		/*api.GetAllCards(userData.userdata.id).then((card: ICard) => {
			console.log("a effectué appel api");
			//console.log(card.dishes);
			if (card.dishes) {
				initDishList(card.dishes as IDish[]);
			}
			if (card.menus) {
				initMenuList(card.menus as IMenu[]);
			}

		}).catch((err) => {
			console.log("une erreur c'est produite");
			console.log(err);
			setError(true);
			//afficher snack ca c'est mal passé
		});*/
		//console.log(url_server);
	}, [userData, initDishList, initMenuList]);

	return (
		<Fragment>
			<CssBaseline />
			<Container component="div" className={classes.root} maxWidth={false}>
				<div className={classes.scookNavbar}>
					<ScookNavBar></ScookNavBar>
				</div>
				<div className={classes.createGroup}>
					<Typography className={classes.tagline} component="h4" variant="h3">Créer les éléments de ta carte !</Typography>
					<div className={classes.test}>
						<VerticalList data={tileDish}
							onItemClick={(idDish) => handleEditDish(idDish)}
							OnCreateClick={() => { setOpenDish(true); }}
							OnDeleteClick={(idDish) => deleteDish(idDish)}
							displayAddButton={true}
						></VerticalList>
					</div>
				</div>
				<div className={classes.createGroup}>
					<Typography className={classes.tagline} component="h4" variant="h3">Créer tes menu !</Typography>
					<div className={classes.test}>
						<VerticalList
							data={tileMenu}
							onItemClick={(idDish) => { console.log("ouvrir modale edit plat") }}
							OnCreateClick={() => { setOpenMenu(true); }}
							OnDeleteClick={(idMenu) => deleteMenu(idMenu)}
							displayAddButton={true}
						></VerticalList>
					</div>
				</div>
				<div>
					<Button variant="contained" color="secondary">
						Passer aux système de paiement
					</Button>
				</div>

			</Container>
			<Snackbar open={isSucces} autoHideDuration={6000} onClose={() => { }}>
				<Alert onClose={() => { }} severity={isError ? "error" : (isSucces ? "success" : "success")}>
					{`la création rapide a été un succès`}
				</Alert>
			</Snackbar>
			<Snackbar open={isError} autoHideDuration={6000} onClose={() => { }}>
				<Alert onClose={() => { }} severity="error">
					{`l'élément n'a pas pu être crée. `}
				</Alert>
			</Snackbar>
			<SpringModal openModal={openDish} eventClose={handleClose} canclose={true}>
				<DishCrud mode={dishMode} idCurrentItem={dishEdit} OnClose={() => { console.log("premier implementation") }} OnOpen={() => { console.log("premier implementation") }} />
			</SpringModal>
			<SpringModal openModal={openMenu} eventClose={handleCloseMenu} canclose={true}>
				<MenuCrud Advanced={false} OnClose={() => { console.log("premier implementation") }} OnOpen={() => { console.log("premier implementation") }} />
			</SpringModal>
		</Fragment>
	);
};

export default Layout;
