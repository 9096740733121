import React, { 
	createContext, 
	useReducer, useEffect } from "react";
import { ITrader } from "../../types";

import * as AuthReducer from "../../redux/reducers/auth";
import { } from "../../redux/reducers/auth";
import Auth from "../../utils/auth";

const auth = new Auth();

const store = createContext({
	//Auth Reducer
	authState: false,
	profileState: {},
	handleUserLogin: () => { },
	handleUserLogout: () => { },
	handleUserAddProfile: (profile: ITrader | {}) => { },
	handleUserRemoveProfile: () => { },
	//Handle auth
	//handleAuth: (props) => handleAuthentication(props),
	authObj: auth
});
//const store = createContext(initialState);
const { Provider } = store;

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
//const provideContext = createContext<UserState | undefined>(undefined);



const AuthProvider: React.FC<{}> = (Props) => {
	//const { Provider } = provideContext;
	/*
		 Auth Reducer
	   */
	const [stateAuthReducer, dispatchAuthReducer] = useReducer(AuthReducer.AuthReducer, AuthReducer.initialState);

	const handleLogin = () => {
		dispatchAuthReducer(AuthReducer.ACTIONS.login_success())
	}

	/**
	 * 
	 */
	const handleLogout = () => {
		dispatchAuthReducer(AuthReducer.ACTIONS.login_failure())
	}

	/**
	 * Ajout le profile au contexte actuel
	 * @param profile 
	 */
	const handleAddProfile = (profile: ITrader | {}) => {
		dispatchAuthReducer(AuthReducer.ACTIONS.add_profile(profile))
	}

	/**
	 * Supprime le profile du contexte actuel
	 */
	const handleRemoveProfile = () => {
		dispatchAuthReducer(AuthReducer.ACTIONS.remove_profile())
	}

	useEffect(() => {
		//console.log("Provider auth mounted");
	}, []);

	return (
		<Provider value={{
			//Auth Reducer
			authState: stateAuthReducer.is_authenticated,
			profileState: stateAuthReducer.profile,
			handleUserLogin: () => handleLogin(),
			handleUserLogout: () => handleLogout(),
			handleUserAddProfile: (profile) => handleAddProfile(profile),
			handleUserRemoveProfile: () => handleRemoveProfile(),

			//Handle auth
			//handleAuth: (props) => handleAuthentication(props),
			authObj: auth
		}}>
			{Props.children}
		</Provider>
	);
}

/*
const AuthProvider: React.FC<Props> = (Props) => {
	//const AuthProvider = ({ children }: Props) => {
	const [state, dispatch] = useReducer((state: any, action: { type: string, payload: any }) => {
		switch (action.type) {
			case 'login':
				return {
					...state,
					...action.payload,
					isLogged: true,
				};
			case 'logout':
				return initialState;
			default:
				throw new Error();
		};
	}, initialState);

	useEffect(() => {
		console.log("authprovider loaded !");
	}, []);

	return <Provider value={{ state, dispatch }}>{Props.children}</Provider>;
};


let AuthProviderConnected = connect(
	null,
	{
		logOut: LogOutUser,
	}
)(AuthProvider);*/

export {
	store,
	AuthProvider
}

