import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column"
	},
	main: {
		padding: "0vh 0 10vh 0"
	},
}));

export {
	useStyles,
}
