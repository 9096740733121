import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	rootContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		"& section": {
			margin: "10px 0",
		}
	},
	rootPincipalImg: {
		display: "flex",
		flexDirection: "column",
		padding: "1vh 0",
		"& article": {
			width: "100%"
		}
	},
	imgContainer: {
		width: 250,
		height: 250,
		position: "relative",
		zIndex: 0,
	},
	imgBackgroundMinimumRequierement: {
		height: "100%",
		backgroundColor: 'rgb(0,0,0,0.4)',
		position: "absolute",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		fontSiez: "20px",
		fontWeight: "bold",
	},

	m_10: {
		margin: "10px 0"
	},



	App: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	  
	cropContainer: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: "80px",
	},
	
	controls: {
		position: "absolute",
		bottom: 0,
		left: "50%",
		width: "50%",
		transform: "translateX(-50%)",
		height: "80px",
		display: "flex",
		alignItems: "center",
	},
	
	slider: {
		padding: "22px 0px",
	}

}));

export default useStyles;