import React from "react";
import ReactToPrint from 'react-to-print';
import { useStyles } from "./styles";
import { YumButton } from "../../Lib/UI/YumButton";

interface Props {
	onBeforeGetContent?: () => void | Promise<any>,
	onAfterPrint?: () => void,
}

const PrintElement: React.FC<Props> = (props) => {
	const classes = useStyles();
	const componentRef = React.createRef<HTMLDivElement>();
	/*let componentRefButton = React.useRef<HTMLButtonElement | null>(null);

	useEffect(() => {

		if (props.click && componentRefButton.current) {
			componentRefButton.current.click();
			console.log("Launch print !");
			props.clickFinished();
		}

	}, [props.click])*/

	return (
	<div className={classes.rootPrint}>
		<ReactToPrint
		  trigger={() => <YumButton>Imprimer</YumButton>}
		  content={() => componentRef.current}
		  onBeforeGetContent={props.onBeforeGetContent}
		  onAfterPrint={props.onAfterPrint}
		/>
		<div className="printable" ref={componentRef}>
			<style>
				{`@media print {
					.printable {
						display: inline;
					}

					.page-break  { 
						display:block; 
						page-break-before:always; 
					}
				}

				@media screen {
					.printable {
						display: none;
					}
				}
				`}
			</style>
			{props.children}
		</div>
	</div>
	);
}
export default PrintElement;
