import React, { Fragment } from "react";

import useStyles from "./styles";
import { IDish } from "../../types";
import { CssBaseline, Paper, Table, TableBody, TableRow, TableCell, TableHead, Checkbox, TableContainer, TablePagination } from "@material-ui/core";

import SearchBarDish from "../SearchBarDish/SearchBarDish";

interface Props {
	OnKeyDownEnter: (searchText: string) => void;
	OnChangeSearch: (searchText: string) => void;
	OnCategorySelected: (category: string) => void;
	OnSelected: (id: string, value: boolean) => void;
	dataSelect: string[];
	label: string;
	data: IDish[];
}

const SearchTable: React.FC<Props> = ({ OnChangeSearch, OnKeyDownEnter, OnCategorySelected, OnSelected, dataSelect, label, data }: Props) => {
	const classes = useStyles();

	/* 	const [searchText, setSearchText] = React.useState<string>('');
		const [category, setCategory] = React.useState(dataSelect.length > 0 ? dataSelect[0] : ''); */
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, id: string | undefined) => {
		if (id) {
			OnSelected(id, checked);
		}
	};

	return (
		<Fragment>
			<CssBaseline />
			<Paper className={classes.root}>
				<TableContainer className={classes.container}>
					<Table>
						<TableHead>
							<TableRow>
								<div>
									<SearchBarDish
										OnCategorySelected={OnCategorySelected}
										OnChangeSearch={OnChangeSearch}
										OnKeyDownEnter={OnKeyDownEnter}
										dataSelect={dataSelect.map(category => ({value: category, name: category}))}
										/*displayLogo={true}*/
										label={label} />
								</div>
							</TableRow>
							<TableRow className={classes.header}>
								<TableCell>Action</TableCell>
								<TableCell>Photo principale</TableCell>
								<TableCell align="right">Nom</TableCell>
								<TableCell align="right">Prix</TableCell>
								<TableCell align="right">Disponibilité</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((dish, index) => (
								<TableRow key={dish._id}>
									<TableCell>
										<Checkbox
											color="primary"
											inputProps={{ 'aria-label': 'primary checkbox' }}
											onChange={(event, checked) => { handleSelect(event, checked, dish._id) }}
										/>
									</TableCell>
									<TableCell component="th" scope="row">
										{dish.name}
									</TableCell>
									<TableCell align="right">
										<Paper className={classes.paper} elevation={3} />
									</TableCell>
									<TableCell align="right">{dish.priceHT}€</TableCell>
									<TableCell align="right">
										<Checkbox
											checked={dish.available}
											color="secondary"
											inputProps={{ 'aria-label': 'secondary checkbox' }}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 15, 25]}
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</Fragment>
	);
};

export default SearchTable;
