import React, { useContext } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { AppState, ITrader, UserState } from "../../types";
import { connect } from "react-redux";
import { store } from "../../contexts/auth";
import Startup from "../../components/v2/Startup/Startup";
import RestrictionModal from "../../components/Restriction/RestrictionModal/RestrictionModal";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
/*export default function AuthRouteWrapper({ children }: any) {
	let authContext = useContext(store);
	let { path, url } = useRouteMatch();
	let { state } = authContext;

	console.log(state);
	if (state.isLogged) {
		return (
			<React.Fragment>
				{children}
			</React.Fragment>
		);
	}
	return (
		<Route
			path={path}
			render={({ location }) => (
				<Redirect
					to={{
						pathname: "/signin",
						state: { from: location }
					}} />
			)}
		/>
	);
}*/

interface Props {
	userdata: UserState;
	userV2: ITrader | undefined;
}

const AuthRouteWrapper: React.FC<Props> = (Props) => {

	let authContext = useContext(store);
	let { path, 
//		url 
	} = useRouteMatch();

	//useEffect(() => { console.log("userv2 props :", Props.userV2); console.log("wrapper"); Props.onUpdateUserData(); }, []);
	//useEffect(() => { console.log("display userv2 on change :", Props.userV2); }, [Props.userV2]);

	if (Props.userdata.isAuthentified || authContext.authObj.getAccessToken() != null) {
		return (
			<React.Fragment>
				{Props.children}
				<Startup />
				<RestrictionModal />
			</React.Fragment>
		);
	}
	return (
		<Route
			path={path}
			render={({ location }) => (
				<Redirect
					to={{
						pathname: "/signin",
						state: { from: location }
					}} />
			)}
		/>
	);
}

export default connect(
	(state: AppState) => ({
		userdata: state.user,
		userV2: state.userV2.data, 
	}),
	{}
)(AuthRouteWrapper);


//export default AuthRouteWrapper;
