import React, { 
	createContext, 
	useReducer, useEffect } from "react";
import { IStripeBilling } from "../../types";

import * as BillingReducer from "../../redux/reducers/billing";
import { } from "../../redux/reducers/auth";
import Auth from "../../utils/auth";

const auth = new Auth();

const billingStore = createContext({
	//Auth Reducer
	billings: undefined,
	handleAddBillings: (billings: IStripeBilling) => { },

});
//const store = createContext(initialState);
const { Provider } = billingStore;

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
//const provideContext = createContext<UserState | undefined>(undefined);



const BillingProvider: React.FC<{}> = (Props) => {
	//const { Provider } = provideContext;
	/*
		 Auth Reducer
	   */
	const [stateAuthReducer, dispatchAuthReducer] = useReducer(BillingReducer.BillingReducer, BillingReducer.initialState);

	/**
	 * Ajout le profile au contexte actuel
	 * @param profile 
	 */
	const handleAddBillings = (billings: IStripeBilling) => {
		dispatchAuthReducer(BillingReducer.ACTIONS.set_stripe_billing(billings));
	}

	useEffect(() => {
		//console.log("Provider auth mounted");
	}, []);

	return (
		<Provider value={{
			//Auth Reducer
			billings: stateAuthReducer.billing,
			handleAddBillings: (billings) => handleAddBillings(billings),
		}}>
			{Props.children}
		</Provider>
	);
}

export {
	billingStore,
	BillingProvider,
}

