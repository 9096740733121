import React, { Fragment, useState } from "react";
import { useStyles } from "./styles";
import { CssBaseline, Grid,  TextField, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { AppState, UserState } from "../../types";
import { connect } from "react-redux";
import { APIManager } from "../../services";
import { useSnackbar } from "notistack";
import { YumButton } from "../../Lib/UI/YumButton";

interface Props {
	userdata: UserState;
}

const subjectOfContact = ["Offres", "Paiement", "Recommandations", "Autres"];

const Contact: React.FC<Props> = ({ userdata }: Props) => {

	const classes = useStyles();

    const [isSubmited, setIsSubmited] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

	return (
		<Fragment>
			<CssBaseline />
            <div className={classes.text}>
                <Typography component="h1" variant="subtitle1" align="center" style={{ margin: "2vh 2vw" }} gutterBottom>Contactez-nous <span role="img" aria-label=":yum:">😋</span></Typography>
                <Typography component="span" variant="body1" align="center" style={{ margin: "2vh 2vw" }} gutterBottom>Vous pouvez nous contacter en passant par notre formulaire de contact ci-dessous.</Typography>
            </div>
            <Formik 
                initialValues={{ 
                    name: userdata.userdata?.userinfos ? `${userdata.userdata?.userinfos.firstname} ${userdata.userdata?.userinfos.lastname}`: "",
                    email: userdata.userdata?.email ? userdata.userdata?.email : "",
                    mobile: userdata.userdata?.userinfos.mobile ? userdata.userdata?.userinfos.mobile : "",
                    subject: "",
                    message: ""
                }}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                    name: Yup.string().max(30, "Un nom ne peux contenir plus de 30 caractères."),
                    email: Yup.string().email("Votre email est au mauvais format.").required("Veuillez renseigner votre adresse email."),
                    mobile: Yup.string().min(10).max(10).required("Veuillez renseigner un numéro valide."),
                    subject: Yup.mixed().oneOf(subjectOfContact).required("Champ obligatoire"),
                    message: Yup.string().min(5, "Le champs doit obligatoire contenir quelque chose.").required("Veuillez indiquer le contenu de votre message"),
                })}
                onSubmit={(values: any,  { resetForm }) => {
                    setIsSubmited(false);
                    if (userdata.userdata?.userinfos) {
                        APIManager.getContactService().ContactAuth(values.email, values.subject, values.message, values.mobile, values.name).then((res)=> {
                            enqueueSnackbar("Votre message a bien été transmis, l'équipe Yumscan revient vers vous le plus rapidement possible", { 
                                variant: "info",
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal:'right',
                                }
                            });
                        }).catch((err) => {
                            enqueueSnackbar("Une erreur inconnue est survenue veuillez ré-essayez ou contacter le service client via contact@yumscan.fr si le problème persiste.", { 
                                variant: "error",
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal:'right',
                                }
                            });
                        }).finally(() => {
                            resetForm({});
                        });
                    }
                    else {
                        APIManager.getContactService().Contact(values.email, values.subject, values.message, values.mobile, values.name).then((res)=> {
                            enqueueSnackbar("Votre message a bien été transmis, l'équipe Yumscan revient vers vous le plus rapidement possible", { 
                                variant: "info",
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal:'right',
                                }
                            });
                        }).catch((err) => {
                            enqueueSnackbar("Une erreur inconnue est survenue veuillez ré-essayez ou contacter le service client via contact@yumscan.fr si le problème persiste", { 
                                variant: "error",
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal:'right',
                                }
                            });
                        }).finally(() => {
                            resetForm({});
                        });
                    }
                }}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <Grid container spacing={2} alignItems="center" justify="center" >
								<Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={6} 
                                    md={6}>
                                    <TextField
                                        id="name"
                                        type="text"
                                        label="Nom"
                                        name="name"
                                        fullWidth
                                        variant="outlined"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        disabled={userdata.userdata?.userinfos !== undefined}
                                        error={isSubmited && props.errors.name ? true : false}
                                        helperText={isSubmited && props.errors.name}
                                    />
                                </Grid>
                                <Grid 
                                    item 
                                    component='div' 
                                    xs={12} 
                                    sm={6} 
                                    md={6}>
                                    <TextField
                                        id="email"
                                        type="email"
                                        label="Email"
                                        name="email"
                                        variant="outlined"
                                        fullWidth
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={isSubmited && props.errors.email ? true : false}
                                        helperText={isSubmited && props.errors.email}
                                        disabled={userdata.userdata?.email !== undefined}
                                        value={props.values.email}
                                    />
                                </Grid>
                                <Grid 
                                    item 
                                    component='div' 
                                    xs={12} 
                                    sm={6} 
                                    md={6}>
                                    <TextField
                                        id="mobile"
                                        type="text"
                                        label="Mobile"
                                        name="mobile"
                                        variant="outlined"
                                        fullWidth
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={isSubmited && props.errors.mobile ? true : false}
                                        helperText={isSubmited && props.errors.mobile}
                                        disabled={userdata.userdata?.userinfos?.mobile !== undefined}
                                        value={props.values.mobile}
                                    />
                                </Grid>
                                <Grid 
                                    item 
                                    component='div' 
                                    xs={12} 
                                    sm={6} 
                                    md={6}>
                                    <FormControl fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        required
                                    >
                                        <InputLabel 
                                            id="Subject-label" 
                                            error={props.errors.subject ? true : false}
                                            >Sujet</InputLabel>
                                        <Select
                                            labelId="Subject-label"
                                            id="subject"
                                            name="subject"
                                            value={props.values.subject}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            label="Sujet"
                                            required
                                            fullWidth
                                        >
                                            {subjectOfContact.map((value: string, index: number) => {
                                                return (
                                                    <MenuItem key={index} value={value}>{value}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {isSubmited && props.errors.subject && <div>{props.errors.subject}</div>}
                                    </FormControl>
                                </Grid>
                                <Grid 
                                    item 
                                    component='div' 
                                    xs={12} 
                                    sm={12} 
                                    md={12}>
                                    <TextField
                                        id="message"
                                        label="message"
                                        placeholder="Tapez votre message ici"
                                        multiline
                                        fullWidth
                                        variant="outlined"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={isSubmited && props.errors.message ? true : false}
                                        helperText={isSubmited && props.errors.message}
                                        value={props.values.message}
                                        />
                                </Grid>
                                <div className={classes.bouton}>
									<YumButton 
                                        onClick={(event) => {
                                            setIsSubmited(true)
                                        }}
                                        style={{ marginTop: 15 }} 
                                        type="submit">
									    Envoyer
                                    </YumButton>
								</div>		
                         </Grid>
                        </form>
                    )}
            </Formik>
		</Fragment>
	);
};

export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}),
	{
	}
)(Contact);
