import React, { Fragment, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';

import useStyles from "./styles";


interface FadeProps {
	children?: React.ReactElement;
	in: boolean;
	onEnter?: () => {};
	onExited?: () => {};
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter();
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited();
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{children}
		</animated.div>
	);
});

interface Props {
	openModal: boolean,
	canclose : boolean,
	eventClose: (propValueMustBe: boolean, isSucces?: boolean, data?: any) => void;
}
const SpringModal: React.FC<Props> = (Props) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(Props.openModal);

	const handleOpen = () => {
		setOpen(true);
		Props.eventClose(true);
	};

	const handleClose = () => {
		if (Props.canclose) {
			setOpen(false);
		}
		Props.eventClose(false);
	};

	useEffect(() => {
		setOpen(Props.openModal);
	}, [Props.openModal]);

	function VariableComponent() {
		const childrenWithProps = React.Children.map(Props.children, child => {
			// checking isValidElement is the safe way and avoids a typescript error too
			if (React.isValidElement(child)) {
				return React.cloneElement(child, { Advanced: false, OnClose: handleClose, OnOpen: handleOpen });
			}
			return child;
		});

		return <div>{childrenWithProps}</div>;
	}

	return (
		<Fragment>
			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					{VariableComponent()}
				</Fade>
			</Modal>
		</Fragment>
	);
}
//<DishCrud Advanced={false} OnClose={handleClose} OnOpen={handleOpen} />


export default SpringModal;