import React from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import { Chip, TextField, Container } from "@material-ui/core";
import { IEditableIngredient, IIngredient } from "../../types";
import clsx from "clsx";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';


interface Props {
    ingredients?: IEditableIngredient[],
    updateIngredients: (ingredient: IEditableIngredient[]) => any,
}

const IngredientMaker: React.FC<Props> = ({ ingredients, updateIngredients } :Props) => {

    const classes = useStyles();
	//const { enqueueSnackbar } = useSnackbar();
    //let authContext = useContext(store);


    const [ingredientsSteps, setIngredientsStep] = React.useState<IEditableIngredient[]>(ingredients ? ingredients : []);
    const [ingredientSearch, setIngredientSearch] = React.useState('');

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.key === 'Enter') && (ingredientSearch !== "")) {
            event.preventDefault();
            //console.log(ingredientSearch);
            let newIngredient: IEditableIngredient = {
                ingredient: {
                    name: ingredientSearch,
                    category: "Unknow",
                },
                editable: false,
            }
            setIngredientsStep([...ingredientsSteps, newIngredient] as IEditableIngredient[]);
            updateIngredients([...ingredientsSteps]);
            setIngredientSearch('');
        }
    }

    const handleChangeIngredientSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIngredientSearch(event.target.value as string);
    };

    const handleDelete = (event: any, key: number) => {
        //console.log(event);
        event.preventDefault();

        console.info('You clicked the delete icon.');
        let newIngredient = ingredientsSteps.filter((val: IEditableIngredient, keyTab: number) => {
            if (key === keyTab) {
                return false;
            }
            return true;
        });
        setIngredientsStep([...newIngredient]);
        updateIngredients([...newIngredient]);
    };
    
    /* const handleChangeEditableIngredient = (event: React.ChangeEvent<HTMLInputElement>, data: boolean) => {
        console.log("is editable !");
        let indexArray = event.target.name;
        let realIndex: number = parseInt(indexArray, 10);
        if (isNaN(realIndex) || realIndex > ingredientsSteps.length) {
            throw Error("l'index du tableau n'existe pas ou est mal formaté !");
        }
        (ingredientsSteps[realIndex] as IEditableIngredient).editable = data;
        setIngredientsStep([...ingredientsSteps]);
        updateIngredients([...ingredientsSteps]);
    } */


    return (
        <Container maxWidth="lg">
            <div className={classes.formElement}>
                <article>
                    <Typography 
                        variant="subtitle2" 
                        align="center" 
                        component="h1" 
                        gutterBottom>
                        Composition de votre plat
                    </Typography>
                    <Typography 
                        variant="body1" 
                        align="center" 
                        component="h1" 
                        gutterBottom>
                        Précisez les différents ingredients qui constituent votre plat pour permettre à vos clients d'éviter les allergies.
                    </Typography>
                </article>
                <article>
                    <TextField
                        value={ingredientSearch}
                        onKeyDown={handleKeyDown}
                        onChange={handleChangeIngredientSearch}
                        placeholder="Exemple: Poireau"
                        fullWidth id="outlined-basic" 
                        label="Tapez le nom de votre ingrédient puis presser 'entrée'" 
                        variant="outlined" />
                </article>
                <article>
                    <Typography 
                        variant="subtitle2" 
                        align="center" 
                        component="h1" 
                        gutterBottom>
                            Les ingredients 
                    </Typography>
                    {ingredientsSteps.length === 0 && 
                    (<div className={clsx(classes.m_2_v,classes.p_10)}>
                        <Typography
                            variant="body2" 
                            align="center" 
                            component="h1" 
                            gutterBottom>
                                Vous n'avez saissis aucun ingrédients
                        </Typography>
                    </div>)}
                    {ingredientsSteps.length > 0 && 
                    (<div className={clsx(classes.chipRoot,classes.m_2_v,classes.p_10)}>
                        {ingredientsSteps.map((ingredient: IEditableIngredient, index: number) => {
                            return (
                                <Chip
                                    key={index}
                                    label={(ingredient.ingredient as IIngredient).name}
                                    clickable
                                    className={classes.chipsDisplay}
                                    color="secondary"
                                    onDelete={event => handleDelete(event, index)}
                                    deleteIcon={<HighlightOffRoundedIcon />}
                                />
                            );
                        })}  
                    </div>)}
                </article>

                {/* {<article>
                    <Typography
                        variant="body1">
                        Cocher les ingredients pouvant être allèrgènes dans votre plat , par défaut les ingrédients ne sont pas noté comme allèrgène
                    </Typography>
                    <div>
                        {ingredientsSteps.map((item: IEditableIngredient, index: number) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            key={index}
                                            checked={item.editable as boolean}
                                            onChange={handleChangeEditableIngredient}
                                            name={index.toString(10)}
                                            color="primary" />
                                    }
                                    label={(item.ingredient as IIngredient).name}
                                />
                            );
                        })}
                    </div>
                </article>} */}

            </div>                
        </Container>
    )
    

};

export default IngredientMaker;