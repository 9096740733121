/* import React from "react";
import { connect } from "react-redux";
import Layout from "./Layout";
import { UserInfos, UserType } from "../../types";

interface Props {
  logUser: (user: UserInfos, type: UserType) => any;
}

const Landing: React.FC<Props> = ({ logUser }: Props) => <Layout />;

export default connect(null, {})(Landing);
 */

import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import Layout from "./Layout";
import {
	//Restaurant, 
	AppState, UserState
} from "../../types";
import { LogOutUser } from "../../redux/user";
import { store } from "../../contexts/auth";

interface Props {
	logOut: () => any;
	userData: UserState;
}

const Home: React.FC<Props> = ({ logOut, userData }: Props) => {
	let authContext = useContext(store);

	useEffect(() => {
		//console.log("Base Home component loaded");
	}, []);

	return (
		<Layout userData={userData} logOut={logOut} authContext={authContext} />);
};

export default connect(
	(state: AppState) => ({
		userData: state.user
	}),
	{
		logOut: LogOutUser,
	}
)(Home);
