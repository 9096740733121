import React, { Fragment, useContext, useEffect } from "react";
// import { NavLink } from "react-router-dom";

//import useStyles from "./styles";
//import { CssBaseline, Badge, AppBar, Toolbar } from "@material-ui/core";
import { billingStore } from "../../../contexts/billingInformations";
import SpringModal from "../../SpringModal";
import { APIManager } from "../../../services";
import { store } from "../../../contexts/auth";
import { AppState, IStripeBilling, ITrader, TRADER_PAYEMENT_STATUS, UserInfos } from "../../../types";
import { DateTimeUtils } from "../../../utils";
import PopUpBillingStatus from "../PopUpBillingStatus/PopUpBillingStatus";
import { StorageServiceManager } from "../../../services/storageManager";
import { DAYLY_POP_UP_HAS_BEEN_SHOWED, DAYS_UNTIL_TRIAL_END_POP_UP } from "../../../types/constantes/constantes";
import DisplayOffers from "../../DisplayOffers";
import PopUpUnpaid from "../PopUpUnpaid/PopUpUnpaid";
import { connect } from "react-redux";
import { set_stripe_billing } from "../../../redux/reducers/billing/action";

interface Props {
    billingdata: IStripeBilling | undefined;
    userdata: UserInfos | undefined;
    setBillingState: (billing: IStripeBilling) => any;
}

const RestrictionModal: React.FC<Props> = ({ billingdata, userdata, setBillingState}: Props) => {

	let billingsContext: any = useContext(billingStore);
    let authContext: any = useContext(store);

    //const [profil, setProfil] = React.useState<ITrader | undefined>(undefined);
    const [billing, setBilling] = React.useState<IStripeBilling | undefined>(undefined);

    //** DIfferent state */

    const DaysUntilTrialEnd = React.useMemo(() => {
        if (!billing?.trial_end_date) {
            return 0;
        }
        return DateTimeUtils.dateDiffInDays(new Date(), new Date(billing?.trial_end_date as Date));;
    } , [billing, billing?.trial_end_date]);

    const isXdaysLeftUntilTrialEnd = React.useMemo(() => {
        let hasBeenDisplayed = StorageServiceManager.getStorageService().getData<boolean>(DAYS_UNTIL_TRIAL_END_POP_UP);


        if (hasBeenDisplayed === true || !billing?.trial_end_date || billing.status.toLowerCase() !== TRADER_PAYEMENT_STATUS.Trialing) {
            return false;
        } else if (DaysUntilTrialEnd >= 0 && DaysUntilTrialEnd <= 3) {
            return true;
        } else {
            return false;
        }
    }, [billing]);


    const isTrialEnded = React.useMemo(() => {
        if (billing?.status.toLowerCase() === TRADER_PAYEMENT_STATUS.Trialing 
        && new Date(billing?.trial_end_date as any) < new Date()) {
            return true;
        }
        return false;
    }, [billing]);

    const isBillingUnpaid = React.useMemo(() => {
        let hasBeenDisplayed = StorageServiceManager.getStorageService().getData<boolean>(DAYLY_POP_UP_HAS_BEEN_SHOWED);

        //console.log("hasbeeddisplay", billing?.status.toLowerCase() === TRADER_PAYEMENT_STATUS.Unpaid, billing);
        if (!hasBeenDisplayed && billing?.status.toLowerCase() === TRADER_PAYEMENT_STATUS.Unpaid) {
            return true;
        } else if (billing?.status.toLowerCase() === TRADER_PAYEMENT_STATUS.Past_due || billing?.start_period_unpaid) {
            return true;
        } else {
            return false;
        }
    }, [billing]);

    const handleCloseMenu = () => {
        return false;
    }

    const getBillings = async () => {
		try 
        {
            if (billingsContext.billings) {
                console.log("Billing context :", billingsContext.billings);

                setBilling(billingsContext.billings);
            } else {
                
                let billing = await APIManager.getBillingService().getBillingInformations();               

                setBillingState(billing);
                setBilling(billing);
            }

		} catch (err) {			
			console.log(err);
		}
	}

    /**
     * Vérifie que l'utilsateur connecté n'est pas bloqué.
     */
    const isBlockedUser = React.useMemo(()=> { 

        if (authContext.authObj.userProfile !== {} && (authContext.authObj.userProfile as ITrader).authorized === true) {
			return false;
		}
        //l'utilisateur est bloqué
        return true;
    }, [authContext.authObj.userProfile]);

    /**
     * Défini si la modal doit etre ouverte ou non.
     */
    const modalCanBeOpened = React.useMemo(()=> { 
        //console.log("IsBIlling unpaid", isBillingUnpaid);
        return isBlockedUser || isXdaysLeftUntilTrialEnd || isBillingUnpaid;
    }, [isBlockedUser, isXdaysLeftUntilTrialEnd, isBillingUnpaid]);

    /**
     * Défini si la modal peut etre fermée ou non.
     */
    const modalCanBeClosed = React.useMemo(()=> { 
        return (isBlockedUser === false);
    }, [isBlockedUser]);


    useEffect(() => {
        if (authContext.authObj.userProfile === {} || billing !== undefined) {
			return;
		}
        getBillings();
    }, []);

    useEffect(() => {
		if (authContext.authObj.userProfile !== {}) {
			return;
		}
		authContext.authObj.getProfile();
	}, [authContext.authObj.userProfile]);

    
    
    return (
        <Fragment>
            <SpringModal openModal={modalCanBeOpened} eventClose={handleCloseMenu} canclose={modalCanBeClosed}>
                {isXdaysLeftUntilTrialEnd && <PopUpBillingStatus DaysUntilEnd={DaysUntilTrialEnd} />}
                {isTrialEnded && 
                    <DisplayOffers 
                        Advanced={false} 
                        OnClose={() => { console.log("premier implementation") }} 
                        OnOpen={() => { console.log("premier implementation") }} />}
                {isBillingUnpaid && console.log("isBillingUnpaid", isBillingUnpaid) && <PopUpUnpaid />}
            </SpringModal>
        </Fragment>
    )
  
  }

export default connect(
	(state: AppState) => ({
		billingdata: state.billing.billing,
        userdata: state.user.userdata,
	}),
    {
        setBillingState: set_stripe_billing,

    }
)(RestrictionModal);

