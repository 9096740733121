import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'initial',
		height: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 'initial'
		}
	},
	layout: {
		width: 'auto',
		height: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 1200,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		flex: 1,
		flexDirection: 'column',
	},
	firstGroup: {
		display: "flex",
		flexDirection: "row",
		flex: 1,
		justifyContent: "space-between"
	},
	AdvancedDiscounts: {
		flexDirection: "column",
	},
	calculatedPrice: {
		display: "flex",
		flexDirection: "row",
	},
	inputCustom: {
		color: 'black',
	},
	inputs: {
		marginTop: '1%',
		marginBottom: '1%',
	},
	test: {
		maxWidth: '1200px',
		overflow: "hidden",
		'&::-webkit-scrollbar': {
			width: '0.4em'
		},
		'&::-webkit-scrollbar-track': {
			'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgb(0 0 0)',
			outline: '1px solid slategrey'
		}
	},
}));

export default useStyles;
