import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import useStyles from "./styles";
import { AppState, UserState } from "../../types";
import { CssBaseline, Popper, Grow, Paper, MenuList, MenuItem, ClickAwayListener, IconButton, Container } from "@material-ui/core";
import LogoName from "../LogoName/LogoName";
import MenuIcon from '@material-ui/icons/Menu';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import { LogOutUser } from "../../redux/user";
import { storageservice, APIManager } from "../../services";
import { HashLink as Link } from 'react-router-hash-link';


interface Props {
	userdata: UserState;
	logOut: () => any;
}

const Navbar: React.FC<Props> = ({ userdata, logOut }: Props) => {
	const classes = useStyles();

	const handleLogOut = (event: React.MouseEvent) => {
		logOut();
		storageservice.deleteAll();
		APIManager.clearTokenAPIS();
	}

	/**
	 * Renvoie les éléments à affichés lorsque l'utilisateur est connecté
	 */
	function LoggedDisplay() {

		let deferredPrompt : any;
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			deferredPrompt = e;
		});

		const classes = useStyles();
		const [open, setOpen] = React.useState(false);
		const anchorRef = React.useRef<HTMLButtonElement>(null);

		const handleToggle = () => {
			setOpen((prevOpen) => !prevOpen);
		};

		const handleClose = (event: React.MouseEvent<EventTarget>) => {
			if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
				return;
			}

			setOpen(false);
		};

		const handleInstall = (event: React.MouseEvent<EventTarget>) => {
			//console.log("IM HERE");
			//console.log(event);

			// Show the prompt
			deferredPrompt.prompt();
			// Wait for the user to respond to the prompt
			deferredPrompt.userChoice.then((choiceResult:any) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the A2HS prompt');
				} else {
					console.log('User dismissed the A2HS prompt');
				}
				deferredPrompt = null;
			});
		}

		function handleListKeyDown(event: React.KeyboardEvent) {
			if (event.key === 'Tab') {
				event.preventDefault();
				setOpen(false);
			}
		}

		// return focus to the button when we transitioned from !open -> open
		const prevOpen = React.useRef(open);
		React.useEffect(() => {
			if (prevOpen.current === true && open === false) {
				anchorRef.current!.focus();
			}

			prevOpen.current = open;
		}, [open]);

		return (
			<Fragment>
				<div>
					<IconButton color="secondary"
						ref={anchorRef}
						aria-controls={open ? 'userpanel-list-grow' : undefined}
						aria-haspopup="true"
						onClick={handleToggle}
						aria-label="UserPanel">
						<LocalLibraryIcon />
					</IconButton>
					<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList autoFocusItem={open} id="userpanel-list-grow" onKeyDown={handleListKeyDown}>
											<MenuItem onClick={handleClose}>
												<NavLink className={classes.link} to="/home">Panneau de control</NavLink>
											</MenuItem>
											<MenuItem onClick={handleInstall}>Installer app</MenuItem>
											<MenuItem onClick={handleClose}>
												<NavLink className={classes.link} onClick={handleLogOut} to="/">
													Déconnexion
												</NavLink>
											</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div>
			</Fragment>
		);
	}

	/**
	 * Renvoie les éléments à affichés lorsque l'utilisateur est connecté
	 */
	function ResponsiveMenu() {
		const classes = useStyles();
		const [open, setOpen] = React.useState(false);
		const anchorRef = React.useRef<HTMLButtonElement>(null);

		const handleToggle = () => {
			setOpen((prevOpen) => !prevOpen);
		};

		const handleClose = (event: React.MouseEvent<EventTarget>) => {
			if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
				return;
			}

			setOpen(false);
		};

		function handleListKeyDown(event: React.KeyboardEvent) {
			if (event.key === 'Tab') {
				event.preventDefault();
				setOpen(false);
			}
		}

		// return focus to the button when we transitioned from !open -> open
		const prevOpen = React.useRef(open);
		React.useEffect(() => {
			if (prevOpen.current === true && open === false) {
				anchorRef.current!.focus();
			}

			prevOpen.current = open;
		}, [open]);

		return (
			<Fragment>
				<div>
					{/*<Typography>Bonjour {userdata.userdata?.email}</Typography>
					<AddToHomeScreen />
					<NavLink className={classes.link} to="/dashboard">Dashboard</NavLink>
					<NavLink className={classes.link} to="/createadvanceddish">Plats</NavLink>
					<NavLink className={classes.link} to="/createmenu">Menu</NavLink>
		<NavLink className={classes.link} onClick={handleLogOut} to="/">Déconnexion</NavLink> */}
					<IconButton color="secondary"
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={handleToggle}
						aria-label="Menu">
						<MenuIcon />
					</IconButton>
					{/*<Button
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={handleToggle}
					>
						Toggle Menu Grow
					</Button>*/}
					<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
											<MenuItem onClick={handleClose}>
												<Link
													to="/#display-offres"
													smooth
													className={classes.navLink}
												>
													<Typography component="h4" variant="h5">
														Offres
													</Typography>
												</Link>
											</MenuItem>
											<MenuItem onClick={handleClose}>
												<Link
													to="/#display-whatisit"
													smooth
													className={classes.navLink}
												>
													<Typography component="h4" variant="h5">
														Yumscan, c'est quoi ?
													</Typography>
												</Link>
											</MenuItem>
											<MenuItem onClick={handleClose}>
												<Link
													to="/#faq-questions"
													smooth
													className={classes.navLink}
												>
													<Typography component="h4" variant="h5">
														Faq
													</Typography>
												</Link>
											</MenuItem>
											<MenuItem onClick={handleClose}>
												<Link
													to="/#contact-landing"
													smooth
													className={classes.navLink}
												>
													<Typography component="h4" variant="h5">
														Contact
													</Typography>
												</Link>
											</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div>
			</Fragment>
		);
	}

	/**
	 * Renvoie la listes des produits de yumscan
	 */

/* 	function Products() {
		const classes = useStyles();
		const [open, setOpen] = React.useState(false);
		const anchorRef = React.useRef<HTMLButtonElement>(null);

		const handleToggle = () => {
			setOpen((prevOpen) => !prevOpen);
		};

		const handleClose = (event: React.MouseEvent<EventTarget>) => {
			if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
				return;
			}

			setOpen(false);
		};

		function handleListKeyDown(event: React.KeyboardEvent) {
			if (event.key === 'Tab') {
				event.preventDefault();
				setOpen(false);
			}
		}

		// return focus to the button when we transitioned from !open -> open
		const prevOpen = React.useRef(open);
		React.useEffect(() => {
			if (prevOpen.current === true && open === false) {
				anchorRef.current!.focus();
			}

			prevOpen.current = open;
		}, [open]);

		return (
			<Fragment>
				<div>
					<Typography ref={anchorRef}
						aria-controls={open ? 'product-list-grow' : undefined}
						aria-haspopup="true"
						aria-label="Product"
						onClick={handleToggle}
						variant="h5"
					>Produits</Typography>
					<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
											<MenuItem onClick={handleClose}>
												<NavLink className={classes.link} to="/product/pdf">Carte PDF</NavLink>
											</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</div>
			</Fragment>
		);
	} */

	/**
	 * Renvoie les éléments à affichés lorsque l'utilisateur n'est pas connecté
	 */
	function UnLoggedDisplay() {
		return (
			<div className={classes.subRoot}>
				{/* <Button className={classes.signIn} variant="contained" color="primary">
					<NavLink to="/show/offers" className={classes.link}>
						<Typography component="p" variant="h6">S'abonner</Typography>
					</NavLink>
				</Button> */}
				<Button color="inherit">
					<NavLink to="/signin" className={classes.link}>
						<Typography className={classes.responsiveFont} component="p" variant="h5">Connexion</Typography>
					</NavLink>
				</Button>
			</div>
		);
	}

	/**
	 * Renvoie les bonnes options selon l'utilisateur connecté
	 * @param props
	 */
	function VariableComponent(props: any) {
		const isLoggedIn: boolean = props.isLoggedIn;

		if (isLoggedIn) {
			return <LoggedDisplay />;
		}
		return <UnLoggedDisplay />;
	}



	return (
		<Fragment>
			<CssBaseline />
			<AppBar elevation={0} position="sticky" color="default" className={classes.root}>
				<Container maxWidth="lg">
					<Toolbar className={classes.subRoot} disableGutters={true}>
						{/*<div className={classes.navLinkRootResponsive}>
							<ResponsiveMenu />
						</div>*/}
						<LogoName />
						{/*<div className={classes.navLinkRoot}>
							<Link
								to="/#display-offres"
								smooth
								className={classes.navLink}
							>
								<Typography component="h4" variant="h5">
									Offres
								</Typography>
							</Link>
							<Link
								to="/#display-whatisit"
								smooth
								className={classes.navLink}
							>
								<Typography component="h4" variant="h5">
									Yumscan, c'est quoi ?
								</Typography>
							</Link>
							<Link
								to="/#faq-questions"
								smooth
								className={classes.navLink}
							>
								<Typography component="h4" variant="h5">
									Faq
								</Typography>
							</Link>
							<Link
								to="/#contact-landing"
								smooth
								className={classes.navLink}
							>
								<Typography component="h4" variant="h5">
									Contact
								</Typography>
							</Link>
						</div>*/}
						<div>
							<VariableComponent isLoggedIn={userdata.isAuthentified} />
						</div>
					</Toolbar>
				</Container>
			</AppBar>
		</Fragment>
	);
};

export default connect(
	(state: AppState) => ({
		restaurants: state.entities.restaurants || [],
		userdata: state.user,
	}),
	{
		logOut: LogOutUser,
	}
)(Navbar);
