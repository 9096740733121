import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "100vh",
		paddingTop: "8vh",
		paddingBottom: "6vh",
		color: theme.palette.secondary.main,
		backgroundImage: "linear-gradient(135deg, #eaac7e 0%, #f47150 100%)",
	},

	titleAnnouncement: {
		fontWeight: "bold",
	},
	titlesSection: {
		color: "white",
		textAlign: "center",
		"& strong": {
			color: theme.palette.secondary.main,
		}
	},
	logo: {
		border: 'white 1px solid',
		padding: '5px',
		borderRadius: '5px',
		backgroundColor: 'white'
	},
	marginDiv: {
		margin: "3vh 0"
	},

	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		padding: "3vh 3vw",
		height: "auto",
		borderRadius: "10px",
		backgroundColor: "white",
		boxShadow: "0px 2px 18px 0px rgb(34 56 101 / 10%)",
		[theme.breakpoints.between('xs', 'sm')]: {
			height: "initial",
			textAlign: "center",
		},
	},
	headerRoot: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		position: "absolute",
	},
	header: {
		top: 0,
		padding: "15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
		position: "absolute",
		[theme.breakpoints.between('xs', 'sm')]: {
			position: "relative",
		},
	},
	packRoot: {
		padding: "30px",
		borderRadius: "15px"
	},
	priceMonthly : {
		display: "flex", 
		flexDirection: "row", 
		justifyContent: "center", 
		alignItems: "baseline",
		marginTop: "10px",
	},

	btnCreateAccount: {
		width: "100%", 
		display: "flex", 
		justifyContent: "flex-end",
		[theme.breakpoints.between('xs', 'sm')]: {
			justifyContent: "center",
		},
	},

	cursorPointer: {
		'&:hover': {
			cursor: 'pointer',
	   },    
	}
}));

const useStylesBackdrop = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff',
		},
	}),
);

export {
	useStylesBackdrop,
	useStyles,
}