import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	root: {
		flex: 1,
		flexDirection: "column",
	},
	media: {
		height: 140,
	},
	navLink: {
		marginLeft: "10px",
		marginRight: "10px",
	},
	navHeader: {
		width: '100%',
		maxHeight: '50px',
		overflow: "hidden",
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	headerRoot: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		backgroundColor: 'white',
	},
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		margin: "10px 0",
		alignItems: "flex-end"
	},
	navHeaderRoot: {
		width: '100%',
		overflow: 'scroll',
		overflowY: 'hidden',
		'&::-webkit-scrollbar': {
			background: "transparent", /* make scrollbar transparent */
		},
		'&::-webkit-scrollbar-track': {
			background: "transparent", /* make scrollbar transparent */
		},
		'&::-webkit-scrollbar-thumb': {
			background: "transparent", /* make scrollbar transparent */
		}
	},
	navHeaderSubRoot: {
		display: "flex",
		justifyContent: "space-between",
	},
	categoryName: {
		padding: "20px 5px 5px 20px",
		backgroundColor: "#e0e0e0",
		width: "100%",
		fontFamily: ["Fredoka One", "Abel", "Lato", "Open Sans", "Roboto"].join(","),
	},
	title: {
		textTransform: "uppercase",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	cardContainer: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: "10px 5px",
	},

	chipsItemNav: {
		marginRight: "15px",
	},
	IdTraderContainer: {
		display: "flex",
		flexDirection: "row",
		fontFamily: 'Amatic SC',
	},
	addButton: {
		flex: 1
	},

	/* SECTION AFFICHAGE PLAT */

	descriptionContainer: {
		width: "100%",
		color: "#767a7d",
		textAlign: "center",
	},
	description: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		width: "100%",
		fontSize: "14px",
	},
	/* Affichage du prix */
	navHeaderBottom: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		"& div": {
			margin: "0 5px",
			fontFamily: "Fredoka One",
		},
	},

	/* Image plat*/
	minValues: {
		display: "flex",
		flexDirection: "column",
		borderRadius: "8px",
		justifyContent: "center",
		overflow: "hidden",
		marginBottom: "10px"
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		borderRadius: "8px",
		justifyContent: "center",
		overflow: "hidden"
	},
	coverContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "20vh",
		position: "relative",
	},
	cover: {
		width: '100%',
		height: '100%',
		borderRadius: "8px"
	},
	makeBackground: {
		height: 'auto',
		position: 'absolute',
		top: 10,
		left: 10,
		padding: "5px",
		borderRadius: "5px",
		backgroundColor: 'rgb(0,0,0,0.4)',
	},
	ImgAddBackground: {
		position: 'absolute',
		bottom: 10,
		right: 10,
		display: "flex",
		flexDirection: "row",
		backgroundColor: 'rgb(0,0,0,0.4)',
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "10px",
		padding: "5px",
		color: "white",

		"& span": {
			fontWeigth: "bold"
		}
	},

	/* SECTION PANIER */
	rootCart: {
		position: "fixed",
		padding: "10px",
	},
	stickycart: {
		display: 'none',
	},

	/* SECTION AFFICHAGE COMME UBER */
	addBtn: {
		display: "flex",
		flexDirection: "row",
		backgroundColor: 'rgb(0,0,0,0.4)',
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "10px",
		padding: "5px",
		color: "white",

		"& span": {
			fontWeigth: "bold"
		}
	}
}));

export default useStyles;
