import { 
	AApi, 
	RESTVerb, 
	IPackYum 
} from "../../types";
import * as httpStatus from 'http-status';


export class PackServices extends AApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

	/**
	 * Récupère l'ensemble des packs enregistré dans la bdd
	 * @param user 
	 */
	public GetAllPacks(): Promise<IPackYum[]> {
		return this.JsonNoAuthRequest(RESTVerb.GET, "pack").then(async (response: Response) => {
			//console.log(response);	
			if (response.status === httpStatus.OK) {
				let result = await response.json();
				return result as IPackYum[];
			} else if (response.status === httpStatus.NO_CONTENT) {
				return [];
			} else {
				throw new Error("Conflict ! ");
			}
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble des packs enregistré pour l'utilisateur
	 * @param user 
	 */
	public GetAllPacksForTrader(idTrader: string): Promise<IPackYum[]> {
		return this.JsonNoAuthRequest(RESTVerb.GET, `pack/${idTrader}`).then(async (response: Response) => {
			let result = await response.json();
			if (!result || response.status !== httpStatus.OK) {
				throw new Error("Une erreur est survenue");
			}
			//console.log(response);
			return result as IPackYum[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}
}
