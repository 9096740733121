import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 'initial',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: '30px',
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "white",
		textAlign: "center",
		borderRadius: "10px",
	},
	buttonCointainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		justifyContent: "space-evenly",

	},
	margin:  {
		margin: "1vh 0vw"
	},
	link: {
		textDecoration: 'none',
		color: 'inherit',
		width: "100%",
	},
	HighlightLine: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
	},
}));

export default useStyles;
