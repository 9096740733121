import { ADDITIONNAL_DATA_TYPE, CARD_ITEM_TYPE } from "./enum";
import { IDiscountSchedule } from "./idiscountSchedule";
import { ICategoryLink, IDish, IMenu } from "../entities";


export interface AdditionnalItem {
	name: string;
	ref?: IDish | string;
	additionnalPrice?: number;
}

export interface AdditionnalData {
	name: string;
	description?: string,
	type: ADDITIONNAL_DATA_TYPE,
	items: AdditionnalItem[],
}

/**
 * Représente la base de l'ensemble des items dans le panier
 */
export interface BaseItem {
	// Définis si l'élément de la carte est archivé ou non.
	archived: boolean;
	// Disponibilité du plat
	available: boolean;
	// Horraire des réduction
	discountSchedule: string | IDiscountSchedule[];
	// Stock du plat
	stock?: number;
	// Quantité du plat
	quantity?: number;
	// Identifiant de la carte sur laquelle l'item est présent
	//idCarte: string;
	// Nom du plat
	name: string;
	// Description de l'item
	description?: string;
	// Prix Hors taxe
	priceHT: number;
	// Prix tout taxes comprise
	priceTTC: number;
	// Catégorie du plats
	category: string | ICategoryLink;
	// Image principal du plat
	picturePrincipal: string;
	// Gallerie de photos du plat
	gallerie?: string[];
	// Identifiant du commercant
	trader: string;
	// Additionnal data about how to compose the item
	additionnalData?: AdditionnalData[]
	// Metadata: Add additionnal data about anything on the item
	metadata?: object;
	// Date of creation
	createdAt?: Date;
	// Date of update
	updatedAt?: Date;
	// Shape to be display to user
	displayShape: DISPLAY_SHAPE_ITEM;
}

export enum DISPLAY_SHAPE_ITEM {
	Square = "Square",
    Large = "Large",
}

export interface CardItem {
	dishes?: IDish | string,
	menus?: IMenu | string,
	type: CARD_ITEM_TYPE,
}
