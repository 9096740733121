import _ from "lodash";
import { AppColorPersonnalization } from "..";

const IMG_CONSTANTES = {
	DEFAULT_IMG: 'https://makitweb.com/demo/broken_image/images/noimage.png',
}

/**
 * Template d'impression d'un QRcode
 */
let template_HTML_Print = _.template(`<!doctype html>
<html lang="en" style="
height: 100vh;
">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">

    <!-- Bootstrap CSS -->
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl" crossorigin="anonymous">

    <title>Scannez-moi :)</title>
    <style>
        @page { margin: 1cm /* or 0, if you want */ }
    </style>
  </head>
  <body style="height: 100vh;" class="container-sm" >
    <div style="
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10vh 10vw;
    ">
        <div>
			<% if (tagline) { %>
				<h3 style="
					text-align: justify;
				"><%= tagline %></h2>
			<% }; %>
        </div>
        <div style="
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            justify-content: center;
        ">            
            <h1 style="
                margin: 6vh 4vw;
                font-size: 3.5rem;
                font-weight: bold;
            ">Scannez-moi</h1>
            <div style="
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            ">
                <!-- <img style="
                    width: 100%;
                    max-width: 170px;
                    margin-right: 4vw;
                " src="/arrow.png" alt="fleche" /> -->
                <img style="
                        width: 100%;
                        max-width: 300px;
                    " src="<%= qrcode %>" alt="qrcode">
                <!-- <img style="
                    width: 100%;
                    max-width: 170px;
                    margin-left: 4vw;
                    transform: scaleX(-1);
                " src="/arrow.png" alt="fleche" /> -->
            </div>
        </div>
        <h1>Bonne dégustation !</h1>
    </div>

    <!-- Optional JavaScript; choose one of the two! -->

    <!-- Option 1: Bootstrap Bundle with Popper -->
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js" integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0" crossorigin="anonymous"></script>

    <!-- Option 2: Separate Popper and Bootstrap JS -->
    <!--
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.6.0/dist/umd/popper.min.js" integrity="sha384-KsvD1yqQ1/1+IA7gi3P0tyJcT3vR+NdBTt13hSJ2lnve8agRGXTTyNaBYmCR/Nwi" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.min.js" integrity="sha384-nsg8ua9HAw1y0W1btsyWgBklPnCUAFLuTMS2G72MMONqmOymq585AcH49TLBQObG" crossorigin="anonymous"></script>
    -->
  </body>
</html>`);

const TVA_VALUES: number[] = [
	0,
	2.1,
	5.5,
	10,
	20,
]

export const InitialAppColorization: AppColorPersonnalization = {

	/**
	 * 
	 */
	PrincipalColor: {
        hex: "#f47150",
        hsl: {h: 12.073170731707314, s: 0.8817204301075271, l: 0.6352941176470588, a: 1},
        rgb: {r: 244, g: 113, b: 80, a: 1},
    },

	/**
	 * Couleur secondaire
	 */
	SecondaryColor:  {
        hex: "#f47150",
        hsl: {h: 12.073170731707314, s: 0.8817204301075271, l: 0.6352941176470588, a: 1},
        rgb: {r: 244, g: 113, b: 80, a: 1},
    },

	/**
	 * Couleur du text
	 */
	TextColor: {
        hex: "#f47150",
        hsl: {h: 12.073170731707314, s: 0.8817204301075271, l: 0.6352941176470588, a: 1},
        rgb: {r: 244, g: 113, b: 80, a: 1},
    },

	/**
	 * Dégradé de couleur
	 */
	ColorGradient: {
		Stage1:  {
            hex: "#eaac7e",
            hsl: {h: 12.073170731707314, s: 0.8817204301075271, l: 0.6352941176470588, a: 1},
            rgb: {r: 244, g: 113, b: 80, a: 1},
        },
		Stage2:  {
            hex: "#f47150",
            hsl: {h: 12.073170731707314, s: 0.8817204301075271, l: 0.6352941176470588, a: 1},
            rgb: {r: 244, g: 113, b: 80, a: 1},
        },
	}
}

/**
 * Storage key to know if the pop up for days until has been displayed
 */
export const DAYS_UNTIL_TRIAL_END_POP_UP = "DAYS_UNTIL_TRIAL_END_POP_UP";

export const DAYLY_POP_UP_HAS_BEEN_SHOWED = "DAYLY_POP_UP_HAS_BEEN_SHOWED";

export {
	IMG_CONSTANTES,
	template_HTML_Print,
    TVA_VALUES
}