import { Route } from "react-router-dom";
import AuthRouteWrapper from "../wrappers/AuthRouteWrapper";
import React, { lazy } from "react";
import { Dashboard } from "../../pages";

const v1Home = lazy(() => import('../../pages/Home'));
const BillingSubscription = lazy(() => import('../../pages/BillingSubscription'));
const DigitalCardManagement = lazy(() => import('../../pages/DigitalCard/DigitalCardManagement'));
const CreateAdvancedMenu = lazy(() => import('../../pages/CreateAdvancedMenu'));
const CreateAdvancedDish = lazy(() => import('../../pages/CreateAdvancedDish'));
const CreateDish = lazy(() => import('../../pages/CreateDish'));

const myHome = lazy(() => import('../../pages/Panel/MyHome/Layout'));
const myApp = lazy(() => import('../../pages/Panel/MyApp/Layout'));
const myCard = lazy(() => import('../../pages/Panel/MyCard/Layout'));
const mySuscribe = lazy(() => import('../../pages/Panel/MySuscribe/Layout'));
const myData = lazy(() => import('../../pages/Panel/MyData/Layout'));
const myContact = lazy(() => import('../../pages/Panel/MyContact/Layout'));
const myQrcode = lazy(() => import('../../pages/Panel/MyQrcode/Layout'));

export function AuthRoutes() {

	return (
		<AuthRouteWrapper>
			<Route exact path="/dashboard" component={Dashboard} />
			<Route exact path="/createdish" component={CreateDish} />
			<Route exact path="/createadvanceddish" component={CreateAdvancedDish} />
			<Route exact path="/createmenu" component={CreateAdvancedMenu} />
			<Route exact path="/v1/home" component={v1Home} />

			<Route exact path="/home" component={myHome} />
			<Route exact path="/billing" component={BillingSubscription} />
			<Route exact path="/card/digital/manage" component={DigitalCardManagement} />
			<Route exact path="/home/myapp" component={myApp} />
			<Route exact path="/home/mycard" component={myCard} />
			<Route exact path="/home/myqrcode" component={myQrcode} />
			<Route exact path="/home/mydata" component={myData} />
			<Route exact path="/home/mysuscribe" component={mySuscribe} />
			<Route exact path="/home/help" component={myContact} />
			<Route exact path="/" component={myHome} />
			
		</AuthRouteWrapper>
	)
}