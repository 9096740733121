import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { CssBaseline } from "@material-ui/core";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { AppState, UserState } from "../../types";
import { YumButton } from "../../Lib/UI/YumButton";


type CheckoutBillingButtonProps = {
	userData: UserState;
	productId: string;
};

const CheckoutBillingButton: React.FC<CheckoutBillingButtonProps> = ({ userData, productId }: CheckoutBillingButtonProps) => {
	//const classes = useStyles();
	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
	//const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		//console.log("Product id", productId);
	});

	function StripeButtonInYumScan(props: any) {
		const stripe = useStripe();
		const elements = useElements();

		const handleClick = (event: React.MouseEvent) => {

			// Block native form submission.
			event.preventDefault();

			if (!stripe || !elements) {
				// Stripe.js has not loaded yet. Make sure to disable
				// form submission until Stripe.js has loaded.
				return;
			}

			/*APIManager.getBillingService().BillingSuscriptionProduct(productId).then((session) => {
				stripe.redirectToCheckout({
					sessionId: session.sessionId
				}).then((handleResult) => {
					// TODO LOG Error ! + mettre message avec code d'erreur
					enqueueSnackbar('Une erreur inconnue est survenue veuillez contacter le service client', { variant: "error" });
				});
			}).catch((err) => {
				// TODO LOG Error ! + mettre message avec code d'erreur
				enqueueSnackbar('Une erreur inconnue est survenue veuillez ré-essayez ou contacter le service client si le problème persiste', { variant: "error" });
			});*/

		}
		return (
			<YumButton onClick={handleClick}>
				Souscrire a l'abonnement
			</YumButton>);
	}

	return (
		<Fragment>
			<CssBaseline />
			<Elements stripe={stripePromise}>
				<StripeButtonInYumScan />
			</Elements>
		</Fragment>
	);
}

export default connect(
	(state: AppState) => ({
		userData: state.user
	}),
	{
	}
)(CheckoutBillingButton);
