import React, { useEffect } from "react";
import Alert from '@material-ui/lab/Alert';

import {
	Button,
	CssBaseline,
	Link,
	Typography,
	FormControlLabel,
	FormGroup,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	InputAdornment,
	Chip,
	Snackbar,
} from "@material-ui/core";
import useStyles from "./styles";
import { IOSSwitch } from "../IosSwitch/IosSwitch";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import {
	IEditableIngredient, UserState, IDish, AppState, ComposantMode, ICategory, ICategoryLink, UserInfos, IIngredient, DISPLAY_SHAPE_ITEM
} from "../../types";
import { APIManager } from "../../services";
import { DishServices } from "../../api";
import { connect } from "react-redux";
import clsx from "clsx";
import { CategoryServices } from "../../api/services/CategoryServices";
import { IMG_CONSTANTES } from "../../types/constantes/constantes";
import UploadImgZone from "../UploadImgZone";

interface Props {
	userdata: UserState,
	mode: ComposantMode,
	OnClose: () => void,
	OnOpen: () => void,
	idCurrentItem?: string | IDish,
}

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="/">
				SCOOKS
        </Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

//const categoryDishes = ['Accompagnement', 'Boissons', 'Plat', 'Entrée', 'Dessert'];

const Layout: React.FC<Props> = ({ userdata, mode, OnClose, OnOpen, idCurrentItem }: Props) => {


	//const [idCurrentItem, setIdCurrentItem] = React.useState<string | undefined | IDish>(undefined);

	const classes = useStyles();
	//	const inputRef = React.useRef<HTMLInputElement>(null);

	// Images
	const [
		//file
		, setFile] = React.useState<File | null>(null);
	const [gallery, setGallery] = React.useState<File[]>([]);

	// Formulaire
	const [isAvailable, setAvailable] = React.useState(true);
	const [category, setCategory] = React.useState<string>("");
	const [priceHT, setPrice] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [ingredients, setIngredient] = React.useState<IEditableIngredient[]>([]);
	const [name, setName] = React.useState('');
	const [ingredientSearch, setIngredientSearch] = React.useState('');
	const [categoryDishes, setCategoryDishes] = React.useState<ICategory[]>([]);
	//	const [fnc, setFnc] = React.useState<Function | undefined>(undefined);

	// Gestion des evenements du formulaire
	const [isError, setError] = React.useState<boolean>(false);
	const [isSucces, setSucces] = React.useState<boolean>(false);

	const handleDelete = (event: any, key: number) => {
		//console.log(event);
		console.info('You clicked the delete icon.');
		let newIngredient = ingredients.filter((val: IEditableIngredient, keyTab: number) => {
			if (key === keyTab) {
				return false;
			}
			return true;
		});
		setIngredient([...newIngredient]);
	};

	const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
		setCategory(event.target.value as string);
	};

	const handleChangeIngredientSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIngredientSearch(event.target.value as string);
	};

	/* 	const handleChangeEditableIngredient = (event: React.ChangeEvent<HTMLInputElement>, data: boolean) => {
			console.log("is editable !");
			let indexArray = event.target.name;
			let realIndex: number = parseInt(indexArray, 10);
			if (isNaN(realIndex) || realIndex > ingredients.length) {
				throw Error("l'index du tableau n'existe pas ou est mal formaté !");
			}
			(ingredients[realIndex] as IEditableIngredient).editable = data;
			setIngredient([...ingredients]);
		} */

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value as string);
	};

	const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPrice(event.target.value as string);
	};

	const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDescription(event.target.value as string);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			//console.log(ingredientSearch);
			let newIngredient: IEditableIngredient = {
				ingredient: {
					name: ingredientSearch,
					category: "Unknow",
				},
				editable: false,
			}
			setIngredient([...ingredients, newIngredient] as IEditableIngredient[]);
			setIngredientSearch('');
		}
	}

	/* 	const handleAddSchedule = (item: IDiscountSchedule) => {
			setDiscount([...discount, item])
		} */

	const handleSubmit = (event: any) => {
		//		let api: CardServices = APIManager.getCardServices();
		let apiDish: DishServices = APIManager.getDishServices();
		let userLogged = userdata.userdata;
		setError(false);
		setSucces(false);

		// Mode Reading is up
		if (mode === ComposantMode.Read) {
			setSucces(true);
			OnClose();
			return;
		}
		let dish: IDish = {
			name: name,
			description: description,
			priceHT: parseInt(priceHT, 10),
			priceTTC: parseInt(priceHT, 10),
			category: category as string,
			picturePrincipal: mode !== ComposantMode.Update ? IMG_CONSTANTES.DEFAULT_IMG : (idCurrentItem as IDish).picturePrincipal,
			trader: (userLogged as UserInfos).id,
			ingredients: (ingredients as IEditableIngredient[]),
			available: isAvailable,
			discountSchedule: [],
			archived: false,
			createdAt: new Date(),
			updatedAt: new Date(),
			displayShape: DISPLAY_SHAPE_ITEM.Square,
		};
		(mode === ComposantMode.Update ?
			APIManager.getDishServices().uploadDishSchedule((idCurrentItem as IDish)._id as string, dish) :
			APIManager.getCardServices().createDishSchedule(dish))
			.then((dishNewlyCreated: IDish) => {
				//console.log(dishNewlyCreated);
				let idCreated = dishNewlyCreated._id;

				if (!dishNewlyCreated || !idCreated) {
					console.log("Le plat a été créer ou mis à jour mais un probleme est apparue.");
					return;
				}
				console.log("Le plat a été créer");
				//console.log("ID du plat a mettre a jour : " + idCreated);
				//console.log(gallery);
				if (gallery.length !== 0) {
					let formData = new FormData();

					formData.append('files', gallery[0]);
					apiDish.UploadImg(idCreated, formData).then((r: boolean) => {
						console.log("resultat upload image");
						//console.log(r);
						setSucces(true);
						OnClose();
					});
					return;
				} else {
					setSucces(true);
					OnClose();
				}
			}).catch((val: any) => {
				console.log("Composant value fail:");
				//console.log(val);
				setError(true);
			});

	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setAvailable(event.target.checked);
	}

	function resetForm() {
		setAvailable(true);
		setCategory("");
		setPrice('');
		setDescription('');
		setIngredient([]);
		setName('');
		setIngredientSearch('');

		// Gestion des evenements du formulaire
		setError(false);
		setSucces(false);
	}

	useEffect(() => {
		let api: CategoryServices = APIManager.getCategoryServices();


		if (categoryDishes.length > 0) {
			return;
		}

		//console.log(mode);
		api.GetAllCategories().then((categories: ICategory[]) => {
			setCategoryDishes([...categoryDishes, ...categories]);
			let categoryChoosen: ICategory | undefined = undefined;
			// MODE Reading
			if (idCurrentItem && mode === ComposantMode.Read) {
				let api: DishServices = APIManager.getDishServices();

				api.GetDishById(idCurrentItem as string).then((dish) => {
					let idcat = ((dish.category as ICategoryLink)._id as string);
					categoryChoosen = categoryDishes.find(x => x._id as string === idcat);
					if (categoryChoosen) {
						setCategory(categoryChoosen._id as string);
					}

					// Set data from the dish
					setAvailable(dish.available);
					setPrice(dish.priceHT.toString());
					setDescription(dish.description ? dish.description : description);
					setIngredient(dish.ingredients);
					setName(dish.name);
				}).catch((err) => {
					console.log(err);
				});
				return;
			}
			// MODE Update
			if (idCurrentItem && mode === ComposantMode.Update) {
				let dish = idCurrentItem as IDish;
				//console.log(idCurrentItem);
				// Retrieve Category of dish
				let idcat = dish.category ? ((dish.category as ICategoryLink)._id as string) : undefined;
				categoryChoosen = categories.find(x => x._id as string === idcat);
				if (categoryChoosen) {
					setCategory(categoryChoosen._id as string);
				}

				// Set all data from dish
				setAvailable(dish.available);
				setPrice(dish.priceHT.toString());
				setDescription(dish.description ? dish.description : description);
				setName(dish.name);

				// Initialise les ingrédiens du plat
				if (dish.ingredients.length > 0) {
					let tabeditingredient = dish.ingredients.map((item) => {
						return {
							editable: item.editable,
							ingredient: {
								name: (item.ingredient as any)[0].name,
								category: (item.ingredient as any)[0].category,
							}
						} as IEditableIngredient;
					});
					//console.log(tabeditingredient);
					setIngredient([...tabeditingredient]);
				}
				//setFnc(APIManager.getDishServices().uploadDishSchedule);
				return;
			}
			//setFnc(APIManager.getCardServices().createDishSchedule);
		}).catch((err) => {
			console.log("Erreur chargement catgéorie!");
			console.log(err);
		})

	}, [categoryDishes, description, idCurrentItem, mode])

	return (
		<React.Fragment>
			<CssBaseline />
			<form onSubmit={handleSubmit} className={classes.root}>
				<div className={classes.layout}>
					<Typography variant="h3" style={{ textAlign: 'center' }}>Créer votre plat</Typography>
					<div className={classes.inputs}>
						<UploadImgZone minHeight={350}
							shape={DISPLAY_SHAPE_ITEM.Square}
							minWidth={350}
							onFileChange={(fileEv) => setFile(fileEv)}
							onGalleryChange={(files) => setGallery(files)}
							titleGallery="Images secondaires"
							titlePrincipal="Image principale" />
					</div>
					<Typography variant="h6">Informations concernant le plats</Typography>
					<div className={classes.inputs}>
						<FormGroup className={classes.firstGroup}>
							<TextField className={classes.firstGroupItem}
								id="outlined-basic"
								label="Intitulé du plat"
								variant="outlined"
								value={name}
								onChange={handleChangeName}
								InputProps={{
									className: classes.inputCustom
								}}
								disabled={mode === ComposantMode.Read}
							/>
							<FormControl className={classes.firstGroupItem} variant="outlined">
								<InputLabel id="demo-simple-select-outlined-label">Catégorie</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={category}
									onChange={handleChangeCategory}
									label="catégorie"
									disabled={mode === ComposantMode.Read}
								>
									<MenuItem key={-1} value="">Aucun selection</MenuItem>
									{categoryDishes.map((category: ICategory, index: number) => {
										return (
											<MenuItem key={index} value={category._id as string}>{category.name}</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<FormControl className={classes.firstGroupItem} variant="outlined">
								<InputLabel htmlFor="outlined-adornment-amount">Prix TTC</InputLabel>
								<OutlinedInput
									id="outlined-adornment-amount"
									value={priceHT}
									onChange={handleChangePrice}
									startAdornment={<InputAdornment position="start">€</InputAdornment>}
									labelWidth={40}
									disabled={mode === ComposantMode.Read}
								/>
							</FormControl>
						</FormGroup>
					</div>
					<div className={classes.inputs + "" + classes.firstGroupItem}>
						<FormGroup>
							<TextField
								fullWidth
								id="outlined-textarea"
								value={description}
								onChange={handleChangeDescription}
								label="Description"
								placeholder="Description du plat "
								multiline
								variant="outlined"
								disabled={mode === ComposantMode.Read}
							/>
							{mode !== ComposantMode.Read &&
								(<TextField className={classes.inputs}
									value={ingredientSearch}
									onKeyDown={handleKeyDown}
									onChange={handleChangeIngredientSearch}
									placeholder="Tapez le nom de votre ingrédient puis entrée."
									fullWidth id="outlined-basic" label="Ingrédients" variant="outlined" />)}
						</FormGroup>
					</div>
					<div className={classes.chipsGroup + " " + classes.inputs}>
						{ingredients.map((ingredient: IEditableIngredient, index: number) => {
							return (
								<Chip
									key={index}
									label={(ingredient.ingredient as IIngredient).name}
									clickable
									color="secondary"
									onDelete={event => handleDelete(event, index)}
									deleteIcon={<HighlightOffRoundedIcon />}
									disabled={mode === ComposantMode.Read}
								/>
							);
						})}
					</div>
					<div className={clsx(classes.inputs)}>
						<FormGroup className={classes.lastrow}>
							<FormControlLabel
								control={<IOSSwitch checked={isAvailable} onChange={handleChange} name="checkedB" />}
								label="Disponibilité"
								labelPlacement="start"
								disabled={mode === ComposantMode.Read}
							/>
							<Button variant="contained" color="secondary" onClick={handleSubmit}>
								{mode === ComposantMode.Read ? "Fermer" : mode === ComposantMode.Create ? " Sauvegarder" : "Mettre à jour"}
							</Button>
						</FormGroup>
					</div>
				</div>
				<Copyright />
			</form>
			<Snackbar open={isSucces} autoHideDuration={6000} onClose={resetForm}>
				<Alert onClose={resetForm} severity={isError ? "error" : (isSucces ? "success" : "success")}>
					{`le plat ${name} a été crée avec succès`}
				</Alert>
			</Snackbar>
			<Snackbar open={isError} autoHideDuration={6000} onClose={resetForm}>
				<Alert onClose={resetForm} severity="error">
					{`le plat ${name} n'a pas pu être crée. `}
				</Alert>
			</Snackbar>
		</React.Fragment>
	);
}

export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}),
	{}
)(Layout);

//export default Layout;
