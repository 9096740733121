import {
	AAuthApi,
	RESTVerb,
	IQrcode,
	//APIError,
	//IPdfCard,
} from "../../types";
// import { EnumUtils } from "../../utils";
import httpStatus from "http-status";

export class QrcodeServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = serverURL;
	}

	/**
	 * Récupère l'ensemble des qrcodes du commercant connecté.
	 */
	public GetAllQrCode(): Promise<IQrcode[]> {
		//console.log("token " + this.token);
		return this.JsonAuthRequest(RESTVerb.GET, `qrcode/`).then(async (res: Response) => {
			let result = await res.json();

			//console.log(result);
			//console.log(res.status);
			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as IQrcode[];
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble des qrcodes du commercant connecté.
	 */
	public UpdateStatus(idQrcode: string, statut: boolean): Promise<IQrcode> {
		//console.log("token " + this.token);
		return this.JsonAuthRequest(RESTVerb.PATCH, `qrcode/activate/${idQrcode}`, { activate: statut }).then(async (res: Response) => {
			if (res.status !== httpStatus.OK) {
				throw new Error(res.status.toString());
			}
			let result = await res.json();

			//console.log(result);
			return result as IQrcode;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met a jour un qrcode avec une carte digital.
	 */
	public UpdateDigitalCard(idQrcode: string, idCard: string): Promise<IQrcode> {
		//console.log("token " + this.token);
		return this.JsonAuthRequest(RESTVerb.PATCH, `qrcode/digitalcard/${idQrcode}/card/${idCard}`).then(async (res: Response) => {
			if (res.status !== httpStatus.OK) {
				throw new Error(res.status.toString());
			}
			let result = await res.json();

			//console.log(result);
			return result as IQrcode;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble des qrcodes du commercant connecté.
	 */
	public CreateQrCode(qrcode: IQrcode): Promise<IQrcode> {
		//console.log("token " + this.token);
		return this.JsonAuthRequest(RESTVerb.POST, `qrcode/create`, qrcode).then(async (res: Response) => {
			if (res.status !== httpStatus.CREATED) {
				throw new Error(`${res.status}`);
			}
			let result = await res.json();

			return result as IQrcode;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble des qrcodes du commercant connecté.
	 */
	public delete(idQrcode: string): Promise<boolean> {
		//console.log("token " + this.token);
		return this.JsonAuthRequest(RESTVerb.DELETE, `qrcode/byid/${idQrcode}`).then(async (res: Response) => {

			//console.log(res.status);
			if (res.status !== httpStatus.OK) {
				return false;
			}
			return true;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble de la carte pour un utilisateur
	 * @param userid
	 */
	public GetQrCodeById(idQrcode: string): Promise<IQrcode> {
		//console.log("token" + this.token);
		return this.JsonAuthRequest(RESTVerb.GET, `qrcode/id/${idQrcode}`).then(async (res: Response) => {
			let result = await res.json();

			//console.log(result);
			//console.log(res.status);
			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as IQrcode;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble de la carte pour un utilisateur
	 * @param userid
	 */
	public GetQrCodePdfById(idQrcode: string): Promise<string | undefined> {
		//console.log("token" + this.token);
		return this.JsonAuthRequest(RESTVerb.GET, `qrcode/pdfpath/${idQrcode}`).then(async (res: Response) => {
			
			if (res.status === httpStatus.OK) {
				let result = await res.json();
				return (result as { pdfpath: string }).pdfpath;
			}
			if (res.status === httpStatus.NOT_FOUND) {
				return undefined;
			}
			throw new Error(res.status.toString());
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Récupère l'ensemble de la carte pour un utilisateur
	 * @param userid
	 */
	public QrcodeIsScanned(idQrcode: string): Promise<IQrcode> {
		//console.log("token" + this.token);
		return this.JsonAuthRequest(RESTVerb.GET, `qrcode/id/${idQrcode}`).then(async (res: Response) => {
			let result = await res.json();

			//console.log(result);
			//console.log(res.status);
			if (!result || res.status !== httpStatus.OK) {
				throw new Error("Impossible de charger le contenu");
			}
			return result as IQrcode;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Modification du nom du qrcode 
	 * @param idQrcode 
	 * @param newQrcodeName 
	 * @returns 
	 */

	public changeQrcodeName(idQrcode: string, newQrcodeName: string): Promise<IQrcode | undefined> {
		return this.JsonAuthRequest(RESTVerb.PATCH, `qrcode/update/name/${idQrcode}`, { name: newQrcodeName }).then(async (res: Response) => {
			if (res.status === httpStatus.OK) {
				let result = await res.json();
				return result;
			}
			else if (res.status === httpStatus.CONFLICT) {
				return undefined;
			}
			throw new Error(`${res.status}`);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

}
