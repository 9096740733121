import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

import dotenv from "dotenv";
import "./configs";

import "./index.css";
import { APIManager } from "./services";

import App from "./App";
import * as serviceWorker from './serviceWorker';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { StorageServiceManager } from "./services/storageManager";

dotenv.config();

APIManager.init();
StorageServiceManager.init();
dotenv.config();

ReactDOM.render(
	<Provider store={store}>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<App />
		</MuiPickersUtilsProvider>
	</Provider>,
	document.getElementById("root")
);

serviceWorker.register();