import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'initial',
		minHeight: '100%',
		paddingTop: "3vh"
	},
	title : {
		textTransform: "uppercase",
		margin: "15px 0",
		color: theme.palette.primary.main,
		fontWeight: "bold",
	}
}));

export default useStyles;
