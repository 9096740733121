import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	root: {
		width: 'initial',
		height: '100%',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 'initial'
		},
		backgroundColor: 'white',
		padding: '15px'
	},
	firstGroup: {
		display: "flex",
		flexDirection: "row",
		flex: 1,
		color: 'red',
	},
	AdvancedDiscounts: {
		flexDirection: "column",
	},
	firstGroupItem: {
		flexGrow: 1,
		paddingLeft: '5px',
		paddingRight: '5px',
	},
	chipsGroup: {
		border: '1px solid #d4d4d4',
		padding: '5px',
		borderRadius: '3px',
	},
	ingredientAdvanced: {
		display: 'flex',
		flexDirection: 'column',
	},
	inputCustom: {
		color: 'black',
	},
	inputs: {
		marginTop: '1%',
		marginBottom: '1%',
	},
	lastrow: {
		display: 'flex',
		flexDirection: "row",
		width: '100%',
		justifyContent: 'space-between',
	},
	layout: {
		width: 'auto',
		height: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 1200,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		flex: 1,
		flexDirection: 'column',
	},
	importantSentence: {
		color: 'orange',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	image: {
		width: '100%',
		height: '285px',
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
	},


	imgBackgroundMinimumRequierement: {
		height: "100%",
		backgroundColor: 'rgb(0,0,0,0.4)',
		position: "absolute",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		fontSiez: "20px",
		fontWeight: "bold",
	},
}));

export default useStyles;
