import React from "react";

import {
    Grid,
	Typography,
} from "@material-ui/core";

import { DropzoneArea } from 'material-ui-dropzone';
import MaterialTable from "material-table";

interface Props {
}

const DetailsForm: React.FC<Props> = () => {

    const { useState } = React;

    const [columns, 
        //setColumns
    ] = useState([
        { id: 1, title: 'Name', field: 'name' },
        { id: 2, title: 'Category', field: 'category' },
        { id: 3, title: 'Editable', field: 'editable', lookup: { 1: 'True', 0: 'False' }, },
    ]);

    const [data, setData] = useState([
        { name: "delete this row", category: "delete this row", editable: "False", tableData: {id : -1} },
    ]);

	return (
        <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dish details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <DropzoneArea
            acceptedFiles={['image/*']}
            dropzoneText={"Drag and drop an image here or click"}
            onChange={(files) => console.log('Files:', files)}
            />
        </Grid>
        <Grid item xs={12}>
        <MaterialTable
            title="Add Ingrédients"
            columns={columns}
            data={data}
            editable={{
                onRowAdd: newData =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                    setData([...data, newData]);
                    resolve();
                    }, 1000)
                }),
                onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                    const dataUpdate = [...data];
                    const index = oldData?.tableData?.id ? oldData?.tableData?.id : -1;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    resolve();
                    }, 1000)
                }),
                onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                    setTimeout(() => {
                    const dataDelete = [...data];
                    const index = oldData?.tableData?.id ? oldData?.tableData?.id : -1;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                    resolve()
                    }, 1000)
                }),
            }}
            />
        </Grid>
      </Grid>
    </React.Fragment>
	);
}

export default DetailsForm;
