import { DishCategory } from "../types";

export class EnumUtils {

	public static DishCategoryToString(): string[] {
		let keys: string[] = [];

		for (var enumMember in DishCategory) {
			var isValueProperty = parseInt(enumMember, 10) >= 0;
			if (isValueProperty) {
				keys.push(DishCategory[enumMember] as string);
			}
		}
		return keys;
	}

	/**
	 * Renvoie les valeurs de l'enum sous forme de tableau de chaine de caractères.
	 * @param enumValue Enum a itérer
	 */
	public static EnumToStrArray<T>(enumValue: any): Array<T> {
		return Object.keys(enumValue)
			.filter((value: any) => {
				return isNaN(Number(value));
			})
			.map((key: string) => enumValue[key] as T);
	}

	public static getKeys(enumType: any): string[] {
		let enumValues = [];

		for (var log in enumType) {
			if (isNaN(Number(log))) {
				enumValues.push(log);
			}
		}
		return enumValues
	}
	/**
	 * Get an enum value from a string
	 * @param valueEnum Value researched
	 * @param enumValue Enum value type
	 */
	public static StringToEnumValue<E>(valueEnum: any, enumValue: any): E | null {

		let keyEnum = Object.keys(enumValue)
			.filter((value: any) => {
				return isNaN(Number(value));
			})
			.find((key: any) => enumValue[key] === valueEnum);

		if (!keyEnum) {
			return null;
		}
		return enumValue[keyEnum] as E;
	}
}
