import React, { useEffect } from "react";
import Alert from '@material-ui/lab/Alert';

import {
	Button,
	CssBaseline,
	Typography,
	FormControlLabel,
	FormGroup,
	TextField,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	Chip,
	Snackbar,
	Avatar,
	Popover,
} from "@material-ui/core";
import useStyles from "./styles";
import { IOSSwitch } from "../IosSwitch/IosSwitch";
import {
	UserState,
	IDish,
	AppState,
	TileData,
	IMenu,
	DISPLAY_SHAPE_ITEM
} from "../../types";
import { APIManager } from "../../services";
import { CardServices, DishServices, MenuServices } from "../../api";
import { connect } from "react-redux";
import clsx from "clsx";
import SearchBarDish from "../SearchBarDish/SearchBarDish";
import VerticalList from "../VerticalList";
import DropZone from "../DropZone";
import { IMG_CONSTANTES } from "../../types/constantes/constantes";
import { DropzoneArea } from "material-ui-dropzone";

interface Props {
	userdata: UserState,
	Advanced: boolean,
	OnClose: () => void,
	OnOpen: () => void,
}


const categoryDishes = ['Toutes', 'Accompagnement', 'Boissons', 'Plat', 'Entrée', 'Dessert'];
const url_server = process.env.REACT_APP_BASE_URL;


const Layout: React.FC<Props> = ({ userdata, Advanced, OnClose, OnOpen }: Props) => {

	const classes = useStyles();
	const inputRef = React.useRef<HTMLInputElement>(null);

	/* Formulaire */
	// Image formulaire
	const [file, setFile] = React.useState<File | null>(null);
	const [
		//gallery
		, setGallery] = React.useState<File[]>([]);
	// Inputs formulaire
	const [isAvailable, setAvailable] = React.useState(true);
	const [
		//category
		, setCategory] = React.useState<string>(categoryDishes[0]);
	const [price, setPrice] = React.useState('');
	const [description, setDescription] = React.useState('');
	const [name, setName] = React.useState('');

	// Liste des plats de l'utilisateur affiché
	const [tileDish, setTileDish] = React.useState<TileData[]>([]);
	// Liste de plats après avoir appliqué le filtre
	const [searchTile, setSearchTile] = React.useState<TileData[]>([]);
	// Liste de plats de l'utilisateur provenant du back
	const [dishes, setDishes] = React.useState<IDish[]>([]);
	// Liste de plats contenu dans le menu
	const [selectedTileDish, setSelectedTileDish] = React.useState<TileData[]>([]);

	// Gestion des evenements du formulaire
	const [isError, setError] = React.useState<boolean>(false);
	const [isSucces, setSucces] = React.useState<boolean>(false);

	const handleDelete = (event: any, key: string) => {
		//console.log(event);
		console.info('You clicked the delete icon.');
		let tileSelected = selectedTileDish.find(x => x.keyTile === key);

		if (tileSelected) {
			let updatedSelectedTile = selectedTileDish.filter(x => x.keyTile !== key);

			setSelectedTileDish([...updatedSelectedTile]);
			setTileDish([...tileDish, tileSelected]);
		}
	};

	const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPrice(event.target.value as string);
	};

	const handleSubmit = (event: any) => {
		let api: CardServices = APIManager.getCardServices();
		let apiMenu: MenuServices = APIManager.getMenuServices();
		let userLogged = userdata.userdata;
		setError(false);
		setSucces(false);

		if (!userLogged) {
			throw new Error("Vous n'êtes pas connecté.");
		}
		let menu: IMenu = {
			name: name,
			dishes: selectedTileDish.map(x => x.keyTile),
			trader: userLogged.id,
			priceHT: parseInt(price, 10),
			priceTTC: parseInt(price, 10),
			available: isAvailable,
			picturePrincipal: 'https://makitweb.com/demo/broken_image/images/noimage.png',
			discountSchedule: [],
			description: description,
			archived: false,
			category: "test",
			displayShape: DISPLAY_SHAPE_ITEM.Large,
		};

		//console.log(menu);
		api.createMenuSchedule(menu).then((menuNewlyCreated: IMenu) => {
			console.log("Composant value:");
			//console.log(menuNewlyCreated);
			console.log("dish added !");
			let idCreated = menuNewlyCreated._id;
			let formData = new FormData();

			if (!menuNewlyCreated || !idCreated) {
				console.log("Le menu a été créer mais un probleme est apparue.");
				return;
			}
			if (!file) {
				console.log("aucun fichier a uploader !");
				return;
			}
			console.log("Le plat a été créer");
			//console.log("ID du plat a mettre a jour : " + idCreated);
			//console.log(file);
			formData.append('files', file);

			apiMenu.UploadImg(idCreated, formData).then((r: boolean) => {
				console.log("resultat upload image");
				//console.log(r);
				setSucces(true);
				OnClose();
			}).catch((val: Error) => {
				console.log("Composant value fail:");
				//console.log(val);
				setError(true);
				OnClose();
			});

		}).catch((val: any) => {
			console.log("Composant value fail:");
			//console.log(val);
			setError(true);
		})

	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		switch (event.target.name) {
			case 'intitule':
				setName(event.target.value as string);
				break;
			case 'description':
				setDescription(event.target.value as string);
				break;
			case 'checkedB':
				setAvailable(event.target.checked);
				break;
			default:
				break;
		}
	}

	function resetForm() {
		setAvailable(true);
		setCategory(categoryDishes[0]);
		setPrice('');
		setDescription('');
		setName('');

		// Gestion des evenements du formulaire
		setError(false);
		setSucces(false);
	}

	const OnKeyDownEnter = (searchText: string) => {
		let searchTiles = tileDish.filter((tile) => {
			if (tile.title && tile.title.indexOf(searchText) !== -1) {
				return true;
			}
			return false;
		});
		setSearchTile([...searchTiles]);
	}

	const OnChangeSearch = (searchText: string) => {
		//console.log("Search :" + searchText);
		if (searchText === '') {
			setSearchTile([...tileDish]);
			return;
		}
		let searchTiles = tileDish.filter((tile) => {
			if (tile.title && tile.title.indexOf(searchText) !== -1) {
				return true;
			}
			return false;
		});
		setSearchTile([...searchTiles]);
	}

	/* 	const OnDropTest = (data: string) => {
			console.log(data);
		} */

	const OnDrop = (data: string) => {
		let droppedDish = tileDish.find((x) => {
			if (x.keyTile === data) {
				return true;
			}
			return false;
		});

		if (droppedDish) {
			let tab = tileDish.filter(x => x.keyTile !== data);
			setTileDish([...tab]);
			setSearchTile([...tab]);
			setSelectedTileDish([...selectedTileDish, droppedDish]);
		}
	}

	const OnCategorySelected = (category: string) => {
		if (category === 'Toutes') {
			setSearchTile([...tileDish]);
			return;
		}
		let searchResult = tileDish.filter((tile) => {
			let dish = dishes.find(x => x.category === category && x._id === tile.keyTile);

			if (dish) {
				return true;
			}
			return false;
		});
		setSearchTile([...searchResult]);
	}

	const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileEvent = e.target.files?.item(0);
		//let formData = new FormData();
		if (fileEvent) {
			setFile(fileEvent);
		}
	}

	useEffect(() => {
		let api: DishServices = APIManager.getDishServices();

		if (!userdata.userdata) {
			return;
		}
		api.GetAllDishByTrader(userdata.userdata?.id).then((dish) => {
			let tile = dish.map((x) => {
				let tile: TileData = {
					picture: x.picturePrincipal === IMG_CONSTANTES.DEFAULT_IMG ? x.picturePrincipal : `${url_server}/${x.picturePrincipal}`,
					keyTile: x._id as string,
					title: x.name,
					price: x.priceHT,
				};
				return tile;
			});
			setDishes([...dish]);
			setTileDish([...tile]);
			setSearchTile([...tile]);
		}).catch((err) => {
			console.log(err);
		});
	}, [userdata.userdata]);

	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

	/* 	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
			setAnchorEl(event.currentTarget);
		}; */

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<React.Fragment>
			<CssBaseline />
			<div onSubmit={handleSubmit} className={classes.root}>
				<div className={classes.layout}>
					<div className={classes.inputs}>
						<SearchBarDish
							OnCategorySelected={OnCategorySelected}
							OnChangeSearch={OnChangeSearch}
							OnKeyDownEnter={OnKeyDownEnter}
							dataSelect={categoryDishes.map(category => ({value: category, name: category}))}
							/* displayLogo={true} */
							label={"catégorie"} />
					</div>
					<VerticalList
						data={searchTile}
						onItemClick={() => { console.log("ouvrir modale edit plat") }}
						OnCreateClick={() => { }}
						displayAddButton={false}
					/>
					<div style={{ display: "flex", flexDirection: "row" }} className={classes.chipsGroup + " " + classes.inputs}>
						<div style={{ flex: 1 }}>
							<Typography style={{ width: "100%", textTransform: "uppercase" }} variant="h6" align="center">Contenu du menu</Typography>
							<div style={{ height: "250px" }}>
								<DropZone OnDrop={OnDrop}
									Sentence="Glissez les plats dans la zone pour les ajouter au menu">
									<div className={classes.chipsItem}>
										{selectedTileDish.map((dish) => {
											return (
												<Chip
													key={dish.keyTile}
													avatar={<Avatar><img src={dish.picture} alt={"img"}></img></Avatar>}
													label={dish.title}
													onDelete={(e) => { handleDelete(e, dish.keyTile); }}
													color="secondary"
												/>);
										})}
									</div>
								</DropZone>
							</div>
						</div>
						<div style={{ flex: 2, padding: "0 20px" }}>
							<Typography style={{ width: "100%", textTransform: "uppercase" }} variant="h6" align="center">Images du menu</Typography>
							<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
								{/* Zone imgae principale */}
								<div style={{ width: 250, height: 250, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
									<div style={{ width: "100%" }}>
										<div style={{ width: "100%", maxHeight: 200, position: "relative", zIndex: 0 }}>
											{!file && (<div className={classes.imgBackgroundMinimumRequierement}>
												<Typography variant="h5">350 x 350 Minimum </Typography>
											</div>)}
											<img style={{ width: "100%", height: "100%" }} alt="img" src={file ? URL.createObjectURL(file) : IMG_CONSTANTES.DEFAULT_IMG} />
										</div>
									</div>
									<Button color="secondary" onClick={() => {
										if (inputRef.current) {
											inputRef.current.click();
										}
									}} style={{ width: "100%", alignSelf: "flex-end" }} variant="contained" size="large">Telecharge votre image</Button>
								</div>
								{/* Zone galerries principale */}
								<div>
									<DropzoneArea
										acceptedFiles={['image/*']}
										dropzoneText={"Faites glisser les images de votre plat a afficher sur le details"}
										onChange={(files) => setGallery([...files])}
									/>
								</div>
							</div>
							<input style={{ display: "none" }} ref={inputRef} accept="image/*" onChange={handleFileSelected} type="file" />
						</div>
					</div>
					<div className={classes.inputs}>
						<FormGroup className={classes.firstGroup}>
							<TextField className={classes.firstGroupItem}
								id="intitule"
								label="Intitulé du menu"
								variant="outlined"
								name="intitule"
								value={name}
								onChange={handleChange}
							/>
							<TextField className={classes.firstGroupItem}
								id="description"
								label="Description du menu"
								variant="outlined"
								name="description"
								value={description}
								onChange={handleChange}
							/>
							<FormControl className={classes.firstGroupItem} variant="outlined">
								<InputLabel htmlFor="price-HT">Prix HT</InputLabel>
								<OutlinedInput
									id="price-HT"
									value={price}
									onChange={handleChangePrice}
									startAdornment={<InputAdornment position="end">€</InputAdornment>}
								/>
							</FormControl>
							<FormControl className={classes.firstGroupItem} variant="outlined">
								<InputLabel htmlFor="price-TTC">Prix TTC</InputLabel>
								<OutlinedInput
									id="price-TTC"
									value={price}
									onChange={handleChangePrice}
									startAdornment={<InputAdornment position="end">€</InputAdornment>}
								/>
							</FormControl>
						</FormGroup>
					</div>
					<div className={clsx(classes.inputs)}>
						<FormGroup className={classes.lastrow}>
							<FormControlLabel
								control={<IOSSwitch checked={isAvailable} onChange={handleChange} name="checkedB" />}
								label="Disponibilité"
								labelPlacement="start"
							/>
							<Button variant="contained" color="secondary" onClick={handleSubmit}>
								Sauvegarder
							</Button>
						</FormGroup>
					</div>
				</div>
			</div>
			<Snackbar open={isSucces} autoHideDuration={6000} onClose={resetForm}>
				<Alert onClose={resetForm} severity={isError ? "error" : (isSucces ? "success" : "success")}>
					{`le plat ${name} a été crée avec succès`}
				</Alert>
			</Snackbar>
			<Snackbar open={isError} autoHideDuration={6000} onClose={resetForm}>
				<Alert onClose={resetForm} severity="error">
					{`le plat ${name} n'a pas pu être crée. `}
				</Alert>
			</Snackbar>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography>I use Popover.</Typography>
			</Popover>
		</React.Fragment>
	);
}

export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}),
	{}
)(Layout);

//export default Layout;
