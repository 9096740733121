import React from "react";
import Layout from "./Layout";
import { BILLING_TYPE, IPackYum, UserInfos, UserType } from "../../types";
import { connect } from "react-redux";
import { LoginUser } from "../../redux/user";

interface Props {
	logUser: (user: UserInfos, type: UserType) => any;
	nextStep?: () => void;
	choice?:  { pack: IPackYum, billing_type: BILLING_TYPE }
}

const Register: React.FC<Props> = ({ logUser, nextStep, choice }: Props) => (
	<Layout logUser={logUser} nextStep={nextStep} choice={choice} />
);

export default connect(null, {
	logUser: LoginUser,
})(Register);
