import { AAuthApi, IMenu, RESTVerb } from "../../types";

import httpStatus from "http-status";

export class MenuServices extends AAuthApi {
	baseUrl: string;
	token?: string;

	constructor(serverURL: string) {
		super();
		this.baseUrl = `${serverURL}/menu`;
	}

	/**
	 * Upload image for a dish
	 * @param dish 
	 */
	public UploadImg(idMenu: string, file: FormData): Promise<boolean> {
		return fetch(`${this.baseUrl}/upload/${idMenu}`, {
			method: 'POST',
			headers: {
				"Authorization": `Token ${this.token}`
			},
			body: file,
		}).then((res) => {
			return (res.status === httpStatus.OK);
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	/**
	 * Met à jour le plat 
	 * @param dish 
	 */
	 public uploadMenuSchedule(idMenu: string, menu: IMenu): Promise<IMenu> {
		//console.log(JSON.stringify(dish));
		//console.log("token " + this.token);
		return this.JsonAuthRequest(RESTVerb.PUT, `${idMenu}`, menu).then(async (res: Response) => {

			if (res.status !== httpStatus.OK) {
				throw new Error("Le plat n'as pas été mis a jour");
			}
			let result = await res.json();

			//console.log(result);
			return result as IMenu;
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}
}
