import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'initial',
		minHeight: '100%',
		paddingTop: "3vh"
	},
	'@global': {
		ul: {
			margin: 0,
			padding: 0,
			listStyle: 'none',
		},
	},

	/** Nav section */
	navSection: {
		display: "flex",
		flexDirection: "row",
		marginBottom: "3vh"
	},

	cardHeader: {
		//backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
		backgroundColor: "black",
		color: "white",
		fontWeight: "bold",
	},
	cardPricing: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
		marginBottom: theme.spacing(2),
	},

}));

export default useStyles;
