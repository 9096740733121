import React from "react";
import Layout from "./Layout";
import { UserInfos, UserType } from "../../types";
import { connect } from "react-redux";
import { LoginUser } from "../../redux/user";

interface Props {
  logUser: (user: UserInfos, type: UserType) => any;
}

const Login: React.FC<Props> = ({ logUser }: Props) => (
  <Layout logUser={logUser} />
);

export default connect(null, {
  logUser: LoginUser,
})(Login);
