import React, { Fragment, useCallback, 
	//useEffect
 } from "react";
// import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import {
	CssBaseline,
	Button,
	//Dialog,
	Slider
} from "@material-ui/core";
//import { DropzoneArea } from "material-ui-dropzone";
//import { IMG_CONSTANTES } from "../../types/constantes/constantes";
//import Resizer from 'react-image-file-resizer';
//import { useSnackbar } from "notistack";
import Cropper from "react-easy-crop";
import { CanvasUtils } from "../../utils";

interface Props {
	file: File | null,
	cropSize?: number,
	onValidCropImage: (croppedImg: File | null) => any,
	onCancel: () => void,
}

const CropImage: React.FC<Props> = ({ file, cropSize, onValidCropImage, onCancel}: Props) => {
	const classes = useStyles();

	const [imageSrc, 
		//setImageSrc
	] = React.useState<File | null>(null)
	const [crop, setCrop] = React.useState<{ x: number, y: number }>({ x: 0, y: 0 })
	const [rotation, setRotation] = React.useState<number>(0)
	const [zoom, setZoom] = React.useState<number>(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
	//const [croppedImage, setCroppedImage] = React.useState(null)

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	const showCroppedImage = useCallback(async () => {
		if (!file || croppedAreaPixels === null) {
			return;
		}
		let croppedImage: File | null = null;
		
		try {
			let blob: any = await CanvasUtils.getCroppedImg(
				file,
				((croppedAreaPixels as unknown) as {
					x: number;
					y: number;
					width: number;
					height: number;
				}),
				rotation
			)
			croppedImage = CanvasUtils.blobToFile(blob, file.name);
			console.log("data of blob", blob);
			console.log('donee', croppedImage);
			console.log('donee2', file);
						//onClose();
		} catch (e) {
			console.error(e)
		} finally {
			//onClose();
			onValidCropImage(croppedImage);
		}

	}, [imageSrc, croppedAreaPixels, rotation])

	/* const onClose = useCallback(() => {
		setCroppedImage(null)
	}, []); */

	return (
		<Fragment>
			<CssBaseline />
			
			<div className={classes.cropContainer}>
				{file !== null && <Cropper
				image={URL.createObjectURL(file)}
				crop={crop}
				rotation={rotation}
				zoom={zoom}
				aspect={cropSize ?? 1 / 1}
				onCropChange={setCrop}
				onRotationChange={setRotation}
				onCropComplete={onCropComplete}
				onZoomChange={setZoom}
				/>}
			</div>
			<div className={classes.controls}>
				<div className={classes.sliderContainer}>
					<Typography
						variant="overline"
						classes={{ root: classes.sliderLabel }}
					>
						Zoom
					</Typography>
					<Slider
						value={zoom}
						min={1}
						max={3}
						step={0.1}
						aria-labelledby="Zoom"
						classes={{ root: classes.slider }}
						onChange={(e, zoom) => setZoom(zoom as number)}
					/>
				</div>
				<div className={classes.sliderContainer}>
					<Typography
						variant="overline"
						classes={{ root: classes.sliderLabel }}
					>
						Rotation
					</Typography>
					<Slider
						value={rotation}
						min={0}
						max={360}
						step={1}
						aria-labelledby="Rotation"
						classes={{ root: classes.slider }}
						onChange={(e, rotation) => setRotation(rotation as number)}
					/>
				</div>
				<div className={classes.sliderContainer}>
					<Button
						onClick={showCroppedImage}
						variant="contained"
						color="primary"
						classes={{ root: classes.cropButton }}
						>
						Valider
					</Button>
					<Button
						onClick={onCancel}
						variant="contained"
						color="secondary"
						classes={{ root: classes.cropButton }}
						>
						Annuler
					</Button>
				</div>
			</div>
		</Fragment>
	);
};

export default CropImage;
