import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { APIManager } from "../../services";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { AppState, IPackYum, UserState } from "../../types";
import { CssBaseline } from "@material-ui/core";
import { useSnackbar } from "notistack";
import dotenv from "dotenv";
import { Redirect } from "react-router-dom";
import { YumButton } from "../../Lib/UI/YumButton";

dotenv.config();

interface Props {
	userData: UserState;
	idTrader?: string;
	priceId?: string;
	pack: IPackYum
	text: string;
	color?: string;
}

const StripeCheckoutButton: React.FC<Props> = ({ priceId, idTrader, text, color, pack }: Props) => {

	const [isRedirectFree, setIsRedirectFree] = useState<boolean>(false);

	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);
	const { enqueueSnackbar } = useSnackbar();
	//const [reelIdTrader, ] = useState<string | undefined>(userData.userdata?.userinfos._id ? userData.userdata?.userinfos._id : idTrader);

	console.log("pack ! :", pack);

	function StripeLogicalCheckout(props: any) {
		//const classes = useStyles();
		const stripe = useStripe();
		const elements = useElements();
		let text = props.text;
		let idProduct = props.priceId;
		let propsIdTrader = props.idTrader;
		//let color = props.color;


		const handleClick = (event: React.MouseEvent) => {

			// Block native form submission.
			event.preventDefault();

			if (!stripe || !elements) {
				// Stripe.js has not loaded yet. Make sure to disable
				// form submission until Stripe.js has loaded.
				enqueueSnackbar("Une erreur de chargement est survenue, rechargez la page svp", {
					variant: "warning",
					anchorOrigin: {
						vertical: "top",
						horizontal: "right",
					}
				});
				return;
			}

			if (pack.metadata.isFree) {

				APIManager.getBillingService().BillingSuscriptionFreeProduct(idTrader as string, pack._id as string,  idProduct).then((result) => {
					setIsRedirectFree(true);
					/* enqueueSnackbar("test ca marche ", {
						variant: "warning",
						anchorOrigin: {
							vertical: "top",
							horizontal: "right",
						}
					}); */
				}).catch((err: Error) => {
					enqueueSnackbar(err.message, {
						variant: "warning",
						anchorOrigin: {
							vertical: "top",
							horizontal: "right",
						}
					});
					console.log(err);
				});

			} else {

				APIManager.getBillingService().BillingSuscriptionProduct(propsIdTrader, pack._id as string,  idProduct).then((session) => {
					stripe.redirectToCheckout({
						sessionId: session.sessionId
					}).then((handleResult) => {
						enqueueSnackbar("Une erreur est survenue, merci de contacter le service client si cela persiste.", {
							variant: "warning",
							anchorOrigin: {
								vertical: "top",
								horizontal: "right",
							}
						});
						// todo log front
						//console.log(handleResult.error.message);
					});
					//console.log("test !!", session);
				}).catch((err: Error) => {
					enqueueSnackbar(err.message, {
						variant: "warning",
						anchorOrigin: {
							vertical: "top",
							horizontal: "right",
						}
					});
					console.log(err);
				});
			}

		}

		return (
			<Fragment>
				<CssBaseline />
				<YumButton 
					onClick={handleClick}
					disabled={!stripe || !elements}
					>
					{text}
				</YumButton>
			</Fragment>
		);
	}

	return (
		<Elements stripe={stripePromise}>
			{isRedirectFree && <Redirect to='/statement/payementStatement?r=1' />}
			<StripeLogicalCheckout idTrader={idTrader} priceId={priceId} text={text} color={color} />
		</Elements>
	)
};

export default connect(
	(state: AppState) => ({
		userData: state.user
	}),
	{
	}
)(StripeCheckoutButton);
