import { UPDATE_ENTITIES } from './actionTypes';
import { ReducerData, Restaurant, Dish } from "../../../types"


export interface EntitiesState {
  restaurants?: Restaurant[]
  dishes?: Dish[]
  isLoading: boolean
  error?: string
}

// const mockedData: EntitiesState = {
//   restaurants: [{
//     Name: 'Pizza-Five',
//     IDRestaurant: 123,
//   }],
//   dishes: [
//     {
//       Name: 'Pizza Capricciosa',
//       Image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Pizza_capricciosa.jpg/240px-Pizza_capricciosa.jpg',
//       Price: 4.99,
//       IDDish: 1,
//       IDRestaurant: 123,
//       Ingredients: ['Viande de kebab', 'Fromage', 'Mozzarella', 'Sauce Tomate', 'Escalope de Dinde'],
//       Description: 'Sah quelle maginifique Pizza',
//     },
//     {
//       Name: 'Tomate, fromage, basilic',
//       Image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Strada%2C_Marylebone%2C_London_%283675997689%29.jpg/240px-Strada%2C_Marylebone%2C_London_%283675997689%29.jpg',
//       Price: 4.99,
//       IDDish: 2,
//       IDRestaurant: 123,
//       Ingredients: ['Viande de kebab', 'Fromage', 'Mozzarella', 'Sauce Tomate', 'Escalope de Dinde'],
//       Description: 'Sah quelle maginifique Pizza',
//     },
//     {
//       Name: 'Fromage, crevette, et pesto.',
//       Image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Pizzas_food_cheeses_shrimp_pesto.jpg/240px-Pizzas_food_cheeses_shrimp_pesto.jpg',
//       Price: 4.99,
//       IDDish: 3,
//       IDRestaurant: 123,
//       Ingredients: ['Viande de kebab', 'Fromage', 'Mozzarella', 'Sauce Tomate', 'Escalope de Dinde'],
//       Description: 'Sah quelle maginifique Pizza',
//     },
//     {
//       Name: 'Pizza quatre saisons',
//       Image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Pizza_Quattro_Stagioni.jpg/240px-Pizza_Quattro_Stagioni.jpg',
//       Price: 4.99,
//       IDDish: 4,
//       IDRestaurant: 123,
//       Ingredients: ['Viande de kebab', 'Fromage', 'Mozzarella', 'Sauce Tomate', 'Escalope de Dinde'],
//       Description: 'Sah quelle maginifique Pizza',
//     },
//     {
//       Name: 'Pizza au saumon fumé',
//       Image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c6/Seafood_pizza_with_salmon.jpg/240px-Seafood_pizza_with_salmon.jpg',
//       Price: 4.99,
//       IDDish: 5,
//       IDRestaurant: 123,
//       Ingredients: ['Viande de kebab', 'Fromage', 'Mozzarella', 'Sauce Tomate', 'Escalope de Dinde'],
//       Description: 'Sah quelle maginifique Pizza',
//     },
//     {
//       Name: 'Pizza aux fruit de mer.',
//       Image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Seafood_pizza_%281%29.jpg/240px-Seafood_pizza_%281%29.jpg',
//       Price: 4.99,
//       IDDish: 6,
//       IDRestaurant: 123,
//       Ingredients: ['Viande de kebab', 'Fromage', 'Mozzarella', 'Sauce Tomate', 'Escalope de Dinde'],
//       Description: 'Sah quelle maginifique Pizza',
//     },
//     {
//       Name: 'Pizza Sfincione',
//       Image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Sfincione_palermitano_2.jpg/240px-Sfincione_palermitano_2.jpg',
//       Price: 4.99,
//       IDDish: 7,
//       IDRestaurant: 123,
//       Ingredients: ['Viande de kebab', 'Fromage', 'Mozzarella', 'Sauce Tomate', 'Escalope de Dinde'],
//       Description: 'Sah quelle maginifique Pizza',
//     },
//     {
//       Name: 'Pizza hawaïenne',
//       Image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Pizza_Hawaii_Special_p%C3%A5_Pizzeria_Papillon_i_Sala_1343.jpg/240px-Pizza_Hawaii_Special_p%C3%A5_Pizzeria_Papillon_i_Sala_1343.jpg',
//       Price: 4.99,
//       IDDish: 8,
//       IDRestaurant: 123,
//       Ingredients: ['Viande de kebab', 'Fromage', 'Mozzarella', 'Sauce Tomate', 'Escalope de Dinde'],
//       Description: 'Sah quelle maginifique Pizza',
//     }],
//   error: undefined,
//   isLoading: false,
// };

const initialState: EntitiesState = {
  isLoading: false,
};

const entitiesReducer = (state: EntitiesState = initialState, action: ReducerData) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_ENTITIES:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default entitiesReducer;
