import React, { Fragment, useEffect } from "react";
import { Typography, Container, Grid, Breadcrumbs, Card, CardContent, CardActions, Snackbar, TextField, FormControlLabel, IconButton, Divider } from "@material-ui/core";
import useStyles from "./styles";
import { IQrcode, AppState, ApplicationDataState } from "../../../types";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { APIManager } from "../../../services";
import { Alert, AlertTitle } from "@material-ui/lab";
import { IOSSwitch } from "../../../components/IosSwitch/IosSwitch";
import QRCode from "easyqrcodejs";
import PrintElement from "../../../components/PrintElement/PrintElement";
import QrcodePrintable from "../../../components/QrcodePrintable/QrcodePrintable";
import { Document, Page, Text, View, PDFDownloadLink, StyleSheet, Image } from '@react-pdf/renderer';
import { useSnackbar } from "notistack";
import YumChangeInput from "../../../components/Lib/UI/YumChangeInput";
import { PutQrcode } from "../../../redux/reducers/applicationdata";
import { connect } from "react-redux";
import { YumButton } from "../../../Lib/UI/YumButton";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TipsManager from "../../../components/TipsManager";


interface Props {
	applicationdata: ApplicationDataState;
	PutQrcode? : (qrcode: IQrcode[]) => any;
	ExceedLimit?: boolean | undefined;
	onUpdate?: () => void;
}

interface NewQrCode {
	activate: boolean;
	name: string;
}

//const DEFAULT_IMG = "https://assets.fishersci.com/TFS-Assets/CCG/product-images/default.jpg-650.jpg";
//const DEFAULT_IMG = "www.yumscan.app";

const Layout: React.FC<Props> = ({ applicationdata, PutQrcode,ExceedLimit, onUpdate }: Props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [isSucces, setSucess] = React.useState(false);
	const [isError, setError] = React.useState(false);
	const [succesMsg, setSucessMsg] = React.useState<string>('Le pdf a été télécharger avec sucess');
	const [errorMsg, setErrorMsg] = React.useState<string>('Le téléchargement a rencontré une erreur, contacter le service client');

	const [qrcodes, setQrcodes] = React.useState<IQrcode[]>([]);
	//const [newQrcode, setNewQrcode] = React.useState<NewQrCode>({ activate: true, name: '' });

	// 	const inputRef = React.useRef<HTMLInputElement>(null);

	const handlingClosePopUp = () => {
		setSucess(false);
		setError(false);
		setSucessMsg('Le pdf a été télécharger avec sucess');
		setErrorMsg('Le téléchargement a rencontré une erreur, contacter le service client');
	}

	/* const createNewQrcode = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		let qrcodeAPI = APIManager.getQrCodeServices();
		let qrcodeToCreate = {
			...newQrcode
		}
		qrcodeAPI.CreateQrCode(qrcodeToCreate).then((qrcodeCreated) => {
			setQrcodes([...qrcodes, ...[qrcodeCreated]]);

			enqueueSnackbar(`Le Qrcode ${qrcodeToCreate.name} a été crée.`, { 
				variant: "success",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			//setSucess(true);
			//setSucessMsg('Le qrcode a été crée');
			if (onUpdate) {
				onUpdate();
			}
		}).catch((err) => {
			enqueueSnackbar(`Une erreur est survenue lors de la création du Qrcode, veuillez ré-essayer et contacter le service client via le formulaire si l'erreur persiste.`, { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			//setError(true);
			//setErrorMsg('Une erreur dans le chargement');
		});
	} */


	const handlingDeleteQrcode = (idQrcode: string) => {
		let qrcodeAPI = APIManager.getQrCodeServices();

		qrcodeAPI.delete(idQrcode).then((hasbeendeleted) => {
			let newQrcodes = [...qrcodes];
			if (hasbeendeleted) {
				newQrcodes = qrcodes.filter(qr => qr._id !== idQrcode);
			} else {
				throw new Error("Contactez le service client")
			}
			setQrcodes([...newQrcodes]);
			//setSucess(true);
			//setSucessMsg('Le qrcode a été supprimé');
			enqueueSnackbar(`Le Qrcode sélectionné a été supprimé.`, { 
				variant: "success",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			if (onUpdate) {
				onUpdate();
			}
		}).catch((err: Error) => {
			//setError(true);
			//setErrorMsg(`Le qrcode n'a pas pu être supprimé. ${err.message}`);
			enqueueSnackbar(`Une erreur est survenue lors de la suppréssion du Qrcode, veuillez ré-essayer et contacter le service client via le formulaire si l'erreur persiste.`, { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		});
	}

	const handlingChangeStatusQrCode = (event: React.ChangeEvent<{}>, checked: boolean, idQRcode: string) => {
		let qrcodeAPI = APIManager.getQrCodeServices();

		qrcodeAPI.UpdateStatus(idQRcode, checked).then((hasbeendeleted) => {
			let newQrcodes = [...qrcodes];
			if (hasbeendeleted) {
				newQrcodes.forEach((qrItem) => {
					if (qrItem._id === idQRcode) {
						qrItem.activate = checked;
					}
				});
			} else {
				throw new Error("Contactez le service client")
			}
			setQrcodes([...newQrcodes]);
			//setSucess(true);
			//setSucessMsg('Le qrcode a été mis à jour.');
			enqueueSnackbar(`Le Qrcode a été mis à jour.`, { 
				variant: "success",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			if (onUpdate) {
				onUpdate();
			}
		}).catch((err: Error) => {
			enqueueSnackbar(`Le Qrcode n'a pas pu être mis à jour. Si l'erreur persiste contactez le service client via le formulaire ou a l'adresse contact@yumscan.fr`, { 
				variant: "success",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			//setError(true);
			//setErrorMsg(`Le qrcode n'a pas pu être mis a jour. ${err.message}`);
		});
	}


	const styles = StyleSheet.create({
		phrase: {
			width: '100%',
			textAlign: 'center',
			marginTop: '160pt',
		},
		title: {
			width: '100%',
			textAlign: 'center',
			fontSize: '25pt',
			marginTop: '30pt',
		},
		qrcode: {
			width: '240pt',
			height: '240pt',
			marginLeft: '180pt',
			marginTop: '50pt',
			marginBottom: '50pt',
		},
		qrcodeSize1: {
			width: '100pt',
			height: '100pt',
			marginLeft: '100pt',
			marginTop: '20pt',
			marginBottom: '20pt',
			display: "flex",
			justifyContent: "center",
		},
		qrcodeSize2: {
			width: '150pt',
			height: '150pt',
			marginLeft: '100pt',
			marginTop: '20pt',
			marginBottom: '20pt',
		},
		qrcodeSize3: {
			width: '200pt',
			height: '200pt',
			marginLeft: '100pt',
			marginTop: '20pt',
			marginBottom: '20pt',
		},
		subtitle: {
			width: '100%',
			textAlign: 'center',
			fontSize: '12pt',
			marginBottom: '30pt',
		},
		subtitleBold: {
			fontWeight: 'bold',
		},
		footer: {
			width: '100%',
			textAlign: 'center',
			fontSize: '23pt',
		},
	});


	function MyDocument(props : {tagline?: string, codeRef: React.RefObject<HTMLDivElement>}) {

		const [qrcode, setqrcode] = React.useState<string|undefined>(undefined);  
		let canvas;

		useEffect(() => {
			if (!qrcode && props.codeRef.current && (canvas = props.codeRef.current.getElementsByTagName('canvas')).length > 0) {
				setqrcode((canvas[0] as HTMLCanvasElement).toDataURL("image/png"));
			}
		}, [qrcode, props.codeRef.current]);

		return (<Document>
		  <Page size="A4">
			<View>
			  <Text style={styles.phrase}>{props.tagline}</Text>
			</View>
			<View>
			  <Text style={styles.title}>SCANNEZ-MOI</Text>
			</View>
			<View>
				{qrcode && <Image style={styles.qrcode} src={qrcode}></Image>}
			</View>
			<View>
			  <Text style={styles.subtitle}>Scan le qr code à partir de <Text style={styles.subtitleBold}> l'appareil photo de ton smartphone </Text> ou directement sur <Text style={styles.subtitleBold}> www.yumscan.app</Text></Text>
			</View>
			<View>
			  <Text style={styles.footer}>Bonne dégustation</Text>
			</View>
		  </Page>
{/* 		  <Page size="A4">
		  	<View>
			  {qrcode && <Image style={styles.qrcodeSize1} src={qrcode}></Image>}
			  {qrcode && <Image style={styles.qrcodeSize1} src={qrcode}></Image>}
			  {qrcode && <Image style={styles.qrcodeSize1} src={qrcode}></Image>}
			</View>

			<View>
			  {qrcode && <Image style={styles.qrcodeSize2} src={qrcode}></Image>}
			  {qrcode && <Image style={styles.qrcodeSize2} src={qrcode}></Image>}
			  {qrcode && <Image style={styles.qrcodeSize2} src={qrcode}></Image>}
			</View>

			<View>
			  {qrcode && <Image style={styles.qrcodeSize3} src={qrcode}></Image>}
			  {qrcode && <Image style={styles.qrcodeSize3} src={qrcode}></Image>}
			</View>
		  </Page> */}
		</Document>)
	};
	

	function QRcodeItemComponent(props: any) {

		const qrcode: IQrcode = props.item;
		const code = React.useRef<HTMLDivElement>(null);

		const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
		const [isTagline, setIsTagline] = React.useState(false);
		const [tagline, setTagline] = React.useState<string | undefined>(undefined);
		const [qrcodeBase64, setQrcodeBase64] = React.useState<string | undefined>(undefined);
		const [isQrcodeSet, setIsQrcodeSet] = React.useState<boolean>(false);
		const [stateDocument, setStateDocument] = React.useState<string>("");

		const AskForAgreement = () => {
			setDialogIsOpen(true);
		  };
		
		const handleClose = () => {
			setDialogIsOpen(false);
		};

		const handleCloseTagline = () => {
			setIsTagline(false);
		};

		const deleteAfter = () => {
			handlingDeleteQrcode(qrcode._id as string);
			handleClose();
		}


		const GetUrlQrcode = () => {
			//const currentDNS = window.location.hostname;
			const currentDNS = process.env.REACT_APP_FRONT_CLIENT_URL as string
			//return currentDNS.indexOf("http") !== -1 ? currentDNS : `http://${currentDNS}/router?id=${qrcode._id}`;
			return `${currentDNS}/router?id=${qrcode._id}`;
		}

		function actionChangeQrcodeName(id: string, newQrcodeName: string) : Promise<boolean> {
			//let newStateQrcode = [...cards];
	
			return new Promise((resolve, reject) => {
				APIManager.getQrCodeServices().changeQrcodeName(id, newQrcodeName)
				.then((result) =>{ 
					if (result === undefined) {
						resolve(false);
					}
					else {
						//newStateQrcode[index] = result; 
						//setQrcode([...newStateQrcode]); 
						allQrCode();
						resolve(true);
					} })
				.catch((except) => { reject(except)});
			});
		}

		useEffect(() => {
			//const currentDNS = window.location.hostname;
			//console.log("current dns :" + currentDNS);
			//let dns = currentDNS.indexOf("http") !== -1 ? currentDNS : `http://${currentDNS}/router?id=${qrcode._id}`;
			//console.log("dns :" + dns);
			//console.log("logo adress", `${process.env.PUBLIC_URL}/logo.png`);
			if (code.current && !isQrcodeSet) {
				new QRCode(code.current, {
					text: GetUrlQrcode(),
					width: 300,
					height: 300,
					/*logo: "/logo_write.jpeg"*/
				});
				setIsQrcodeSet(true);
			}
		}, [code, qrcode._id, isQrcodeSet]);

		useEffect(() => {
			if (!qrcodeBase64 && qrcode._id) {
				setQrcodeBase64(GetUrlQrcode());				
			}
		}, [qrcode._id]);

		return (
			<React.Fragment>
				<Card elevation={5} className={classes.card}>
					<CardContent>
						<IconButton color="secondary" onClick={(event) => AskForAgreement()}
							style={{ position: "absolute", right: "5px", top: "5px" }} aria-label="settings">
							<DeleteForeverIcon />
						</IconButton>
						<div className={classes.cardTitle}>

							<YumChangeInput textValue={qrcode.name} action={(newQrcodeName) => { return actionChangeQrcodeName(qrcode._id as string, newQrcodeName);}}/>

						</div>
						<div className={classes.cardImageContainer}>
							<div ref={code} id={qrcode.name}></div>
						</div>
						<div className={classes.description}>
							<FormControlLabel
								control={<IOSSwitch
									checked={qrcode.activate}
									name="checkedB" />}
								label="Disponibilité"
								labelPlacement="start"
								onChange={(event, checked) => {
									handlingChangeStatusQrCode(event, checked, qrcode._id as string);
								}}
							/>
						</div>
					</CardContent>
					<CardActions style={{ justifyContent: "center" }}>
						<YumButton onClick={() => { setStateDocument("Download"); setIsTagline(true); }} disabled={!code.current }>Télécharger</YumButton>
						<YumButton 
							onClick={() => { setStateDocument("Print"); setIsTagline(true); }}>
								Imprimer
						</YumButton>
					</CardActions>
				</Card>
				<Dialog
					open={isTagline}
					onClose={handleCloseTagline}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					>
					<DialogTitle id="alert-dialog-title">
						<Typography component="span" align="center" variant="subtitle1" >
								Phrase personnalisée
						</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<Typography
								style={{ marginBottom: "2vh"}}
								component="span" 
								variant="body1"  
								align="center" 
								gutterBottom>
								Vous pouvez personnaliser le message de votre affiche Qrcode avec une phrase
							</Typography>
						</DialogContentText>
						<TextField
								id="outlined-name"
								label="Phrase"
								placeholder="Si aucune phrase n'est fournis nous n'imprimerons rien sur votre feuille"
								value={tagline}
								onChange={(event) => {
									setTagline(event.target.value);
								}}
								fullWidth
								variant="outlined"
								/>	
					</DialogContent>
					<DialogActions>
						{stateDocument === "Download" && <PDFDownloadLink
							document={<MyDocument tagline={tagline} codeRef={code} />}
							fileName={`${qrcode.name ? qrcode.name : qrcode._id }.pdf`}
							style={{
								textDecoration: "none"
							}}
						>
							{({ blob, url, loading, error }) => 
								(<YumButton onClick={()=> {handleCloseTagline()}} disabled={loading || !code.current }>Télécharger</YumButton>)
							}
						</PDFDownloadLink>}
						{stateDocument === "Print" && <PrintElement onAfterPrint={() => {
							handleCloseTagline();
						}}>
							<QrcodePrintable tagline={tagline} url={qrcodeBase64} />
						</PrintElement>}
					</DialogActions>
				</Dialog>
				<Dialog
					open={dialogIsOpen}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					>
					<DialogTitle id="alert-dialog-title">
						<Typography component="span" align="center" variant="subtitle1" >
								Suppression du Qrcode "{qrcode.name}"
						</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<Typography component="span" variant="body1"  align="justify">
								La suppression d'un qrcode est définitive, les données relatives à ce Qrcode seront elles aussi effacées. Etes-vous sûr de vouloir éffectuer cette action ?
							</Typography>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<YumButton 
							onClick={handleClose}>
						Annuler
						</YumButton>
						<YumButton 
							onClick={deleteAfter} 
							autoFocus>
						Supprimer
						</YumButton>
					</DialogActions>
				</Dialog>
			</React.Fragment>);
	}

	async function allQrCode() {

		try {
			let qrcodesServer = await APIManager.getQrCodeServices().GetAllQrCode();
			setQrcodes([...qrcodesServer]);
		}
		catch (err) {
			enqueueSnackbar(`Il est possible que l'ensemble de vos Qrcodes n'aient pas été chargés. Recharger la page, et contactez le service client via le formulaire si l'erreur persiste.`, { 
				variant: "warning",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		}
	}

	useEffect(() => {

		allQrCode();

	}, []);



	return (
		<Fragment>
			<Container className={classes.root} maxWidth={false} component="main">
				<div className={classes.navSection}>
					<Breadcrumbs aria-label="breadcrumb">
						{/*<Link color="inherit" href="/" onClick={() => { }}>*/}
							<Typography
								component="span"
								variant="body2"
							>
								Mes Qrcodes
							</Typography>
						{/*</Link>*/}
					</Breadcrumbs>
				</div>
				<TipsManager />
				{/* <div>
					<Typography style={{ textTransform: "uppercase" }} variant="subtitle1"  component="h2">Créer vos qr codes</Typography>
				</div>
				<Divider /> */}
				{/* {ExceedLimit !== true &&
					(<div className={classes.quickNavSection}>
						{/*<FormControlLabel
							control={<IOSSwitch
								checked={newQrcode.activate}
								onChange={(event) => {
									setNewQrcode({
										...newQrcode, activate: event.target.checked
									})
								}}
								name="checkedB" />}
							label="Disponibilité"
							labelPlacement="start"
							style={{ marginRight: "1vw" }}
						/>*/}
						{/* <TextField
							id="outlined-basic"
							label="Nom du Qrcode"
							variant="outlined"
							onChange={(event) => {
								setNewQrcode({ ...newQrcode, name: event.target.value as string });
							}}
							value={newQrcode.name}
							style={{ marginRight: "1vw" }}
						/>
						<Button onClick={createNewQrcode}
							style={{ marginRight: "1vw" }}
							color="secondary"
							variant="contained"
							endIcon={<AddIcon />}>
							Créer un QrCode
					</Button> */}
					{/* </div>)} */}
				{/* {ExceedLimit === true &&
					(<div className={classes.quickNavSection}>
						<Typography
							variant="subtitle1" 
							component="span"
							gutterBottom>
								Vous avez atteint votre <strong>limite</strong> de création de Qrcode. Pour profiter de plus de Qrcodes contactez-nous
						</Typography>
					</div>)} */}
				<div>
					<Typography style={{ textTransform: "uppercase" }} variant="subtitle1" component="h2">Espace de gestion des QRCodes</Typography>
				</div>
				<Divider />
				<div style={{ padding: "2vh 1vw"}}>
					<Typography
						variant="body1" 
						component="span"
						gutterBottom>
						{`Vous possédez ${qrcodes.length}  Qrcode${qrcodes.length > 0 ? "s" : ""}`}
					</Typography>
				</div>
				<Grid container alignContent="center" alignItems="center" spacing={2}>
					{qrcodes.map((qrcode) =>
						(<Grid item key={qrcode._id as string} component='div' xs={12} sm={6} md={4} lg={4}>
							<QRcodeItemComponent item={qrcode} />
						</Grid>))
					}
				</Grid>
				<Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={isSucces || isError} autoHideDuration={6000} onClose={handlingClosePopUp}>
					<Alert variant="filled" onClose={handlingClosePopUp} severity={isError ? "error" : "success"}>
						{isError ? errorMsg : succesMsg}
					</Alert>
				</Snackbar>
			</Container>
		</Fragment >
	);
};

export default connect(
	(state: AppState) => ({
		applicationdata: state.applicationdata,
	}), {
	putqrcode: PutQrcode,
})(Layout);
