import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	container: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
	},
	paper: {
		minWidth: '25px',
		backgroundColor: "black",
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		height: 28,
		margin: 4,
	},
	link: {
		textDecoration: 'none',
		marginLeft: "5px",
		marginRight: "5px",
		color: "white",
		fontWeight: 'bold',
	},
	header: {
		'&:nth-of-type(odd)': {
			backgroundColor: "black",
			color: "white",
			fontSize: "bold",
		}
	}

}));

export default useStyles;