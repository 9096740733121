import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: "100vh",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "5vh 5vw",
		textAlign: "center",
	},
	ScanMe: {
		textTransform: "uppercase",
	},
	Main:  {
		display: "flex", 
		flexDirection: "column", 
		justifyContent: "space-around",
		"& div": {
			margin: "2vh 0",
			"& img": {
				width: "100%",
				maxWidth: "300px",
			}
		},
		"& h1": {
			margin: "2vh 0"
		},
	}
}));

export {
	useStyles,
}
