import React, { useEffect } from "react";
import { ICard } from "../../../types";
import { APIManager } from "../../../services";
import { useSnackbar } from "notistack";
import ImageDirectUpload from "../../../components/ImageDirectUpload";


interface Props {
	card: ICard;
	callback: () => void;
}

/**
 * Personnalisation de l'application des utilisateurs
 * @param param0 
 * @returns 
 */
const UploadBanner: React.FC<Props> = ({ card, callback }: Props) => {
	const { enqueueSnackbar } = useSnackbar();
	
	const dimension = { height: 300, width: 320 };
	const SUCCESS_MSG = "Votre bannière a été mise à jour avec succès";
	const FAILED_MSG = "Votre bannière n'a pas pu être mise à jour, si l'erreur persiste veuillez contacter le service client";

	const [urlBanner, setUrlBanner] = React.useState<string | undefined>(card.banner);

	useEffect(() => { setUrlBanner(card.banner); }, [card.banner]);

	const DeleteBannerFromCard = () => {
		return APIManager.getCardServices().RemoveBannerFromCard(card._id as string)
		.then(() => {
			callback();
			enqueueSnackbar(SUCCESS_MSG, { 
				variant: "success",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		})
		.catch(() => {
			enqueueSnackbar(FAILED_MSG, { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		});
	};

	const UploadBannerToCard = (file: File) => {
		let form = new FormData();

		form.append("banner", file as File); 
		return APIManager.getCardServices().UpdateBannerForCard(form, card._id as string)
		.then((infos) => {
			callback();
			enqueueSnackbar(SUCCESS_MSG, { 
				variant: "success",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		})
		.catch(() => {
			enqueueSnackbar(FAILED_MSG, { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		});
	}


	return (
		<React.Fragment>
			<ImageDirectUpload
				imageUrl={urlBanner}
				textButton="Télécharger une bannière"
				DeleteImage={DeleteBannerFromCard}
				UploadImage={UploadBannerToCard}
				dimension={dimension}/>
		</React.Fragment>
	);
};

export default UploadBanner;

