import React, { useEffect, useContext, Fragment, useCallback  } from 'react';
import { store } from "../contexts/auth";
import { StorageServiceManager } from '../services/storageManager';
import { ITrader, UserType, UserInfos, AppState, UserState } from '../types';
import { LoginUser, LogOutUser } from '../redux/user';
import { connect } from 'react-redux';

interface Props {
	userdata: UserState;
	logUser: (user: UserInfos, type: UserType) => any;
	logOut: () => any;
}

const AuthCheck: React.FC<Props> = ({ logUser, userdata, logOut }: Props) => {

	let authContext = useContext(store);
	const [isLoading, SetLoading] = React.useState<boolean>(true);
	const instanceStorage = StorageServiceManager.getStorageService();

	const _retrieveUserInfosFromStorage = useCallback(() => {
		let accesToken = instanceStorage.getData<string>('access_token');
		let userinfos = instanceStorage.getData<ITrader>('user_infos');
		let type = instanceStorage.getData<UserType>('type');

		// Retrouve les données contenu dans les cookies
		if (accesToken && userinfos && type !== undefined) {
			return {
				access_token: accesToken,
				user_infos: userinfos,
				type: type

			};
		}
		// Renvoie undefined si aucun cookie n'a été sauvegardé
		return undefined;
	}, [instanceStorage]);

	const TryLogUserFromInfoStored = async () => {

		// Fonction de déconnexion si aucun cookie
		const logOutFnc = () => {
			logOut();
			authContext.authObj.logout();
			authContext.handleUserLogout();
		}
		
		try {
			let currentToken = _retrieveUserInfosFromStorage();
			let userProfile = await authContext.authObj.login();

			// Utilisateur connecté via les cookies
			if (userProfile !== {} && currentToken !== undefined) {
				logUser({
					id: (userProfile as ITrader)._id as string,
					userinfos: (userProfile as ITrader),
					email: (userProfile as ITrader).email,
					token: authContext.authObj.getAccessToken() as string,
					type: currentToken?.type as UserType

				}, currentToken?.type as UserType); // Appel du store pour mettre a jour la connexion
				authContext.handleUserLogin();
				authContext.handleUserAddProfile(authContext.authObj.userProfile);
			} else {
				// Utilisateur non connecté via les cookies
				logOutFnc();
			}
		} catch(error) {
			logOutFnc();
		}
		  
	}

	useEffect(() => {
		if (isLoading === false) {
			return;
		}
		// Utilisateur déjà authentifié
		if (authContext.authState || userdata.isAuthentified) {
			SetLoading(false);
			return;
		}
		TryLogUserFromInfoStored();
		SetLoading(false);
	}, [authContext, isLoading, userdata.isAuthentified]);

	return (
		<Fragment>
		</Fragment>
	)
}


export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}),
	{
		logUser: LoginUser,
		logOut: LogOutUser,

	}
)(AuthCheck);