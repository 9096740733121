export enum ComposantMode {
	Create,
	Read,
	Update,
	Delete,
}

export enum RESTVerb {
	GET,
	POST,
	PATCH,
	PUT,
	DELETE,
}

export enum DishCategory {
	Starter,
	MainCourse,
	Dessert,
	Accompagnement,
}

export enum YUMSCAN_FEATURES {
	Partner = 0x0,  // 0 *
	Pdf = 0x1,  // 1
	Digital = 0x2,  // 2
};


export enum DAYS {
	All = 0x0,  // 0 *
	Sunday = 0x1,  // 1
	Monday = 0x2,  // 2
	Tuesday = 0x4,  // 4
	Wednesday = 0x8,  // 8
	Thursday = 0x10, // 16
	Friday = 0x20, // 32
	Saturday = 0x40  // 64
};

export enum TRADER_TYPE {
	RESTAURANT = "Restaurant",
	FAST_FOOD = "Fast Food",
	BAR = "Bar",
	FOOD_TRUCK = "Food truck",
	FOOD_CHAIN = "Chaine de restauration",
	BRASSERIE = "Brasserie",
	FESTIVAL = "Festival",
	OTHER = "Autre"
}

export enum COORDINATES_TYPE {
	POINT = "Point"
}

export enum YUMSCAN_PACK {
	FREE,
	PRO,
	PRO_PLUS,
	CHAIN,
}

export enum BILLING_TYPE {
	YEARLY = "yearly",
	MONTHLY = "monthly",
}

export enum TRADER_PAYEMENT_STATUS {
	Incomplete = 'incomplete',
	Incomplete_Expired = 'incomplete_expired',
	Trialing = 'trialing',
	Active = 'active',
	Past_due = 'past_due',
	Canceled = 'canceled',
	Unpaid = 'unpaid',
}

export enum CATEGORY_TYPE {
	BASIC = 0,
	USER = 1,
	ADDITIONNAL = 2,
}

export enum CARD_ITEM_TYPE {
	DISH = "0",
	MENU = "1",
}

export enum ADDITIONNAL_DATA_TYPE {
	checkbox = "0",
	radio = "1",
}

export enum SOURCE_SCAN {
	Application,
	Pwa,
	Web
}

export enum DISCOUNT_TYPE {
	POURCENT = "0",
	REAL_PRICE = "1"
}

export enum CATEGORIE_TIPS {
	TIPS = "0",
	ANNONCE = "1"
}