import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'initial',
		minHeight: '100%',
		paddingTop: "3vh"
	},
	subRoot: {
		padding: "3vh 1vw"
	},

	settingsColor: {
		display: "flex", 
		flexDirection: "row", 
		justifyContent: "space-evenly", 
		alignItems: "center", 
		width: "100%",
	},
	settingColorViewer: {
		height: "15px", 
		width: "15px", 
	},


	imgContainer: {
		maxWidth: 300,
		maxHeight: 160,
		position: "relative",
		zIndex: 0,
	},
	imgBackgroundMinimumRequierement: {
		height: "100%",
		backgroundColor: 'rgb(0,0,0,0.4)',
		position: "absolute",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		fontSize: "2em",
		fontWeight: "bold",
	},

	m_10: {
		margin: "10px 0"
	},

	/** Partie details de l'abonnement */
	billingDiv: {
		width: "100%",
		display: "flex", 
		justifyContent: "center", 
		alignItems: "center",
	},
	title: {
		textTransform: "uppercase", 
		margin: "15px 0"
	},

	manageBillingDiv: {
		display:"flex",
		flexDirection: "row"
	},

	/** General */
	uppercaseText: {
		textTransform: "uppercase", 
	},
	mg_1v: {
		margin: "2vh 0"
	}
}));

export default useStyles;
