import React, { Fragment, useEffect } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import { Typography, Container, Grid, List, ListItemIcon, ListItemText, withStyles, ListSubheader, IconButton, Button,
	Snackbar, Breadcrumbs, AppBar, Toolbar, Popper, Grow, ClickAwayListener, Paper, MenuList, MenuItem, Backdrop,
	CircularProgress, Badge, FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import { useStyles, useStylesBackDrop } from "./styles";
import { UserState, ITrader, IQrcode, TRADER_PAYEMENT_STATUS } from "../../../types";
import Divider from '@material-ui/core/Divider';

// ICONS
import AppsIcon from '@material-ui/icons/Apps';
import CropFreeIcon from '@material-ui/icons/CropFree';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { Theme } from '@material-ui/core';
import CardStack from "../CardStack";
import QrcodeStack from "../QrcodeStack";
import SettingsStack from "../SettingsStack";
import BillingSettingsStack from "../BillingSettingsStack";
import PackChoice from "../PackChoice";
import ContactStack from "../ContactStack";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { APIManager } from "../../../services";
import QuickCreateQrCode from "../../../components/QuickCreateQrCode/QuickCreateQrCode";
import { Redirect } from "react-router-dom";
import Auth from "../../../utils/auth";
import { SnackDataItem } from "../../../utils";
import AddToHomeScreen from "../../../components/AddToHomeScreen";
import AppSettingsStack from "../AppSettingsStack";
import RestrictionModal from "../../../components/Restriction/RestrictionModal/RestrictionModal";
import { YumButton } from "../../../Lib/UI/YumButton";


interface Props {
	userData: UserState;
	logOut: () => any;
	authContext: {
		authState: boolean;
		profileState: any;
		handleUserLogin: () => void;
		handleUserLogout: () => void;
		handleUserAddProfile: (profile: {} | ITrader) => void;
		handleUserRemoveProfile: () => void;
		authObj: Auth;
	}
}

interface DataMenuNav {
	key: number;
	title: string;
	icons: any;
}

//const url_server = process.env.REACT_APP_BASE_URL;

const ListItem = withStyles((theme: Theme) => ({
	root: {
		borderRadius: "5px",
		color: "#A5ADC6",
		"&$selected": {
			backgroundColor: theme.palette.primary.main,
			'& span': {
				fontWeight: "bold",
				color: "black",
			}
		},
		"&$selected:hover": {
			backgroundColor: "#A5ADC6",
			color: "white"
		},
		"&:hover": {
			backgroundColor: theme.palette.secondary.main,
			color: "white",
			'& span': {
				fontWeight: "bold",
			}
		}
	},
	selected: {}
}))(MuiListItem);

const Layout: React.FC<Props> = ({ logOut, userData, authContext }: Props) => {

	/*const ControlPanel: DataMenuNav[] = [
		{
			key: 0,
			title: "Home",
			icons: AppsIcon,
		},
		{
			key: 1,
			title: "Dashboard",
			icons: DashboardIcon,
		},
		{
			key: 2,
			title: "Ma carte",
			icons: RestaurantMenuIcon,
		},
		{
			key: 3,
			title: "Sous-compte",
			icons: SupervisorAccountIcon,
		},
		{
			key: 4,
			title: "QrCode",
			icons: CropFreeIcon,
		},
	];*/
	const classes = useStyles();
	const classesBackDrop = useStylesBackDrop();
	const anchorRef = React.useRef<HTMLButtonElement>(null);

	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [currentLogTrader, 
		//setCurrentTrader
	] = React.useState<ITrader | undefined>(authContext.authObj.userProfile === {} ? (userData.userdata?.userinfos) as ITrader : (authContext.authObj.userProfile) as ITrader);
	const [pdfLink, 
	//	setPdfLink
	] = React.useState<string | undefined>(undefined);
	const [qrcodesLimitExceed, setQrcodeLimitExceed] = React.useState<boolean | undefined>(undefined);
	const [open, setOpen] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);
	const [requirePayement, setRequirePayement] = React.useState<boolean | undefined>(undefined);
	const [
		//billingStatus
		, setBillingStatus] = React.useState<string | undefined>(undefined);
	const [
		//reminder
		, setReminder] = React.useState<boolean>(false);

	const handleListItemClick = (
		event: React.MouseEvent,
		index: number,
	) => {
		setSelectedIndex(index);
	};

	const OnQrcodeUpdate = async () => {
		try  {
			let limit = await APIManager.getAccountServices().qrcodeLimitExceed();
			
			setQrcodeLimitExceed(limit);

		} catch(err) {
			console.log("Erreur limit qrcode", err);
			setQrcodeLimitExceed(undefined);
		}		
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}
		setOpen(false);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	}

	const prevOpen = React.useRef(open);

	const traderRequirePayement = async () => {
		try {
			let billing = await APIManager.getBillingService().getBillingInformations();
			
			if (billing.status === TRADER_PAYEMENT_STATUS.Incomplete 
				|| billing.status === TRADER_PAYEMENT_STATUS.Incomplete_Expired 
				|| billing.status === TRADER_PAYEMENT_STATUS.Canceled ) {
				setRequirePayement(true);
			} else if (billing.status === TRADER_PAYEMENT_STATUS.Past_due || billing.status === TRADER_PAYEMENT_STATUS.Unpaid) {
				setBillingStatus(billing.status);
				setReminder(true);
			} else {
				setRequirePayement(false);
				setReminder(false);
			}
		} catch (err) {
			setRequirePayement(false);
			setReminder(false);
			
			console.log(err);
		}
	}

	/* const handleCloseMenu = (val: boolean, isSucess?: boolean, data?: any) => {
		// Pour être sûr que les modal se ferme on passe false et non val.
		//setRequirePayement(false);
		setReminder(false);
	} */

	useEffect(() => {
		if (authContext.authObj.userProfile !== {}) {
			setIsLoading(false);
			return;
		}
		authContext.authObj.getProfile().then((val) => {
			if (val !== {}) {
				console.log("load user data")
				//setCurrentTrader(val as ITrader);
				//OnQrcodeUpdate();
			}
			// Si aucune valeur on laisse celle de redux !
		}).finally(() => {
			setIsLoading(false);
		});
	}, [authContext.authObj.userProfile]);

	useEffect(() => {
		if (authContext.authObj.userProfile === {} || requirePayement !== undefined) {
			return;
		}
		traderRequirePayement();
	}, [authContext.authObj.userProfile, requirePayement]);

	useEffect(() => {
		if (authContext.authObj.userProfile === {}) {
			return;
		}
		OnQrcodeUpdate();
	}, [selectedIndex]);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus();
		}

		prevOpen.current = open;
	}, [open]);

	return (
		<Fragment>
			<Container className={classes.root} maxWidth={false} component="main">
				<Grid container className={classes.root}>
					<Grid item className={classes.navSection} component='div' xs={12} sm={3} md={3}>
						<div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "3vh 0" }}>
							<Badge color="primary"
								overlap="circle"
								variant="dot"
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}>
								<div style={{ display: "flex", flexDirection: "row"}}>
									<Typography variant="h2" color="primary" noWrap>Yum</Typography>
									<Typography style={{ color: "black" }} variant="h2" noWrap>Scan</Typography>
								</div>
							</Badge>
						</div>
						<Divider />
						<List component="nav"
							aria-labelledby="subheader-general"
							subheader={
								<ListSubheader component="div" id="subheader-general">
									<Typography
										component="span"
										variant="h5"
									>
										Panneau de control
										</Typography>
								</ListSubheader>
							}>
							{/*<ListItem
								button
								selected={selectedIndex === -1}
								onClick={(event) => handleListItemClick(event, -1)}
							>
								<ListItemIcon>
									<AppsIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Admin
										</Typography>
									</React.Fragment>
								} />
							</ListItem>*/}
							<ListItem
								button
								selected={selectedIndex === 0}
								onClick={(event) => handleListItemClick(event, 0)}
							>
								<ListItemIcon>
									<AppsIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Home
										</Typography>
									</React.Fragment>
								} />
							</ListItem>
							{/*<ListItem
								button
								selected={selectedIndex === 1}
								onClick={(event) => handleListItemClick(event, 1)}
							>
								<ListItemIcon>
									<DashboardIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>

										</Typography>
									</React.Fragment>
								} />
							</ListItem> */}
							<ListItem
								button
								selected={selectedIndex === 2}
								onClick={(event) => handleListItemClick(event, 2)}
							>
								<ListItemIcon>
									<RestaurantMenuIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Mes modules
										</Typography>
									</React.Fragment>
								} />
							</ListItem>
							{/*<ListItem
								button
								selected={selectedIndex === 3}
								onClick={(event) => handleListItemClick(event, 3)}
							>
								<ListItemIcon>
									<SupervisorAccountIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Sous-compte
										</Typography>
									</React.Fragment>
								} />
							</ListItem> */}
							<ListItem
								button
								selected={selectedIndex === 4}
								onClick={(event) => handleListItemClick(event, 4)}
							>
								<ListItemIcon>
									<CropFreeIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											QrCode
										</Typography>
									</React.Fragment>
								} />
							</ListItem>
						</List>
						<Divider />
						<List component="nav"
							aria-labelledby="subheader-account"
							subheader={
								<ListSubheader component="div" id="subheader-account">
									Compte
									</ListSubheader>
							}>
							<ListItem
								button
								selected={selectedIndex === 5}
								onClick={(event) => handleListItemClick(event, 5)}
							>
								<ListItemIcon>
									<SettingsIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Paramètres compte
										</Typography>
									</React.Fragment>
								} />
							</ListItem>
							<ListItem
								button
								selected={selectedIndex === 9}
								onClick={(event) => handleListItemClick(event, 9)}
							>
								<ListItemIcon>
									<PhoneIphoneIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Paramètres application
										</Typography>
									</React.Fragment>
								} />
							</ListItem>
							<ListItem
								button
								selected={selectedIndex === 6}
								onClick={(event) => handleListItemClick(event, 6)}
							>
								<ListItemIcon>
									<AccountBalanceIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Paramètres Offre
										</Typography>
									</React.Fragment>
								} />
							</ListItem>
							<ListItem
								button
								selected={selectedIndex === 7}
								onClick={(event) => handleListItemClick(event, 7)}
							>
								<ListItemIcon>
									<ContactSupportIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Contact
										</Typography>
									</React.Fragment>
								} />
							</ListItem>
							{/*<ListItem
								button
								selected={selectedIndex === 7}
								onClick={(event) => handleListItemClick(event, 7)}
							>
								<ListItemIcon>
									<SecurityIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Paramètres de sécurité
										</Typography>
									</React.Fragment>
								} />
							</ListItem>*/}
							<ListItem
								button
								selected={selectedIndex === 8}
								onClick={(event) => handleListItemClick(event, 8)}
							>
								<ListItemIcon>
									<PowerSettingsNewIcon />
								</ListItemIcon>
								<ListItemText primary={
									<React.Fragment>
										<Typography
											component="span"
											variant="h5"
										>
											Déconnexion
										</Typography>
									</React.Fragment>
								} />
							</ListItem>
						</List>
					</Grid>
					<AppBar className={classes.navSectionResponsive} elevation={0} position="sticky" color="default" >
						<Toolbar component="main" disableGutters={true}>
							<div style={{ position: "absolute", left: 0 }}>
								<div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "3vh 0" }}>
									<Badge color="primary"
										overlap="circle"
										variant="dot"
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right',
										}}>
										<div style={{ display: "flex", flexDirection: "row"}}>
											<Typography variant="h2" color="primary" noWrap>Yum</Typography>
											<Typography style={{ color: "black" }} variant="h2" noWrap>Scan</Typography>
										</div>
									</Badge>
								</div>
							</div>
							<IconButton color="secondary"
								ref={anchorRef}
								aria-controls={open ? 'menu-list-grow' : undefined}
								aria-haspopup="true"
								onClick={handleToggle}
								aria-label="Menu"
								style={{ position: "absolute", right: 0 }}>
								<MenuIcon />
							</IconButton>
							<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleClose}>
												<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
													<MenuItem onClick={(event) => {
														handleListItemClick(event, 0);
														handleClose(event)
													}}>
														Home
														</MenuItem>
													<MenuItem onClick={(event) => {
														handleListItemClick(event, 2);
														handleClose(event)
													}}>
														Mes modules
														</MenuItem>
													<MenuItem onClick={(event) => {
														handleListItemClick(event, 4);
														handleClose(event)
													}}>
														Qrcode
														</MenuItem>
													<MenuItem onClick={(event) => {
														handleListItemClick(event, 5);
														handleClose(event)
													}}>
														Paramètres compte
														</MenuItem>
													<MenuItem onClick={(event) => {
														handleListItemClick(event, 6);
														handleClose(event)
													}}>
														Paramètres offres
														</MenuItem>
													<MenuItem onClick={(event) => {
														handleListItemClick(event, 9);
														handleClose(event)
													}}>
														Paramètres application
														</MenuItem>
													<MenuItem onClick={(event) => {
														handleListItemClick(event, 7);
														handleClose(event)
													}}>
														Contact
														</MenuItem>
													<MenuItem onClick={(event) => {
														handleListItemClick(event, 8);
														handleClose(event)
													}}>
														Déconnexion
														</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</Toolbar>
					</AppBar>
					<Grid item component='div' xs={12} sm={12} md={9}>
						{<DynamicComponent selectedComponent={selectedIndex} currentTrader={currentLogTrader} />}
						<Backdrop className={classesBackDrop.backdrop} open={isLoading}>
							<CircularProgress color="inherit" />
						</Backdrop>
					</Grid>
				</Grid>
				<RestrictionModal />

				{/*<SpringModal openModal={requirePayement === undefined ? false : requirePayement} eventClose={handleCloseMenu} canclose={false}>
					<DisplayOffers Advanced={false} OnClose={() => { console.log("premier implementation") }} OnOpen={() => { console.log("premier implementation") }} />
				</SpringModal>
				<SpringModal openModal={reminder} eventClose={handleCloseMenu} canclose={true}>
					<div>
						<Typography>Pensez a mettre a jour vos informations de paiement pour continuer à utiliser le service YUMSCAN</Typography>
					</div>
				</SpringModal>*/}
			</Container>
			
		</Fragment >
	);

	function HomeComponent(props: any) {

		const [stepper, setStepper] = React.useState<number>(0);
		const [openSnack, setOpenSnack] = React.useState<boolean>(false);
		const [snackData, setSnackData] = React.useState<SnackDataItem | undefined>(undefined);
		const [homePdf, setHomePdf] = React.useState<string | undefined>(pdfLink);
		const [isLoadingHome, setIsLoadingHome] = React.useState<boolean>(true);
		const [trader, setTrader] = React.useState<ITrader | undefined>(undefined);
		const [qrcodeCreated, setQrcodeCreated] = React.useState<IQrcode | undefined>(undefined);
		const [qrcodes, setQrcodes] = React.useState<IQrcode[]>([]);
		const [isLoadingQrcode, setIsLoadingQrcode] = React.useState<boolean>(true);
		const [isRequestingHome, setIsRequestingHome] = React.useState<boolean>(false);
		const [value, setValue] = React.useState<string>('');


		const inputRef = React.useRef<HTMLInputElement>(null);

		const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files?.item(0);
			let formData = new FormData();
			//setIsLoading(true);

			if (!file 
				|| (file.type !== "application/pdf" 
				&& file.type !== "image/gif" 
				&& file.type !== "image/png"
				&& file.type !== "image/bmp"
				&& file.type !== "image/jpeg"
				&& file.type !== "image/webp")) {
				// display error
				setOpenSnack(true);

				setSnackData({
					severity: file ? "warning" : "error",
					msg: file ? "Le fichier doit être au format pdf" : "vous n'avez soumis aucun fichier",
				});
				return;
			}
			if (!qrcodeCreated) {
				setOpenSnack(true);
				setSnackData({
					severity: "error",
					msg: `Aucun qrcode n'a été lors de l'étape précedente`,
				});
				return;
			}
			// Check taille du pdf max pour ne pas saturé le serveur
			formData.append('files', file);
			setIsRequestingHome(true);
			APIManager.getCardServices().UploadPDF(qrcodeCreated._id as string,formData).then((res: boolean) => {
				//console.log("Resultat getcardservice dans telechargement pdf", res);
					return APIManager.getQrCodeServices().GetQrCodePdfById(qrcodeCreated._id as string).then((path: string | undefined) => {
						//console.log("Resultat getPdfForTrader pdf", path);
						if (path) {
							setHomePdf(path);
							setOpenSnack(true);
							setSnackData({
								severity: "success",
								msg: `Le pdf a été importé avec succès.`,
							});
							setStepper(3);
						} else {
							throw new Error("Impossible de charger le lien vers votre pdf");
						}
					});				
			}).catch((err: Error) => {
				console.log(err);
				setOpenSnack(true);
				setSnackData({
					severity: "error",
					msg: ` ${err.message}`,
				});
			}).finally(() => {
				setIsRequestingHome(false);
			});
		}

		const sendEmailVerif = (event: React.MouseEvent) => {
			/*APIManager.getAccountServices().EmitVerifEmail().then((res) => {
				setOpenSnack(true);
				setSnackData({
					severity: res ? "info" : "warning",
					msg: res ? `Un mail de verification a été envoyé :)` : "Votre compte est déjà vérifié",
				});
			}).catch((err) => {
				setOpenSnack(true);
				setSnackData({
					severity: "error",
					msg: ` ${err.message}`,
				});
			});*/
		}

		const getUserInformations = async () => {
			try {
				await authContext.authObj.getProfile();

				setTrader(authContext.authObj.userProfile as ITrader);
			} catch(err) {
				console.log("Err lors du chargement", err);
			} finally {
				setIsLoadingHome(false);
			}
		};

		const actualizeQrcode = async () => {
			let qrcodeAPI = APIManager.getQrCodeServices();
	
			try
			{
				let qrcodesServer = await qrcodeAPI.GetAllQrCode();
	
				setQrcodes([...qrcodesServer]);
	
			} catch(err) {
				console.log("error loading ", err);
			} finally {
				setIsLoadingQrcode(false);
			};
		}

		const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			let findQrcode = qrcodes.find((qr) => qr._id === event.target.value);

			if (findQrcode)  {
				setValue(event.target.value);
				setStepper(2);
				setOpenSnack(true);
				setSnackData({
					severity: "success",
					msg: `le qrcode ${findQrcode.name} a été séléctionné.`
				});
				setQrcodeCreated(findQrcode);
			}
		};

		useEffect(() => {
			console.log("Home component loaded", isLoading);
			if (trader || isLoading || !isLoadingHome) {
				console.log("Ne charge pas car trader récupéré ou composant parent entrain de charger", isLoading);
				return;
			}
			getUserInformations();
			
		}, [trader]);

	
		useEffect(() => {
			if (!isLoadingQrcode){
				return;
			}
			actualizeQrcode();
		}, [isLoadingQrcode]);

		return (
			<div className={classes.homeSection}>
				<Breadcrumbs aria-label="breadcrumb">
					<Typography
						component="span"
						variant="h5"
					>
						Accueil
					</Typography>
				</Breadcrumbs>
				{!isLoadingHome && 
				<div className={classes.userDetailsSection}>
					<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
						<Typography 
							component="h1" 
							variant="h1">
							Bonjour {authContext.profileState?.firstname}
						</Typography>
						<div className={classes.userStatus}>
							<Typography 
								component="span" 
								variant="subtitle1">
								Statut de votre compte :
							</Typography>
							{trader && trader.verified ?
								(<VerifiedUserIcon className={classes.userStatusValid} />) :
								(<RemoveCircleIcon className={classes.userStatusFail} />)}
						</div>
						{/*<div className={classes.userStatus}>
							<Typography component="span" variant="subtitle1">Votre limite mensuel </Typography>
							<Typography component="span" variant="h6">1000</Typography>
							</div>*/}
					</div>
					<Paper 
						elevation={4} 
						className={classes.userAbonnementStatus}>
						<div>
							<Typography 
								component="h1" 
								variant="h6">
								Statut de l'abonnement :
							</Typography>
							{trader && trader.authorized ? (<FiberManualRecordIcon style={{ marginLeft: 5, fontSize: "1.2rem", color: "green" }} />) : (<FiberManualRecordIcon style={{ marginLeft: 5, fontSize: "1.2rem", color: "red" }} />)}
						</div>
						<AddToHomeScreen />
					</Paper>
				</div>}
				{isLoadingHome && (<CircularProgress color="primary" size={68} />)}
				{/*<div style={{ marginTop: "15px", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
					<Typography component="h1" variant="h4">Nombre de scan mensuel restant: </Typography>
					<Typography component="h1" variant="h2" style={{ marginLeft: 5 }}>100</Typography>
				</div>*/}
				{stepper === 0 && (
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%"}}>
					<div style={{ textAlign: "center", margin: "2vh 0" }}>
						<YumButton 
							onClick={(event) => {
							if (!trader || !trader.authorized) {
								setOpenSnack(true);
								setSnackData({
									severity: "warning",
									msg: `Pour profiter de yumscan, souscrivez a un abonnement`,
								});
								return;
							}
							/*if (qrcodesLimitExceed === true) {
								setOpenSnack(true);
								setSnackData({
									severity: "info",
									msg: `Vous disposez déjà de qrcode, il ne vous reste plus qu'a mettre votre carte au format pdf.`,
								});
								setStepper(2);
							}
							else {
								setStepper(1);
							}*/
							setStepper(1);

						}}>
							<Typography 
								component="h1" 
								variant="body1" >
								Cliquez ici pour commencer rapidement avec YUMPDF
							</Typography>
						</YumButton>
					</div>

					{trader && !trader.authorized &&
						<React.Fragment>
							<Alert 
								style={{ marginBottom: "15px" }}
								severity="error">
								<AlertTitle><strong>Votre abonnement n'est pas encore activé</strong></AlertTitle>
								Procéder au premier paiement pour activer votre abonnement <strong>Proceder au paiement ?</strong>
							</Alert>
						</ React.Fragment>}

					{trader && 
						!trader.verified &&
						<React.Fragment>
							<Alert 
								style={{ marginBottom: "15px" }}
								severity="warning">
								<AlertTitle><strong>Votre compte n'est pas encore vérifié</strong></AlertTitle>
								Vous ne pouvez pas accéder a la totalité des fonctionnalitées de yumscan
								<Button 
									color="secondary"
									variant="contained" 
									onClick={sendEmailVerif}>
									Faire vérifié mon compte ?
								</Button>
							</Alert>
						</React.Fragment>}
					{/*<Alert 
						style={{ marginBottom: "15px" }} 
						severity="info">
						<AlertTitle><strong>Yummer Astuce</strong></AlertTitle>
						Gérez et téléchargez vos cartes aux format pdf dans la section 
						<strong 
							className={classes.cursorPointer} 
							onClick={ (event) => {
								handleListItemClick(event, 2);
							}}>
							"Mes modules"
						</strong>
					</Alert>
					<Alert 
						style={{ marginBottom: "15px" }} 
						severity="info">
						<AlertTitle><strong>Yummer Astuce</strong></AlertTitle>
						Vous pouvez gérer votre abonnement dans la rubrique 
						<strong 
							className={classes.cursorPointer} 
							onClick={ (event) => {
								handleListItemClick(event, 6);
							}}>
							"Paramètres Offre"
						</strong>
					</Alert>
					<Alert style={{ marginBottom: "15px" }} severity="success">
						<AlertTitle><strong>Recommandations</strong></AlertTitle>
						<span style={{ marginBottom: "5px" }}>C'est avec vous que la communauté de <strong>Yummers</strong> grandit et s'améliore tous les jours. N'hesitez pas à nous faire part de vos recommandations dans la rubrique <strong className={classes.cursorPointer} onClick={ (event) => {
							handleListItemClick(event, 7);
						}}>"contact"</strong> {'->'} <strong>"recommandations"</strong></span>
					</Alert>
					<Alert style={{ marginBottom: "15px" }} severity="info">
						<AlertTitle><strong>Yummer Astuce</strong></AlertTitle>
						<span style={{ marginBottom: "5px" }}>Certains <strong>Yummers</strong> lient une carte à un qrcode pour chaque catégories. <br /> <strong>Exemple</strong>: qrcode {'->'} <strong>"boissons"</strong>, qrcode {'->'} <strong>"plats {'&'} desserts"</strong></span>
					</Alert>*/}
				</div>)}
				{stepper !== 0 && (
					<div className={classes.quickStart}>
						{stepper === 1 && (
						<React.Fragment>
							<Typography 
								component="h1" 
								variant="h3" 
								gutterBottom>
								Etape 1 - { (qrcodes.length === 0 || !qrcodesLimitExceed ) ? "Créez un Qrcode" : "Sélectionnez un Qrcode" }
							</Typography>
							<div className={classes.StepQuickStart}>
								{!isLoadingQrcode && 
								(qrcodes.length === 0 || !qrcodesLimitExceed) && 
								<QuickCreateQrCode
									onCreate={(qrcode) => {
										setStepper(2);
										setOpenSnack(true);
										setSnackData({
											severity: "success",
											msg: `le qrcode ${qrcode.name} a été créé.`
										});
										setQrcodeCreated(qrcode);
									}}
									onError={(error) => {
										setOpenSnack(true);
										setSnackData({
											severity: "error",
											msg: error.message
										});
									}}
								/>}
								{!isLoadingQrcode && 
								(qrcodes.length > 0 && qrcodesLimitExceed) && 
								(<div style={{ display: "flex", flexDirection: "column"}}>
									<Typography 
										variant="body1" 
										component="span" 
										align="center" 
										gutterBottom>
										Sélectionnez le Qrcode pour lequel vous souhaitez lier ou modifier une carte
									</Typography>
									<div style={{ margin: "15px 0" }} className={classes.chipsDisplay}>
										<FormControl component="fieldset">
											<RadioGroup 
												aria-label="qrcodeSelect" 
												name="qrcodeSelect" 
												style={{ flexDirection: "row" }} 
												value={value} 
												onChange={handleChange}>
												{qrcodes.map((qrcode) => 
													<Paper 
														key={qrcode._id as string}
														elevation={5}
														style={{ 
																padding: "0px 15px", 
																borderRadius: "25px", 
																width: "max-content", 
																margin: "10px"
														}}>
														<FormControlLabel
															value={qrcode._id as string}
															control={<Radio key={qrcode._id as string} />}
															label={qrcode.name}
														/>
													</Paper>)}
											</RadioGroup>
										</FormControl>                            
									</div>
								</div>)}
							</div>
						</React.Fragment>)}
						{stepper === 2 && (
						<React.Fragment>
							<Typography 
								component="h1" 
								variant="h3" 
								gutterBottom>
								Etape 2 - Ajoutez une carte au Qrcode "<strong>{qrcodeCreated?.name}</strong>"
							</Typography>
							<div className={classes.StepQuickStart}>
								{isRequestingHome && <CircularProgress color="inherit" />}
								<Typography 
									component="span" 
									variant="h5"
									align="center"
									gutterBottom>
									Vous pouvez mettre une carte aux format <strong className={classes.highlightsentences}>PDF</strong>
								</Typography>
								<input 
									style={{ display: "none" }} 
									ref={inputRef} 
									onChange={handleFileSelected} 
									type="file" />
								<div 
									style={{ 
										textAlign: "center", 
										margin: "3vw 0"
									}}>
									<Button 
										color="primary" 
										disabled={isRequestingHome} 
										onClick={() => {
											if (inputRef.current) {
												inputRef.current.click();
											}
										}}
										variant="contained">
										<Typography 
											component="span" 
											variant="h5" >
											Cliquez {!pdfLink ? "pour télècharger" : "pour mettre à jour"} votre carte
										</Typography>*
									</Button>
								</div>
							</div>
						</React.Fragment>)}
						{stepper === 3 && (
						<React.Fragment>
							<Typography 
								component="h1" 
								variant="h3" 
								gutterBottom>
								Etape 3 - Visualisez la carte mise sur votre Qrcode
							</Typography>
							<div className={classes.StepQuickStart}>
								<Typography 
									component="span" 
									variant="h4" 
									style={{ margin: "2vh 0"}} 
									gutterBottom>
										Félicitation ! <span role="img" aria-label=":clap:">👏</span><br />
										votre carte est maintenant disponible à partir de votre Qrcode "<strong>{qrcodeCreated?.name}</strong>"
								</Typography>
								<div 
									style={{ 
										display: "flex", 
										flexDirection: "row", 
										justifyContent: "center", 
										alignItems: "center" 
									}}>
									<Button 
										style={{ marginRight: "1vw" }}
										color="primary"
										variant="contained"
										endIcon={<VisibilityIcon />}
										onClick={() => {
											if (homePdf) {
												let windowName = trader ? trader?.corporate_name : "votre carte";

												window.open(`${homePdf}`, windowName);
											} else {
												setOpenSnack(true);
												setSnackData({
													severity: "error",
													msg: `Vous ne disposez d'aucun pdf`,
												});
											}
										}}>
										<Typography 
											component="span" 
											variant="h6" >
											Visualiser ma carte
										</Typography>
									</Button>
									<Button 
										style={{ marginRight: "1vw" }}
										color="secondary"
										variant="contained"
										onClick={(event) => {
											handleListItemClick(event, 2);
										}}>
										<Typography 
											component="span" 
											variant="h6" >
											Gérer mes cartes
										</Typography>
									</Button>
								</div>
							</div>
						</React.Fragment>)
						}
					</div>
				)}
				<Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openSnack && snackData !== undefined} autoHideDuration={6000} onClose={() => { setOpenSnack(false); setSnackData(undefined); }}>
					<Alert variant="filled" onClose={() => { setOpenSnack(false); setSnackData(undefined); }} severity={snackData?.severity}>
						{snackData?.title && (<AlertTitle>{snackData?.title}</AlertTitle>)}
						{snackData?.msg}
					</Alert>
				</Snackbar>
				{/*<div>
					Tableau avec historique des commandes sur la journées
				</div>*/}
			</div >
		)
	}

	function DynamicComponent(props: any) {
		const selectedComponent: number = props.selectedComponent;
		//const currentTrader: ITrader | undefined = props.currentTrader;

		switch (selectedComponent) {
			/*case -1:
				return <AdminStack />;*/
			case 0:
				return <HomeComponent />;
			case 1:
				return <PackChoice />;
			case 2:
				return <CardStack authContext={authContext} />;
			case 4:
				return <QrcodeStack />;
			case 5:
				return <SettingsStack />;
			case 6:
				return <BillingSettingsStack />;
			case 7:
				return <ContactStack />;
			case 9:
				return <AppSettingsStack />;
			case 8:
				authContext.handleUserLogout();
				authContext.authObj.logout();
				logOut();
				return <Redirect to="/" />
			default:
				return <HomeComponent />;
		}
	}
};

export default Layout;
