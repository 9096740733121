import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	formValidationMail: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxWidth: "600px"
	}
}));

export {
	useStyles,
}
