import { Container, CssBaseline, Typography } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { YumButton } from "../../../Lib/UI/YumButton";
import { StorageServiceManager } from "../../../services/storageManager";
import { AppState, IStripeBilling, UserInfos } from "../../../types";
import { DAYS_UNTIL_TRIAL_END_POP_UP } from "../../../types/constantes/constantes";
import DisplayOffers from "../../DisplayOffers";
import useStyles from "./styles";

interface Props {
    DaysUntilEnd: number,
    billingdata: IStripeBilling | undefined;
    userdata: UserInfos | undefined
}

const PopUpBillingStatus: React.FC<Props> = ({ billingdata, userdata, DaysUntilEnd }: Props) => {

	const classes = useStyles();
    const [subscribe, setSuscribe] = React.useState<boolean>(false);

    useEffect(() => {
        StorageServiceManager.getStorageService().setData(DAYS_UNTIL_TRIAL_END_POP_UP, true, true);
    }, []);

    function subscribeInverse() {
        setSuscribe(!subscribe);
    }

    function displaySentence(daysUntil: number) {
        let daysSupTo0 = `ta période d'essaie se termine dans ${daysUntil} jours et tes clients ne pourront alors plus profiter des fonctionnalitées de yumscan`;
        let daysEqualsTo0 = "ta période d'essaie vient de prendre fin et tes clients ne peuvent plus profiter des fonctionnalitées de yumscan";

        return daysUntil > 0 ? daysSupTo0 : daysEqualsTo0;
    }

    return (
        <Fragment>
            <CssBaseline />
                {!subscribe && 
                <Container component="div" maxWidth="md" className={classes.root}>
                     <Typography 
                        component="h1"
                        variant="subtitle1"
                        className={classes.HighlightLine}                       
                        gutterBottom>
                            {userdata?.userinfos.firstname},
                        </Typography>
                    <Typography 
                        component="h1"
                        variant="body1"                        
                        gutterBottom
                        className={classes.margin}>
                        {displaySentence(DaysUntilEnd)}.<br />☹️
                        </Typography>
                    <Typography
                        component="h2"
                        variant="body1"  
                        className={classes.margin}
                        gutterBottom>Faire partie de la communauté Yummer t'as plus ? pense a t'abonner</Typography>
                    <YumButton 
                        onClick={subscribeInverse}>                    
                        s'abonner dès maintenant
                    </YumButton>
                </Container>}
                {subscribe && 
                 <DisplayOffers 
                    Advanced={false} 
                    OnClose={() => { console.log("premier implementation") }} 
                    OnOpen={() => { console.log("premier implementation") }} />}
        </Fragment>
    )
  
  }

export default connect(
	(state: AppState) => ({
		billingdata: state.billing.billing,
        userdata: state.user.userdata,
	}),
    {}
)(PopUpBillingStatus);


//export default PopUpBillingStatus;
