import { SET_COLOR } from "./actionTypes";
import { ReduxAction } from "./types";
import { ApplicationDataState } from "../../../types";
import { InitialAppColorization } from "../../../types/constantes/constantes";

const initialState: ApplicationDataState = {
	Color: InitialAppColorization,
};

export default (state = initialState, action: ReduxAction<any>) => {
	switch (action.type) {
		case SET_COLOR:
			//console.log('set app color ->', action.payload);
			return {
				...state,
				Color: action.payload,
			};
		default:
			return state;
	}
};
