import React from "react";
import Layout from "./DigitalCardManagement";
import { AppState, 
  //UserState 
} from "../../types";
import { connect } from "react-redux";

interface Props {
  back: () => void;
}

const DigitalCard: React.FC<Props> = ({back}: Props) => (
  <Layout backToDefault={back} />
);



export default connect(
  (state: AppState) => ({
    userdata: state.user,
  }),
  null
)(DigitalCard);
