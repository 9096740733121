import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: 'initial',
		minHeight: '100%',
		paddingTop: "3vh"
	},
	subRoot: {
		padding: "3vh 1vw"
	},

	/** Partie details de l'abonnement */
	billingDiv: {
		width: "100%",
		display: "flex", 
		justifyContent: "center", 
		alignItems: "center",
	},
	title: {
		textTransform: "uppercase", 
		margin: "15px 0",
		color: theme.palette.primary.main,
		fontWeight: "bold",
	},

	manageBillingDiv: {
		display:"flex",
		flexDirection: "row"
	},

	/** General */
	uppercaseText: {
		textTransform: "uppercase", 
	},
	mg_1v: {
		margin: "2vh 0"
	}
}));

export default useStyles;
