import { LOGIN_USER, LOGGOUT_USER, SET_USERDATA } from "./actionTypes";
import { ITrader, UserInfos, UserType } from "../../types";

export const LoginUser = (user: UserInfos, type: UserType) => ({
	type: LOGIN_USER,
	payload: {
		userdata: user,
		role: undefined,
	},
});

export const LogOutUser = () => ({
	type: LOGGOUT_USER,
	payload: {
		token: undefined,
		email: undefined,
		id: undefined,
	},
});

export const SetUserData = (trader: ITrader) => ({
	type: SET_USERDATA,
	payload: {
		trader: trader,
	},
});
