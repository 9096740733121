import React, { Fragment, useEffect } from "react";
import useStyles from "./styles";
import { ICard } from "../../../types";
import { CircularProgress, CssBaseline, Grid, TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { APIManager } from "../../../services";
import { useSnackbar } from "notistack";
import clsx from "clsx";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import QuickCreateQrCode from "../../../components/QuickCreateQrCode/QuickCreateQrCode";
import { Formik } from "formik";
import * as Yup from "yup";
import ManageQrcodeDigital from "../ManageQrcodeDigital";
import { YumButton } from "../../../Lib/UI/YumButton";


interface Props {
	idCard?: string;
	back: () => any;
	next: (card: ICard) => any;
}

//const url_server = process.env.REACT_APP_BASE_URL;

enum STATE_PAGE_CREATECARD {
	CREATE_QRCODE,
	CREATE_CARD,
	CRUD_CATEGORIE,
	CRUD_DISH_MENU,
}

const Layout: React.FC<Props> = ({ idCard, back, next }: Props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	//let authContext = useContext(store);

	const [pageState, setPageState] = React.useState<STATE_PAGE_CREATECARD>(STATE_PAGE_CREATECARD.CREATE_CARD);
	const [currentCard, setCurrentCard] = React.useState<ICard | undefined>(undefined);

	return (
		<Fragment>
			<CssBaseline />
			<div className={classes.root}>
				{pageState === STATE_PAGE_CREATECARD.CRUD_DISH_MENU && <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
					<div onClick={() => {
						setPageState(STATE_PAGE_CREATECARD.CRUD_CATEGORIE);
					}} className={clsx(classes.backArrow,classes.makeLink)} >
						<ArrowBackIcon style={{ margin: "0 1vw" }}/>
						<Typography align="left" component="span" variant="subtitle1" gutterBottom>Retour</Typography>
					</div>
				</div>}
				<DynamicComponent />
			</div>
		</Fragment>)

	function CreateCard(props: any) {
		const [isCardSubmited, setIsCardSubmited] = React.useState<boolean>(false);
		const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

		return (
			<Fragment>
				<Typography variant="h6" component="span"  align="center" className={classes.highlightTitle} gutterBottom> Nommez votre carte </Typography>
				<Formik
					initialValues={{ 
						cardName: "",
					}}
					validateOnChange={false}
					onSubmit={(values) => {
						setIsSubmitting(true);
						console.log("launch verification !");

						APIManager.getCardServices().createCard(values.cardName).then((card: ICard) => {
							setCurrentCard(card);
							setPageState(STATE_PAGE_CREATECARD.CREATE_QRCODE);
						}).catch(() => {
							enqueueSnackbar(`La carte ${values.cardName} n'a pa pu être crée.`, { 
								variant: "error",
								anchorOrigin: {
									vertical: 'top',
									horizontal:'right',
								}
							});
						})
						.finally(() => {
							setIsSubmitting(false);
						})
					}}
					validationSchema={Yup.object().shape({
						cardName: Yup.string().min(1).required("Champ obligatoire"),
					})}
					>
					{props => (
						<div style={{ display: "flex", flexDirection: "row", width: "100%"}}>
							 <Grid container spacing={2} alignItems="center" justify="center" >
								<Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={6} 
                                    md={10}>
									<TextField
										variant="outlined"
										margin="normal"
										id="cardName"
										type="text"
										label="Nom de votre carte"
										name="cardName"
										placeholder="Vins & boissons🥂🍷,  Alcool 🥃🍸, Dégustation 🍽️"
										value={props.values.cardName}
										onChange={props.handleChange}
										onBlur={props.handleBlur}
										error={isCardSubmited && props.errors.cardName ? true : false}
										helperText={isCardSubmited ? props.errors.cardName : undefined}
										required
										fullWidth
									/>
								</Grid>
								<Grid 
                                    item 
                                    component='div'
                                    xs={12} 
                                    sm={12} 
                                    md={2}>
									<YumButton 
										onClick={(e) => {
											setIsCardSubmited(true);
											props.handleSubmit();
										}}>
										{isSubmitting ? <CircularProgress /> : "Créer"}
									</YumButton>
								</Grid>
							</Grid>							
						</div>)
					}
				</Formik>
			</Fragment>
		);
	}

	function QrcodeCreation(props: any) {

		const [exceedLimit, setExceedLimit] = React.useState<boolean | undefined>(undefined);

		const QrcodeExceedLimit = async () => {
			try {

				let limit = await APIManager.getAccountServices().qrcodeLimitExceed();

				setExceedLimit(limit);
			} catch (err) {
				setExceedLimit(true);
			}
		}

		useEffect(() => {
			if (exceedLimit !== undefined) {
				return;
			}
			QrcodeExceedLimit();
		}, [exceedLimit]);

		return (
			<Fragment>
				<Typography component="span" variant="h6" align="center" className={classes.highlightTitle} gutterBottom> Ajoutez un qrcode à votre carte </Typography>

				{exceedLimit && <Fragment>
					<Typography component="span" variant="body2" align="center" gutterBottom>Vous avez atteint votre nombre de qrcode maximal, choissisez un qrcode existant à ajouter à votre carte "<Typography component="span" variant="body1">{currentCard?.name}</Typography>"</Typography>
					<ManageQrcodeDigital back={() => { if (currentCard) {
								next(currentCard);
							}}} card={currentCard as ICard}/>
					</Fragment>}

				{!exceedLimit && <QuickCreateQrCode
						onCreate={(qrcode) => {
							enqueueSnackbar(`Le qrcode ${qrcode.name} a été crée`, { 
								variant: "success",
								anchorOrigin: {
									vertical: 'top',
									horizontal:'right',
								}
							});

							if (currentCard) {
								next(currentCard);
							}
						}}
						IdCard={currentCard?._id as string}
						onError={(error) => {
							console.log(error);
							enqueueSnackbar(error.message.indexOf("204") > -1 ? `Le qrcode n'a pas pu être crée, ré-éssayez, si le problème persiste contactez le service client.` : "Vous avez atteint votre limite de creation de qrcodes maximale.", { 
								variant: "error",
								anchorOrigin: {
									vertical: 'top',
									horizontal:'right',
								}
							});
							setExceedLimit(true);

							/*if (currentCard) {
								next(currentCard);
							}*/
						}}
					/>}
			</Fragment>
		);
	}

	function DynamicComponent(props: any) {

		switch(pageState) {
			case STATE_PAGE_CREATECARD.CREATE_QRCODE:		
                return <QrcodeCreation />;
			case STATE_PAGE_CREATECARD.CREATE_CARD:
			default:
				return <CreateCard />;
        }
	}
};

export default Layout;
