import React, { Fragment, useContext, useEffect } from "react";

import {
	Container,
	Typography,
	Divider,
	CircularProgress,
	Grid,
	TextField,
	Button,
	IconButton,
	Tooltip,
} from "@material-ui/core";
import useStyles from "./styles";

import { ITrader, IPackYum, AppState, UserState } from "../../../types";

import { APIManager } from "../../../services";
//import BillingPayement from "../../BillingPayement";
import { connect } from "react-redux";
import { store } from "../../../contexts/auth";
import { useSnackbar } from "notistack";
import ColorPicker from "material-ui-color-picker";
import { Formik } from "formik";
import * as Yup from "yup";
import { IMG_CONSTANTES } from "../../../types/constantes/constantes";
import { DeleteForever } from "@material-ui/icons";

//import Resizer from 'react-image-file-resizer';
//import { Alert, AlertTitle } from "@material-ui/lab";

interface Props {
	userstate: UserState;
}

const Layout: React.FC<Props> = ({ userstate }: Props) => {
	const classes = useStyles();
	let authContext = useContext(store);
	const { enqueueSnackbar } = useSnackbar();
	const inputRef = React.useRef<HTMLInputElement>(null);

	const [
		//isError
		, setError] = React.useState<boolean>(false);
	const [
		//currentPack
		, setCurrentPack] = React.useState<IPackYum | undefined>(undefined);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [file, setFile] = React.useState<File | null>(null);
	const [isSubmited, setIsSubmited] = React.useState<boolean>(false);
	const [currentInfoTrader, setCurrentInfoTrader] = React.useState<{} | ITrader>(authContext.authObj.userProfile);


	const GetPackForConnectedTrader = async (idTrader: string) => {

		try {
			let pack = await APIManager.getAccountServices().getPackForTrader(idTrader);
			
			setCurrentPack(pack);
		} catch (err) {
			console.log("Recupération pack", err);
			enqueueSnackbar("Une erreur inconnue est survenue veuillez recharger la page ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
			setError(true);
		} finally {
			setIsLoading(false);
		}
	}

	const getProfilConnected = async () => {
		try {
			await authContext.authObj.getProfile();
			setCurrentInfoTrader(authContext.authObj.userProfile);
		} catch (err) {
			enqueueSnackbar("Une erreur inconnue est survenue veuillez recharger la page ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		}
	}
	
	const getManuallyProfilData = async () => {
		try  {
			let traderInfos = await APIManager.getAccountServices();
			if (traderInfos){
				setCurrentInfoTrader(traderInfos);
			}
		} catch (except) {
			console.log("exception !", except);
			enqueueSnackbar("Une erreur inconnue est survenue veuillez recharger la page ou contacter le service client via contact@yumscan.fr ou le formulaire de contact si le problème persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		}
	}

	const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileEvent = e.target.files?.item(0);
		//let formData = new FormData();
		if (!fileEvent) {
			return;
		}
		setFile(fileEvent);
		// Todo resize image logo
		/*
		var reader = new FileReader();
		reader.onload = function (e) {
			var img = new Image;
			img.onload = function() {
				console.log("The width of the image is " + img.width + "px. and height is "+ img.height, Math.min(img.width / img.height));
				// Image carré
				if (Math.min(img.width / img.height) == 1) {
					setFile(fileEvent);
				} else {
					// TODO RESIZE IMAGE
					let newSize = resize(img.width , img.height);
					console.log("new size :", newSize);
					try {
						Resizer.imageFileResizer(
							fileEvent,
							newSize.w,
							newSize.h,
							'JPEG',
							100,
							0,
							uri => {
								console.log(uri);
								setFile(uri as File);
							},
							'file',
							100,
							100,
						);
					} catch(err) {
						//setFile(fileEvent);
						//onFileChange(fileEvent);
						enqueueSnackbar(`Impossible de redéfinir la taille de l'image téléchargé.`, { 
							variant: "success",
							anchorOrigin: {
								vertical: 'top',
								horizontal:'right',
							}
						});
					}
				}
			};
			img.src = reader.result as string;
		};
        reader.readAsDataURL(fileEvent);
		*/
	}

/* 	function resize(width: number, height: number): {w: number, h: number } {
		var ratio = Math.min( 720 / width, 252 / height);
		var newWidth = ratio * width;
		var newHeight = ratio * height;
	
		//console.log(newWidth + ' ' + newHeight); // Test
		return {
			w: newWidth,
			h: newHeight
		};
	} */
	
	const getImageForLogo = () => {
		if ((authContext.authObj.userProfile as ITrader).logo_url && !file) {
			return (authContext.authObj.userProfile as ITrader).logo_url;
		} else if (file) {
			return URL.createObjectURL(file);
		} else {
			return IMG_CONSTANTES.DEFAULT_IMG;
		}
	}

	useEffect(() => {
		if (!isLoading || authContext.authObj.userProfile !== {}) {
			return;
		}
		// Récupère le pack du commercant connecté.
		getProfilConnected();		
	}, [authContext.authObj.userProfile]);

	useEffect(() => {
		if (!isLoading || authContext.authObj.userProfile === {}) {
			return;
		}
		// Récupère le pack du commercant connecté.
		GetPackForConnectedTrader((authContext.authObj.userProfile as ITrader)._id as string);		
	}, [ authContext.authObj.userProfile, isLoading ]);

	const removeLogoFromTrader = () => {
		APIManager.getAccountServices().RemoveAppSettingsInformationsLogo()
		.then((res) => {
			enqueueSnackbar(res ? "Votre logo a été supprimé." : "Votre logo n'a pas pu être supprimé. ", { 
				variant: res ? "success" : "warning",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		})
		.catch(() => {
			enqueueSnackbar("Une erreur est survenue, merci de contacter le service client si celle-ci persiste", { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});
		})
	}

	return (
		<Fragment>
			<Container className={classes.root} maxWidth="lg" component="main">
				{!isLoading && 
					(<div style={{ width: "100%" }}>
						{/*<section className={classes.mg_1v}>
							<Alert className={classes.mg_1v} severity="info">
								<AlertTitle><strong>Yummer Astuce</strong></AlertTitle>
								Vous pouvez personnaliser l'affichage de la couleur des titres de votre carte digital en modifiant la couleur du champ <strong>"Principal"</strong>.
							</Alert>
							<Alert className={classes.mg_1v} severity="info">
								<AlertTitle><strong>Yummer Astuce</strong></AlertTitle>
								Vous pouvez personnaliser l'affichage de la couleur de l'arrière-plan de la categorie selectionné de votre carte digital en modifiant la couleur du champ <strong>"Secondaire"</strong>.
							</Alert>
							<Alert className={classes.mg_1v} severity="info">
								<AlertTitle><strong>Yummer Astuce</strong></AlertTitle>
								Vous pouvez personnaliser l'affichage de la couleur de l'arrière-plan de votre logo sur votre carte digital en modifiant la couleur du champ <strong>"Arrière-plan"</strong>.
							</Alert>		
						</section>*/}
						<section>
							<Typography
								className={classes.title}
								variant="h6" 
								component="h2" 
								gutterBottom 
								align="center" 
								>
								Paramétrage de votre application
							</Typography>
							<Divider />
							<Formik
								initialValues={{ 
									principalColor: (currentInfoTrader as ITrader).css_options?.primaryColor,
									secondaryColor: (currentInfoTrader as ITrader).css_options?.secondaryColor,
									backgroundColor: (currentInfoTrader as ITrader).css_options?.textColor,
									website: (currentInfoTrader as ITrader).metadata?.webSite,
									etablishment_phone: (currentInfoTrader as ITrader).metadata?.etablishment_phone,
									etablishment_description: (currentInfoTrader as ITrader).establishment_description,
									annoucement: (currentInfoTrader as ITrader).metadata?.annoucement,
									contact:  (currentInfoTrader as ITrader).metadata?.contact,
									facebook: (currentInfoTrader as ITrader).metadata?.social_network?.facebook,
									instagram: (currentInfoTrader as ITrader).metadata?.social_network?.instagram,
									twitter: (currentInfoTrader as ITrader).metadata?.social_network?.twitter,
								}}
								validateOnChange={false}
								validationSchema={Yup.object().shape({
									principalColor: Yup.string(),
									secondaryColor: Yup.string(),
									backgroundColor: Yup.string(),
									website: Yup.string(),
									etablishment_phone: Yup.string(),
									etablishment_description: Yup.string(),
									annoucement: Yup.string(),
									contact: Yup.string(),
									facebook: Yup.string(),
									instagram: Yup.string(),
									twitter: Yup.string(),
								})}
								onSubmit={(values) => {
									
									let form = new FormData();

									setIsLoading(true);

									console.log("annoncement ", values.annoucement);
									console.log("profile prev update :", currentInfoTrader);


									let metadata = {
										webSite: values.website === '' ? undefined : values.website,
										etablishment_phone: values.etablishment_phone === '' ? undefined : values.etablishment_phone,
										contact: values.contact === '' ? undefined : values.contact,
										annoucement: values.annoucement === '' ? undefined : values.annoucement,
										social_network: {
											facebook: values.facebook === '' ? undefined : values.facebook,
											instagram: values.instagram === '' ? undefined : values.instagram,
											twitter: values.twitter === '' ? undefined : values.twitter,
										},
									}

									let css_options = {
										textColor: values.backgroundColor,
										primaryColor: values.principalColor,
										secondaryColor: values.secondaryColor,
									};
								
									form.append("establishment_description", values.etablishment_description ? values.etablishment_description : '');
									form.append("logo", file as File); 
									form.append("css_options", JSON.stringify(css_options));
									form.append("metadata", JSON.stringify(metadata));

									APIManager.getAccountServices().UpdateAppSettingsInformations(form).then((result) => {									
										enqueueSnackbar(result ? `Vos réglages d'application ont été pris en compte` : "Vos régléges d'application ont été pris en compte mais votre logo n'a pa pu être telechargé", { 
											variant: result ? "success" : "warning",
											anchorOrigin: {
												vertical: 'top',
												horizontal:'right',
											}
										});
										setTimeout(() => {
											getProfilConnected();
											console.log("profile after update settimeout :", currentInfoTrader);
											setIsLoading(false);
										}, 3000);
										
									}).catch((err) => {
										enqueueSnackbar(`Vos réglages d'application n'ont pas été pris en compte`, { 
											variant: "error",
											anchorOrigin: {
												vertical: 'top',
												horizontal:'right',
											}
										});
										setIsLoading(false);

									}).finally(() => {
										getManuallyProfilData();
										console.log("profile after update :", currentInfoTrader);
									})
								}}>
								{formProps => (
									<form onSubmit={formProps.handleSubmit}>
										<div className={classes.subRoot} >
											<div style={{ display: "flex", flexDirection:"column", justifyContent: "center" , alignItems: "center"}}>
												<div style={{ display: "flex", justifyContent: "center"}}>
													<div className={classes.imgContainer}>
														{!file && (<div className={classes.imgBackgroundMinimumRequierement}>
															<Typography variant="h5">{`${340} x ${150}`} Minimum </Typography>
														</div>)}
														<img alt="img" style={{ width: "100%", height: "100%" }} src={getImageForLogo()} />
													</div>
												</div>
												<div className={classes.m_10}>
													<Button color="secondary" onClick={() => {
														if (inputRef.current) {
															inputRef.current.click();
														}
													}} style={{ alignSelf: "center" }} variant="contained" size="large">Télèchargez votre logo</Button>
													<Tooltip style={{ margin: "0 1vw" }} title="Supprimer le logo">
														<IconButton
															color="secondary"
															aria-label="delete"
															onClick={(event) => {
																removeLogoFromTrader();
															}}>
															<DeleteForever color="secondary"  />
														</IconButton>
													</Tooltip>
												</div>
												<input style={{ display: "none" }} ref={inputRef} accept="image/*" onChange={handleFileSelected} type="file" />
											</div>
											<Typography 
												variant="h4" 
												component="h2"
												gutterBottom>
												Couleurs
											</Typography>
											<Grid style={{ margin: "1vh 0 3vh 0" }} container spacing={2} alignItems="center" justify="center" >
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={4} 
													md={4}>
													<div className={classes.settingsColor}>
														<Typography 
															variant="h5" 
															component="h2">
															Principal:
														</Typography>
														<ColorPicker
															name='color'
															defaultValue='#000'
															id="principalColor"
															// value={this.state.color} - for controlled component
															onChange={(color) => {
																formProps.setFieldValue('principalColor', color);
                                                                console.log("color principal", color);
															}}
															value={formProps.values.principalColor ? formProps.values.principalColor : undefined}
															/>
														<div className={classes.settingColorViewer} style={{ backgroundColor: formProps.values.principalColor ? formProps.values.principalColor : "#000" }}>
														</div>
													</div>
												</Grid>
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={4} 
													md={4}>
													<div className={classes.settingsColor}>
														<Typography 
															variant="h5" 
															component="h2">
															Secondaire:
														</Typography>
														<ColorPicker
															name='color'
															defaultValue='#000'
															id="secondaryColor"
															// value={this.state.color} - for controlled component
															onChange={(color) => {
																formProps.setFieldValue('secondaryColor', color);
															}}
															value={formProps.values.secondaryColor ? formProps.values.secondaryColor : undefined}
															/>
														<div className={classes.settingColorViewer} style={{ backgroundColor: formProps.values.secondaryColor ? formProps.values.secondaryColor : "#000" }}>
														</div>
													</div>
												</Grid>
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={4} 
													md={4}>
													<div className={classes.settingsColor}>
														<Typography 
															variant="h5" 
															component="h2">
															Arrière-plan :
														</Typography>
														<ColorPicker
															name='color'
															defaultValue='#000'
															id="backgroundColor"
															// value={this.state.color} - for controlled component
															onChange={(color) => {
																formProps.setFieldValue('backgroundColor', color);
															}}
															value={formProps.values.backgroundColor ? formProps.values.backgroundColor : undefined}
															/>
														<div className={classes.settingColorViewer} style={{ backgroundColor: formProps.values.backgroundColor ? formProps.values.backgroundColor : "#000" }}>
														</div>
													</div>
												</Grid>
											</Grid>
											<Typography 
												variant="h4" 
												component="h2"
												gutterBottom>
												Données marketing
											</Typography>
											<Grid style={{ margin: "1vh 0 3vh 0" }} container spacing={2} alignItems="center" justify="center" >
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={6} 
													md={6}>
													<div className={classes.settingsColor}>
														<TextField
															id="website"
															type="text"
															label="Votre site"
															name="website"
															placeholder="Exemple: www.votreSite.com, ..."
															fullWidth
															variant="outlined"
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															value={formProps.values.website}
															error={isSubmited && formProps.errors.website ? true : false}
															helperText={isSubmited && formProps.errors.website}
														/>
													</div>
												</Grid>
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={6} 
													md={6}>
													<div className={classes.settingsColor}>
														<TextField
															id="etablishment_phone"
															type="text"
															label="Numéro commercial"
															name="etablishment_phone"
															placeholder="Exemple: 0145678998"
															fullWidth
															variant="outlined"
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															value={formProps.values.etablishment_phone}
															error={isSubmited && formProps.errors.etablishment_phone ? true : false}
															helperText={isSubmited && formProps.errors.etablishment_phone}
														/>
													</div>
												</Grid>
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={6} 
													md={6}>
													<div className={classes.settingsColor}>
														<TextField
															id="etablishment_description"
															type="text"
															label="Description de votre établissement"
															name="etablishment_description"
															placeholder="Exemple: Yumscan est une application "
															fullWidth
															variant="outlined"
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															value={formProps.values.etablishment_description}
															error={isSubmited && formProps.errors.etablishment_description ? true : false}
															helperText={isSubmited && formProps.errors.etablishment_description}
														/>
													</div>
												</Grid>
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={6} 
													md={6}>
													<div className={classes.settingsColor}>
														<TextField
															id="contact"
															type="text"
															label="mail commercial"
															name="contact"
															placeholder="Exemple: contact@votre-site.com"
															fullWidth
															variant="outlined"
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															value={formProps.values.contact}
															error={isSubmited && formProps.errors.contact ? true : false}
															helperText={isSubmited && formProps.errors.contact}
														/>
													</div>
												</Grid>
												<Grid 
													item 
													component='div'
													style={{ display:"flex", flexDirection:"row", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={12} 
													md={12}>
													<div className={classes.settingsColor}>
														<TextField
															id="annoucement"
															type="text"
															label="Annonce"
															name="annoucement"
															placeholder="Exemple: Happy hour de 19h a 23h"
															fullWidth
															variant="outlined"
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															value={formProps.values.annoucement}
															error={isSubmited && formProps.errors.annoucement ? true : false}
															helperText={isSubmited && formProps.errors.annoucement}
														/>
														<Button
															color="secondary"
															style={{ alignSelf: "center", marginLeft: 15 }}
															disabled={formProps.values.annoucement === undefined}
															onClick={(e) => {
																e.preventDefault();
																formProps.setFieldValue('annoucement', undefined, false);
															}}
															variant="contained" 
															size="large">
															réinitialiser
														</Button>
													</div>
												</Grid>
											</Grid>
											<Typography 
												variant="h4" 
												component="h2"
												gutterBottom>
												Réseau sociaux
											</Typography>
											<Grid style={{ margin: "1vh 0 3vh 0" }} container spacing={2} alignItems="center" justify="center" >
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={4} 
													md={4}>
													<div className={classes.settingsColor}>
														<TextField
															id="facebook"
															type="text"
															label="lien vers facebook"
															name="facebook"
															placeholder="Exemple: www.facebook.com/Yumscan-100548318750789"
															fullWidth
															variant="outlined"
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															value={formProps.values.facebook}
															error={isSubmited && formProps.errors.facebook ? true : false}
															helperText={isSubmited && formProps.errors.facebook}
														/>
													</div>
												</Grid>
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={4} 
													md={4}>
													<div className={classes.settingsColor}>
														<TextField
															id="instagram"
															type="text"
															label="lien instagram"
															name="instagram"
															placeholder="Exemple: https://www.instagram.com/yum.scan"
															fullWidth
															variant="outlined"
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															value={formProps.values.instagram}
															error={isSubmited && formProps.errors.instagram ? true : false}
															helperText={isSubmited && formProps.errors.instagram}
														/>
													</div>
												</Grid>
												<Grid 
													item 
													component='div'
													style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
													xs={12} 
													sm={4} 
													md={4}>
													<div className={classes.settingsColor}>
														<TextField
															id="twitter"
															type="text"
															label="lien twitter"
															name="twitter"
															placeholder="Exemple: www.twitter.com/yumscan"
															fullWidth
															variant="outlined"
															onChange={formProps.handleChange}
															onBlur={formProps.handleBlur}
															value={formProps.values.twitter}
															error={isSubmited && formProps.errors.twitter ? true : false}
															helperText={isSubmited && formProps.errors.twitter}
														/>
													</div>
												</Grid>
											</Grid>
											<Button
												color="primary"
												style={{ alignSelf: "center" }}
												disabled={isLoading}
												onClick={(e) => {
													setIsSubmited(true);
													//let form = new FormData();

													setIsLoading(true);
													e.preventDefault();
													console.log("annoncement ", formProps.handleSubmit);
													formProps.handleSubmit();
													/*let metadata = {
														webSite: formProps.values.website,
														etablishment_phone: formProps.values.etablishment_phone,
														contact: formProps.values.contact,
														annoucement: formProps.values.annoucement?.length > 0 ? formProps.values.annoucement : undefined,
														social_network: {
															facebook: formProps.values.facebook,
															instagram: formProps.values.instagram,
															twitter: formProps.values.twitter,
														},
													}

													let css_options = {
														textColor: formProps.values.backgroundColor,
														primaryColor: formProps.values.principalColor,
														secondaryColor: formProps.values.secondaryColor,
													};
												
													form.append("establishment_description", formProps.values.etablishment_description ? formProps.values.etablishment_description : '');
													form.append("logo", file as File); 
													form.append("css_options", JSON.stringify(css_options));
													form.append("metadata", JSON.stringify(metadata));

													APIManager.getAccountServices().UpdateAppSettingsInformations(form).then((result) => {									
														enqueueSnackbar(result ? `Vos réglages d'application ont été pris en compte` : "Vos régléges d'application ont été pris en compte mais votre logo n'a pa pu être telechargé", { 
															variant: result ? "success" : "warning",
															anchorOrigin: {
																vertical: 'top',
																horizontal:'right',
															}
														});

													}).catch((err) => {
														enqueueSnackbar(`Vos réglages d'application n'ont pas été pris en compte`, { 
															variant: "error",
															anchorOrigin: {
																vertical: 'top',
																horizontal:'right',
															}
														});
													}).finally(() => {
														GetProfilConnected();
														setIsLoading(false);
													})*/
												}}
												variant="contained" 
												size="large">
												Mettre à jour
											</Button>
										</div>
									</form>)}
							</Formik>
						
						</section>
												
					</div>)}
				{isLoading && 
					(<div className={classes.billingDiv}>
						<CircularProgress 
							color="primary" 
							size={50} />
					</div>)}
			</Container>
		</Fragment >
	);
};


export default connect(
	(state: AppState) => ({
		userstate: state.user
	}),
	{
	}
)(Layout);
