import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flex: 1,

	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		fontSize: '4em',
	},
	link: {
		textDecoration: 'none',
		marginLeft: "5px",
		marginRight: "5px",
		color: "white",
		fontWeight: 'bold',
	},
	root: {
		boxShadow: "initial",
		backgroundColor: "initial",
		paddingTop: "15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	menu: {
		background: 'linear-gradient(35deg, #eaac7e 30%, #9c4809 95%)',
		boxShadow: '-5px 5px 5px #9c4809',
		color: "white",
		display: "flex",
		padding: "10px"
	}
}));

export default useStyles;