import React, { Fragment, useEffect } from "react";
// import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";
import { CssBaseline, 
// 	Badge, 
	//AppBar, 
	//Toolbar, 
    Chip,
    //Avatar,
    Button,
    Paper,
    FormControlLabel,
    //Checkbox,
    Divider,
    FormControl,
    RadioGroup,
    Radio,
    CircularProgress,
    Snackbar,
    //Snackbar
} from "@material-ui/core";
import { APIManager } from "../../services";
import { IPdfCard, IQrcode } from "../../types";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useSnackbar } from "notistack";
import QuickCreateQrCode from "../QuickCreateQrCode/QuickCreateQrCode";
import { SnackDataItem } from "../../utils";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { YumButton } from "../../Lib/UI/YumButton";
import { AddIcon } from "@material-ui/data-grid";



interface Props {
	menus?: any;
	dishes?: any;
    back: () => void;
}

const LinkCardManage: React.FC<Props> = ({ back }: Props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

    const inputRef = React.useRef<HTMLInputElement>(null);

    const colorGreen = { color: "green" };
    const colorRed = { color: "red" };

    const [qrcodes, setQrcodes] = React.useState<IQrcode[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>('');
    const [selectedQrcode, setSelectedQrcode] = React.useState<IQrcode | undefined>(undefined);
    const [stepper, setStepper] = React.useState<number>(0);
    const [qrcodesLimitExceed, setQrcodeLimitExceed] = React.useState<boolean | undefined>(undefined);
    const [openSnack, setOpenSnack] = React.useState<boolean>(false);
    const [snackData, setSnackData] = React.useState<SnackDataItem | undefined>(undefined);
    const [qrcodeCreated, setQrcodeCreated] = React.useState<IQrcode | undefined>(undefined);
    const [isRequestingHome, setIsRequestingHome] = React.useState<boolean>(false);
    const [pdfLink, 
        //setPdfLink
    ] = React.useState<string | undefined>(undefined);
    const [homePdf, setHomePdf] = React.useState<string | undefined>(pdfLink);

    const OnQrcodeUpdate = async () => {
		try  {
			let limit = await APIManager.getAccountServices().qrcodeLimitExceed();
			
			setQrcodeLimitExceed(limit);

		} catch(err) {
			console.log("Erreur limit qrcode", err);
			setQrcodeLimitExceed(undefined);
		}		
	};

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      setSelectedQrcode(qrcodes.find((qr) => qr._id === event.target.value));
    };

    const handleChangeCreatePDF = (event: React.ChangeEvent<HTMLInputElement>) => {
        let findQrcode = qrcodes.find((qr) => qr._id === event.target.value);

        if (findQrcode)  {
            setValue(event.target.value);
            setStepper(2);
            setOpenSnack(true);
            setSnackData({
                severity: "success",
                msg: `le qrcode ${findQrcode.name} a été séléctionné.`
            });
            setQrcodeCreated(findQrcode);
        }
    };

    const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.item(0);
        let formData = new FormData();

        if (!file) {
            enqueueSnackbar("Aucun fichier n'a été fournis", {
                variant: "warning",
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                }
            });
            return;
        }
        if (!selectedQrcode) {
            enqueueSnackbar("Aucun qrcode n'a été selectionné", {
                variant: "warning",
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                }
            });
            return;
        }
        if (file.type !== "application/pdf" 
        && file.type !== "image/gif" 
        && file.type !== "image/png"
        && file.type !== "image/bmp"
        && file.type !== "image/jpeg"
        && file.type !== "image/webp") {
            enqueueSnackbar("Le fichier renseigné n'est pas un pdf", {
                variant: "warning",
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                }
            });
            return;
        }
        // Check taille du pdf max pour ne pas saturé le serveur
        formData.append('files', file);
        setIsRequesting(true);

        //console.log("Id selected :", selectedQrcode._id as string);
        APIManager.getCardServices().UploadPDF(selectedQrcode._id as string,formData).then((res: boolean) => {
            if (res) {
                enqueueSnackbar("Votre carte a été mis a jour avec succès", {
                    variant: "info",
                    anchorOrigin: {
                        horizontal: "right",
                        vertical: "top"
                    }
                });
                /*if (authContext.authObj.userProfile !== {}){
                    console.log("reload page !");
                    GetPdfAsync((authContext.authObj.userProfile as ITrader)._id as string);
                }*/
            } else {
                enqueueSnackbar("Votre qrcode n'as pu être mis à jour, si le probleme persiste contacter le service client.", {
                    variant: "error",
                    anchorOrigin: {
                        horizontal: "right",
                        vertical: "top"
                    }
                })
            }
            actualizeQrcode().then(() => {
                setSelectedQrcode(qrcodes.find(qr => qr._id === selectedQrcode._id));
            })
        }).catch((err: Error) => {
            enqueueSnackbar(err.message, {
                variant: "error",
                anchorOrigin: {
                    horizontal: "right",
                    vertical: "top"
                }
            });
        }).finally(() => {
            setIsRequesting(false);
        });
    }

    const handleFileSelectedCreatePDF = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.item(0);
        let formData = new FormData();
        //setIsLoading(true);

        if (!file 
            || (file.type !== "application/pdf" 
            && file.type !== "image/gif" 
            && file.type !== "image/png"
            && file.type !== "image/bmp"
            && file.type !== "image/jpeg"
            && file.type !== "image/webp")) {
            // display error
            setOpenSnack(true);

            setSnackData({
                severity: file ? "warning" : "error",
                msg: file ? "Le fichier doit être au format pdf" : "vous n'avez soumis aucun fichier",
            });
            return;
        }
        if (!qrcodeCreated) {
            setOpenSnack(true);
            setSnackData({
                severity: "error",
                msg: `Aucun qrcode n'a été lors de l'étape précedente`,
            });
            return;
        }
        // Check taille du pdf max pour ne pas saturé le serveur
        formData.append('files', file);
        setIsRequestingHome(true);
        APIManager.getCardServices().UploadPDF(qrcodeCreated._id as string,formData).then((res: boolean) => {
            //console.log("Resultat getcardservice dans telechargement pdf", res);
                return APIManager.getQrCodeServices().GetQrCodePdfById(qrcodeCreated._id as string).then((path: string | undefined) => {
                    //console.log("Resultat getPdfForTrader pdf", path);
                    if (path) {
                        setHomePdf(path);
                        setOpenSnack(true);
                        setSnackData({
                            severity: "success",
                            msg: `Le pdf a été importé avec succès.`,
                        });
                        setStepper(3);
                    } else {
                        throw new Error("Impossible de charger le lien vers votre pdf");
                    }
                });				
        }).catch((err: Error) => {
            console.log(err);
            setOpenSnack(true);
            setSnackData({
                severity: "error",
                msg: ` ${err.message}`,
            });
        }).finally(() => {
            setIsRequestingHome(false);
        });
    }
    
    const actualizeQrcode = async () => {
        let qrcodeAPI = APIManager.getQrCodeServices();

        try
        {
            let qrcodesServer = await qrcodeAPI.GetAllQrCode();

            setQrcodes([...qrcodesServer]);

        } catch(err) {
            console.log("error loading ", err);
        } finally {
            setIsLoading(false);
        };
    }

    useEffect(() => {
        if (!isLoading){
            return;
        }
        actualizeQrcode();
        OnQrcodeUpdate();
    }, []);

	return (
		<Fragment>
			<CssBaseline />
            <div className={classes.root}>
                {<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%" }}>
					<div onClick={() => {
						back();
					}} className={clsx(classes.backArrow,classes.makeLink)} >
						<ArrowBackIcon style={{ margin: "0 1vw" }}/>
						<Typography align="left" component="span" variant="subtitle1" gutterBottom>Retour</Typography>
					</div>
				</div>}

                {stepper === 0 && (<div>
                    {!qrcodesLimitExceed &&
					(<div style={{ display:"flex", flexDirection: "column", alignItems: "center", textAlign: "center", margin: "2vh 0" }}>
                        <Typography
                            variant="body1"
                            gutterBottom>
                            Cliquez sur le bouton pour créer rapidement votre carte au format PDF.
                        </Typography>
                        <YumButton 
                            onClick={(event) => {
                            setStepper(1);
                        }}>
                            <Typography 
                                component="h1" 
                                variant="body1" >
                                Créer un QrCode PDF
                            </Typography>
                            <AddIcon />
                        </YumButton>
                    </div>)}

                    {qrcodesLimitExceed &&
					(<div className={classes.quickNavSection}>
						<Typography
							variant="body1"
							component="span"
							gutterBottom>
								Vous avez atteint votre <strong>limite</strong> de création de Qrcode. Pour profiter de plus de Qrcodes contactez-nous
						</Typography>
					</div>)}

                    <div className={clsx(classes.title, classes.m_10_vertical)}>
                        <img src="https://ozebpm.stripocdn.email/content/guids/CABINET_71a23039df12c477589addf72ee2bc0f/images/18061612179653922.png" alt="qrcode" />
                        <Typography variant="subtitle1" component="span" align="center">Infos cartes</Typography>
                    </div>
                    <Divider />
                    <article>
                        <section className={classes.m_10_vertical}>
                            <Typography variant="body1" align="left" gutterBottom>{qrcodes.filter((qrcode) => qrcode.pdfCard !== undefined 
                                || qrcode.digitalCard !== undefined).length}/{qrcodes.length} qrcodes lié à une carte</Typography>
                            <div className={classes.chipsDisplay}>
                                {qrcodes.filter((qrcode) => qrcode.pdfCard !== undefined 
                                || qrcode.digitalCard !== undefined).map((qrcode) => 
                                <Chip 
                                    key={qrcode._id as string}
                                    style={{ margin: "10px"}}
                                    label={<div className={classes.chipRoot}>
                                        <Typography variant="body2" component="span" >{qrcode.name}</Typography>
                                        <FiberManualRecordIcon className={classes.FiberManualRecord} style={qrcode.activate ? colorGreen : colorRed} />
                                    </div>}
                                    variant="outlined"
                                />)}
                            </div>
                        </section>
                        <section className={classes.m_10_vertical}>
                            <Typography variant="body1" align="left" gutterBottom>{qrcodes.filter((qrcode) => qrcode.pdfCard === undefined 
                                && qrcode.digitalCard === undefined).length}/{qrcodes.length} qrcodes lié à aucune cartes</Typography>
                            <div className={classes.chipsDisplay}>
                                {qrcodes.filter((qrcode) => qrcode.pdfCard === undefined 
                                && qrcode.digitalCard === undefined).map((qrcode) => 
                                <Chip 
                                    key={qrcode._id as string}
                                    style={{ margin: "10px"}}
                                    label={<div className={classes.chipRoot}>
                                        <Typography variant="body1" component="span" >{qrcode.name}</Typography>
                                        <FiberManualRecordIcon className={classes.FiberManualRecord} style={qrcode.activate ? colorGreen : colorRed} />
                                    </div>}
                                    variant="outlined"
                                />)}
                            </div>
                        </section>                
                    </article>
                    <div className={clsx(classes.title, classes.m_10_vertical)}>
                        <img src="https://ozebpm.stripocdn.email/content/guids/CABINET_71a23039df12c477589addf72ee2bc0f/images/18061612179653922.png" alt="qrcode" />
                        <Typography variant="subtitle1" component="span" align="center">Actions</Typography>
                    </div>
                    <Divider />
                    <article>
                        <Typography variant="body1" align="left" gutterBottom>Selectionner le qrcode pour lequel vous souhaitez effectuer une action</Typography>
                        <div className={classes.chipsDisplay}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="qrcodeSelect" name="qrcodeSelect" style={{ flexDirection: "row" }} value={value} onChange={handleChange}>
                                    {qrcodes.map((qrcode) => 
                                    <Paper 
                                        key={qrcode._id as string}
                                        elevation={5}
                                        style={{ padding: "0px 15px", borderRadius: "25px", width: "max-content", margin: "10px"}}>
                                        <FormControlLabel
                                            value={qrcode._id as string}
                                            control={<Radio key={qrcode._id as string} />}
                                            label={qrcode.name}
                                        />
                                    </Paper>)}
                                </RadioGroup>
                            </FormControl>                            
                        </div>
                        {selectedQrcode && 
                        (<div 
                            className={classes.m_10_vertical} 
                            style={{ display: "flex", flexDirection:"row", justifyContent: "center"}}>
                            <Button 
                                className={classes.m_10_vertical} 
                                style={{ marginRight: "10px"}} 
                                variant="contained" 
                                color="secondary"
                                onClick={() => {
                                    /* if (authContext.authObj.userProfile !== {} && !(authContext.authObj.userProfile as ITrader).authorized) {
                                        enqueueSnackbar("Vous n'avez pas encore souscrit à l'abonnement yumscan PDF", { variant: "warning" });
                                    } else */
                                    if (inputRef.current) {
                                        inputRef.current.click();
                                    }
                                }}
                                disabled={isRequesting || isLoading}>
                                {selectedQrcode.pdfCard || selectedQrcode.digitalCard ? "Mettre à jour" : "Télécharger"} votre carte
                            </Button>
                            {(selectedQrcode.pdfCard) &&
                            (<Button 
                                className={classes.m_10_vertical} 
                                color="primary" 
                                variant="contained" 
                                endIcon={<VisibilityIcon />}
                                onClick={() => {
                                    window.open(`${(selectedQrcode.pdfCard as IPdfCard).pdfPath}`, selectedQrcode.name);
                                }}
                                disabled={selectedQrcode.pdfCard === undefined || isRequesting || isLoading}>
                                Visualiser la carte
                            </Button>)}
                        </div>)}                   
                    </article>
                    <input style={{ display: "none" }} ref={inputRef} onChange={handleFileSelected} type="file" />
                </div>)}
                {stepper !== 0 && (
					<div className={classes.quickStart}>
						{stepper === 1 && (
						<React.Fragment>
							<Typography 
								component="h1" 
								variant="subtitle1" 
								gutterBottom>
								Etape 1 - { (qrcodes.length === 0 || !qrcodesLimitExceed ) ? "Créez un Qrcode" : "Sélectionnez un Qrcode" }
							</Typography>
							<div className={classes.StepQuickStart}>
								{!isLoading && 
								(qrcodes.length === 0 || !qrcodesLimitExceed) && 
								<QuickCreateQrCode
									onCreate={(qrcode) => {
										setStepper(2);
										setOpenSnack(true);
										setSnackData({
											severity: "success",
											msg: `le qrcode ${qrcode.name} a été créé.`
										});
										setQrcodeCreated(qrcode);
									}}
									onError={(error) => {
										setOpenSnack(true);
										setSnackData({
											severity: "error",
											msg: error.message
										});
									}}
								/>}
								{!isLoading && 
								(qrcodes.length > 0 && qrcodesLimitExceed) && 
								(<div style={{ display: "flex", flexDirection: "column"}}>
									<Typography 
										variant="body1" 
										component="span" 
										align="center" 
										gutterBottom>
										Sélectionnez le Qrcode pour lequel vous souhaitez lier ou modifier une carte
									</Typography>
									<div style={{ margin: "15px 0" }} className={classes.chipsDisplay}>
										<FormControl component="fieldset">
											<RadioGroup 
												aria-label="qrcodeSelect" 
												name="qrcodeSelect" 
												style={{ flexDirection: "row" }} 
												value={value} 
												onChange={handleChangeCreatePDF}>
												{qrcodes.map((qrcode) => 
													<Paper 
														key={qrcode._id as string}
														elevation={5}
														style={{ 
																padding: "0px 15px", 
																borderRadius: "25px", 
																width: "max-content", 
																margin: "10px"
														}}>
														<FormControlLabel
															value={qrcode._id as string}
															control={<Radio key={qrcode._id as string} />}
															label={qrcode.name}
														/>
													</Paper>)}
											</RadioGroup>
										</FormControl>                            
									</div>
								</div>)}
							</div>
						</React.Fragment>)}
						{stepper === 2 && (
						<React.Fragment>
							<Typography 
								component="h1" 
								variant="subtitle1" 
								gutterBottom>
								Etape 2 - Ajoutez une carte au Qrcode "<strong>{qrcodeCreated?.name}</strong>"
							</Typography>
							<div className={classes.StepQuickStart}>
								{isRequestingHome && <CircularProgress color="inherit" />}
								<Typography 
									component="span" 
									variant="body1"
									align="center"
									gutterBottom>
									Vous pouvez mettre une carte aux format <strong className={classes.highlightsentences}>PDF</strong>
								</Typography>
								<input 
									style={{ display: "none" }} 
									ref={inputRef} 
									onChange={handleFileSelectedCreatePDF} 
									type="file" />
								<div 
									style={{ 
										textAlign: "center", 
										margin: "3vw 0"
									}}>
									<Button 
										color="primary" 
										disabled={isRequestingHome} 
										onClick={() => {
											if (inputRef.current) {
												inputRef.current.click();
											}
										}}
										variant="contained">
										<Typography 
											component="span" 
											variant="subtitle1" >
											Cliquez {!pdfLink ? "pour télécharger" : "pour mettre à jour"} votre carte
										</Typography>*
									</Button>
								</div>
							</div>
						</React.Fragment>)}
						{stepper === 3 && (
						<React.Fragment>
							<Typography 
								component="h1" 
								variant="subtitle1" 
								gutterBottom>
								Etape 3 - Visualisez la carte mise sur votre Qrcode
							</Typography>
							<div className={classes.StepQuickStart}>
								<Typography 
									component="span" 
									variant="subtitle1" 
									style={{ margin: "2vh 0"}} 
									gutterBottom>
										Félicitation ! <span role="img" aria-label=":clap:">👏</span><br />
										votre carte est maintenant disponible à partir de votre Qrcode "<strong>{qrcodeCreated?.name}</strong>"
								</Typography>
								<div 
									style={{ 
										display: "flex", 
										flexDirection: "row", 
										justifyContent: "center", 
										alignItems: "center" 
									}}>
									<Button 
										style={{ marginRight: "1vw" }}
										color="primary"
										variant="contained"
										endIcon={<VisibilityIcon />}
										onClick={() => {
											if (homePdf) {
												let windowName = "votre carte";

												window.open(`${homePdf}`, windowName);
                                                //window.open(`${(qrcodeCreated?.pdfCard as IPdfCard).pdfPath}`, qrcodeCreated?.name);
											} else {
												setOpenSnack(true);
												setSnackData({
													severity: "error",
													msg: `Vous ne disposez d'aucun pdf`,
												});
											}
										}}>
										<Typography 
											component="span" 
											variant="body1" >
											Visualiser ma carte
										</Typography>
									</Button>
									<Button 
										style={{ marginRight: "1vw" }}
										color="secondary"
										variant="contained"
										onClick={() => {
											back();
										}}>
										<Typography 
											component="span" 
											variant="body1" >
											Gérer mes cartes
										</Typography>
									</Button>
								</div>
							</div>
						</React.Fragment>)
						}
					</div>
				)}
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openSnack && snackData !== undefined} autoHideDuration={6000} onClose={() => { setOpenSnack(false); setSnackData(undefined); }}>
					<Alert variant="filled" onClose={() => { setOpenSnack(false); setSnackData(undefined); }} severity={snackData?.severity}>
						{snackData?.title && (<AlertTitle>{snackData?.title}</AlertTitle>)}
						{snackData?.msg}
					</Alert>
				</Snackbar>
            </div>
            
		</Fragment>
	);
};

export default LinkCardManage;