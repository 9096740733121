import React, { Fragment, useEffect, useContext, useMemo } from "react";
import useStyles from "./styles";
import { ICard, ComposantMode, ICategory, ICategoryLink, CardItem, BaseItem, CARD_ITEM_TYPE, IDish, IMenu } from "../../../types";
import { CircularProgress, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField, Tooltip } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
import { CardServices, CategoryServices } from "../../../api";
import { APIManager } from "../../../services";
import arrayMove from "array-move";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import ManageDigitalCard from "../../../components/ManageDigitalCard/ManageDigitalCard";
import { store } from "../../../contexts/auth";
import { useSnackbar } from "notistack";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from "clsx";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ModalCreateCategory from "../../../components/ModalCreateCategory";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { MobileDetector } from "../../../utils";
import { YumButton } from "../../../Lib/UI/YumButton";
import UploadBanner from "../UploadBanner";
import CardTemplateHandlerModal from "../../../components/CardTemplateHandlerModal";
import CardReviewHandlerModal from "../../../components/CardReviewHandlerModal";
import CardAnnouncementHandlerModal from "../../../components/CardAnnouncementHandlerModal";


interface Props {
	card?: ICard;
	back: () => any;
	mode: ComposantMode;
}

enum STATE_PAGE_MANAGE_CATEGORIES {
	MANAGE_CATEGORIES,
	MANAGE_DISH_IN_CATEGORIE,
}

const Layout: React.FC<Props> = ({ card, mode, back }: Props) => {

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
    let authContext = useContext(store);
	
	const [currentCategories ,setCurrentCategories] = React.useState<ICategoryLink[] | undefined>(undefined);
	const [currentParentCategorie ,setCurrentParentCategorie] = React.useState<ICategoryLink | undefined>(undefined);
	//const [basicCategories ,setBasicCategories] = React.useState<ICategory[]>([]);
	const [
		//isRequestingCategories 
		,setIsRequestingCategories] = React.useState<boolean>(false);
	//const [isSubmited , setIsSubmitted] = React.useState<boolean>(false);
	const [selectedCategory, setSelectedCategory] = React.useState<ICategoryLink | undefined>(undefined);
	const [pageState, setPageState] = React.useState<STATE_PAGE_MANAGE_CATEGORIES>(STATE_PAGE_MANAGE_CATEGORIES.MANAGE_CATEGORIES);
	const [currentCard, setCurrentCard] = React.useState<ICard | undefined>(undefined)
	const [sort, setSort] = React.useState<boolean>(false);
	
	const [
		//totalItems
		, setTotalItems] = React.useState<number | undefined>(0);

	const getAsyncCard =  async() => {
		try {
			if (!card?._id) {
				return;
			}
			let oneCard = await APIManager.getCardServices().GetOneCard(card?._id as string);

			setCurrentCard(oneCard);
			setTotalItems(oneCard.items.length);
		} catch (err) {

		}
	}

	useEffect(() => {
		if (currentCard) {
			return;
		}

		getAsyncCard();
	}, [])

	const SortableListItem = SortableElement(({value, openModal}: {value: ICategoryLink, openModal: () => void}) => <CategoryItem value={value} openModal={openModal} createSubCategory={false} />);
	
	function CategoryItem({value, createSubCategory, openModal}: {value: ICategoryLink, subCategory?: ICategory, createSubCategory: boolean, openModal: () => void}) {

		const [isDeletingCategory, setIsDeletingCategory] = React.useState<boolean>(false);
		const [isOpenDialogDeleteCategoryItem, setIsOpenDialogDeleteCategoryItem] = React.useState<boolean>(false);
		// Etat d'edition pour une composant
		const [isEditingCategory, setIsEditingCategory] = React.useState<boolean>(false);
		// Etat d'édition pour une sous-categorie
		const [editedValue, setEditedValue] = React.useState<{ name: string, description?: string}>({ name: value.name, description: value.description});
		const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
		/*const deleteCategoryItem = async () => {
			
			setIsDeletingCategory(true);
			try {
				await APIManager.getCardServices().deleteCategorieFromCard(card?._id as string, value._id as string);

				getCategoriesUsed();
				enqueueSnackbar(`Les éléments de la catégorie "${value.name}" ont été supprimés.`, { 
					variant: "success",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});

			} catch (except) {
				enqueueSnackbar(`Les éléments de la catégorie "${value.name}" n'ont pa pu supprimés.`, { 
					variant: "error",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
			}
			setIsDeletingCategory(false);
		}*/

		const updateCategory = async () => { 
			setIsRequesting(true);
			try {
				if (editedValue.description === "") {
					editedValue.description = " ";
				}

				await APIManager.getCategoryServices().updateDescriptionAndName(value._id as string, editedValue.name, editedValue.description)

				getCategoriesUsed();
				enqueueSnackbar(`Les éléments de la catégorie "${value.name}" ont mis a jour.`, { 
					variant: "success",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});

			} catch (except) {
				enqueueSnackbar(`Les éléments de la catégorie "${value.name}" n'ont pa pu être mis a jour.`, { 
					variant: "warning",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
			} finally {
				setIsRequesting(false);
				setIsEditingCategory(false);
			}
		}

		const CheckItemInCategory = useMemo(() => {
			let filteredItems = currentCard?.items.filter((item:CardItem) => {
				let itemInCard: BaseItem | undefined = undefined;

				if (item.type === CARD_ITEM_TYPE.DISH && item.dishes) {
					itemInCard = item.dishes as IDish;
				} else if (item.type === CARD_ITEM_TYPE.MENU && item.menus){
					itemInCard = item.menus  as IMenu;
				}
				if (!itemInCard){
					return false;
				}
				return (itemInCard.category as ICategoryLink)._id === value._id;
			});

			return filteredItems;
		}, [currentCard]);

		//const [isDeletingCategory, setIsDeletingCategory] = React.useState<boolean>(false);
		//const [isOpenDialogDeleteCategoryItem, setIsOpenDialogDeleteCategoryItem] = React.useState<boolean>(false);

		const deleteCategoryItem = async () => {
			let apiCard: CardServices = APIManager.getCardServices();
			
			setIsDeletingCategory(true);
			try {
				let resultCardDelete = await apiCard.deleteCategorieFromCard(card?._id as string, value._id as string);

				getCategoriesUsed();
				enqueueSnackbar(`Les éléments de la catégorie "${value.name}" ont été supprimés.`, { 
					variant: "success",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});

			} catch (except) {
				enqueueSnackbar(`Les éléments de la catégorie "${value.name}" n'ont pa pu supprimés.`, { 
					variant: "error",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
			}
			setIsDeletingCategory(false);
		}

		return (<Paper 
			elevation={4}
			key={`${value._id}_key`}
			component={sort ? "li" : "div"}
			style={{ 
				padding: "10px",
				margin: "5px 0px",
				display: "flex", 
				flexDirection: "column", 
				justifyContent: "center", 
				alignItems: "center",
				width: "100%",
				border: createSubCategory && value.subCategory ? "1px solid black" : undefined,
				backgroundColor: createSubCategory && value.subCategory ? "#F5F6FA" : undefined,
			}}>
			<Grid
				container
				style={{ 
					width: "100%",
					display: "flex", 
					flexDirection: "row", 
					justifyContent: "space-around", 
					alignItems: "center",
					zIndex: 1000,
				}}>
				{/*<Button
					onClick={(event) => {
						console.log("value :", (value as ICategory));
						setSelectedCategory(value as ICategory);
						setPageState(STATE_PAGE_MANAGE_CATEGORIES.MANAGE_DISH_IN_CATEGORIE);
					}}
					className={classes.button}
					color="secondary"
					variant="contained"
					size="small">
					<Typography component="span" >Editer</Typography>
				</Button>*/}
				<Grid
					item 
					component='div'
					style={{ display:"flex", flexDirection: isEditingCategory ? "row" : "column", justifyContent: "center", alignItems: isEditingCategory  ? "baseline" : "center"}}
					xs={10} 
					sm={7} 
					md={7}>
					{!isRequesting && !isEditingCategory && <Typography variant="subtitle1" component="span" onDoubleClick={() => {
						setIsEditingCategory(true);
					}}>{value.name}</Typography>}
					{!isRequesting && isEditingCategory && 
					<TextField 
						style={{ margin: "0 1vw" }}
						disabled={isRequesting} 
						value={editedValue.name} 
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setEditedValue({...editedValue , name: event.target.value as string});
						}} 
					/>}
					{!isRequesting && !isEditingCategory && <Typography variant="subtitle2" component="span" onDoubleClick={() => {
						setIsEditingCategory(true);
					}}>{value.description}</Typography>}
					{!isRequesting && isEditingCategory && 
					<TextField 
						style={{ margin: "0 1vw" }} 
						label={ value.description ? undefined : "description" }
						disabled={isRequesting}
						value={editedValue.description}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setEditedValue({...editedValue , description: event.target.value as string});
						}} 
					/>}
					{!isRequesting && isEditingCategory &&
					<div style={{ height: "100%", display:"flex", justifyContent: "center", alignItems: "center" }}>
						<Tooltip title="Modifier la catégorie">
							<CheckCircleOutlineIcon
								style={{ marginLeft: "1vw"}} 
								onClick={() => {
									updateCategory();
								}}
							/>
						</Tooltip>
					</div>}
					{isRequesting && 
					<div style={{ height: "100%", display:"flex", justifyContent: "center", alignItems: "center"}}>
						<CircularProgress />
					</div>}
				</Grid>
					
				{/*!value.subCategories &&
				<Grid
					item 
					component='div'
					xs={2} 
					sm={2} 
					md={2}
					style={{ maxWidth: "70%", display:"flex", flexDirection: "row", alignItems: "baseline", justifyContent: "center"}}>
					{value.subCategories === undefined && <Typography component="span" style={{ margin: "0 5px" }}>{currentCard?.items && (currentCard?.items as CardItem[]).filter((item: CardItem) => {
						let baseItem: BaseItem | undefined = undefined; 
						if (item.type === CARD_ITEM_TYPE.DISH) {
							baseItem = item.dishes as IDish;
						} else {
							baseItem = item.menus as IMenu;
						}
						return (((baseItem?.category as ICategoryLink).category as ICategory)._id as string) === value.category._id
					})?.length}/{totalItems}</Typography>}
				</Grid>*/}
				<Grid
					item 
					component='div'
					style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}
					xs={12} 
					sm={3} 
					md={3}>
					{createSubCategory && (!CheckItemInCategory || CheckItemInCategory.length === 0) &&
					<div style={{ width: "100%" }}>
						<Tooltip title="Créer une sous-catégorie">
							<Button
								color="secondary"
								variant="contained"
								size="small"
								onClick={() => {
									setCurrentParentCategorie(value);
									openModal();
								}}
								className={clsx(classes.YumscanButton, classes.secondaryColor, classes.bouttonWidthICON)}>
								<CreateNewFolderIcon  className={classes.bouttonHOCContent}/>
							</Button>
						</Tooltip>
					</div>}
					{!value.subCategory || value.subCategory.length === 0 && 
					<React.Fragment>
					<div style={{ width: "100%" }}>
						<Tooltip title={`Editer la categorie "${value.name}"`}>
							<Button
								color="secondary"
								variant="contained"
								size="small"
								className={clsx(classes.YumscanButton, classes.secondaryColor, classes.bouttonWidthICON)}
								onClick={(event) => {
									setSelectedCategory(value);
									setPageState(STATE_PAGE_MANAGE_CATEGORIES.MANAGE_DISH_IN_CATEGORIE);
								}}>
								<EditIcon className={classes.bouttonHOCContent} />
							</Button>
						</Tooltip>
					</div>
					<div style={{ width: "100%" }}>
						<Tooltip title={`supprimer la categorie "${value.name}"`}>
							<Button
								color="primary"
								variant="contained"
								size="small"
								disabled={isDeletingCategory}
								className={clsx(classes.YumscanButton, classes.primaryColor, classes.bouttonWidthICON)}
								onClick={(event) => {
									setIsOpenDialogDeleteCategoryItem(true);
								}}>
								<DeleteForeverIcon className={classes.bouttonHOCContent}/>
							</Button>
						</Tooltip>
					</div>
					</React.Fragment>}
				</Grid>
				{value.subCategory && (value.subCategory as ICategoryLink[]).map((subCategory) => (					
						<Grid
							key={(subCategory as ICategoryLink)._id as string} 
							item 
							component='div'
							style={{ display:"flex", flexDirection:"column", justifyContent: "center", alignItems: "center"}}
							xs={12} 
							sm={12} 
							md={12}>
							<CategoryItem 
								value={(subCategory as ICategoryLink)} 
								createSubCategory={false} 
								openModal={() => {}}/>
						</Grid>	
					))}
					{/*(value.subCategory as ICategoryLink[]).map((subCategory) => (					
							<CategoryItem 
								key={(subCategory as ICategoryLink)._id as string} 
								value={(subCategory as ICategoryLink)} 
								createSubCategory={false} 
								openModal={() => {}}/>
					))*/}
				{/*value.subCategory && console.log("subcategory maguel : ", value.subCategory)*/}		
			</Grid>
			<Dialog
				open={isOpenDialogDeleteCategoryItem}
				onClose={() => { setIsOpenDialogDeleteCategoryItem(false) }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography
						variant="subtitle2"
						component="span"
						gutterBottom>
						<strong>Suppréssion définitive</strong> de votre catégorie "{value.name}"
					</Typography>
					</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<Typography
						variant="body1"
						component="span"
						gutterBottom>En cliquant sur <strong className={classes.highlight}>"Supprimer"</strong>, vous supprimerais <strong className={classes.highlight}>définitivement</strong> l'ensemble des plats contenu dans la catgéorie <strong className={classes.highlight}>{value.name}</strong> de votre carte. Etes-vous sûre ?</Typography>
				</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{ display: "flex", flexDirection:"row", flexWrap: "wrap"}}>
					<YumButton 
						onClick={() => { setIsOpenDialogDeleteCategoryItem(false) }}>
						Annuler
					</YumButton>
					<YumButton 
						onClick={() => {
							deleteCategoryItem();
							setIsOpenDialogDeleteCategoryItem(false); 
						}} 
						autoFocus>
						Supprimer
					</YumButton>
				</DialogActions>
			</Dialog>
		</Paper>);
	}

	const SortableListContainer = SortableContainer(({children}: any) => {
		return <ul style={{ width: "100%", }}>{children}</ul>;
	});
		
	const onSortEnd = ({oldIndex, newIndex}: any) => {
		if (!currentCategories) {
			return;
		}

		let newOrder = arrayMove(currentCategories, oldIndex, newIndex);
		

		APIManager.getCardServices().setCategoriesOrder(newOrder.map(x => x._id as string), currentCard?._id as string).then((result) => {
			if (result) {
				enqueueSnackbar(`L'odre de la carte ${currentCard?.name} à été mis a à jour.`, { 
					variant: "success",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
				setCurrentCategories(newOrder);
			} else {
				enqueueSnackbar(`La gestion d'ordre des catégories a echoué conflict`, { 
					variant: "warning",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
			}
		}).catch((err) => {
			console.log(err);
			enqueueSnackbar(`une erreur inconnue est survenue. Si le problème persiste contactez le service client.`, { 
				variant: "error",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});			
		})
	};
	
	/**
	 * Recupère l'ensemble des catégories basique d'un utilisateur.
	 */
	const getCategoriesUsed = async () =>  {
		setIsRequestingCategories(true);

		try {
			let apiCategory: CategoryServices = APIManager.getCategoryServices();
			//let apiCard: CardServices = APIManager.getCardServices();
			//let basicsCategories = await apiCategory.GetAllCategories();

			//setBasicCategories([...basicsCategories]);
			if (card?._id && mode === ComposantMode.Update) {
				let categories = await apiCategory.GetCategoriesFromCard(card._id as string);
				//categories = _.unionBy(basicsCategories, cardCategories, '_id');
				// Retire les doublons de catégories du tableau lors du merge par _id
				setCurrentCategories([...categories]);

			} else {
				setCurrentCategories([]);
			}		
		} catch (err) {
			console.log("Erreur de chargement des catgéories");
			setCurrentCategories([]);
		} finally {
			setIsRequestingCategories(false);
		}
	}

	useEffect(() => {
		if (currentCategories && currentCategories.length !== 0 || authContext.profileState === {}) {
			return;
		}
		getCategoriesUsed();
	}, [pageState]);	

	/**
	 * Methode actualisant l'ensemble des donnees
	 */
	const actualize = () => {
		getAsyncCard();
		//getCategoriesUsed();
	}

	return (
	<Fragment>
		<CssBaseline />
		<div onClick={() => {
			switch(pageState) {
				case STATE_PAGE_MANAGE_CATEGORIES.MANAGE_DISH_IN_CATEGORIE:
					actualize();
					setPageState(STATE_PAGE_MANAGE_CATEGORIES.MANAGE_CATEGORIES);
					break;
				case STATE_PAGE_MANAGE_CATEGORIES.MANAGE_CATEGORIES:
				default:
					back();
					break;
			}
		}} className={clsx(classes.backArrow,classes.makeLink)} >
			<ArrowBackIcon style={{ width: "20px", height: "20px", margin: "0 1vw" }}/>
			<Typography align="left" component="span" variant="body2" gutterBottom>Retour</Typography>
		</div>
		<DynamicComponent />
	</Fragment>);

	function Home(props: any) {

		/*const createCategorie = (categoryName: string) => {
			if (currentCategories.find((x) => x.name.toLowerCase() === categoryName.toLowerCase())) {
				enqueueSnackbar(`La catégorie "${categoryName}" existe déjà`, { 
					variant: "info",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
				return;
			}

			APIManager.getCategoryServices().CreateUserCategory(categoryName).then((newCategories) => {
				setCurrentCategories([...currentCategories, newCategories]);
				enqueueSnackbar(`La catégorie "${categoryName}" a été créée`, { 
					variant: "success",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
			}).catch((err) => {
				enqueueSnackbar(`La catégorie "${categoryName}" n'a pas pu être crée`, { 
					variant: "error",
					anchorOrigin: {
						vertical: 'top',
						horizontal:'right',
					}
				});
			});
		}

		const selectCategorie = (category: ICategory) => {
			// Empêche les doublons dans la catégories courrantes
			let NoDoublecategories = _.unionBy(currentCategories, [category], '_id');

			setCurrentCategories([...NoDoublecategories]);
			enqueueSnackbar(`La catégorie "${category.name}" a été ajouté a votre carte`, { 
				variant: "success",
				anchorOrigin: {
					vertical: 'top',
					horizontal:'right',
				}
			});

		}*/
		const [isOpenModalCategory, setIsOpenModalCategory] = React.useState<boolean>(false);
		const [isOpenModalTemplateHandler, setIsOpenModalTemplateHandler] = React.useState<boolean>(false);
		const [isOpenModalReviewHandler, setIsOpenModalReviewHandler] = React.useState<boolean>(false);
		const [isOpenModalAnnounceHandler, setIsOpenModalAnnounceHandler] = React.useState<boolean>(false);

		return (
			<Fragment>
				{/*<Typography 
					variant="h4" 
					component="span"
					style={{ width: "100%" }}
					align="center"
					gutterBottom>
					Ajoutez des catégories à votre carte
				</Typography>
				<Grid style={{ margin: "1vh 0 3vh 0" }} container spacing={2} alignItems="center" justify="center" >
					<Grid 
						item 
						component='div'
						style={{ display:"flex", justifyContent: "center", alignItems: "center"}}
						xs={12} 
						sm={12} 
						md={12}>
						<ComboBox 
							categories={basicCategories}
							onCreate={createCategorie}
							onSelect={selectCategorie}/>
					</Grid>
				</Grid>*/}

				

					{/*<Button
						style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "15px" }}
						variant="contained" 
						color="secondary"
						>
						<Typography 
							component="span" 
							variant="h6" 
							style={{ marginRight: "5px" }}>Créer une catégorie</Typography>
						<AddIcon />
					</Button>*/}
					{((MobileDetector.iOS() !== null) || (MobileDetector.Android() !== null)) && (
						<div style={{ display: "flex", flexDirection: "column", width: "100%"}}>

							<CardAnnouncementHandlerModal 
								idCard={currentCard?._id as string} 
								onCreateCallback={setCurrentCard} 
								isOpen={isOpenModalAnnounceHandler} 
								callback={setIsOpenModalAnnounceHandler} />

							<CardReviewHandlerModal 
								idCard={currentCard?._id as string} 
								onCreateCallback={setCurrentCard} 
								isOpen={isOpenModalReviewHandler} 
								callback={setIsOpenModalReviewHandler} />

							<CardTemplateHandlerModal 
								idCard={currentCard?._id as string} 
								onCreateCallback={setCurrentCard} 
								isOpen={isOpenModalTemplateHandler} 
								callback={setIsOpenModalTemplateHandler} />

							<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginBottom:"10px"}}>
								{<ModalCreateCategory 
									idCard={currentCard?._id as string}
									parentCategory={currentParentCategorie}
									isOpen={isOpenModalCategory}
									callback={(val) => {
										setCurrentParentCategorie(undefined);
										setIsOpenModalCategory(val);
									}}
									onCreateCallback={(newCategories, parent) => {
										if (!parent && currentCategories) {
											// on ajoute au catégories déja contenu.
											setCurrentCategories([...currentCategories, newCategories])
										} else if (currentCategories){
											let getParentCategory = currentCategories.filter(x => x._id !== parent);

											setCurrentCategories([...getParentCategory, newCategories])
											
											//(getParentCategory?.subCategory as ICategoryLink[])?.push(newCategories as ICategoryLink);
										}
									}} />
								}
							</div>

							<YumButton
								onClick={() => { setSort(!sort); }}
								style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", marginBottom:"10px" }}>
								<Typography
									component="span" 
									variant="body1" 
									style={{ marginRight: "5px" }}>Trier</Typography>
								{sort && <LockOpenIcon />}
								{!sort && <LockIcon />}
							</YumButton>
						</div>
					)}

					{((MobileDetector.iOS() === null) && (MobileDetector.Android() === null)) && (
						<div style={{ display: "flex", flexDirection: "row", width: "100%"}}>

							<CardAnnouncementHandlerModal 
								idCard={currentCard?._id as string} 
								onCreateCallback={setCurrentCard} 
								isOpen={isOpenModalAnnounceHandler} 
								callback={setIsOpenModalAnnounceHandler} />

							<CardReviewHandlerModal 
								idCard={currentCard?._id as string} 
								onCreateCallback={setCurrentCard} 
								isOpen={isOpenModalReviewHandler} 
								callback={setIsOpenModalReviewHandler} />

							<CardTemplateHandlerModal 
								idCard={currentCard?._id as string} 
								onCreateCallback={setCurrentCard} 
								isOpen={isOpenModalTemplateHandler} 
								callback={setIsOpenModalTemplateHandler} />

							{<ModalCreateCategory 
								idCard={currentCard?._id as string}
								parentCategory={currentParentCategorie}
								isOpen={isOpenModalCategory}
								callback={(val) => {
									setCurrentParentCategorie(undefined);
									setIsOpenModalCategory(val);
								}}
								onCreateCallback={(newCategories, parent) => {
									if (!parent && currentCategories) {
										// on ajoute au catégories déja contenu.
										setCurrentCategories([...currentCategories, newCategories])
									} else if (currentCategories){
										let getParentCategory = currentCategories.filter(x => x._id !== parent);
		
										setCurrentCategories([...getParentCategory, newCategories])
										
										//(getParentCategory?.subCategory as ICategoryLink[])?.push(newCategories as ICategoryLink);
									}
								}} />}

							<YumButton
								onClick={() => { setSort(!sort); }}
								style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
								>
								<Typography
									component="span" 
									variant="body1" 
									style={{ marginRight: "5px" }}>Trier</Typography>
								{sort && <LockOpenIcon />}
								{!sort && <LockIcon />}
							
							</YumButton>
						</div>
					)}
				<div
					className={classes.bannerDiv}>
					{currentCard && <UploadBanner card={currentCard} callback={() => { getAsyncCard()}} />}
				</div>
				<Typography 
					variant="subtitle1" 
					component="span"
					style={{ width: "100%", textDecoration: "underline" }}
					align="center"
					gutterBottom>
					Catégories de la carte 
					<Typography 
						className={classes.highlight} 
						component="span"
						variant="h6"
						>"{card?.name}"</Typography>
				</Typography>	
				{sort && <SortableListContainer onSortEnd={onSortEnd}>
					{currentCategories && currentCategories.map((value, index) => (
					<SortableListItem key={value._id as string} index={index} value={value} openModal={() => {
						setIsOpenModalCategory(true);
					}}/>
					))}
				</SortableListContainer>}
				{!sort && <div style={{ display: "flex", flexDirection: "column", width: "100%"}}>
					{currentCategories && currentCategories.map((value, index) => (
					<CategoryItem key={value._id as string} value={value} createSubCategory={true} openModal={() => {
						setIsOpenModalCategory(true);
					}} />
					))}
				</div>}
				{currentCategories && currentCategories.length <= 0 && 
				<Typography variant="body2" 
					component="span"
					style={{ width: "100%" }}
					align="center"
					gutterBottom>La carte "<Typography className={classes.highlight} component="span" variant="h6"  >{card?.name}</Typography>" ne possède aucune catégorie.</Typography>}
				{!currentCategories && <CircularProgress />}
				{/*<div style={{ padding: "1vh 10vw", width: "100%", margin: "1vh 0", display: "flex", flexDirection: "row", alignItems: "center" }}>
					<div
						style={{
							width: "100%",
							padding: "0vh 0"
						}}><Divider /></div>
					<Typography
						variant="h4" 
						component="span"
						style={{ width: "100%" }}
						align="center"
						gutterBottom>OU</Typography>
					<div
						style={{
							width: "100%",
							padding: "0vh 0",
						}}><Divider /></div>
				</div>
				
				<Formik
					initialValues={{ 
						categoryName: "",
					}}
					validateOnChange={false}
					onSubmit={(values, { setSubmitting }) => {
						
						if (currentCategories.find((x) => x.name.toLowerCase() === values.categoryName.toLowerCase())) {
							enqueueSnackbar(`La catégorie "${values.categoryName}" existe déjà`, { 
								variant: "success",
								anchorOrigin: {
									vertical: 'top',
									horizontal:'right',
								}
							});
							return;
						}

						APIManager.getCategoryServices().CreateUserCategory(values.categoryName).then((newCategories) => {
							
							setCurrentCategories([...currentCategories, newCategories]);
							enqueueSnackbar(`La catégorie "${values.categoryName}" a été créée`, { 
								variant: "success",
								anchorOrigin: {
									vertical: 'top',
									horizontal:'right',
								}
							});
						}).catch((err) => {
							enqueueSnackbar(`La catégorie "${values.categoryName}" n'a pas pu être crée`, { 
								variant: "error",
								anchorOrigin: {
									vertical: 'top',
									horizontal:'right',
								}
							});
						});
					}}
					validationSchema={Yup.object().shape({
						categoryName: Yup.string().min(1).required("Champ obligatoire"),
					})}
					>
					{props => {
						const {
						values,
						errors,
						handleChange,
						handleBlur,
						handleSubmit,
						} = props;
						return (
						<form style={{ display: "flex", flexDirection: "row", alignItems:"center", width: "100%"}} onSubmit={handleSubmit}>
							<Grid container spacing={2} alignItems="center" justify="center" >
								<Grid 
									item 
									component='div'
									xs={12} 
									sm={8} 
									md={8}>
									<TextField
										variant="outlined"
										margin="normal"
										id="categoryName"
										type="text"
										label="Nom de votre catégorie"
										name="categoryName"
										placeholder="Pizza🍕, Bento🍱"
										value={values.categoryName}
										onChange={handleChange}
										onBlur={handleBlur}
										error={isSubmited && errors.categoryName ? true : false}
										helperText={isSubmited ? errors.categoryName : undefined}
										required
										fullWidth
									/>
								</Grid>
								<Grid 
									item 
									component='div'
									xs={12} 
									sm={4} 
									md={2}>
									<Button  
										type="submit" 
										variant="contained" 
										color="secondary"
										fullWidth>
										Ajouter une catégorie
									</Button>
								</Grid>
							</Grid>					
						</form>
						);
					}}
				</Formik>*/}
			</Fragment>
		);
	}

	function DynamicComponent(props: any) {

		switch(pageState) {
            case STATE_PAGE_MANAGE_CATEGORIES.MANAGE_DISH_IN_CATEGORIE:
                return <Fragment>
					{selectedCategory && <ManageDigitalCard card={card as ICard} cardCategories={currentCategories ? currentCategories : []} category={selectedCategory} back={() => {

					}} />}
				</Fragment>
            case STATE_PAGE_MANAGE_CATEGORIES.MANAGE_CATEGORIES:
            default:
                return <Home />;
        }
	}
};

export default Layout;
