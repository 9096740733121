import React from "react";
import Layout from "./Layout";
import { AppState, UserState } from "../../types";
import { connect } from "react-redux";

interface Props {
	userdata: UserState;
}

const CreateAdvancedMenu: React.FC<Props> = ({ userdata }: Props) => <Layout userData={userdata} />;

export default connect(
	(state: AppState) => ({
		userdata: state.user,
	}),
	null
)(CreateAdvancedMenu);
