import React from "react";

import {
	Link,
	Typography,
	Box,
	Grid,
	Container,
} from "@material-ui/core";
import useStyles from "./styles";
import { NavLink } from "react-router-dom";

/* const getCurrentDomaineName = () => {
	return window.location.hostname;
} */

const footers = [
	{
		title: 'Entreprise',
		description: [{ title: "yumscan, c'est quoi ?", link: `/#display-whatisit` }, { title: 'Contact', link: `/#contact-landing` }],
	},
	/*{
		title: 'Features',
		description: [{ title: 'Carte YumPDF', link: "#" }],
	},*/
	{
		title: 'Ressources',
		description: [{ title: 'Offres', link: `/#display-offres` }, { title: 'FAQ', link: `/#faq-questions` }],
	},
	{
		title: 'Légal',
		description: [
			/* { title: 'Conditions générales de vente', link: `/legal/condition-general-vente` }, 
			{ title: 'Conditions générales d\'utilisation ', link: `/legal/condition-general-utilisation` },  */
			{ title: 'Charte de confidentialité ', link: `/legal/privacy-policy` }],
	},
];

interface Props {
}

function Copyright() {
	
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'©'}
			<Link color="inherit" href="/">
				YUMSCAN
        </Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const Layout: React.FC<Props> = (props: Props) => {

	const classes = useStyles();
	// Formulaire

	

	return (
		<div className={classes.root}>
			{/* Footer */}
			<Container maxWidth="lg" component="footer" className={classes.footer}>
				<Grid container spacing={4} justify="space-evenly">
					{footers.map((footer) => (
						<Grid className={classes.titleGrid} item xs={12} sm={12} md={3} key={footer.title}>
							<Typography variant="h6" color="textPrimary" gutterBottom>
								{footer.title}
							</Typography>
							<ul>
								{footer.description.map((item) => (
									<li key={item.title}>
										<NavLink className={classes.navLink} to={item.link}>
											<Typography variant="subtitle1" color="textSecondary" gutterBottom>
												{item.title}
											</Typography>
										</NavLink>
									</li>
								))}
							</ul>
						</Grid>
					))}
				</Grid>
				<Box mt={5}>
					<Copyright />
				</Box>
			</Container>
			{/* End footer */}
		</div>
	);
}

export default Layout;

//export default Layout;
